import React, { Fragment, useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import { Tabs, TabHooks, PanelHooks } from '../../utils/tabs/tabs';
import { newIcons, colours } from '../../utils/get-icons/icons';
import { updateHotspots, deleteHotspot, updateHotspotsDestination } from '../../../api-helper/api-hotspots';
import { ReactComponent as CloseButton } from '../../../images/viewer-icons/cross.svg';
import greenTick from '../../../images/viewer-icons/green-tick.svg';
import whiteTick from '../../../images/viewer-icons/white-tick-no-circle.svg';
import imageIcon from '../../../images/viewer-icons/edit-hotspot-empty.svg';
import hotspotIcon from '../../../images/viewer-icons/hotspot.svg';
import 'font-awesome/css/font-awesome.min.css';
import './edit-hotspot.scss';
import './modals.scss';
import { EnumHotspotsSize } from '../interaces';
import { Viewer } from '@vva/pannellum-viewer';
import { IHotspot } from '@vva/pannellum-viewer/lib/interfaces/IHotspot';
import { customHotspotRoomFunc } from '@vva/pannellum-viewer';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { IPanorama } from '../../../api-helper/interface/interfaces';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import { updateTourGeneralConfig } from '../../../api-helper/api-tours';
import { IVTConfig } from '../../main/settings/settings';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../../utils/notifications/notification';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';

interface INewReturnLink {
  srcId?: string;
  destId?: string;
  phi?: number;
  theta?: number;
  attributes?: { version: number };
}

interface IProps {
  currentPanorama: IPanorama;
  changeLeftSidebar: (option: string) => void;
  panoramaData: IPanorama[];
  selectedHotspot: any;
  toggleSuccessNotification: (message: string) => void;
  getHotspotList: (deleteHotspot?: string) => void;
  updatePanoramaList: () => void;
}

// Viewer object
let miniViewer: any;

const EditHotspot: React.FC<IProps> = ({
  currentPanorama,
  changeLeftSidebar,
  panoramaData,
  selectedHotspot,
  toggleSuccessNotification,
  getHotspotList,
  updatePanoramaList,
}) => {
  const { handleLogoutState } = useAuth();
  const [hexCode, setHexCode] = useState<string>('');
  const [isHexCodeValid, setIsHexCodeValid] = useState<boolean>(false);
  const [newDest, setNewDest] = useState<any>({});
  const [filteredPanoData, setFilteredPanoData] = useState<any>();
  const [selectedIcon, setSelectedIcon] = useState<any>();
  const [selectedColour, setSelectedColour] = useState<any>();
  const [iconSize, setIconSize] = useState<string>('');
  const [selectedMiniViewerPanorama, setSelectedMiniViewerPanorama] = useState<string[]>();
  const [miniViewerLoaded, setMiniViewerLoaded] = useState<boolean>(false);
  const [newReturnLink, setNewReturnLink] = useState<INewReturnLink>();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  // Hotspot icons
  const listIcons = newIcons;

  useEffect(() => {
    // Enable new hotspot when viewer loads
    if (miniViewerLoaded) {
      if (miniViewer) {
        miniViewer.setOnDoubleClick(setStartingPoint, true);
      }
    }
  }, [miniViewerLoaded]);

  const setStartingPoint = (coordinates: number[]) => {
    miniViewer.removeCurrentHotspot(3600);
    //Args
    const args: any = {
      iconColor: '#FFFFFF',
      iconSize: '18px',
      backgroundColor: '#48C9B0',
      iconType: 'fa-angle-double-up',
      roomName: currentPanorama.name,
      src: currentPanorama.equiPreviewUrl,
    };
    //Hotspot object
    const hotspot: IHotspot = {
      pitch: coordinates[0],
      yaw: coordinates[1],
      type: 'info',
      id: 3600,
      hotspotLabel: 'link',
      cssClass: 'custom-hotspot-room',
      createTooltipFunc: customHotspotRoomFunc,
      createTooltipArgs: args,
    };

    // Add hotspot to viewer
    setNewReturnLink({
      srcId: newDest.id,
      destId: currentPanorama.id,
      phi: coordinates[0],
      theta: coordinates[1],
      attributes: { version: 1 },
    });

    miniViewer.addNewHotspot(hotspot);
  };

  useEffect(() => {
    if (panoramaData && selectedHotspot) {
      const filteredPanos = panoramaData.filter((pano: any) => pano.id !== selectedHotspot.srcId && pano.id !== selectedHotspot.destId);
      setFilteredPanoData(filteredPanos);
    }
  }, [selectedHotspot, panoramaData]);

  useEffect(() => {
    // Load current hotspot icon/size
    if (selectedHotspot) {
      const args = selectedHotspot.createTooltipArgs;
      setIconSize(args.iconSize);
      setSelectedColour(args.iconColor);
      const findIcon = listIcons.find(icon => icon.icon === args.iconType);
      if (findIcon) {
        setSelectedIcon(findIcon);
      }
    }
  }, [selectedHotspot]);

  const validateHex = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    setHexCode(e.target.value);
    let validPattern = new RegExp(/^#[0-9a-f]{3}(?:[0-9a-f]{3})?$/i);

    if (validPattern.test(inputValue)) {
      setHexCode(e.target.value);
      setIsHexCodeValid(true);
    } else {
      setIsHexCodeValid(false);
    }
  };

  useEffect(() => {
    // componentDidMount - Initialize Viewer
    if (selectedMiniViewerPanorama) {
      initViewer();
    }

    // componentWillUnmount - Destroy Viewer
    return () => {
      miniViewer && miniViewer.destroyViewer();
    };
  }, [selectedMiniViewerPanorama]);

  const initViewer = () => {
    // code to run on component mount
    const config: any = {
      type: 'cubemap',
      cubeMap: selectedMiniViewerPanorama,
      autoLoad: true,
      northOffset: 247.5,
      showControls: false,
      crossOrigin: 'anonymous',
      doubleClickZoom: false,
      hfov: 120,
    };
    miniViewer = new Viewer('mini-viewer', config);

    miniViewer.setOnLoad(stopLoading);
  };

  const stopLoading = () => {
    setMiniViewerLoaded(true);
  };

  const resetAllValues = () => {
    setIconSize('');
    setSelectedIcon('');
    setSelectedColour('');
    setHexCode('');
  };

  const toggleNotification = (type: EnumNotificationType, message?: string) => {
    setTypeNotification(type);
    setNotificationMessage(message || linguiTranslateToString('There was an error please try again'));
    setShowNotification(true);
  };

  const updateHotspotDestination = async () => {
    ReportCustomEvent(EnumCategory.EditLink, EnumEvent.Changedestination);
    toggleNotification(EnumNotificationType.Success, 'Link destination has been changed! Continue editing your link!');
    const newPanoramaData = {
      name: null,
      image: 'target',
      srcId: (newReturnLink && newReturnLink.srcId) || 0,
      destId: newDest.id,
      type: 0,
      phi: (newReturnLink && newReturnLink.phi) || 0,
      theta: (newReturnLink && newReturnLink.theta) || 0,
      attributes: { version: 1 },
    };
    try {
      await updateHotspotsDestination(selectedHotspot.id, newPanoramaData);
      getHotspotList(selectedHotspot.id);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const deleteSelectedHotspot = async () => {
    try {
      await deleteHotspot(selectedHotspot.id);
      changeLeftSidebar('');
      toggleSuccessNotification('Your link has been deleted.');
      setDeleteModal(false);
      getHotspotList(selectedHotspot.id);
      updatePanoramaList();
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const updateHotspotIcon = async () => {
    ReportCustomEvent(EnumCategory.EditLink, EnumEvent.ChangeIcon);
    try {
      const newHotspotData: any = {
        id: selectedHotspot.id,
      };

      if (iconSize !== '') {
        newHotspotData.size = iconSize;
      }

      if (selectedIcon?.name) {
        newHotspotData.image = selectedIcon.name;
      }
      toggleNotification(EnumNotificationType.Success, 'Hotspot icon have been upated!');
      await updateHotspots(newHotspotData);
      getHotspotList(selectedHotspot.id);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const updateHotspotIconColour = async () => {
    ReportCustomEvent(EnumCategory.EditLink, EnumEvent.ChangeColor);
    try {
      const newHotspotData = {
        id: selectedHotspot.id,
        colors: selectedColour + selectedColour,
      };

      await updateHotspots(newHotspotData);
      getHotspotList(selectedHotspot.id);
      toggleNotification(EnumNotificationType.Success, 'Hotspot color have been upated!');
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const resetToOriginal = async (resetIconSize?: string) => {
    const attributes = {
      iconSize: EnumHotspotsSize.medium,
      version: 1,
    };

    try {
      const newHotspotData = {
        id: selectedHotspot.id,
        srcId: selectedHotspot.srcId,
        destId: selectedHotspot.destId,
        phi: selectedHotspot.pitch,
        theta: selectedHotspot.yaw,
        image: 'target',
        attributes: attributes,
        colors: '#ECF0F1#BDC3C7',
        customised: false,
      };

      await updateHotspots(newHotspotData);
      getHotspotList(selectedHotspot.id);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const deleteConfirmationModal = (
    <>
      <div className='delete-confirmation-modal'>
        <div className='delete-confirmation-modal-header'>
          <p>
            <Trans id='Delete Link' />
          </p>
        </div>
        <div className='delete-confirmation-modal-body'>
          <p>
            <Trans id='Are you sure you want to delete this link?' />
          </p>
          <div className='delete-buttons-group'>
            <button className='delete-hotspot-btn-cancel' onClick={() => setDeleteModal(false)}>
              <Trans id='Cancel' />
            </button>
            <button
              className='delete-hotspot-btn-delete'
              onClick={() => {
                deleteSelectedHotspot();
              }}
            >
              <Trans id='Delete Link' />
            </button>
          </div>
        </div>
      </div>
    </>
  );

  const closeModal = () => {
    // Remove all success states and reset mini viewer
    changeLeftSidebar('');
    setNewDest({});
    setNewReturnLink({});
    resetAllValues();
    setSelectedMiniViewerPanorama(undefined);
    setMiniViewerLoaded(false);
    getHotspotList(selectedHotspot.id);
    updatePanoramaList();
  };

  const setSizeIconAsDefault = async () => {
    try {
      let body: IVTConfig = {};
      if (iconSize !== '') {
        body.viewerDefaultIconSize = iconSize;
      }

      if (selectedIcon?.name) {
        body.viewerDefaultIcon = selectedIcon.name;
      }
      toggleNotification(EnumNotificationType.Success, 'Hotspot size have been saved by default!');
      await updateTourGeneralConfig(body, currentPanorama.tourId);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const setColourAsDefault = async () => {
    const body = {
      viewerDefaultIconColour: selectedColour + selectedColour,
    };
    try {
      toggleNotification(EnumNotificationType.Success, 'Hotspot color have been saved by default!');
      await updateTourGeneralConfig(body, currentPanorama.tourId);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  return (
    <>
      {showNotification && (
        <Notification
          showNotification={showNotification}
          size={EnumNotificationSize.Large}
          type={typeNotification || EnumNotificationType.Info}
          message={notificationMessage}
          setShowNotification={setShowNotification}
          fullScreen={true}
        />
      )}
      <div className='modal-full-screen'>
        <div className='modal-background-layer'></div>
        {deleteModal ? (
          deleteConfirmationModal
        ) : (
          <Fragment>
            <div className='editor-hotspot-container'>
              <div className='editor-modal-title'>
                <h1 className='editor-modal-header-title'>
                  <Trans id='Edit link' />
                </h1>
                <button onClick={closeModal} className='close-button btn-remove-default'>
                  <CloseButton />
                </button>
              </div>
              <div className='editor-modal-content edit-hotspot-layout'>
                <Tabs tabIndex={0}>
                  <ul className='edit-hotspot-tabs tabs'>
                    <TabHooks>
                      <div className='edit-hotspot-tab-first'>
                        <Trans id='Change link destination' />
                      </div>
                    </TabHooks>
                    <TabHooks>
                      <div className='edit-hotspot-tab-second'>
                        <Trans id='Change link icon' />
                      </div>
                    </TabHooks>
                    <TabHooks>
                      <div className='edit-hotspot-tab-third'>
                        <Trans id='Change link colour' />
                      </div>
                    </TabHooks>
                  </ul>
                  <PanelHooks>
                    <div className='edit-hotspot-panelhook contents'>
                      <div className='edit-hotspot-description'>
                        <>
                          <p className='edit-modal-headers'>
                            <Trans id='You can delete your link or change link destination. To change link destination:' />
                          </p>
                          <p className='edit-modal-headers subtitle'>
                            <Trans id='First, select new image for your link destination. Second, add a return link with a double-click on the large thumbnail.' />
                          </p>
                        </>
                      </div>
                      <div className='edit-hotspot-content'>
                        <div className='edit-hotspot-panoramas'>
                          {filteredPanoData &&
                            filteredPanoData.map((panorama: any, i: number) => (
                              <div key={i} className='edit-hotspot-panorama-container'>
                                <button
                                  className='btn-remove-default'
                                  onClick={() => {
                                    setNewDest(panorama);
                                    setNewReturnLink({});
                                    setMiniViewerLoaded(false);
                                    setSelectedMiniViewerPanorama(panorama.cubeMap);
                                  }}
                                >
                                  <div className='dhp-image-container'>
                                    {newDest && newDest.id === panorama.id && (
                                      <div className='selected-panorama'>
                                        <img className='preview-tick-link' src={greenTick} alt='Selected preview tick' />
                                      </div>
                                    )}
                                    <img className='dhp-image' src={panorama.thumbnailPreviewUrl} alt='thumbnail' />
                                  </div>
                                  <p className='ehp-text'>{panorama.name}</p>
                                </button>
                              </div>
                            ))}
                        </div>
                        <div className='edit-hotspot-preview'>
                          <div className='edit-destination-viewer' id='mini-viewer' />
                          {!selectedMiniViewerPanorama && (
                            <div className='empty-state-edit'>
                              <img src={imageIcon} alt='Panorama preview' />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='edit-hotspot-buttons'>
                        <button onClick={() => setDeleteModal(true)} className='button-hover edit-hotspot-btn edit-hotspot-btn-secondary'>
                          <Trans id='Delete link' />
                        </button>
                        <button
                          disabled={newReturnLink && newReturnLink.phi ? false : true}
                          onClick={() => updateHotspotDestination()}
                          className={`edit-hotspot-btn ${
                            newReturnLink && newReturnLink.phi ? 'edit-hotspot-btn-primary-active' : 'edit-hotspot-btn-primary-inactive'
                          }`}
                        >
                          <Trans id='Save changes' />
                        </button>
                      </div>
                    </div>
                  </PanelHooks>
                  <PanelHooks>
                    <div className='edit-hotspot-panelhook contents'>
                      <div className='edit-hotspot-description'>
                        <>
                          <p className='edit-modal-headers'>
                            <Trans id='Select a different link icon from our icon gallery. Change icon size to your needs.' />
                          </p>
                          <p className='edit-modal-headers subtitle'>
                            <Trans id='Changes will NOT be applied globally and will only affect the icon you have selected.' />
                          </p>
                        </>
                      </div>
                      <div className='edit-hotspot-content'>
                        <div className='edit-hotspot-icons'>
                          <p className='dhi-text'>
                            <Trans id='Select an icon from the gallery' />
                          </p>
                          <div>
                            {listIcons &&
                              listIcons.map((icon, index) => (
                                <button
                                  key={index}
                                  className={`fa-container ${
                                    selectedIcon && selectedIcon.name === icon.name ? 'fa-container-selected' : ''
                                  }`}
                                  /* fa-stack fa-lg */ onClick={() => setSelectedIcon(icon)}
                                >
                                  <i
                                    style={{ color: selectedIcon && selectedIcon.name === icon.name ? '#ffffff' : '#0e0333' }}
                                    className={`fa ${icon.icon} fa-lg fa-2x fa-fw `}
                                  ></i>
                                </button>
                              ))}
                          </div>
                        </div>
                        <div className='edit-hotspot-size-selector'>
                          <p className='dhi-text'>
                            <Trans id='Change icon size' />
                          </p>
                          <div className='ehi-size-preview'>
                            <div className='ehi-preview-item'>
                              {selectedIcon ? (
                                <i className={`icon-size-preview-small fa ${selectedIcon && selectedIcon.icon} fa-2x`}></i>
                              ) : (
                                <img className='icon-size-preview-small' src={hotspotIcon} alt='Icon size preview' />
                              )}
                              <p className='ehi-preview-text'>
                                <Trans id='Small' />
                              </p>
                              <p className='ehi-preview-text subtitle'>
                                <Trans id='40x40px' />
                              </p>
                              <input
                                id='ehi-radio'
                                type='radio'
                                name='icon-size'
                                value='small'
                                onChange={() => setIconSize(EnumHotspotsSize.small)}
                              />
                            </div>
                            <div className='ehi-preview-item'>
                              {selectedIcon ? (
                                <i className={`icon-size-preview-medium fa ${selectedIcon && selectedIcon.icon} fa-3x`}></i>
                              ) : (
                                <img className='icon-size-preview-medium' src={hotspotIcon} alt='Icon size preview' />
                              )}
                              <p className='ehi-preview-text'>
                                <Trans id='Medium' />
                              </p>
                              <p className='ehi-preview-text subtitle'>
                                <Trans id='56x56px' />
                              </p>
                              <input
                                id='ehi-radio'
                                type='radio'
                                name='icon-size'
                                value='medium'
                                defaultChecked
                                onChange={() => setIconSize(EnumHotspotsSize.medium)}
                              />
                            </div>
                            <div className='ehi-preview-item'>
                              {selectedIcon ? (
                                <i className={`icon-size-preview-large fa ${selectedIcon && selectedIcon.icon} fa-4x`}></i>
                              ) : (
                                <img className='icon-size-preview-large' src={hotspotIcon} alt='Icon size preview' />
                              )}
                              <p className='ehi-preview-text'>
                                <Trans id='Large' />
                              </p>
                              <p className='ehi-preview-text subtitle'>
                                <Trans id='80x80px' />
                              </p>
                              <input
                                id='ehi-radio'
                                type='radio'
                                name='icon-size'
                                value='large'
                                onChange={() => setIconSize(EnumHotspotsSize.large)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='edit-hotspot-buttons'>
                        <button
                          className='button-hover edit-hotspot-btn edit-hotspot-btn-secondary'
                          onClick={() => resetToOriginal('iconSize')}
                        >
                          <Trans id='Reset to original' />
                        </button>
                        <button
                          className={`edit-hotspot-btn ${
                            selectedIcon || iconSize !== ''
                              ? 'edit-hotspot-btn-primary-active button-hover'
                              : 'edit-hotspot-btn-primary-inactive'
                          }`}
                          onClick={() => updateHotspotIcon()}
                        >
                          <Trans id='Save changes' />
                        </button>
                        <button
                          onClick={setSizeIconAsDefault}
                          className={`edit-hotspot-btn ${
                            selectedIcon || iconSize !== ''
                              ? 'edit-hotspot-btn-primary-active button-hover'
                              : 'edit-hotspot-btn-primary-inactive'
                          }`}
                        >
                          <Trans id='Save as default' />
                        </button>
                      </div>
                    </div>
                  </PanelHooks>
                  <PanelHooks>
                    <div className='edit-hotspot-panelhook contents'>
                      <div className='edit-hotspot-description'>
                        <>
                          <p className='edit-modal-headers'>
                            <Trans id='Change the colour of the link icon. Choose a default colour or define your own colour.' />
                          </p>
                          <p className='edit-modal-headers subtitle'>
                            <Trans id='Changes will NOT be applied globally and will only affect the icon you have selected.' />
                          </p>
                        </>
                      </div>
                      <div className='edit-hotspot-content ehc-center'>
                        <div className='edit-hotspot-colours'>
                          <p className='ehc-text'>
                            <strong>
                              <Trans id='Choose a default colour' />
                            </strong>
                          </p>
                          <div className='ehc-colours-container'>
                            {colours.map((colour: any, i: number) => (
                              <button
                                key={i}
                                onClick={() => setSelectedColour(colour.colour)}
                                style={{
                                  background: colour.colour,
                                  border: i === 0 ? '1px solid #D0D0D0' : '',
                                }}
                                className='ehc-colour'
                              >
                                {selectedColour && selectedColour === colour.colour && (
                                  <img className='ehc-colour-selected' src={whiteTick} alt='select-colour' />
                                )}
                              </button>
                            ))}
                          </div>
                        </div>
                        <div className='edit-hotspot-own-colour'>
                          <p className='ehc-text'>
                            <strong>
                              <Trans id='Define your own colour' />
                            </strong>
                          </p>
                          <div className='ehc-controls'>
                            <div className='ehc-controls-selector'>
                              <button style={{ background: hexCode }} className='ehc-colour no-margin'>
                                {isHexCodeValid && <img className='ehc-colour-selected' src={whiteTick} alt='select-hex' />}
                              </button>
                              <input
                                type='text'
                                placeholder='Hex code'
                                value={hexCode}
                                className='ehc-input-colour-select'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => validateHex(e)}
                              />
                            </div>
                            <button
                              className={`ehc-controls-button edit-hotspot-btn ${
                                isHexCodeValid ? 'edit-hotspot-btn-primary-active' : 'edit-hotspot-btn-primary-inactive'
                              }`}
                              onClick={() => setSelectedColour(hexCode)}
                            >
                              + <Trans id='Add colour' />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='edit-hotspot-buttons'>
                        <button className=' button-hover edit-hotspot-btn edit-hotspot-btn-secondary' onClick={() => resetToOriginal()}>
                          <Trans id='Reset to original' />
                        </button>
                        <button
                          className={`edit-hotspot-btn ${
                            selectedColour ? ' button-hover edit-hotspot-btn-primary-active' : 'edit-hotspot-btn-primary-inactive'
                          }`}
                          onClick={() => updateHotspotIconColour()}
                        >
                          <Trans id='Save changes' />
                        </button>
                        <button
                          onClick={setColourAsDefault}
                          className={`edit-hotspot-btn ${
                            selectedColour !== '' ? 'edit-hotspot-btn-primary-active button-hover' : 'edit-hotspot-btn-primary-inactive'
                          }`}
                        >
                          <Trans id='Save as default' />
                        </button>
                      </div>
                    </div>
                  </PanelHooks>
                </Tabs>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </>
  );
};

export default EditHotspot;
