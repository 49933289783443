import React, { useEffect, useState } from 'react';
import { getLinksMap } from '../../../../api-helper/api-panoramas';
import { ILink, IPanoramaLinksMap } from '../../../editing-viewer/modals/links-map';
import { getDestName } from '../../../editing-viewer/viewer-canvas-utils/hotspot-factory';
import { orderNoLinksFirst } from '../../../utils/order/order';
import noLink from '../../../../images/viewer-icons/no-link-icon.svg';
import smallLink from '../../../../images/viewer-icons/hotspot-link-small.svg';
import { Link } from 'react-router-dom';
import '../tours.scss';
import { Trans } from '@lingui/react';
import noLinks from '../../../../images/empty-states-illustrations/no-links-state.svg';

interface IProps {
  tour: any;
  panoramas: any;
  tabIndex?: number;
}

const LinksMap: React.FC<IProps> = ({ tour, panoramas, tabIndex }) => {
  const [links, setLinks] = useState<IPanoramaLinksMap[]>();

  const getHotspotsFromPanorama = async () => {
    const linksMap = await getLinksMap(tour.id);
    // Show ones without links first;
    const reorderPanoramas = orderNoLinksFirst(linksMap);
    setLinks(reorderPanoramas);
  };

  useEffect(() => {
    if (tour?.id) {
      if (tabIndex === 1) {
        getHotspotsFromPanorama();
      }
    }
  }, [tour, tabIndex]);

  const previewPanoramas = (panorama: IPanoramaLinksMap, links: ILink[], panoramasLength: number) => {
    const linksAvailable = panoramasLength === 1 ? true : links && links.length > 0 ? true : false;

    return (
      <div className='panorama-links-container' style={{ border: linksAvailable ? '' : '1px solid #E74C3C' }}>
        <div className='links-map-image-container'>
          <img className='links-map-thumbnail-preview' src={panorama.thumbnailPreviewUrl} alt='thumbnail' />
          {!linksAvailable && (
            <div className='no-link-icon'>
              <img className='no-link-img' src={noLink} alt='no-link' />
            </div>
          )}
        </div>

        {panoramasLength === 1 ? (
          <>
            <div className='display-flex flex-column'>
              <div className='links-map-pano-info'>
                <div className='links-map-pano-name'>{panorama.panoramaName}</div>
                <div className='line-between-text-links'>
                  <Trans id='is NOT linked' />
                </div>
              </div>
            </div>
            <div style={{ fontSize: 14, textAlign: 'center' }}>
              <div>No links required</div>
              <div>for a 1 panorama tour</div>
            </div>
          </>
        ) : linksAvailable ? (
          <>
            <div className='display-flex flex-column'>
              <div className='links-map-pano-info'>
                <div className='links-map-pano-name'>{panorama.panoramaName}</div>
                <div className='line-between-text-links'>
                  <Trans id='is linked to' />
                </div>
              </div>
            </div>
            <ul style={{ overflow: 'auto', margin: '0 20px' }}>
              {links && links.length && links.map((link: ILink, i: number) => <li key={i}>• {getDestName(link.destId, panoramas)}</li>)}
            </ul>
          </>
        ) : (
          <>
            <div className='display-flex flex-column'>
              <div className='links-map-pano-info'>
                <div className='links-map-pano-name' style={{ color: '#E74C3C' }}>
                  {panorama.panoramaName}
                </div>
                <div className='line-between-text-links' style={{ color: '#E74C3C' }}>
                  <Trans id='is NOT linked' />
                </div>
              </div>
            </div>
            <button className='links-btn-change-panorama button-hover'>
              <Link
                className='default-link link-style-adjustment'
                to={{
                  pathname: `/editing-viewer/`,
                  search: `?tourId=${tour.id}&panoramaId=${panorama.panoramaId}`,
                  state: { tour: tour, displayEditTour: true },
                }}
              >
                <img src={smallLink} className='small-link-img' alt='small-link' />
                <Trans id='Link image' />
              </Link>
            </button>
          </>
        )}
      </div>
    );
  };
  if (panoramas && links?.length) {
    return (
      <>
        {panoramas &&
          links?.map((pano: IPanoramaLinksMap, i: number) => <div key={i}>{previewPanoramas(pano, pano.links, panoramas.length)}</div>)}
      </>
    );
  } else {
    return (
      <div className='container-no-links'>
        <div className='container-text-no-links'>
          <div className='title-text-no-links'>
            <Trans id='You haven’t linked any of your images yet! ' />
          </div>
          <div className='sub-text-no-links'>
            <Trans
              id='Click on the <0>Create links</0> button to start linking your images together.'
              components={{ 0: <strong className='text-strong'></strong> }}
            />
          </div>
          <button className='button-no-links button-hover'>
            <Trans id='Create links' />
          </button>
        </div>
        <img src={noLinks} className='no-links-state' alt='empty' />
      </div>
    );
  }
};

export default LinksMap;
