import React, { useEffect, useState } from 'react';
import './list-points.scss';
import '../editing-viewer/modals/modals.scss';
import { ReactComponent as Bin } from '../../images/viewer-icons/bin.svg';
import { ReactComponent as Empty } from '../../images/viewer-icons/empty-annotation.svg';
import { useConfig } from '../utils/user-config-context/user-config-context';
import { orderAlphabetically } from '../utils/order/order';
import { ReportCustomEvent } from '../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../google-analytics-track-list';
import { Trans } from '@lingui/react';

interface IProps {
  hotspots: any;
  annotateActive: boolean;
  handleRemove: (list: any, index: any) => void;
  handleUpdateHotspotColour: (index: number, newColour: string) => void;
  toggleAnnotation: () => void;
}

interface IAnnotation {
  colour: string;
  name: string;
  value: string;
}

const ListsPoints = ({ hotspots, annotateActive, handleRemove, handleUpdateHotspotColour, toggleAnnotation }: IProps) => {
  const [hotspotObject, setHotspotObject] = useState<any>();
  const [annotationType, setAnnotationType] = useState([]);
  const config = useConfig();

  useEffect(() => {
    if (config && config.annotationType) {
      const keyToSortBy = 'name';
      const placeLast = 'other';
      const order = orderAlphabetically(config.annotationType, keyToSortBy, placeLast);
      setAnnotationType(order);
    }
  }, [config]);

  const handleOptionChange = (e: any, i: any) => {
    const newColour = e.target.value;
    handleUpdateHotspotColour(i, newColour);
    setHotspotObject({ ...hotspotObject, hotspotObject });
  };

  useEffect(() => {
    setHotspotObject(hotspots);
  }, [hotspots]);

  return (
    <React.Fragment>
      {hotspotObject && Array.isArray(hotspotObject.data) && hotspotObject.data.length > 0 ? (
        hotspotObject.data.map((list: any, i: number) => {
          return (
            <div key={i} className='points-list-container'>
              <div className='detected-item-container'>
                <div className='annotation-container'>
                  <div className='annotation-options'>
                    <div className={`annotation-colour annotation-colour-${list[0].hotspotLabel || 'default'}`}>
                      <svg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M9 1.74194C13.0112 1.74194 16.2581 4.98814 16.2581 9C16.2581 13.0112 13.0119 16.2581 9 16.2581C4.98876 16.2581 1.74194 13.0119 1.74194 9C1.74194 4.98876 4.98814 1.74194 9 1.74194ZM9 0C4.02942 0 0 4.02942 0 9C0 13.9706 4.02942 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02942 13.9706 0 9 0ZM9 6.09677C7.39658 6.09677 6.09677 7.39658 6.09677 9C6.09677 10.6034 7.39658 11.9032 9 11.9032C10.6034 11.9032 11.9032 10.6034 11.9032 9C11.9032 7.39658 10.6034 6.09677 9 6.09677Z'
                          fill={list[0].hotspotColor ? list[0].hotspotColor : ''}
                        />
                      </svg>
                    </div>
                    <div className='annotation-name'>{(list[0].hotspotLabel && list[0].hotspotLabel.split('_').join(' ')) || 'other'}</div>
                    <select className='select-annotation' value={list[0].hotspotLabel} onChange={(e: any) => handleOptionChange(e, i)}>
                      {annotationType.map((annotations: IAnnotation, index) => (
                        <option key={index} className='annotation-dropdown' value={annotations.value}>
                          {annotations.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button
                    onClick={() => {
                      ReportCustomEvent(EnumCategory.CreateAnnotation, EnumEvent.DeleteAnnotation);
                      handleRemove(list, i);
                    }}
                    className='delete-btn'
                  >
                    <Bin />
                  </button>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className='annotation-empty'>
          <Empty />
        </div>
      )}
      <div className='annotation-toggle'>
        <button
          className='new-active-button button-hover'
          onClick={() => {
            ReportCustomEvent(EnumCategory.CreateAnnotation, EnumEvent.StartFinishAnnotation);
            toggleAnnotation();
          }}
        >
          {annotateActive ? <Trans id='Finish annotation' /> : <Trans id='Start annotation' />}
        </button>
      </div>
    </React.Fragment>
  );
};

export default ListsPoints;
