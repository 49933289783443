import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import './style.scss';

interface Props {
  visible: boolean;
  text: string;
  tittle: string;
  onFirstBtn: () => void;
  onSecondBtn: () => void;
  onFirstBtnLabel: string;
  onSecondBtnLabel: string;
  onLogoBtn?: () => void;
}

/**
 * Component that split paragraph into different lines when \n character is found in the string
 * @param text
 * @returns Array of <p> tags
 */

interface INewlineTextProps {
  text: string;
}

const NewlineText: FunctionComponent<INewlineTextProps> = ({ text }) => {
  const textToSplit = text;
  const paragraph = textToSplit.split('\\n').map((str, index) => (
    <p key={index} style={{ paddingBottom: '10px' }}>
      {str}
    </p>
  ));
  return <React.Fragment>{paragraph}</React.Fragment>;
};

/**
 * Modal
 * @param visible: boolean;
 * @param text: string;
 * @param tittle: string;
 * @param onFirstBtn: () => void;
 * @param onSecondBtn: () => void;
 * @param onFirstBtnLabel: string;
 * @param onSecondBtnLabel: string;
 * @returns modal component
 */
const Modal = (props: Props) =>
  props.visible
    ? ReactDOM.createPortal(
        <div className='use-modal'>
          <div className='modal-pop' role='dialog' aria-modal='true'>
            <h3 className='tittle'>{props.tittle}</h3>
            <div className='main-text'>
              <NewlineText text={props.text} />
            </div>
            <div style={{ paddingBottom: '16px' }}>
              <button
                className='btn-cancel button-hover'
                type='button'
                onClick={event => {
                  event.preventDefault();
                  props.onFirstBtn();
                }}
              >
                {props.onFirstBtnLabel}
              </button>
              <button
                className='btn-accept button-hover'
                type='button'
                onClick={event => {
                  event.preventDefault();
                  props.onSecondBtn();
                }}
              >
                {props.onSecondBtnLabel}
              </button>
            </div>
          </div>
          <div className='modal-overlay'></div>
        </div>,
        document.body,
      )
    : null;

export default Modal;
