import React, { Fragment } from 'react';
import '../../editing-viewer/modals/modals.scss';
import { ReactComponent as CloseButton } from '../../../images/viewer-icons/cross.svg';
import { Trans } from '@lingui/react';

interface IProps {
  addressSearch: any;
  searchGeoWithPin: () => void;
  changeAddressMapsSearch: (search: string) => void;
  confirmAddress: () => void;
  toggleModal: () => void;
  onDragTextShowInput: boolean;
  onDragText: string;
}

const MapsModal: React.FC<IProps> = ({
  addressSearch,
  searchGeoWithPin,
  changeAddressMapsSearch,
  confirmAddress,
  toggleModal,
  onDragTextShowInput,
  onDragText,
}) => {
  return (
    <Fragment>
      <div className='modal-background-layer' style={{ zIndex: 3 }}></div>
      <div className='editor-modal-container-maps'>
        <div className='editor-modal-title'>
          <h1 className='editor-modal-header-title'>Confirm the location on the map</h1>
        </div>
        <button onClick={() => toggleModal()} className='close-button btn-remove-default'>
          <CloseButton />
        </button>
        <div>
          <div className='edit-modal-inside-container-download'>
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
                searchGeoWithPin();
              }}
            >
              <input
                name='postcode'
                className='property-address-input-maps'
                placeholder='Start typing to refine your search'
                type='text'
                value={(addressSearch && addressSearch.formatted_address) || ''}
                onChange={(e: any) => changeAddressMapsSearch(e.target.value)}
              />
              <button
                onClick={(e: any) => {
                  e.preventDefault();
                  searchGeoWithPin();
                }}
                className='search-maps-location editor-modal-btn-active'
              >
                Search
              </button>
            </form>
            <div className='map-container'>
              <div id='map'></div>
              <div className='text-map-container'>
                <p className='text-map'>
                  <Trans id='Drag & Drop on the map to place the pin and confirm the address' />
                </p>
              </div>
              {onDragTextShowInput && (
                <input
                  readOnly
                  name='verification'
                  className={`${addressSearch && addressSearch.formatted_address ? 'property-address-input-maps-inside' : 'remove-input'}`}
                  type='text'
                  value={onDragText || ''}
                />
              )}
            </div>
          </div>
          <div className='modal-btn-container-download'>
            <button
              onClick={() => confirmAddress()}
              className={`editor-modal-btn ${
                addressSearch && addressSearch.formatted_address === '' ? 'editor-modal-btn-disable' : 'editor-modal-btn-active'
              }`}
            >
              Confirm address
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MapsModal;
