import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import vieweet from '../../../images/vieweet-logo/vieweet-logo.svg';
import { activateAccount, reSendVerificationEmail } from '../../../api-helper/api-auth';
import accountActive from '../../../images/icons/email-activated.svg';
import accountNotActive from '../../../images/icons/email-not-activated.svg';
import { Trans } from '@lingui/react';
import Footer from '../../utils/footer/footer';
import './confirm-account.scss';
import LoadingSpinner from '../../utils/loading-spinner/loading-spinner';
import { isMobileOnly } from 'react-device-detect';
import { useAuth } from '../../../auth-routes/auth-context';
import { handleError } from '../../../api-helper/api-error-handling';

const ConfirmAccount = () => {
  const { handleLogoutState } = useAuth();
  const [activeAccount, setActiveAccount] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // Update the document title using the browser API
    const urlParam = new URLSearchParams(window.location.search);
    const urlToken: any = urlParam.get('token');
    const email: any = urlParam.get('email');
    setUserEmail(email);
    setToken(urlToken);
    if (token !== '') {
      onConfirmation();
    }
  }, [token]);

  const onConfirmation = async () => {
    try {
      await activateAccount(token);
      setActiveAccount(true);
      setLoading(false);
    } catch (err: any) {
      setActiveAccount(false);
      console.log(err.message);
      setLoading(false);
    }
  };

  const resendActivationCode = async () => {
    try {
      await reSendVerificationEmail(userEmail);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  } else {
    return (
      <div className='initial-pages-bg'>
        <div className='onboarding-content-layout'>
          <div>
            <a href='https://www.vieweet.com/'>
              <img className='onboarding-topbar-vieweet-logo' src={vieweet} alt='vieweet' />
            </a>
          </div>
          {!isMobileOnly && (
            <div className='onboarding-topbar-links-section'>
              <div className='onboarding-topbar-link-no-border-below'>
                <Link to='/login'>
                  <Trans id='Login' />
                </Link>
              </div>
              <div className='onboarding-topbar-link-border-below'>
                <h3>
                  <Trans id='Sign up' />
                </h3>
              </div>
            </div>
          )}
          <div className='confirm-account-initial-pages-container'>
            <div className='confirm-account-container'>
              {activeAccount ? (
                <React.Fragment>
                  <img className='image-confirm-account' src={accountActive} alt='tick' />
                  <h3 id='title-success'>
                    <Trans id='Your account is activated' />
                  </h3>
                  <div className='text-message text-message-margin-bottom'>
                    {isMobileOnly ? (
                      <p>
                        <Trans id='You can now create 360° tours by login on the mobile app or web browser' />
                      </p>
                    ) : (
                      <p>
                        <Trans id='You can now create 360° tours' />
                      </p>
                    )}
                  </div>
                  {!isMobileOnly && (
                    <div className='login-signup-btn-container-link'>
                      <Link to='/login'>
                        <Trans id='Log in' />
                      </Link>
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <img className='image-confirm-account' src={accountNotActive} alt='tick' />
                  <h3 data-testid='not-activated-text' id='title-error'>
                    <Trans id='Something went wrong' />
                  </h3>
                  <div className='text-message'>
                    <p id='message-first'>
                      <Trans id='We cannot activate your account right now' />
                    </p>
                    <p id='message-second'>
                      <Trans id='Please try again' />
                    </p>
                  </div>
                  <div className='login-signup-btn-container'>
                    <button className='btn-confirmation-account' onClick={resendActivationCode}>
                      <Trans id='Resend activation email' />
                    </button>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <Footer isPublicRoute={true} />
      </div>
    );
  }
};

export default ConfirmAccount;
