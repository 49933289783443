import { logout } from "./api-auth";

export const handleError = async (error: any, handleLogoutState: () => void) => {
	if (error.getStatusCode) {
		const errorCode = error.getStatusCode();
		if (errorCode === 401) {
			// if unauthorized log out
			await logout();
			handleLogoutState();
		}
	}
}