import React, { Fragment, useEffect, useState } from 'react';
import { getLinksMap } from '../../../api-helper/api-panoramas';
import { ReactComponent as CloseButton } from '../../../images/viewer-icons/cross.svg';
import noLink from '../../../images/viewer-icons/no-link-icon.svg';
import { getDestName, IAttributes } from '../viewer-canvas-utils/hotspot-factory';
import { paginateModalSlider } from '../../utils/pagination/utils';
import smallLink from '../../../images/viewer-icons/hotspot-link-small.svg';
import backArrow from '../../../images/viewer-icons/left-circle-arrow.svg';
import forwardArrow from '../../../images/viewer-icons/right-circle-arrow.svg';
import backArrowDisable from '../../../images/viewer-icons/left-circle-arrow-disable.svg';
import forwardArrowDisable from '../../../images/viewer-icons/right-circle-arrow-disable.svg';
import noLinkStudio from '../../../images/empty-states-illustrations/no-link-studio.svg';
import { orderNoLinksFirst } from '../../utils/order/order';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { IPanorama } from '../../../api-helper/interface/interfaces';
import { Trans } from '@lingui/react';
import { ILeftSidebar } from '../viewer-canvas-utils/viewer-canvas-helper';

interface IProps {
  tourId: string;
  panoramaData: IPanorama[];
  changeLeftSidebar: (option: string) => void;
  changePanorama: (panorama: IPanorama) => void;
  toggleNewHotspotEditorMode: (state: boolean) => void;
  leftSidebar: ILeftSidebar;
}

export interface IPanoramaLinksMap {
  links: ILink[];
  missingLinks: boolean;
  panoramaId: string;
  panoramaName: string;
  thumbnailPreviewUrl: string;
}

export interface ILink {
  HOTSPOT_CREATED: Date;
  HOTSPOT_UPDATED: Date;
  attributes: IAttributes;
  colors: string;
  createdAt: Date;
  description?: string;
  destId: string;
  from: number;
  id: string;
  image: string;
  info: string;
  name?: string;
  oldPhi: number;
  oldTheta: number;
  phi: number;
  size: number;
  srcId: string;
  status: number;
  theta: number;
  type: number;
  updatedAt: Date;
  windows: number;
}

const LinksMap: React.FC<IProps> = ({
  tourId,
  panoramaData,
  changeLeftSidebar,
  changePanorama,
  toggleNewHotspotEditorMode,
  leftSidebar,
}) => {
  const [missingLinks, setMissingLinks] = useState<boolean>(false);
  const [links, setLinks] = useState<IPanoramaLinksMap[][]>();
  const [page, setPage] = useState<number>(0);
  const [emptyState, setEmptyState] = useState<boolean>(false);

  useEffect(() => {
    if (leftSidebar?.linksMap?.status) {
      if (panoramaData) {
        getHotspotsFromPanorama();
      }
    }
  }, [leftSidebar]);

  const getHotspotsFromPanorama = async () => {
    const linksMap = await getLinksMap(tourId);
    const find = linksMap.find((panorama: IPanoramaLinksMap) => panorama.links.length > 0);
    setEmptyState(find ? false : true);
    setMissingLinks(
      linksMap.find((panorama: IPanoramaLinksMap) => {
        return panorama && panorama.links && panorama.links.length <= 0 ? true : false;
      }),
    );
    // Show ones without links first;
    const reorderPanoramas = orderNoLinksFirst(linksMap);

    const limit = 3;
    const pagePanoramas = paginateModalSlider(reorderPanoramas, limit);
    setLinks(pagePanoramas);
  };

  const changeCurrentPanorama = (panorama: IPanoramaLinksMap) => {
    ReportCustomEvent(EnumCategory.YourLinks, EnumEvent.LinkImage);
    const panoramaFullData = panoramaData.find((pano: IPanorama) => pano.id === panorama.panoramaId);
    if (panoramaFullData) {
      changePanorama(panoramaFullData);
      setTimeout(() => {
        changeLeftSidebar('addHotspot');
        toggleNewHotspotEditorMode(true);
      }, 1500);
    }
  };

  const previewPanoramas = (panorama: IPanoramaLinksMap, links: ILink[]) => {
    const linksAvailable = links && links.length > 0 ? true : false;

    return (
      <div className='panorama-links-container' style={{ border: linksAvailable ? '' : '1px solid #E74C3C' }}>
        <div className='links-map-image-container'>
          <img className='links-map-thumbnail-preview' src={panorama.thumbnailPreviewUrl} alt='thumbnail' />
          {!linksAvailable && (
            <div className='no-link-icon'>
              <img className='no-link-img' src={noLink} alt='no-link' />
            </div>
          )}
        </div>

        {linksAvailable ? (
          <>
            <div className='display-flex flex-column'>
              <div className='links-map-pano-info'>
                <div className='links-map-pano-name'>{panorama.panoramaName}</div>
                <div className='line-between-text-links'>
                  <Trans id='is linked to' />
                </div>
              </div>
            </div>
            <ul style={{ overflow: 'auto', margin: '0 20px' }}>
              {links && links.length && links.map((link: ILink, i: number) => <li key={i}>• {getDestName(link.destId, panoramaData)}</li>)}
            </ul>
          </>
        ) : (
          <>
            <div className='display-flex flex-column'>
              <div className='links-map-pano-info'>
                <div className='links-map-pano-name' style={{ color: '#E74C3C' }}>
                  {panorama.panoramaName}
                </div>
                <div className='line-between-text-links' style={{ color: '#E74C3C' }}>
                  <Trans id='is NOT linked' />
                </div>
              </div>
            </div>
            <button
              className='links-btn-change-panorama button-hover'
              onClick={() => {
                changeCurrentPanorama(panorama);
              }}
            >
              <img src={smallLink} className='small-link-img' alt='small-link' />
              <Trans id='Link image' />
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <div className='modal-background-layer'></div>
      <div className='links-map-container'>
        <div className='links-map-modal-title'>
          <h1 className='editor-modal-header-title'>
            <Trans id='Your links' />
          </h1>
          <button
            onClick={() => {
              changeLeftSidebar('moveAround');
            }}
            className='close-button btn-remove-default'
          >
            <CloseButton />
          </button>
        </div>
        <div className='modal-btn-container-links'>
          {emptyState ? (
            <div className='empty-state-container'>
              <div className='text-container-link'>
                <div className='title-no-link'>
                  <Trans id='You haven’t linked any of your images yet!' />
                </div>
                <div className='sub-text-link'>
                  <Trans
                    id='Click on <0>Create links</0> button to start linking your images together.'
                    components={{ 0: <strong className='text-strong'></strong> }}
                  />
                </div>
                <button
                  onClick={() => {
                    changeLeftSidebar('addHotspot');
                    toggleNewHotspotEditorMode(true);
                  }}
                  className='no-link-button'
                >
                  <Trans id='Create links' />
                </button>
              </div>
              <img src={noLinkStudio} className='no-link-large' alt='illustration' />
            </div>
          ) : (
            <>
              {missingLinks ? (
                <div className='links-map-error'>
                  <img className='missing-links-image' src={noLink} alt='no-link' />
                  <div className='links-map-error-text'>
                    <Trans
                      id='You have some missing links. Click on <0>Link image</0> button to create links to this image.'
                      components={{ 0: <b></b> }}
                    />
                  </div>
                </div>
              ) : (
                <div className='links-map-success'>
                  <b>Great news!</b>
                  <br /> <Trans id='All your images are linked' />
                </div>
              )}
              <div className='links-map-panoramas'>
                <button className='btn-remove-default' onClick={() => setPage(page === 0 ? page : page - 1)}>
                  <img src={page === 0 ? backArrowDisable : backArrow} alt='Back slider' />
                </button>
                <div className='links-paginated-container'>
                  {links &&
                    links.length &&
                    links[page].length &&
                    links[page].map((pano: IPanoramaLinksMap, i: number) => <div key={i}>{previewPanoramas(pano, pano.links)}</div>)}
                </div>
                <button className='btn-remove-default' onClick={() => setPage(links && page + 1 !== links.length ? page + 1 : page)}>
                  <img src={links && page === links.length - 1 ? forwardArrowDisable : forwardArrow} alt='Forward slider' />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default LinksMap;
