import React, { Fragment, useEffect, useState } from 'react';
import { ReactComponent as CloseButton } from '../../../images/viewer-icons/cross.svg';
import { PanelHooks, TabHooks, Tabs } from '../../utils/tabs/tabs';
import cross from '../../../images/viewer-icons/delete-media-cross.svg';
import imageIconDrop from '../../../images/viewer-icons/image-drop-icon.svg';
import mediaIconDrop from '../../../images/viewer-icons/media-drop-icon.svg';
import autoPlay from '../../../images/viewer-icons/autoplay.svg';
import controls from '../../../images/viewer-icons/controls.svg';
import mute from '../../../images/viewer-icons/mute.svg';
import checkboxUnchecked from '../../../images/viewer-icons/checkbox-unchecked.svg';
import checkboxChecked from '../../../images/viewer-icons/checkbox-checked.svg';
import './modals.scss';
import { createCustomMediaHotspot, deleteHotspot, updateCustomHotspotMedia } from '../../../api-helper/api-hotspots';
import SmallerLoadingSpinner from '../../utils/loading-spinner/smaller-loading-spinner';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { Trans } from '@lingui/react';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';

interface IProps {
  selectedHotspot: any;
  mediaEdit: boolean;
  setMediaEdit: (edit: boolean) => void;
  changeLeftSidebar: (option: string) => void;
  stillImageHotspot: any;
  getHotspotList: (deleteHotspot?: string) => void;
  toggleSuccessNotification: (message: string) => void;
}

const StillImage: React.FC<IProps> = ({
  selectedHotspot,
  mediaEdit,
  setMediaEdit,
  stillImageHotspot,
  changeLeftSidebar,
  getHotspotList,
  toggleSuccessNotification,
}) => {
  const { handleLogoutState } = useAuth();
  const [textInfo, setTextInfo] = useState<any>({
    imageTitle: '',
    imageDescription: '',
    mediaTitle: '',
    mediaDescription: '',
  });
  const [media, setMedia] = useState<any>({});
  const [validation, setValidation] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [errorImage, setErrorImage] = useState<boolean>(false);
  const [errorVideo, setErrorVideo] = useState<boolean>(false);
  const defaultOptions = {
    enableAutoplay: true,
    isMuted: false,
    enableControls: false,
  };
  const [mediaOptions, setMediaOptions] = useState(defaultOptions);

  useEffect(() => {
    if (mediaEdit) {
      if (selectedHotspot.typeFrontViewer === 1) {
        setTextInfo({
          imageTitle: selectedHotspot.createTooltipArgs.title || '',
          imageDescription: selectedHotspot.createTooltipArgs.text || '',
        });
      } else {
        setTextInfo({
          mediaTitle: selectedHotspot.createTooltipArgs.title || '',
          mediaDescription: selectedHotspot.createTooltipArgs.text || '',
        });
        setMediaOptions({
          enableAutoplay: selectedHotspot.createTooltipArgs.enableAutoplay || false,
          isMuted: selectedHotspot.createTooltipArgs.isMuted || false,
          enableControls: selectedHotspot.createTooltipArgs.enableControls || false,
        });
      }

      const file = {
        data: selectedHotspot.createTooltipArgs.src,
        uploaded: true,
        failed: false,
        type: selectedHotspot.typeFrontViewer,
      };
      setMedia(file);
    }
  }, [mediaEdit, selectedHotspot]);

  useEffect(() => {
    // Validate fields
    if (
      media &&
      media.data &&
      media.type === 1 &&
      textInfo &&
      textInfo.imageTitle &&
      textInfo.imageTitle !== '' &&
      textInfo.imageDescription &&
      textInfo.imageDescription !== ''
    ) {
      setValidation(true);
    } else if (
      media &&
      media.data &&
      media.type === 3 &&
      textInfo &&
      textInfo.mediaTitle &&
      textInfo.mediaTitle !== '' &&
      textInfo.mediaDescription &&
      textInfo.mediaDescription !== ''
    ) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [media, textInfo]);

  useEffect(() => {
    if (media && !media.data) {
      setErrorImage(false);
      setErrorVideo(false);
    }
  }, [media]);

  const resetValues = () => {
    setMedia({});
    setTextInfo({});
    changeLeftSidebar('');
    setMediaEdit(false);
    setMediaOptions(defaultOptions);
  };

  const onFileDrop = (e: any, type: string) => {
    const uploadedFile = e.target.files;
    let fileReader = new FileReader();
    const newFile = uploadedFile[0];
    if (newFile) {
      fileReader.onload = () => {
        const file = {
          data: fileReader.result,
          file: newFile,
          uploaded: false,
          failed: false,
          type: type === 'image' ? 1 : 3,
        };
        setMedia(file);
      };
      fileReader.readAsDataURL(newFile);
      fileReader.onabort = () => {
        alert('Reading aborted');
      };
      fileReader.onerror = () => {
        alert('Reading error!');
      };
    }
  };

  const createNoteHotspot = async () => {
    ReportCustomEvent(EnumCategory.UploadStillImage, EnumEvent.UploadStillImage);
    setMediaEdit(false);
    setLoading(true);
    const title = media.type === 3 ? textInfo.mediaTitle : textInfo.imageTitle;
    const description = media.type === 3 ? textInfo.mediaDescription : textInfo.imageDescription;
    const hotspotIconImage = media.type === 3 ? 'camera' : 'image';
    const attributes = media.type === 3 ? { ...mediaOptions, version: 1 } : { version: 1 };

    const mediaHotspot = {
      type: media.type,
      name: title,
      description: description,
      srcId: stillImageHotspot.id,
      phi: stillImageHotspot.pitch,
      theta: stillImageHotspot.yaw,
      image: hotspotIconImage,
      attributes: attributes,
      destId: stillImageHotspot.id,
    };

    try {
      await createCustomMediaHotspot(mediaHotspot, media.file);
      getHotspotList();
      toggleSuccessNotification('Media has been added. Double-click on the media icon to make changes.');
      resetValues();
      setLoading(false);
    } catch (error) {
      if (media.type === 1) {
        setErrorImage(true);
      } else {
        setErrorVideo(true);
      }
      setLoading(false);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const deleteMediaHotspot = async () => {
    setMediaEdit(false);
    try {
      await deleteHotspot(selectedHotspot.id);
      changeLeftSidebar('');
      toggleSuccessNotification('Your Media has been deleted ');
      getHotspotList(selectedHotspot.id);
      // Reset fields
      setTextInfo({});
      setMedia({});
      setMediaOptions(defaultOptions);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const updateMediaHotspot = async () => {
    ReportCustomEvent(EnumCategory.UploadMedia, EnumEvent.UploadMedia);
    setMediaEdit(false);
    setLoading(true);
    const title = media.type === 3 ? textInfo.mediaTitle : textInfo.imageTitle;
    const description = media.type === 3 ? textInfo.mediaDescription : textInfo.imageDescription;
    const hotspotIconImage = media.type === 3 ? 'camera' : 'image';
    const attributes = media.type === 3 ? mediaOptions : null;

    const mediaHotspot: any = {
      id: selectedHotspot.id,
      type: media.type,
      name: title,
      description: description,
      image: hotspotIconImage,
      attributes: attributes,
      file: media.data,
    };

    try {
      await updateCustomHotspotMedia(mediaHotspot, media.file);
      changeLeftSidebar('');
      toggleSuccessNotification('Media changes have been applied');
      getHotspotList(selectedHotspot.id);
      // Reset fields
      setTextInfo({});
      setMedia({});
      setLoading(false);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <div className='modal-background-layer'></div>
      {loading && <SmallerLoadingSpinner />}
      <div className='editor-modal-smaller-container'>
        <div className='links-map-modal-title'>
          <h1 className='editor-modal-header-title'>
            <Trans id='Upload a still image or media' />
          </h1>
          <button onClick={() => resetValues()} className='close-button btn-remove-default'>
            <CloseButton />
          </button>
        </div>
        <Tabs tabIndex={0}>
          <ul className='still-image-tabs'>
            <TabHooks>
              <Trans id='Upload still image' />
            </TabHooks>
            <TabHooks>
              <Trans id='Upload media' />
            </TabHooks>
          </ul>
          <div style={{ height: '60%' }}>
            <PanelHooks>
              <div className='media-upload-container'>
                <p className='upload-still-image-subtitle'>
                  <Trans id='Select your preferred method to upload a still image. Format must be a JPEG or PNG.' />
                </p>
                <div className='still-image-container'>
                  <div className='drag-image-container'>
                    {errorImage && (
                      <div className='still-media-not-compatible'>
                        <Trans id='The image format is not compatible.' /> <br /> <Trans id='Make sure the media is jpg or png' />
                      </div>
                    )}
                    {media && media.data && media.type === 1 ? (
                      <div className='position-relative'>
                        <button className='btn-remove-default' onClick={() => setMedia({})}>
                          <img className='cross' src={cross} alt='cross' />
                        </button>
                        <img style={{ height: '200px', width: '100%', padding: '5px' }} src={media.data} alt='still' />
                      </div>
                    ) : (
                      <div className='still-image-drop-zone'>
                        <div className='still-image-helper-text'>
                          <img className='drop-media-img' src={imageIconDrop} alt='imageIconDrop' />
                          <Trans id='Drag your image here' /> <div className='media-drop-or'> or </div>{' '}
                          <div className='upload-media-button'>
                            <Trans id='Upload an image' />
                          </div>
                        </div>
                        <input
                          id='upload-multiple'
                          className='center-input-still-image'
                          type='file'
                          name='file-drop-zone'
                          accept='image/png, image/jpeg'
                          onDragOver={e => {
                            e.preventDefault();
                            // Not sure about this one
                            e.stopPropagation();
                          }}
                          onDrop={(e: any) => onFileDrop(e, 'image')}
                          onChange={(e: any) => onFileDrop(e, 'image')}
                        />
                      </div>
                    )}
                  </div>
                  <div style={{ flex: '1' }}>
                    <div className='image-container-title'>
                      <p className='add-image-label'>
                        <Trans id='Image title' />
                      </p>
                      <input
                        type='text'
                        className={`add-image-input ${textInfo.imageTitle !== '' ? 'valid-input' : ''}`}
                        value={textInfo.imageTitle ? textInfo.imageTitle : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTextInfo({ ...textInfo, imageTitle: e.target.value })}
                      />
                    </div>
                    <div className='image-container-description' style={{ marginTop: '15px' }}>
                      <p className='add-image-description-label'>
                        <Trans id='Description' />
                      </p>
                      <Trans
                        id={'Describe shorty your image'}
                        render={({ translation }: any) => (
                          <textarea
                            maxLength={200}
                            className={`add-image-text-area ${textInfo.imageDescription !== '' ? 'valid-input' : ''}`}
                            name='message'
                            rows={4}
                            placeholder={translation}
                            value={textInfo.imageDescription ? textInfo.imageDescription : ''}
                            onChange={(e: any) => setTextInfo({ ...textInfo, imageDescription: e.target.value })}
                          ></textarea>
                        )}
                      />
                      <p style={{ textAlign: 'right' }}>{textInfo.imageDescription ? textInfo.imageDescription.length : 0}/200</p>
                    </div>
                  </div>
                </div>
              </div>
            </PanelHooks>
            <PanelHooks>
              <div className='media-upload-container'>
                <p className='upload-still-image-subtitle'>
                  <Trans id='Select your preferred method to upload media. Media format must be MP4.' />
                </p>
                <div className='still-image-container'>
                  <div className='drag-image-container'>
                    {errorVideo && (
                      <div className='still-media-not-compatible'>
                        <Trans id='The media format is not compatible.' /> <br /> <Trans id='Make sure the media is mp3 or mp4' />
                      </div>
                    )}
                    {media && media.data && media.type === 3 ? (
                      <div className='position-relative'>
                        <button className='btn-remove-default' onClick={() => setMedia({})}>
                          <img className='cross' src={cross} alt='cross' />
                        </button>
                        <video
                          style={{ height: '200px', width: '100%', padding: '5px' }}
                          controls={mediaOptions.enableControls}
                          autoPlay={mediaOptions.enableAutoplay}
                          muted={mediaOptions.isMuted}
                          src={media.data}
                        />
                      </div>
                    ) : (
                      <div className='still-image-drop-zone'>
                        <div className='still-image-helper-text'>
                          <img className='drop-media-img' src={mediaIconDrop} alt='imageIconDrop' />
                          <Trans id='Drag your media file here' />{' '}
                          <div className='media-drop-or'>
                            {' '}
                            <Trans id='or' />{' '}
                          </div>
                          <div className='upload-media-button'>
                            <Trans id='Upload media' />
                          </div>
                        </div>
                        <input
                          id='upload-multiple'
                          className='center-input-still-image'
                          type='file'
                          name='file-drop-zone'
                          accept='video/mp4,video/x-m4v,video/*'
                          onDragOver={e => {
                            e.preventDefault();
                            // Not sure about this one
                            e.stopPropagation();
                          }}
                          onDrop={(e: any) => onFileDrop(e, 'video')}
                          onChange={(e: any) => onFileDrop(e, 'video')}
                        />
                      </div>
                    )}
                    <div className='media-control-options'>
                      <div className='media-controls-container'>
                        <div>
                          <button
                            className='btn-remove-default'
                            onClick={() => setMediaOptions({ ...mediaOptions, enableAutoplay: !mediaOptions.enableAutoplay })}
                          >
                            <img src={mediaOptions.enableAutoplay ? checkboxChecked : checkboxUnchecked} alt='checked' />
                          </button>
                        </div>
                        <div>
                          <img className='media-control-options-img' src={autoPlay} alt='control-options' />
                          <Trans id='Autoplay' />
                        </div>
                      </div>
                      <div className='media-controls-container'>
                        <div>
                          <button
                            className='btn-remove-default'
                            onClick={() => setMediaOptions({ ...mediaOptions, isMuted: !mediaOptions.isMuted })}
                          >
                            <img src={mediaOptions.isMuted ? checkboxChecked : checkboxUnchecked} alt='checked' />
                          </button>
                        </div>
                        <div>
                          <img className='media-control-options-img' src={mute} alt='control-options' />
                          <Trans id='Mute' />
                        </div>
                      </div>
                      <div className='media-controls-container'>
                        <div>
                          <button
                            className='btn-remove-default'
                            onClick={() => setMediaOptions({ ...mediaOptions, enableControls: !mediaOptions.enableControls })}
                          >
                            <img src={mediaOptions.enableControls ? checkboxChecked : checkboxUnchecked} alt='checked' />
                          </button>
                        </div>
                        <div>
                          <img className='media-control-options-img' src={controls} alt='control-options' />
                          <Trans id='Controls' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ flex: '1' }}>
                    <div className='media-container-title'>
                      <p className='add-media-label'>
                        <Trans id='Media title' />
                      </p>
                      <input
                        type='text'
                        className={`add-media-input ${textInfo.mediaTitle !== '' ? 'valid-input' : ''}`}
                        value={textInfo.mediaTitle ? textInfo.mediaTitle : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTextInfo({ ...textInfo, mediaTitle: e.target.value })}
                      />
                    </div>
                    <div className='media-container-description' style={{ marginTop: '15px' }}>
                      <p className='add-media-description-label'>
                        <Trans id='Description' />
                      </p>
                      <Trans
                        id={'Describe shorty your media file'}
                        render={({ translation }: any) => (
                          <textarea
                            maxLength={200}
                            className={`add-media-text-area ${textInfo.mediaDescription !== '' ? 'valid-input' : ''}`}
                            name='message'
                            rows={4}
                            placeholder={translation}
                            value={textInfo.mediaDescription ? textInfo.mediaDescription : ''}
                            onChange={(e: any) => setTextInfo({ ...textInfo, mediaDescription: e.target.value })}
                          ></textarea>
                        )}
                      />
                      <p style={{ textAlign: 'right' }}>{textInfo.mediaDescription ? textInfo.mediaDescription.length : 0}/200</p>
                    </div>
                  </div>
                </div>
              </div>
            </PanelHooks>
          </div>
        </Tabs>
        {mediaEdit ? (
          <div className='save-changes-add-media'>
            <button className='apply-layer-button  apply-layer-active' style={{ marginRight: '15px' }} onClick={() => deleteMediaHotspot()}>
              <Trans id='Delete media' />
            </button>
            <button
              disabled={!validation}
              className={validation ? 'new-blur-active-button button-hover' : 'apply-layer-button'}
              style={{ marginLeft: '15px' }}
              onClick={() => updateMediaHotspot()}
            >
              <Trans id='Save changes' />
            </button>
          </div>
        ) : (
          <div className='save-changes-add-media'>
            <button
              className='apply-layer-button button-hover apply-layer-active'
              style={{ marginRight: '15px' }}
              onClick={() => resetValues()}
            >
              <Trans id='Cancel' />
            </button>
            <button
              disabled={!validation}
              className={validation ? 'new-blur-active-button button-hover' : 'apply-layer-button'}
              style={{ marginLeft: '15px' }}
              onClick={() => createNoteHotspot()}
            >
              <Trans id='Save changes' />
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default StillImage;
