import React, { useEffect, createContext, useState, useContext } from 'react';

const AuthContext = createContext<any>({});

const AuthProvider = (props: object) => {
  const [auth, setAuth] = useState<boolean | null>(null);
  const loggedInUser = localStorage.getItem('Bearer');

  useEffect(() => {
    if (loggedInUser) {
      handleLoginState();
    }
  }, [loggedInUser]);

  const handleLoginState = () => {
    setAuth(true);
  };

  const handleLogoutState = () => {
    setAuth(false);
  };

  const authContextValue = { handleLoginState, handleLogoutState, auth };

  return <AuthContext.Provider value={authContextValue} {...props} />;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
