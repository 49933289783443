import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import oneActive from '../../../images/tours/one-active.svg';
import oneInactive from '../../../images/tours/one-inactive.svg';
import twoActive from '../../../images/tours/two-active.svg';
import binIcon from '../../../images/tours/bin-icon.svg';
import twoInactive from '../../../images/tours/two-inactive.svg';
import UploadingSpinner from '../../utils/loading-spinner/uploading-spinner';
import cross from '../../../images/general-icons/cross.svg';
import imageIcon from '../../../images/viewer-icons/image-drop-icon.svg';
import retryIcon from '../../../images/viewer-icons/retry.svg';
import greenTick from '../../../images/viewer-icons/green-tick.svg';
import { IModalContent } from '../tour-views/interface';
import { ReactComponent as CloseButton } from '../../../images/icons/modal-icons/close-modal.svg';
import { getCompletedElement, getFailedElement, getPendingElement } from '../image-loading-utils/image-loading-utils';
import { removeExtension } from '../images-editor/image-utils';
import { EnumUploadState, IFileArray } from '../../editing-viewer/interaces';
import {
  createFloorplan,
  createFloorplanHotspot,
  deleteFloorplan,
  deleteFloorplanHotspot,
  uploadFloorplan,
} from '../../../api-helper/api-floorplan';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import { IFloorplans } from '../../../api-helper/interface/interfaces';
import SearchBar from '../searchbar/searchbar';
import defaultImage from '../../../images/empty.svg';
import { GenerateUUID } from '@vva/pannellum-viewer';
import { IFloorplanHotspots, ISelectedFloorPlan } from '../../main/tour/tabs/floorplan';
import { EnumNotificationType } from '../notifications/notification';
import { linguiTranslateToString } from '../lingui-utils/utils-lingui';

interface IProps {
  hotspots?: IFloorplanHotspots[];
  setHotspots: (state: IFloorplanHotspots[]) => void;
  selectedFloorplan?: ISelectedFloorPlan;
  changeSelectedFlooplan: (floorplanId: string, photoUrl: string) => void;
  panoramas: any;
  floorplanData: IFloorplans[] | undefined;
  hideModal: () => void;
  modalContent: IModalContent;
  loadFloorplan: () => void;
  toggleNotification: (type: EnumNotificationType, message?: string) => void;
  getFloorplansAsync: () => void;
  filesArray: any[];
  setFilesArray: (state: any[]) => void;
  currentTour: any;
  setCurrentTour: (floorplanExist: boolean) => void;
}

const UploadFloorplanModal: React.FC<IProps> = ({
  hotspots,
  setHotspots,
  selectedFloorplan,
  changeSelectedFlooplan,
  panoramas,
  floorplanData,
  hideModal,
  modalContent,
  loadFloorplan,
  toggleNotification,
  getFloorplansAsync,
  filesArray,
  setFilesArray,
  currentTour,
  setCurrentTour,
}) => {
  const [imagePreview, setImagePreview] = useState<boolean>(false);
  const [editLinks, setEditLinks] = useState<boolean>(false);
  const { handleLogoutState } = useAuth();
  const [searchWord, setSearchWord] = useState<string>();
  const [filteredPanoramas, setFilteredPanoramas] = useState<any>();
  const [selectedHotspotDelete, setSelectedHotspotDelete] = useState<string>();
  const [disabledSelectDest, setDisabledSelectDest] = useState<boolean>(true);
  const [selectedDest, setSelectedDest] = useState<string>();
  const [validHotspot, setValidHotspot] = useState(false);
  const [currentHotspot, setCurrentHotspot] = useState<string>();
  const [expandFloorplan, setExpandFloorplan] = useState<boolean>(false);

  useEffect(() => {
    if (searchWord || searchWord !== '') {
      if (filteredPanoramas?.length) {
        const filterBySearch = filteredPanoramas.filter((panorama: any) => {
          const panoramaName = panorama?.name.toLowerCase();
          const search = searchWord?.toLowerCase();
          return panoramaName.includes(search);
        });
        setFilteredPanoramas(filterBySearch);
      }
    } else {
      if (panoramas.length) {
        setFilteredPanoramas(panoramas);
      }
    }
  }, [searchWord]);

  useEffect(() => {
    if (selectedDest && selectedFloorplan && currentHotspot) {
      setValidHotspot(true);
    } else {
      setValidHotspot(false);
    }
  }, [selectedDest, selectedFloorplan, currentHotspot]);

  useEffect(() => {
    if (floorplanData?.length && floorplanData[0].photoUrl && !selectedFloorplan) {
      changeSelectedFlooplan(floorplanData[0].floorplanId, floorplanData[0].photoUrl);
    }
  }, [floorplanData, selectedFloorplan]);

  useEffect(() => {
    if (panoramas.length) {
      setFilteredPanoramas(panoramas);
    }
  }, [panoramas]);

  useEffect(() => {
    if (filesArray.length < 1) {
      setImagePreview(false);
    } else {
      setImagePreview(true);
    }
  }, [filesArray]);

  const onFileDrop = (e: any) => {
    const file = e.target.files;
    const arr: IFileArray[] = [];
    for (let i = 0; i <= file.length; i++) {
      let fileReader = new FileReader();
      const newFile = file[i];
      if (newFile) {
        fileReader.onload = async () => {
          const file: IFileArray = {
            data: fileReader.result,
            file: newFile,
            name: removeExtension(newFile.name),
            completion: EnumUploadState.Pending,
            id: '',
          };
          arr.push(file);
          addLoadedFileToArray(arr);
          const uploadFile = await uploadFloorplanAsync(file);
          arr.pop();
          file.completion = uploadFile.completion;
          file.id = uploadFile.id || '';
          arr.push(file);
          addLoadedFileToArray(arr);
        };
        fileReader.readAsDataURL(newFile);
        fileReader.onabort = () => {
          alert('Reading aborted');
        };
        fileReader.onerror = () => {
          alert('Reading error!');
        };
      }
    }
  };

  const uploadFloorplanAsync = async (file: IFileArray) => {
    //  Only do this if the upload has not been uploaded yet
    try {
      // Create panorama
      const createNewPanorama = await createFloorplan(modalContent.tourId || '');
      // Upload panorama to S3
      await uploadFloorplan(file.file, createNewPanorama.id);
      file.id = 'createNewPanorama.id';
      file.completion = EnumUploadState.Completed;
      loadFloorplan();
      return file;
    } catch (error) {
      console.log(error);
      file.completion = EnumUploadState.Failed;
      return file;
    }
  };

  const addLoadedFileToArray = (file: any) => {
    const joinArrays = filesArray.concat(file);
    setFilesArray(joinArrays);
    setImagePreview(true);
  };

  const removeLoadedFileFromArray = (index: number) => {
    const arr = filesArray.filter((a: any, i: number) => i !== index);
    setFilesArray(arr);
  };

  const retryUploadSinglePanorama = async (index: number) => {
    let tempFilesArray = [...filesArray];
    const panoPending = getPendingElement(tempFilesArray, index);
    setFilesArray(panoPending);
    try {
      const uploadFile = await uploadFloorplan(tempFilesArray[index].file, tempFilesArray[index].id);
      const panoCompleted = getCompletedElement(tempFilesArray, index, uploadFile.id);
      setFilesArray(panoCompleted);
    } catch (error) {
      const panoCompleted = getFailedElement(tempFilesArray, index);
      setFilesArray(panoCompleted);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
    setFilesArray(tempFilesArray);
  };

  const handlerSetSearchBarValue = (keywordValue: string) => {
    if (!disabledSelectDest) {
      setSearchWord(keywordValue);
    }
  };

  const addHotspotDestination = (destId: string) => {
    setSelectedDest(destId);
  };

  const removeFloorplanHotspot = async (id: string | undefined, pending?: boolean) => {
    if (pending || !id) {
      getFloorplansAsync();
      return;
    }
    try {
      await deleteFloorplanHotspot(id);
      toggleNotification(EnumNotificationType.Success, linguiTranslateToString('Hotspot deleted'));
      getFloorplansAsync();
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const createFloorplanHotspotAsync = async () => {
    const findHotspot = hotspots?.find(hotspot => hotspot.id === currentHotspot);
    if (findHotspot?.x && findHotspot?.y && selectedDest && selectedFloorplan?.floorplanId) {
      const data = {
        x: findHotspot.x,
        y: findHotspot.y,
        destId: selectedDest,
        floorplanId: selectedFloorplan.floorplanId,
      };
      try {
        setCurrentTour({ ...currentTour, floorplanExist: true });
        await createFloorplanHotspot(data);
        getFloorplansAsync();
        toggleNotification(EnumNotificationType.Success, linguiTranslateToString('Hotspot created successfully'));
        setSelectedDest('');
      } catch (error) {
        const err = error as Error;
        handleError(err, handleLogoutState);
      }
    }
  };

  const handlerSearching = (isSearchbarLoaded: boolean, page: number) => {};

  const deleteFloorplanAsync = async (floorplanId: string) => {
    try {
      await deleteFloorplan(floorplanId);
      loadFloorplan();
      setCurrentTour({ ...currentTour, floorplanExist: false });
      toggleNotification(EnumNotificationType.Success, linguiTranslateToString('Floorplan deleted successfully'));
      if (floorplanId === selectedFloorplan?.floorplanId) {
        changeSelectedFlooplan('', '');
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  return (
    <>
      {expandFloorplan && (
        <div className='expanded-floorplan-container'>
          <div className='expanded-floorplan'>
            <button className='minimise-button' style={{ right: '10%' }} onClick={() => setExpandFloorplan(false)} />
            <img src={selectedFloorplan?.photoUrl} className='selected-floorplan-pop-up' alt='main-floorplan' />
          </div>
        </div>
      )}
      <div className='modal-background-layer'>
        <div className={imagePreview ? 'modal-big-floorplan' : 'modal-floorplan'}>
          <div className='modal-header-floor'>
            <div className='modal-header-title'>
              <div className='header-floorplan'>
                {!editLinks ? (
                  <>
                    <div className='active-font'>
                      <img className='img-align' src={oneActive} alt='one-green' style={{ marginRight: '10px' }} />
                      <Trans id='Upload a floorplan' />
                    </div>
                    <div className='inactive-font'>
                      <img className='img-align' src={twoInactive} alt='one-green' style={{ marginRight: '10px' }} />
                      <Trans id='Create links' />
                    </div>
                  </>
                ) : (
                  <>
                    <div className='inactive-font'>
                      <img className='img-align' src={oneInactive} alt='one-green' style={{ marginRight: '10px' }} />
                      <Trans id='Upload a floorplan' />
                    </div>
                    <div className='active-font'>
                      <img className='img-align' src={twoActive} alt='one-green' style={{ marginRight: '10px' }} />
                      <Trans id='Create links' />
                    </div>
                  </>
                )}
              </div>
            </div>
            <button
              onClick={() => {
                hideModal();
                loadFloorplan();
              }}
              className='btn-remove-default'
              id='close-modal-button'
            >
              <CloseButton />
            </button>
          </div>
          {editLinks ? (
            <div className='modal-body-floorplan'>
              <div className='edit-modal-inside-container' style={{ height: 'auto' }}>
                <div className='title-add-modal' style={{ margin: '0 24px' }}>
                  <div className='upload-title-modal-image'>
                    <Trans id='Double-click on the floorplan to create links, then select an image from the list.' />
                  </div>
                  <div className='upload-title-modal-image-second'>
                    <Trans id='To delete a link, double-click the dot on the floorplan.' />
                  </div>
                </div>
                <div className='floorplan-container-modal'>
                  <div className='floorplan-images'>
                    <div style={{ display: 'flex', flex: '1 1' }}>
                      <div className='floorplan-list-left' style={{ height: '360px', padding: 'auto' }}>
                        {floorplanData?.length &&
                          floorplanData.map((floorplan, i) => (
                            <button
                              key={i}
                              className='floorplan-option-container'
                              style={{ opacity: selectedFloorplan?.floorplanId === floorplan.floorplanId ? 1 : 0.4 }}
                              onClick={() => changeSelectedFlooplan(floorplan.floorplanId, floorplan.photoUrl)}
                            >
                              <img src={floorplan.photoUrl} className='floorplan-option' alt='floorplan' />
                            </button>
                          ))}
                      </div>
                      <div className='floorplan-selected-container'>
                        {hotspots?.map((hotspot, i) => (
                          <div
                            key={i}
                            style={{ left: `${hotspot.x}%`, top: `${hotspot.y}%` }}
                            onDoubleClick={() => {
                              setSelectedHotspotDelete(hotspot.id);
                            }}
                            className='floorplan-hotspot'
                          >
                            <div style={{ position: 'relative' }}>
                              {selectedHotspotDelete === hotspot.id && (
                                <div style={{ position: 'relative' }}>
                                  <button
                                    className='btn-remove-default hotspot-delete'
                                    onClick={() => removeFloorplanHotspot(hotspot.id, hotspot.pending)}
                                  >
                                    <img src={binIcon} alt='bin'></img>
                                    <Trans id='Delete link' />
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                        <img
                          onClick={() => setSelectedHotspotDelete('')}
                          onDoubleClick={(e: any) => {
                            let tempHotspots = hotspots ? [...hotspots] : [];
                            if (currentHotspot) {
                              tempHotspots = tempHotspots.filter(hotspot => hotspot.id !== currentHotspot);
                            }
                            setDisabledSelectDest(false);
                            setSelectedHotspotDelete('');
                            const id = GenerateUUID();
                            setCurrentHotspot(id);
                            const offset = e.target.getBoundingClientRect();
                            const hotspotData = {
                              id: id,
                              pending: true,
                              x: Math.floor(((e.pageX - offset.left) / offset.width) * 10000) / 100,
                              y: Math.floor(((e.pageY - offset.top) / offset.height) * 10000) / 100,
                            };
                            tempHotspots.push(hotspotData);
                            setHotspots(tempHotspots);
                          }}
                          src={selectedFloorplan?.photoUrl}
                          className='selected-floorplan-pop-up'
                          alt='main-floorplan'
                        />
                        <button className='expand-button' onClick={() => setExpandFloorplan(true)} />
                      </div>
                    </div>
                    <div style={{ flex: '1 1' }}>
                      <div id='searchbar' style={{ height: '32px', marginBottom: '32px' }}>
                        <Trans
                          id={'Search for an image'}
                          render={({ translation }) => (
                            <SearchBar
                              placeholder={String(translation)}
                              searchBarValue={searchWord || ''}
                              handlerSetSearchBarValue={handlerSetSearchBarValue}
                              handlerSearching={handlerSearching}
                              floorplanSearch={true}
                              searchFloorPlanDisabled={disabledSelectDest}
                            />
                          )}
                        />
                      </div>
                      <div className={`container-download-images-floorplan`} style={{ opacity: disabledSelectDest ? 0.4 : 1 }}>
                        {filteredPanoramas &&
                          filteredPanoramas.length > 0 &&
                          filteredPanoramas.map((selectedPanorama: any, i: number, index: any) => (
                            <div
                              id='edit-panoramas'
                              key={i}
                              style={{ position: 'relative' }}
                              className={'image-for-download-container-floorplan'}
                              onClick={() => {
                                if (!disabledSelectDest) {
                                  addHotspotDestination(selectedPanorama.id);
                                }
                              }}
                            >
                              {(selectedPanorama.id === selectedDest ||
                                hotspots?.find(element => element.destId === selectedPanorama.id)) && (
                                <div>
                                  <button className='btn-retry-add' style={{ left: '62px', top: '25px', background: 'transparent' }}>
                                    <img src={greenTick} alt='cross' />
                                  </button>
                                </div>
                              )}

                              <img
                                className={
                                  hotspots?.find(element => element.destId === selectedPanorama.id) ? 'image-disable' : 'image-for-download'
                                }
                                style={{ width: '162px' }}
                                src={selectedPanorama.thumbnailPreviewUrl || defaultImage}
                                alt='panoramas'
                              />
                              <div className='img-label'>{selectedPanorama.name}</div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='display-flex flex-center' style={{ width: '100%' }}>
                <button
                  className={`button-no-floor-save-change  ${!validHotspot ? 'button-no-floor-disabled' : ''}`}
                  onClick={createFloorplanHotspotAsync}
                  disabled={!validHotspot}
                >
                  <Trans id='Save changes' />
                </button>
              </div>
            </div>
          ) : (
            <div className='modal-body-floorplan'>
              <div className='edit-modal-inside-container-floorplan'>
                <div className='title-add-modal'>
                  <div className='upload-title-modal-image'>
                    <Trans id='Upload your floorplan images below.' />
                  </div>
                  <div className='upload-title-modal-image-second'>
                    <Trans id='The image format must be PNG. Maximum size is 50 MB.' />
                  </div>
                </div>
                {imagePreview && (
                  <div className='viewer-upload-preview-floorplan'>
                    {filesArray.map((file: any, index: number) => (
                      <div
                        id='upload-preview'
                        key={index}
                        style={{ position: 'relative' }}
                        className='preview-images-container-viewer-add-container'
                      >
                        {file.completion === EnumUploadState.NotUploaded && (
                          <button
                            id='remove-upload'
                            className='btn-remove-default btn-cross-add-container'
                            onClick={() => removeLoadedFileFromArray(index)}
                          >
                            <img src={cross} alt='cross' />
                          </button>
                        )}
                        {file.completion === EnumUploadState.Pending && (
                          <div className='uploading-image-spinner-create-add' style={{ top: '70px', left: '90px' }}>
                            <UploadingSpinner />
                          </div>
                        )}
                        {file.completion === EnumUploadState.Failed && (
                          <button
                            className='btn-retry-add'
                            style={{ right: '-10px', top: '-10px', left: 'auto' }}
                            onClick={() => retryUploadSinglePanorama(index)}
                          >
                            <img src={retryIcon} alt='cross' />
                          </button>
                        )}
                        {file.completion === EnumUploadState.Completed && (
                          <button
                            id='remove-upload'
                            className='btn-remove-default btn-cross-add-container'
                            style={{ top: '-20px' }}
                            onClick={() => deleteFloorplanAsync(file.id)}
                          >
                            <div className='floorplan-delete-cross' />
                          </button>
                        )}

                        <img className='viewer-preview-image-add-floorplan' src={file.data} alt='preview' />
                      </div>
                    ))}
                  </div>
                )}
                <div className='viewer-drop-zone' style={{ height: imagePreview ? '150px' : '' }}>
                  {!imagePreview && <img className='icon-upload-image' src={imageIcon} alt='icon' />}
                  <div className='helper-text-image-upload'>
                    <Trans id='Drop one or more floorplans here' />
                    <div className='media-drop-or-add'>
                      <Trans id='or' />
                    </div>
                  </div>
                  <button className='select-an-image-file'>
                    <Trans id='Upload an floorplan' />
                  </button>
                  <input
                    style={{ height: imagePreview ? '150px' : '' }}
                    id='upload-multiple'
                    className='center-input'
                    type='file'
                    name='file-drop-zone'
                    multiple
                    onDragOver={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDrop={(e: any) => onFileDrop(e)}
                    onChange={(e: any) => onFileDrop(e)}
                  />
                </div>
              </div>
              {floorplanData && floorplanData?.length > 0 && modalContent.editFloorplan ? (
                <div className='display-flex flex-center' style={{ width: '100%', marginBottom: '20px', height: '10%' }}>
                  <button className='button-no-floor button-hover' onClick={() => setEditLinks(true)}>
                    <Trans id='Skip and go next' />
                  </button>
                </div>
              ) : (
                <div className='display-flex flex-center' style={{ width: '100%', marginBottom: '20px', height: '10%' }}>
                  {!floorplanData || floorplanData?.length < 1 ? (
                    <button className='button-no-floor button-no-floor-disabled'>
                      <Trans id='Create links' />
                    </button>
                  ) : (
                    <button className='button-no-floor-sucess button-hover' disabled={!floorplanData} onClick={() => setEditLinks(true)}>
                      <Trans id='Create links' />
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UploadFloorplanModal;
