import { ClientApi } from '@vva/front';

const clientApi = new ClientApi();

export interface ICreateMeeting {
	id: string;
	code: string;
	tourUrl: string;
	organiserId: string;
	status: number;
}

export interface IOrganiser {
	firstName: string;
	lastName: string;
	token: string;
	email: string;
	phoneNumber: string;
	id?: string;
}

export interface IYourDetails {
	firstName?: string;
	lastName?: string;
	email?: string;
	phoneNumber?: string;
	token?: string
}

// conditional window ENV for testing with jest
clientApi.setHeaders({
	'X-Api-Key': (window.ENV && window.ENV.REACT_APP_API_KEY ? window.ENV.REACT_APP_API_KEY : null) || process.env.REACT_APP_API_KEY,
});

export const createMeeting = async (tourUrl: string, tourName: string, tourOwner: string): Promise<ICreateMeeting> => {
	const meetingResponse: any = await clientApi.postData(
		`${window.ENV.REACT_APP_LIVE_API_URL || process.env.REACT_APP_LIVE_API_URL}/meeting`, { tourUrl, tourName, tourOwner }
	);
	return meetingResponse;
};

export const createOrganiser = async (meetingCode: string, body: IYourDetails): Promise<IOrganiser> => {

	const organiserResponse: any = await clientApi.postData(
		`${window.ENV.REACT_APP_LIVE_API_URL || process.env.REACT_APP_LIVE_API_URL}/meeting/${meetingCode}/organiser`, body
	);
	return organiserResponse;
}