import Encryption from "./Encryption";

export default class Storage {
	static loadSecureStorage(secret: string, key: string) {
		if (typeof window !== "undefined") {
			const encrypted = localStorage.getItem(key);
			const unSecureData = Encryption.decrypt(encrypted, secret, false);
			return unSecureData;
		} else {
			return null;
		}
	}
	static setSecureStorage(secret: string, key: string, value: string) {
		if (typeof window !== "undefined") {
			const secureData = Encryption.encrypt(value, secret, false);
			localStorage.setItem(key, secureData);
			return true;
		} else {
			return false;
		}
	}

	static pushJwtHistory(secret: string, previousJwt: string | null, origin: string) {
		const history = JSON.parse(this.loadSecureStorage(secret, "History") || "[]");
		if (previousJwt) {
			history.push({ previousJwt, origin });
			this.setSecureStorage(secret, 'History', JSON.stringify(history));
		}
	}

	static popJwtHistory(secret: string) {
		const history = JSON.parse(this.loadSecureStorage(secret, "History") || "[]");
		if (history && history.length) {
			const jwt = history.pop();
			this.setSecureStorage(secret, 'History', JSON.stringify(history.previousJwt));
			return jwt;
		} else {
			return null;
		}
	}
}