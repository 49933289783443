import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { getHotspots, createHotspot } from '../../../api-helper/api-hotspots';
import { Trans } from '@lingui/react';
import { ReactComponent as CloseButton } from '../../../images/viewer-icons/cross.svg';
import oneWhite from '../../../images/viewer-icons/one-white.svg';
import twoWhite from '../../../images/viewer-icons/two-white.svg';
import backArrow from '../../../images/viewer-icons/left-circle-arrow.svg';
import forwardArrow from '../../../images/viewer-icons/right-circle-arrow.svg';
import greenTick from '../../../images/viewer-icons/green-tick.svg';
// Viewer
import { Viewer } from '@vva/pannellum-viewer';
// import { IViewerConfig } from '@vva/pannellum-viewer/lib/interfaces/IViewerConfig';
import { IHotspot } from '@vva/pannellum-viewer/lib/interfaces/IHotspot';
import { customHotspotRoomFunc } from '@vva/pannellum-viewer';
import { getCoordinates } from '../viewer-canvas-utils/hotspot-factory';
import './modals.scss';
import { paginateModalSlider } from '../../utils/pagination/utils';
import { IReadAllPanoramaLogos } from '../../../api-helper/interface/interfaces';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';

interface IProps {
  tour: any;
  currentPanorama: any;
  panoramaData: any;
  changeLeftSidebar: (option: string) => void;
  newHotspot: any;
  getHotpostList: () => void;
  toggleNewHotspotEditorMode: (state: boolean) => void;
  updatePanoramaList: () => void;
}

// Viewer object
let miniViewer: any;

const AddHotspot: React.FC<IProps> = ({
  tour,
  currentPanorama,
  panoramaData,
  changeLeftSidebar,
  newHotspot,
  getHotpostList,
  toggleNewHotspotEditorMode,
  updatePanoramaList,
}) => {
  const { handleLogoutState } = useAuth();
  const [returnLinkPage, setReturnLinkPage] = useState<boolean>(false);
  const [returnPanorama, setReturnPanorama] = useState<any>();
  const [panorama, setPanorama] = useState<any>();
  const [panoramaSelected, setPanoramaSelected] = useState<boolean>(false);
  const [newHotspotLink, setNewHotspotLink] = useState<any>();
  const [returnLink, setReturnLink] = useState<any>();
  const [miniViewerLoaded, setMiniViewerLoaded] = useState<boolean>(false);
  const [linksReady, setLinksReady] = useState<boolean>(false);
  const [filteredPanoData, setFilteredPanoData] = useState<any>();
  // modal pagination slider
  const limit = 8;
  const [page, setPage] = useState<number>(0);
  const [pageArray, setPageArray] = useState<IReadAllPanoramaLogos[][]>([]);

  // This is componentWillUnmount
  const componentWillUnmount = useRef(false);

  useEffect(() => {
    // Create the values for the new hotspot
    if (newHotspot && returnPanorama) {
      setNewHotspotLink({
        name: null,
        srcId: newHotspot.id,
        destId: returnPanorama.id,
        phi: newHotspot.pitch,
        theta: newHotspot.yaw,
        attributes: { version: 1 },
      });
    }

    if (newHotspot) {
      const filteredPanos = panoramaData.filter((pano: any) => pano.id !== newHotspot.id);
      setFilteredPanoData(filteredPanos);
    }
  }, [newHotspot, returnPanorama, panoramaData]);

  useEffect(() => {
    // Validation for save hotspots button
    if (newHotspotLink && returnLink) {
      setLinksReady(true);
    } else {
      setLinksReady(false);
    }
  }, [newHotspotLink, returnLink]);

  useEffect(() => {
    if (miniViewerLoaded) {
      miniViewer.setOnDoubleClick(setStartingPoint, true);
    }
  }, [miniViewerLoaded]);

  useEffect(() => {
    // Validation for when a return panorama is selected
    if (panorama === '' || !panorama) {
      setPanoramaSelected(false);
    } else {
      setPanoramaSelected(true);
    }
  }, [panorama]);

  useEffect(() => {
    // Load existing hotspots inside mini viewer
    const getPanoramaHotspots = async () => {
      try {
        const getPanoramaHotspots: any = await getHotspots(returnPanorama.id);
        for (const hotspot in getPanoramaHotspots) {
          createHotspotLink(getPanoramaHotspots[hotspot]);
        }
      } catch (error) {
        const err = error as Error;
        handleError(err, handleLogoutState);
      }
    };
    if (miniViewerLoaded) {
      getPanoramaHotspots();
    }
  }, [returnPanorama, miniViewerLoaded]);

  const previewPanoramas = (panorama: any) => {
    return (
      <div className='panorama-preview-modal'>
        <button
          className='btn-remove-default'
          onClick={() => {
            setReturnPanorama(panorama);
            setPanorama(panorama.cubeMap);
          }}
        >
          <div className='thumbnail-imagen-container'>
            <img className='thumbnail-preview-modal' src={panorama.thumbnailPreviewUrl} alt='thumbnail' />
            {returnPanorama && returnPanorama.id === panorama.id && (
              <div className='selected-panorama'>
                <img className='preview-tick-link' src={greenTick} alt='Success selection' />
              </div>
            )}
          </div>
          <p className='thumbnail-preview-modal-text'>{panorama.name}</p>
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (filteredPanoData) {
      const pagePanoramas = paginateModalSlider(filteredPanoData, limit);
      setPageArray(pagePanoramas);
    }
  }, [filteredPanoData]);

  const setStartingPoint = (coordinates: any) => {
    miniViewer.removeCurrentHotspot(3600);
    //Args
    const args: any = {
      iconColor: '#FFFFFF',
      backgroundColor: '#48C9B0',
      iconType: 'fa-angle-double-up',
      roomName: currentPanorama.name,
      src: currentPanorama.equiPreviewUrl,
    };
    //Hotspot object
    const hotspot: IHotspot = {
      pitch: coordinates[0],
      yaw: coordinates[1],
      type: 'info',
      id: 3600,
      hotspotLabel: 'link',
      cssClass: 'custom-hotspot-room',
      createTooltipFunc: customHotspotRoomFunc,
      createTooltipArgs: args,
    };

    // Add hotspot to viewer
    setReturnLink({
      name: null,
      srcId: returnPanorama.id,
      destId: newHotspot.id,
      phi: hotspot.pitch,
      theta: hotspot.yaw,
      attributes: { version: 1 },
    });

    miniViewer.addNewHotspot(hotspot);
  };

  const createHotspotLink = (hotspotData: any) => {
    // Creates hotspot using pitch and yaw
    const [yaw, pitch] = getCoordinates(currentPanorama, hotspotData);
    const hotspotColour = hotspotData.colors.split('#')[1];
    //Args
    const hotspotPreview = panoramaData.find((pano: any) => pano.id === hotspotData.destId);
    const args: any = {
      iconColor: '#FFFFFF',
      backgroundColor: '#48C9B0',
      iconType: 'fa-angle-double-up',
      roomName: hotspotPreview.name,
      src: hotspotPreview.equiPreviewUrl,
    };

    const hotspot: IHotspot = {
      pitch: pitch,
      yaw: yaw,
      type: 'info',
      text: hotspotData.name,
      id: hotspotData.id,
      hotspotColor: '#' + hotspotColour,
      hotspotLabel: 'link',
      cssClass: 'custom-hotspot-room',
      createTooltipFunc: customHotspotRoomFunc,
      createTooltipArgs: args,
    };

    miniViewer.addNewHotspot(hotspot);
  };

  const createHotspotLinks = async () => {
    ReportCustomEvent(EnumCategory.LinkCreation, EnumEvent.CreateReturnLink);

    try {
      await createHotspot(newHotspotLink);
      await createHotspot(returnLink);
      // Close the modal
      changeLeftSidebar('');
      toggleNewHotspotEditorMode(false);
      miniViewer.removeCurrentHotspot(3600);
      getHotpostList();
      updatePanoramaList();
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  /***************************************               Viewer                 *******************************************************/
  const style: CSSProperties = {
    height: '336px',
    width: '844px',
    borderRadius: '8px',
    margin: '0 108px',
  };

  useEffect(() => {
    // componentDidMount - Initialize Viewer
    if (panorama && returnLinkPage) {
      initViewer();
      componentWillUnmount.current = true;
    }

    // componentWillUnmount - Destroy Viewer
    return () => {
      if (componentWillUnmount.current) {
        miniViewer && miniViewer.destroyViewer();
        componentWillUnmount.current = false;
      }
    };
  }, [panorama, returnLinkPage]);

  const initViewer = () => {
    // code to run on component mount
    const config: any = {
      type: 'cubemap',
      cubeMap: panorama,
      autoLoad: true,
      northOffset: 247.5,
      showControls: false,
      crossOrigin: 'anonymous',
      doubleClickZoom: false,
      hfov: 120,
    };
    miniViewer = new Viewer('mini-panorama', config);

    miniViewer.setOnLoad(stopLoading);
  };

  const stopLoading = () => {
    setMiniViewerLoaded(true);
  };

  return (
    <div className='modal-full-screen'>
      <div className='modal-background-layer'></div>
      <div className='editor-modal-medium-container'>
        <div className='editor-modal-title'>
          <div className='select-hotspot-image' style={{ opacity: !returnLinkPage ? '1' : '0.4' }}>
            <div className='create-hotspot-steps'>
              <img src={oneWhite} alt='one' />
            </div>
            <h1 className='editor-modal-header-title'>
              <Trans id='Select image' />
            </h1>
          </div>
          <div className='select-return-link' style={{ opacity: returnLinkPage ? '1' : '0.4' }}>
            <div className='create-hotspot-steps'>
              <img src={twoWhite} alt='two' />
            </div>
            <h1 className='editor-modal-header-title'>
              <Trans id='Add a return link' />
            </h1>
          </div>
          <button
            onClick={() => {
              changeLeftSidebar('');
              toggleNewHotspotEditorMode(false);
            }}
            className='close-button btn-remove-default'
          >
            <CloseButton />
          </button>
        </div>
        <div className='editor-modal-content'>
          {returnLinkPage ? (
            <>
              <div className='modal-headers-container'>
                <p className='edit-modal-headers'>
                  <Trans
                    id='Double-click anywhere on the image to add a return link to the {panoramaName}.'
                    values={{ panoramaName: currentPanorama.name }}
                  />
                </p>
                <p className='edit-modal-headers subtitle'>
                  <Trans
                    id='After you save this action, you can continue adding links to the {panoramaName} or your other images.'
                    values={{ panoramaName: currentPanorama.name }}
                  />
                </p>
              </div>
              <div className='modal-content-container'>
                <div className='upload-container-viewer'>
                  <div id='mini-panorama' style={style} />
                </div>
              </div>
              <div className='modal-btn-container'>
                <button
                  className={`${linksReady ? 'editor-modal-btn-active button-hover' : 'editor-modal-btn '}`}
                  disabled={!linksReady}
                  onClick={() => createHotspotLinks()}
                >
                  <Trans id='Save and continue' />
                </button>
              </div>
            </>
          ) : (
            <>
              <div className='modal-headers-container'>
                <p className='edit-modal-headers'>
                  <Trans id='Click on one of the images below to select a destination for your link.' />
                </p>
                <p className='edit-modal-headers subtitle'>
                  <Trans
                    id='This will create a shortcut between the {panoramaName} and the selected image.'
                    values={{ panoramaName: currentPanorama.name }}
                  />
                </p>
              </div>
              <div className='modal-content-container'>
                <button
                  className={`btn-remove-default btn-modal-slider ${page === 0 ? 'hide' : 'show'}`}
                  onClick={() => setPage(page === 0 ? page : page - 1)}
                >
                  <img src={backArrow} alt='Back slider' />
                </button>
                <div className='edit-modal-inside-container'>
                  <div className='list-panoramas-return-link'>
                    {pageArray &&
                      pageArray.length > 0 &&
                      pageArray[page].map((thumbnail: any, index: number) => <div key={index}>{previewPanoramas(thumbnail)}</div>)}
                  </div>
                  <div className='list-panoramas-dots-navigation'>
                    {pageArray &&
                      pageArray.map((item: IReadAllPanoramaLogos[], index: number) => (
                        <span
                          key={index}
                          onClick={() => setPage(index)}
                          className={`dot-navigation ${page === index ? 'dot-navigation-active' : ''}`}
                        ></span>
                      ))}
                  </div>
                </div>
                <button
                  className={`btn-remove-default btn-modal-slider ${pageArray && page === pageArray.length - 1 ? 'hide' : 'show'}`}
                  onClick={() => setPage(page + 1 !== pageArray[page].length ? page + 1 : page)}
                >
                  <img src={forwardArrow} alt='Forward slider' />
                </button>
              </div>
              <div className='modal-btn-container'>
                <button
                  disabled={!panoramaSelected}
                  className={`editor-modal-btn ${panoramaSelected ? 'editor-modal-btn-active button-hover' : ''}`}
                  onClick={() => {
                    setReturnLinkPage(true);
                    ReportCustomEvent(EnumCategory.LinkCreation, EnumEvent.CreateLink);
                  }}
                >
                  <Trans id='Next: Add a return link' />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddHotspot;
