import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useAuth } from '../../../auth-routes/auth-context';
import { authenticate, logout } from '../../../api-helper/api-auth';
import vieweet from '../../../images/menu-icons/vieweet-logo.svg';
import { useConfig } from '../user-config-context/user-config-context';
import Storage from '../connect-utils/Storage';
import './nav-sidebar.scss';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { handleError } from '../../../api-helper/api-error-handling';

interface ISidebar {
  [key: string]: { [key: string]: boolean };
}

interface IProps {
  isTooltipActive?: boolean;
  isToolTipClicked?: boolean;
  activeTooltipHandler?: () => void;
}

const NavSidebar: React.FC<IProps> = ({ isTooltipActive, activeTooltipHandler, isToolTipClicked }) => {
  const { handleLogoutState } = useAuth();
  const config = useConfig();
  const [sidebar, setSidebar] = useState<ISidebar>({
    analytics: { active: false },
    settings: { active: false },
    profile: { active: false },
    admin: { active: false },
  });

  const logoutOnPress = async () => {
    try {
      await logout();
      const secret = window.ENV.REACT_APP_CLIENT_SECRET || process.env.REACT_APP_CLIENT_SECRET || '';
      const previousJwt = Storage.popJwtHistory(secret);
      if (previousJwt?.previousJwt) {
        await authenticate(previousJwt.previousJwt);
        if (previousJwt.origin === 'dashboard') {
          document.location.href = '../';
        } else {
          document.location.href = '../admin-management';
        }
      } else {
        handleLogoutState();
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  useEffect(() => {
    if (config && config.modules) {
      let sidebarTemp = { ...sidebar };
      for (const menu of config.modules) {
        const menuKey = Object.keys(menu);
        const key = sidebar[menuKey[0]];
        // if there is a config for a left sidebar option add it
        if (key) {
          sidebarTemp[menuKey[0]] = { active: menu[menuKey[0]] };
        }
        setSidebar(sidebarTemp);
      }
    }
  }, [config]);

  return (
    <div className='main-menu'>
      {config && (
        <div className='menu-icons'>
          <div className='sidebar-items-logo'>
            <Link id='vieweet-logo' to='/live-tours'>
              <img src={vieweet} alt='vieweet' className='vieweet-logo' />
            </Link>
          </div>
          <div
            id='dashboard-icon'
            className='sidebar-items'
            onClick={() => {
              //Report Custom Category and Event
              ReportCustomEvent(EnumCategory.Sidebar, EnumEvent.MainDashboard);
            }}
          >
            <NavLink
              exact
              to='/live-tours'
              className='icon-general sidebar-items-inactive-dashboard'
              isActive={() => ['/live-tours', '/users-tours', '/dollhouse-tours', '/archived-tours'].includes(window.location.pathname)}
              activeClassName='sidebar-items-active-dashboard noHover'
            />
          </div>
          {sidebar.analytics.active && (
            <div
              id='analytics-icon'
              className='sidebar-items'
              onClick={() => {
                //Report Custom Category and Event
                ReportCustomEvent(EnumCategory.Sidebar, EnumEvent.Analytics);
              }}
            >
              <NavLink
                to='/analytics'
                className='icon-general sidebar-items-inactive-analytics'
                activeClassName='sidebar-items-active-analytics noHover'
                isActive={() => ['/analytics', '/leads-generated', '/leads-live-sharing'].includes(window.location.pathname)}
              />
            </div>
          )}
          {sidebar.settings.active && (
            <div
              id='settings-icon'
              className='sidebar-items'
              onClick={() => {
                //Report Custom Category and Event
                ReportCustomEvent(EnumCategory.Sidebar, EnumEvent.Settings);
              }}
            >
              <NavLink
                to='/ai-features'
                className='icon-general sidebar-items-inactive-settings'
                activeClassName='sidebar-items-active-settings noHover'
                isActive={() =>
                  ['/ai-features', '/settings', '/branding', '/lead-generation', '/manage-subscription'].includes(window.location.pathname)
                }
              />
            </div>
          )}
          {sidebar.profile.active && (
            <div
              id='profile-icon'
              className='sidebar-items'
              onClick={() => {
                //Report Custom Category and Event
                ReportCustomEvent(EnumCategory.Sidebar, EnumEvent.Profile);
              }}
            >
              <NavLink
                to='/profile'
                className='icon-general sidebar-items-inactive-profile'
                activeClassName='sidebar-items-active-profile noHover'
                isActive={() => ['/profile', '/security-and-account'].includes(window.location.pathname)}
              />
            </div>
          )}
          {sidebar.admin.active && (
            <div className='sidebar-items'>
              <NavLink
                to='/admin-management'
                className='icon-general sidebar-items-inactive-admin'
                activeClassName='sidebar-items-active-admin noHover'
                isActive={() => {
                  return ['/admin-management', '/users-management', '/create-admin', '/edit-admin', '/create-user', '/edit-user'].includes(
                    window.location.pathname,
                  );
                }}
              />
            </div>
          )}
          {isTooltipActive && (
            <div onClick={activeTooltipHandler} className={`${isToolTipClicked ? 'tooltip-nav-active' : 'tooltip-nav'}`} />
          )}
          <div onClick={() => logoutOnPress()} className='sidebar-items side-exit-item' id='logout-btn'>
            <NavLink id='logout-icon' to='/logout' className='icon-general sidebar-items-inactive-logout' />
          </div>
        </div>
      )}
    </div>
  );
};

export default NavSidebar;
