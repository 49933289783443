// https://github.com/ericgio/react-bootstrap-typeahead/blob/master/docs/API.md
import React, { useState, useRef, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

interface Props {
  selectedProps: any;
  isDisabled: boolean;
  allowNew: boolean;
  labelKey: any;
  handleSelectedItem: (argument: any) => {};
  placeHolderValue?: string;
  dependentOn?: any;
  dataset: any;
  defaultValue?: any;
  IsResultHighlightOnly?: boolean;
}

const DropDownCountries = ({
  selectedProps,
  dataset,
  defaultValue,
  isDisabled = false,
  allowNew = false,
  IsResultHighlightOnly = true,
  handleSelectedItem,
  placeHolderValue = "Choose Item...",
  labelKey,
  dependentOn,
}: Props) => {
  // Selected value
  const [selected, setSelected] = useState<any>(selectedProps);
  const ref: any = useRef();

  useEffect(() => {
    if (selectedProps[0] !== "") {
      setSelected(selectedProps);
    }
  }, [selectedProps]);

  // useEffect - Clear the component when is disable prop changes
  useEffect(() => {
    if (isDisabled === true) {
      ref.current.clear();
    }
    return () => {};
  }, [isDisabled]);

  // Filter dataset
  const filterBy = (option: any, state: any) => {
    if (state.selected.length) {
      return true;
    }
    return option.name.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
  };

  // Handle select - callback
  const handleSelect = (selectedValue: any) => {
    setSelected(selectedValue);
    if (selectedValue) {
      // Call back function to return the value to parent component
      handleSelectedItem(selectedValue);
    }
  };

  return (
    <React.Fragment>
      <Typeahead
        //selected
        selected={selected}
        //
        id="toggle-example"
        // The labelKey prop specifies the string that will be used for searching and rendering options and selections.
        labelKey={labelKey}
        // Allows new input not on suggestion list
        allowNew={allowNew}
        // Indicate whether an asynchronous data fetch is happening.
        isLoading={false}
        // https://github.com/ericgio/react-bootstrap-typeahead/blob/master/docs/Filtering.md#filterby-arraystring--function
        filterBy={filterBy}
        // Full set of options, including any pre-selected options.
        options={dataset}
        // Place holder text for the input.
        placeholder={placeHolderValue}
        // handle selected item
        onChange={handleSelect}
        // The selected option(s) displayed in the input. Use this prop if you want to control the component via its parent.
        defaultSelected={defaultValue}
        // Whether to disable the input.
        disabled={isDisabled}
        // 	Highlights the menu item if there is only one result and allows selecting that item by hitting enter.
        highlightOnlyResult={IsResultHighlightOnly}
        // Object Reference
        ref={ref}
        emptyLabel={`Filter ${dependentOn} to get results...`}
      ></Typeahead>
    </React.Fragment>
  );
};

export default DropDownCountries;
