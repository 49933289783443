import React, { useEffect, useRef, useState } from 'react';
import { Trans } from '@lingui/react';
import useInterval from '../utils/interval-hook/use-interval';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { reOrderPanoramas, updatePanorama } from '../../api-helper/api-panoramas';
import { ReactComponent as AddIcon } from '../../images/viewer-icons/add.svg';
import startingFlag from '../../images/viewer-icons/starting-pano-flag.svg';
import backArrow from '../../images/viewer-icons/left-arrow.svg';
import forwardArrow from '../../images/viewer-icons/right-arrow.svg';
import DropUpMenu from './viewer-canvas-utils/dropup-menu/dropup-menu';
import { changePanoramaName } from '../utils/image-loading-utils/image-loading-utils';
import { ReportCustomEvent } from '../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../google-analytics-track-list';
import { IPanorama, IPanoramaOptions } from '../../api-helper/interface/interfaces';
import { ILeftSidebar } from './viewer-canvas-utils/viewer-canvas-helper';
import { IPresetValues } from '../utils/classes/image-builder';
import { handleError } from '../../api-helper/api-error-handling';
import { useAuth } from '../../auth-routes/auth-context';

interface IProps {
  tour: any;
  setEditedImage: (cubemap: string[]) => void;
  setCurrentPanorama: (panorama: IPanorama) => void;
  panoramaData: IPanorama[];
  changePanoramaOrderState: (newPanoramaDataOrder: IPanorama[]) => void;
  currentPanorama: IPanorama;
  leftSidebar: ILeftSidebar;
  changeLeftSidebar: (option: string) => void;
  changePanorama: (panorama: IPanorama) => void;
  updatePanoramaList: () => void;
  deletePanorama: (panoramaId: string) => void;
  onSetView: (panoramaId: string) => void;
  position: number;
  setPosition: (newPos: number) => void;
  setPresetValues: (options: IPresetValues) => void;
  setImageOptionsResponse: (options: IPresetValues) => void;
}

const PanoramaSlider: React.FC<IProps> = ({
  tour,
  setEditedImage,
  setCurrentPanorama,
  panoramaData,
  changePanoramaOrderState,
  currentPanorama,
  leftSidebar,
  changeLeftSidebar,
  changePanorama,
  updatePanoramaList,
  deletePanorama,
  onSetView,
  setPosition,
  position,
  setPresetValues,
  setImageOptionsResponse,
}) => {
  const { handleLogoutState } = useAuth();
  const [uploadUnfinishedPanoramas, setUploadUnfinishedPanoramas] = useState<boolean>();
  const previewPanoramaWrapper = useRef<HTMLDivElement>(null);
  const [processingPanoramas, setProcessingPanoramas] = useState<string[]>([]);
  let mappedRefs = useRef<HTMLDivElement[]>([]);
  const sliderRef = useRef<HTMLDivElement>(null)
  const scrollIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);


  useEffect(() => {
    // returns true if it finds any panoramas with pending status
    if (panoramaData && panoramaData.length > 0) {
      let newProcessingPanorama = [];
      for (const panorama of panoramaData) {
        if (panorama.status < 5) {
          newProcessingPanorama.push(panorama.id);
        } else {
          const findEditedImage = processingPanoramas.find(p => p === panorama.id);
          if (findEditedImage) {
            if (currentPanorama.id === panorama.id && panorama.cubeMap?.length) {
              setCurrentPanorama(panorama);
              setEditedImage(panorama.cubeMap);

              const panoEditOptions: IPanoramaOptions[] = panorama.editOptions;

              let options: any = {};
              // Check if auto enhance is selected
              for (let i = 0; i < panoEditOptions.length; i++) {
                const optionKey = panoEditOptions[i].panoramaKey.replace('EDITION_', '').toLowerCase();
                if (optionKey === 'auto') {
                  if (panoEditOptions[i].panoramaValue === '1') {
                    options[optionKey] = true;
                  } else {
                    options[optionKey] = false;
                  }
                } else {
                  options[optionKey] = panoEditOptions[i].panoramaValue;
                }
              }

              setPresetValues(options);
              setImageOptionsResponse(options);
            }
          }
        }
      }
      if (newProcessingPanorama.length) {
        setProcessingPanoramas(newProcessingPanorama);
        setUploadUnfinishedPanoramas(true);
      } else {
        setProcessingPanoramas([]);
        setUploadUnfinishedPanoramas(false);
      }
    }
  }, [panoramaData]);

  const changePanoramaNameState = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let tempFilesArray = [...panoramaData];
    const changeName = changePanoramaName(tempFilesArray, index, e.target.value);
    changePanoramaOrderState(changeName);
  };

  const updatePanoramaName = async (index: number) => {
    try {
      const updateBody = {
        name: panoramaData[index].name,
      };

      await updatePanorama(panoramaData[index].id, updateBody);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  useEffect(() => {
    if (currentPanorama && mappedRefs.current && mappedRefs.current.length > 0) {
      const findElementId = mappedRefs.current.findIndex((el: any) => el.id === currentPanorama.id);
      if (findElementId >= 0) {
        mappedRefs.current[findElementId].scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [currentPanorama]);

  useInterval(
    () => {
      // Refreshes panorama list every 5 seconds until it status changes
      updatePanoramaList();
    },
    uploadUnfinishedPanoramas ? 5000 : null,
  );

  const scrollLeft = () => {
    if (sliderRef.current) {
      const scrollOffset = -400;
      const newScrollPosition = sliderRef.current.scrollLeft + scrollOffset;
      if (newScrollPosition < 0) {
        sliderRef.current.scrollLeft = 0;
        setPosition(0);
      } else {
        sliderRef.current.scrollLeft = newScrollPosition;
        setPosition(newScrollPosition);
      }
    }
  };

  const scrollRight = () => {
    const scrollOffset = 400;
    if (sliderRef.current) {
      const maxScrollLeft = sliderRef.current.scrollWidth - sliderRef.current.clientWidth;
      const newScrollPosition = sliderRef.current.scrollLeft + scrollOffset;
      if (newScrollPosition > maxScrollLeft) {
        sliderRef.current.scrollLeft = maxScrollLeft;
        setPosition(maxScrollLeft);
      } else {
        sliderRef.current.scrollLeft = newScrollPosition;
        setPosition(newScrollPosition);
      }
    }
  };

  const startScrollingLeft = () => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
    }
    scrollIntervalRef.current = setInterval(scrollLeft, 250);
  };

  const startScrollingRight = () => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
    }
    scrollIntervalRef.current = setInterval(scrollRight, 250);
  };

  const stopScrolling = () => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }
  };

  const startingImage = (index: number) => {
    const newStartingImage = {
      combine: null,
      destination: { droppableId: 'droppable', index: 0 },
      draggableId: String(index),
      mode: 'FLUID',
      reason: 'DROP',
      source: { index, droppableId: 'droppable' },
      type: 'DEFAULT',
    };
    onDragEnd(newStartingImage);
  };

  const previewPanoramas = (panorama: IPanorama, index: number) => {
    if (panorama.status < 5) {
      return (
        <div className='preview-panorama-wrapper'>
          <div className='position-relative'>
            {currentPanorama && currentPanorama.id === panorama.id && <div className='processing-active-panorama-dot'></div>}
            <div style={{ position: 'absolute', top: '28px' }}>
              <DropUpMenu
                previewPanoramaWrapper={previewPanoramaWrapper}
                setAsStartingImage={startingImage}
                deletePanorama={deletePanorama}
                index={index}
                panoramaId={panorama.id}
                onSetView={onSetView}
                position={position}
                processing={true}
              />
            </div>
            <div
              className='add-an-image-block'
              onClick={() => {
                changePanorama(panorama);
              }}
            >
              <div className='uploading-image-spinner'>
                <i className='fa fa-spinner fa-spin fa-2x fa-fw'></i>
                <span className='sr-only'>
                  <Trans id='Loading...' />
                </span>
              </div>
            </div>
          </div>
          <input
            disabled={uploadUnfinishedPanoramas}
            type='text'
            value={panorama.name || ''}
            style={{ marginTop: '5px' }}
            onClick={() => {
              //Report Custom Category and Event
              ReportCustomEvent(EnumCategory.ThumbnailsBar, EnumEvent.ChangeLabel);
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changePanoramaNameState(e, index)}
            onMouseLeave={() => updatePanoramaName(index)}
            className='thumbnail-preview-text-input'
          />
          <div className={currentPanorama && currentPanorama.id === panorama.id ? 'active-panorama' : 'inactive-panorama'}></div>
        </div>
      );
    } else {
      return (
        <div ref={addRefs} id={panorama.id} className='preview-panorama-wrapper'>
          <div className='btn-remove-default'>
            <div className='position-relative'>
              {currentPanorama && currentPanorama.id === panorama.id && <div className='active-panorama-dot'></div>}
              <DropUpMenu
                previewPanoramaWrapper={previewPanoramaWrapper}
                setAsStartingImage={startingImage}
                deletePanorama={deletePanorama}
                index={index}
                panoramaId={panorama.id}
                onSetView={onSetView}
                position={position}
              />
              {index === 0 && (
                <div
                  className='starting-pano-click-section'
                  onClick={() => {
                    changePanorama(panorama);
                  }}
                >
                  <div className='starting-pano-bg'>
                    <img className='starting-pano-flag' src={startingFlag} alt='flat' />
                  </div>
                </div>
              )}
              <div
                className='starting-pano-click-section'
                onClick={() => {
                  changePanorama(panorama);
                  ReportCustomEvent(EnumCategory.ThumbnailsBar, EnumEvent.GoTo);
                }}
              ></div>
              <img className='thumbnail-preview' src={panorama.thumbnailPreviewUrl} alt='thumbnail' />
            </div>
          </div>
          <input
            disabled={uploadUnfinishedPanoramas}
            type='text'
            value={panorama.name || ''}
            onClick={() => {
              //Report Custom Category and Event
              ReportCustomEvent(EnumCategory.ThumbnailsBar, EnumEvent.ChangeLabel);
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changePanoramaNameState(e, index)}
            onMouseLeave={() => updatePanoramaName(index)}
            className='thumbnail-preview-text-input'
          />
          <div className={currentPanorama && currentPanorama.id === panorama.id ? 'active-panorama' : 'inactive-panorama'}></div>
        </div>
      );
    }
  };

  const onDragEnd = async (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let tempPanoData = [...panoramaData];
    const removeMovedPano = tempPanoData.splice(result.source.index, 1);
    tempPanoData.splice(result.destination.index, 0, removeMovedPano[0]);

    // Change order state order
    changePanoramaOrderState(tempPanoData);

    await reOrderPanoramas(panoramaData[result.draggableId].id, tour.id, result.destination.index);
  };

  const addRefs = (ref: any) => {
    if (ref && !mappedRefs.current.includes(ref)) {
      mappedRefs.current.push(ref);
    }
  };

  return (
    <div className='panorama-select-container'>
      <button className='viewer-button-sliders' onMouseDown={startScrollingLeft} onMouseUp={stopScrolling} onMouseLeave={stopScrolling} onClick={() => scrollLeft()}>
        <img src={backArrow} alt='back' />
      </button>
      <div className='panorama-container'>
        <div
          className='add-an-image-container'
          onClick={() => {
            //Report Custom Category and Event
            ReportCustomEvent(EnumCategory.ThumbnailsBar, EnumEvent.Add360Image);
          }}
        >
          <div
            id='add-images-slider'
            className='add-an-image-block'
            style={{ background: leftSidebar.addImage.status ? '#0e0333' : '#fff' }}
          >
            <button className='btn-remove-default' onClick={() => changeLeftSidebar('addImage')}>
              <div className={leftSidebar.addImage.status ? '' : 'add-icon-colour'}>
                <AddIcon />
              </div>
            </button>
          </div>
          <p className='thumbnail-preview-text tpt-loading'>
            <Trans id='Add image' />
          </p>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable' direction='horizontal'>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div ref={sliderRef} className='list-panoramas-bottom'>
                  {panoramaData &&
                    panoramaData.map((thumbnail: IPanorama, index: number) => (
                      <Draggable key={index} draggableId={String(index)} index={index}>
                        {provided => (
                          <div
                            id='thumbnail-slider'
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {previewPanoramas(thumbnail, index)}
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <button className='viewer-button-sliders' onMouseDown={startScrollingRight} onMouseUp={stopScrolling} onMouseLeave={stopScrolling} onClick={() => scrollRight()}>
        <img src={forwardArrow} alt='forward' />
      </button>
    </div>
  );
};

export default PanoramaSlider;
