import React, { useEffect, useState } from 'react';
import './modal-tripod.scss';
import cross from '../../../images/viewer-icons/cross.svg';
import { updateConfig } from '../../../api-helper/api-account';
import { IVTConfig } from './settings';
import { EnumNotificationType } from '../../utils/notifications/notification';
import { useConfig } from '../../utils/user-config-context/user-config-context';

interface IProps {
  tripodMaskRatio: number;
  tripodMaskShape: EnumShape;
  closeModal: (state: boolean) => void;
  virtualTourConfigurationForm: IVTConfig;
  setVirtualTourConfigurationForm: (config: IVTConfig) => void;
  toggleNotification: (type: EnumNotificationType, message?: string) => void;
}

export enum EnumShape {
  Circle = 'circle',
  Square = 'square',
}

const ModalOptimiseTripodGlobal: React.FC<IProps> = ({
  tripodMaskRatio,
  tripodMaskShape,
  closeModal,
  virtualTourConfigurationForm,
  setVirtualTourConfigurationForm,
  toggleNotification,
}) => {
  const config = useConfig();
  //   const [sliderValue, setSliderValue] = useState<number>();
  const [sliderValueCircle, setSliderValueCircle] = useState<number>();
  const [radius, setRadius] = useState<number>((tripodMaskRatio || 0.3) * 100);
  //   const [shape, setShape] = useState<EnumShape>(tripodMaskShape);

  useEffect(() => {
    if (radius !== undefined) {
      setSliderValueCircle(((radius - 30) * 100) / (90 - 30));
    }
  }, [radius]);

  const updateConfiguration = async () => {
    try {
      if (radius) {
        await updateConfig(
          {
            tripodMaskRatio: radius / 100,
            // tripodMaskShape: shape,
          },
          false,
        );
        setVirtualTourConfigurationForm({
          ...virtualTourConfigurationForm,
          tripodMaskRatio: radius / 100,
          //   tripodMaskShape: shape,
        });
      }

      toggleNotification(EnumNotificationType.Success, 'Your changes have been saved!');
    } catch (error) {
      const err = error as Error;
      console.log(err);
      toggleNotification(EnumNotificationType.Error, 'There was an error');
    } finally {
      closeModal(false);
    }
  };

  const handleRadiusChange = (e: any) => {
    setRadius(e.target.value);
  };

  const sliderStyleCircle = {
    background: `linear-gradient(to right, #D50057 0%, #D50057 ${sliderValueCircle}%, #d3d3d3 ${sliderValueCircle}%, #d3d3d3 100%)`,
  };

  const circleStyle = {
    width: `${radius}%`,
    height: `${radius}%`,
    borderRadius: '50%',
    transition: 'width 0.2s, height 0.2s',
    border: '4px #D50057 dashed',
  };

  //   const squareStyle = {
  //     width: `${radius}%`,
  //     height: `${radius}%`,
  //     transition: 'width 0.2s, height 0.2s',
  //     border: '4px #D50057 dashed',
  //   };

  return (
    <div className='modal-tripod-container'>
      <div className='modal-tripod-box'>
        <div className='tripod-popup-title'>
          <div>Tripod Removal Optimisation</div>
          <button className='tripod-popup-cross' onClick={() => closeModal(false)}>
            <img src={cross} alt='cross' />
          </button>
        </div>
        <div className='box-text-modal'>
          <div className='text-blue-modal'>Adjust the size of the mask to make sure that the tripod fits inside the circle.</div>
          <div className='text-grey-modal'>Once you saved the change, the new tours created will use this mask to remove the tripod.</div>
          <div className='text-grey-modal'>You will be able to change the mask size anytime and for any tour.</div>
        </div>
        <div className='box-image'>
          {/* {shape === EnumShape.Circle ? ( */}
          <div className='tripod-image-container'>
            <img src={`${config.userOptions.tripodDefaultImage}`} alt='current-panorama' className='tripod-image' />
            <div className='tripod-mask' style={circleStyle}></div>
          </div>
          {/*   ) : (
            <div className='tripod-image-container'>
              <img src={`${config.userOptions.tripodDefaultImage}`} alt='current-panorama' className='tripod-image' />
              <div className='tripod-mask-square' style={squareStyle}></div>
            </div>
          )} */}
        </div>
        <div className='manual-editing-tripod'>
          <div className='slider-container'>
            {/* {shape === EnumShape.Circle ? ( */}
            <input
              type='range'
              min={30}
              max={90}
              value={radius !== undefined ? radius : 0}
              className='slider-tripod'
              style={sliderStyleCircle}
              onChange={(e: React.ChangeEvent<any>) => {
                handleRadiusChange(e);
                const ratio = +e.target.value;
                const snailTrail = ((ratio - 30) * 100) / (90 - 30);
                setSliderValueCircle(snailTrail);
              }}
            />
            {/* ) : (
              <input
                type='range'
                min={30}
                max={90}
                value={radius !== undefined ? radius : 0}
                className='slider-tripod'
                style={sliderStyle}
                onChange={(e: React.ChangeEvent<any>) => {
                  handleRadiusChange(e);
                  const ratio = +e.target.value;
                  const snailTrail = ((ratio - 30) * 100) / (90 - 30);
                  setSliderValue(snailTrail);
                }}
              />
            )} */}
            {/* {shape === EnumShape.Circle ? (
              <div className='button-square' onClick={() => setShape(EnumShape.Square)}>
                square
              </div>
            ) : (
              <div className='button-square' onClick={() => setShape(EnumShape.Circle)}>
                circle
              </div>
            )} */}
          </div>
        </div>
        <div className='container-save-button'>
          <button className='button-save button-hover' onClick={updateConfiguration}>
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalOptimiseTripodGlobal;
