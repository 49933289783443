import { Trans } from '@lingui/react';
import React, { useState, useEffect } from 'react';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { ISidebar } from '../../editing-viewer/viewer-canvas';
import ModalConfirmApplyAll from './modal-confirm-apply-all';
import { IVTConfig } from './settings';
import './settings.scss';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';
import { createSubscriptionPortal } from '../../../api-helper/api-payment';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../../utils/notifications/notification';
import ModalOptimiseTripodGlobal, { EnumShape } from './modal-optimise-tripod-global';

interface IProps {
  sidebar: ISidebar;
  virtualTourConfigurationForm: IVTConfig;
  setVirtualTourConfigurationForm: (config: IVTConfig) => void;
  itemModalOpen: boolean;
  onSaveTourConfiguration: (global: boolean) => void;
  toggleModal: (state: boolean) => void;
}

const AiFeaturesConfig: React.FC<IProps> = ({
  sidebar,
  virtualTourConfigurationForm,
  setVirtualTourConfigurationForm,
  itemModalOpen,
  onSaveTourConfiguration,
  toggleModal,
}) => {
  const [proAccount, setProAccount] = useState<boolean>(false);
  const config = useConfig();
  const [currency, setCurrency] = useState(linguiTranslateToString('gbp'));
  const { handleLogoutState } = useAuth();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [showOptimiseTripodRemoval, setShowOptimiseTripodRemoval] = useState<boolean>(false);

  const toggleNotification = (type: EnumNotificationType, message?: string) => {
    setTypeNotification(type);
    setNotificationMessage(message || linguiTranslateToString('There was an error please try again'));
    setShowNotification(true);
  };

  useEffect(() => {
    if (config) {
      const currency = config.countryInfo;
      if (currency) {
        setCurrency(currency.currency.toLowerCase());
      }
    }
  }, [config]);

  useEffect(() => {
    if (sidebar.aiFeatures) {
      setProAccount(sidebar.aiFeatures);
    }
  }, [sidebar]);

  const upgradeToProPlan = async () => {
    try {
      const createPaymentSession = await createSubscriptionPortal(currency);
      if (createPaymentSession?.redirectUrl) {
        // redirect to payment checkout
        window.location.href = createPaymentSession.redirectUrl;
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error, String(<Trans id='There was an issue creating payment session' />));
    }
  };

  return (
    <>
      {showOptimiseTripodRemoval && (
        <ModalOptimiseTripodGlobal
          tripodMaskRatio={virtualTourConfigurationForm.tripodMaskRatio || 0.3}
          tripodMaskShape={virtualTourConfigurationForm.tripodMaskShape || EnumShape.Circle}
          virtualTourConfigurationForm={virtualTourConfigurationForm}
          setVirtualTourConfigurationForm={setVirtualTourConfigurationForm}
          closeModal={setShowOptimiseTripodRemoval}
          toggleNotification={toggleNotification}
        />
      )}
      {showNotification && (
        <Notification
          showNotification={showNotification}
          size={EnumNotificationSize.Large}
          type={typeNotification || EnumNotificationType.Info}
          message={notificationMessage}
          setShowNotification={setShowNotification}
          fullScreen={true}
        />
      )}
      <div className='settings-information-container'>
        {itemModalOpen && <ModalConfirmApplyAll setShowDeleteModal={toggleModal} applyAll={onSaveTourConfiguration} />}
        <div className='settings-information-layout'>
          <div className='panelhook-container'>
            <div>
              <div>
                <div className='settings-config-block'>
                  <div className='settings-config-option'>
                    <div style={{ cursor: 'default' }}>
                      <div className='new-feature-text'>
                        <Trans
                          id='Auto-enhance all images'
                          render={({ translation }) => (
                            <div className={proAccount ? 'field-text' : 'field-text-disable'} style={{ width: '230px' }}>
                              {translation}
                            </div>
                          )}
                        />
                      </div>
                      <Trans
                        id='Automatically enhance all images on upload'
                        render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                      />
                    </div>
                    {sidebar.autoEnhance ? (
                      <div className='field-input-container'>
                        <label className='switch-settings'>
                          <input
                            id='autoEnhance'
                            type='checkbox'
                            checked={virtualTourConfigurationForm.autoEnhance || false}
                            onClick={() => {
                              if (virtualTourConfigurationForm?.autoEnhance) {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableEnhance);
                              } else {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableEnhance);
                              }
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setVirtualTourConfigurationForm({
                                ...virtualTourConfigurationForm,
                                autoEnhance: e.target.checked === true ? true : false,
                              })
                            }
                          />
                          <span id='slider-share-location' className='slider-settings round-settings'>
                            <span className={`slider-text-${virtualTourConfigurationForm.autoEnhance ? 'on' : 'off'}`}>
                              {virtualTourConfigurationForm.autoEnhance ? 'ON' : 'OFF'}
                            </span>
                          </span>
                        </label>
                      </div>
                    ) : (
                      <label className='switch-settings'>
                        <input id='enableTripod' type='checkbox' checked={false} disabled={true} />
                        <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
                      </label>
                    )}
                  </div>
                  <div className='settings-config-option'>
                    <div style={{ cursor: 'default' }}>
                      <div className='new-feature-text'>
                        <Trans
                          id='Auto-blur'
                          render={({ translation }) => (
                            <div className={proAccount ? 'field-text' : 'field-text-disable'} style={{ width: '230px' }}>
                              {translation}
                            </div>
                          )}
                        />
                      </div>
                      <Trans
                        id='Automatically detect and blur faces in all your images'
                        render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                      />
                    </div>
                    {sidebar.autoBlur ? (
                      <div className='field-input-container'>
                        <label className='switch-settings'>
                          <input
                            id='autoBlur'
                            type='checkbox'
                            checked={virtualTourConfigurationForm.autoBlur || false}
                            onClick={() => {
                              if (virtualTourConfigurationForm?.autoBlur) {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableBlur);
                              } else {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableBlur);
                              }
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setVirtualTourConfigurationForm({
                                ...virtualTourConfigurationForm,
                                autoBlur: e.target.checked === true ? true : false,
                              })
                            }
                          />
                          <span id='slider-share-location' className='slider-settings round-settings'>
                            <span className={`slider-text-${virtualTourConfigurationForm.autoBlur ? 'on' : 'off'}`}>
                              {virtualTourConfigurationForm.autoBlur ? 'ON' : 'OFF'}
                            </span>
                          </span>
                        </label>
                      </div>
                    ) : (
                      <label className='switch-settings'>
                        <input id='enableTripod' type='checkbox' checked={false} disabled={true} />
                        <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
                      </label>
                    )}
                  </div>
                </div>
                <div className='settings-config-block'>
                  <div className='settings-config-option'>
                    <div style={{ cursor: 'default' }}>
                      <div className='new-feature-text'>
                        <Trans
                          id='Auto tripod removal'
                          render={({ translation }) => (
                            <div className={proAccount ? 'field-text' : 'field-text-disable'} style={{ width: '230px' }}>
                              {translation}
                            </div>
                          )}
                        />
                      </div>
                      <Trans
                        id='Automatically remove the tripod from the bottom of all your images'
                        render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                      />
                    </div>
                    {sidebar.autoTripodRemoval ? (
                      <div className='field-input-container'>
                        <label className='switch-settings'>
                          <input
                            id='tripodRemoved'
                            type='checkbox'
                            checked={virtualTourConfigurationForm.tripodRemoved || false}
                            onClick={() => {
                              if (virtualTourConfigurationForm?.tripodRemoved) {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableTripodRemoved);
                              } else {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableTripodRemoved);
                              }
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setVirtualTourConfigurationForm({
                                ...virtualTourConfigurationForm,
                                tripodRemoved: e.target.checked === true ? true : false,
                              })
                            }
                          />
                          <span id='slider-share-location' className='slider-settings round-settings'>
                            <span className={`slider-text-${virtualTourConfigurationForm.tripodRemoved ? 'on' : 'off'}`}>
                              {virtualTourConfigurationForm.tripodRemoved ? 'ON' : 'OFF'}
                            </span>
                          </span>
                        </label>
                      </div>
                    ) : (
                      <label className='switch-settings'>
                        <input id='enableTripod' type='checkbox' checked={false} disabled={true} />
                        <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
                      </label>
                    )}
                  </div>
                  <div className='settings-config-option'>
                    <div style={{ cursor: 'default' }}>
                      <div className='new-feature-text'>
                        <Trans
                          id='Auto-alignment'
                          render={({ translation }) => (
                            <div className={proAccount ? 'field-text' : 'field-text-disable'} style={{ width: '230px' }}>
                              {translation}
                            </div>
                          )}
                        />
                      </div>
                      <Trans
                        id='Automatically straighten the images on upload'
                        render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                      />
                    </div>
                    {sidebar.autoAlignment ? (
                      <div className='field-input-container'>
                        <label className='switch-settings'>
                          <input
                            id='autoAligned'
                            type='checkbox'
                            checked={virtualTourConfigurationForm.autoAligned || false}
                            onClick={() => {
                              if (virtualTourConfigurationForm?.autoAligned) {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableAutoAligned);
                              } else {
                                //Report Custom Category and Event
                                ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableAutoAligned);
                              }
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setVirtualTourConfigurationForm({
                                ...virtualTourConfigurationForm,
                                autoAligned: e.target.checked === true ? true : false,
                              })
                            }
                          />
                          <span id='slider-share-location' className='slider-settings round-settings'>
                            <span className={`slider-text-${virtualTourConfigurationForm.autoAligned ? 'on' : 'off'}`}>
                              {virtualTourConfigurationForm.autoAligned ? 'ON' : 'OFF'}
                            </span>
                          </span>
                        </label>
                      </div>
                    ) : (
                      <label className='switch-settings'>
                        <input id='enableTripod' type='checkbox' checked={false} disabled={true} />
                        <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
                      </label>
                    )}
                  </div>
                </div>
                <div className='settings-config-block'>
                  {sidebar.multiRes && (
                    <div className='settings-config-option'>
                      <div style={{ cursor: 'default' }}>
                        <div className='new-feature-text'>
                          <Trans
                            id='Multi-resolution'
                            render={({ translation }) => (
                              <div className={proAccount ? 'field-text' : 'field-text-disable'} style={{ width: '230px' }}>
                                {translation}
                              </div>
                            )}
                          />
                        </div>
                        <Trans
                          id='Converts panorama image into tiles to display a higher resolution image'
                          render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                        />
                      </div>
                      <div className='field-input-container'>
                        {proAccount ? (
                          <label className='switch-settings'>
                            <input
                              id='multiResolution'
                              type='checkbox'
                              checked={virtualTourConfigurationForm.multiResolution || false}
                              onClick={() => {
                                if (virtualTourConfigurationForm.multiResolution) {
                                  //Report Custom Category and Event
                                  ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableMultires);
                                } else {
                                  //Report Custom Category and Event
                                  ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableMultires);
                                }
                              }}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setVirtualTourConfigurationForm({
                                  ...virtualTourConfigurationForm,
                                  multiResolution: e.target.checked === true ? true : false,
                                })
                              }
                            />
                            <span id='slider-share-location' className='slider-settings round-settings'>
                              <span className={`slider-text-${virtualTourConfigurationForm.multiResolution ? 'on' : 'off'}`}>
                                {virtualTourConfigurationForm.multiResolution ? 'ON' : 'OFF'}
                              </span>
                            </span>
                          </label>
                        ) : (
                          <label className='switch-settings'>
                            <input id='enableTripod' type='checkbox' checked={false} disabled={true} />
                            <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
                          </label>
                        )}
                      </div>
                    </div>
                  )}
                  <div className='settings-config-option-tripod'>
                    <button className='optimise-tripod-button button-hover' onClick={() => setShowOptimiseTripodRemoval(true)}>
                      <Trans
                        id='Optimise tripod removal'
                        render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {proAccount ? (
              <div className='save-information-button-container'>
                <div>
                  <button
                    id='save-btn-virtual-tour-configuration'
                    className='save-information-button button-active button-hover'
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      const global = false;
                      onSaveTourConfiguration(global);
                    }}
                  >
                    <Trans id='Save changes' />
                  </button>
                </div>
              </div>
            ) : (
              <div className='save-information-button-container'>
                <button
                  onClick={() => {
                    ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.UpdateToProAccount);
                    upgradeToProPlan();
                  }}
                  id='save-btn-your-branding'
                  className='save-information-button button-active'
                >
                  <Trans id='Upgrade account to PRO to unlock this feature' />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AiFeaturesConfig;
