
import { IPanorama, IPanoramaOptionAudio, IPanoramaResponse, IReadAllPanoramaLogos, IValidateDollhouse } from './interface/interfaces';
import { ClientApi } from '@vva/front';
import { IAudioSettings } from '../components/editing-viewer/modals/add-audio';

const clientApi = new ClientApi();

// conditional window ENV for testing with jest
clientApi.setHeaders({
	'X-Api-Key': (window.ENV && window.ENV.REACT_APP_API_KEY ? window.ENV.REACT_APP_API_KEY : null) || process.env.REACT_APP_API_KEY,
});

export const getPanorama = async (panoramaId: string): Promise<IPanoramaResponse> => {
	const response = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${panoramaId}`,
	);
	return response.data;
};

export const createPanorama = async (tourId: string, panoramaName: string, order?: number) => {
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas`,
		{
			tourId: tourId,
			name: panoramaName,
			hfov: 360,
			vfov: 180,
			level: 0,
			latitude: 0,
			longitude: 0,
			source: 11,
			version: 11,
			order: order || 0
		},
	);
	return response.data;
};

export const downloadPanorama = async (panoramaId: string) => {
	const response = await clientApi.getDataWithHeadersBlobResponse(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${panoramaId}/download`,
	);
	return response;
};

export const downloadPanoramaDynamic = async (panoramaId: string, yaw: number, pitch: number, fov: number) => {
	const response = await clientApi.getDataWithHeadersBlobResponse(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${panoramaId}/downloadDynamic?pitch=${pitch}&yaw=${yaw}&fov=${fov}`,
	);
	return response;
};

export const downloadAllPanorama = async (panoramaId: string) => {
	const response = await clientApi.getDataWithHeadersBlobResponse(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${panoramaId}/downloadAll`,
	);
	return response;
};

export const getPanoramaLabelsLevels = async (panoramaId: string): Promise<Response> => {
	const response = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${panoramaId}/roomLabelsLevels`,
	);
	return response.data;
};

export const getPanoramas = async (tourId: string): Promise<IPanorama[]> => {
	const params = new URLSearchParams(window.location.search);
	const imageEnv = params.get('imageEnv');

	const response = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas?&imageEnv=${imageEnv}&id=${tourId}`,
	);
	return response.data;
};

export const savePanoramaEdited = async (panoramaId: string, options: any) => {
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${panoramaId}/imageOptions`,
		{
			panoramaId: panoramaId,
			options: options,
		},
	);
	return response.data;
};

export const uploadPanoramaWithFile = async (panoramaId: string, file: any) => {
	var formData = new FormData();
	if (file) {
		formData.append("file", file, file.name);
	}
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${panoramaId}/uploadImage`, formData,
	);
	return response.data;
}

export const updatePanorama = async (panoramaId: string, panoramaBody: any) => {
	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${panoramaId}`, panoramaBody,
	);
	return response.data;
};

export const deletePanoramaEdited = async (panoramaId: string) => {
	const response = await clientApi.deleteData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${panoramaId}/deleteOptions`
	);
	return response.data;
}

export const deletePanorama = async (panoramaId: string) => {
	const response = await clientApi.deleteData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${panoramaId}`
	);
	return response.data;
}

export const getLinksMap = async (tourId: string): Promise<any> => {
	const params = new URLSearchParams(window.location.search);
	const imageEnv = params.get('imageEnv');

	const response = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/linksMap?tourId=${tourId}&imageEnv=${imageEnv}`
	);
	return response.data;
};

export const getTripodCapLogos = async (): Promise<IReadAllPanoramaLogos[]> => {
	const response = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/tripodcapLogos`
	);
	return response.data;
};

export const newDefaultTripod = async (tripodLogoId: string): Promise<IReadAllPanoramaLogos[]> => {
	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${tripodLogoId}/defaultTripodLogo`, {}
	);
	return response.data;
};

export const deleteTripodLogo = async (tripodLogoId: string): Promise<IReadAllPanoramaLogos[]> => {
	const response = await clientApi.deleteData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}}/panoramas/tripodcapLogos/${tripodLogoId}`
	);
	return response.data;
};

export const uploadLogoTripod = async (file: any): Promise<IReadAllPanoramaLogos[]> => {
	var formData = new FormData();
	formData.append("file", file);
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/uploadLogoTripod`, formData
	);
	return response.data;
};

export const reprocessPanorama = async (panoramaId: string): Promise<any> => {
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${panoramaId}/processing`, {}
	);
	return response.data;
};

export const getBlurSuggestion = async (panoramaId: string): Promise<any> => {
	const response = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${panoramaId}/blur/suggestions`
	);
	return response.data;
};

export const getAutoEnhancement = (imageUrl: string): string => {
	const autoEditionServerUrl = window.ENV.REACT_APP_IMAGE_ENHANCEMENT || process.env.REACT_APP_IMAGE_ENHANCEMENT;
	return `${autoEditionServerUrl}?url=${encodeURIComponent(imageUrl)}`;
};

export const reOrderPanoramas = async (panoramaId: string, tourId: string, newPosition: number) => {
	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${panoramaId}/order`, {
		tourId,
		newPosition,
	}
	);
	return response.data;
}

export const getPanoramaAudioSettings = async (panoramaId: string): Promise<IPanoramaOptionAudio[]> => {
	const response = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${panoramaId}/audio/settings`
	);
	return response.data;
}

export const uploadPanoramaAudioFile = async (file: any, panoramaId: string) => {
	var formData = new FormData();
	formData.append("file", file.file);
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${panoramaId}/audio/settings`, formData
	);
	return response.data;
}

export const updatePanoramaAudioFile = async (panoramaId: string, body: IAudioSettings) => {
	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/audio/settings`, {
		panoramaId,
		...body,
	}
	);
	return response.data;
}

export const deletePanoramaAudioSettings = async (panoramaId: string): Promise<IPanoramaOptionAudio[]> => {
	const response = await clientApi.deleteData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/${panoramaId}/audio/settings`
	);
	return response.data;
}

export const checkLinks = async (tourId: string): Promise<IPanoramaOptionAudio[]> => {
	const response = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/checkLinks?tourId=${tourId}`
	);
	return response.data;
}

export const validateForDollhouse = async (tourId: string): Promise<IValidateDollhouse> => {
	const response = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/validateForDollhouse?tourId=${tourId}`
	);
	return response.data;
}
