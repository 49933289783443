import React, { Fragment, useEffect, useState } from 'react';
import { ReactComponent as CloseButton } from '../../../images/viewer-icons/cross.svg';
import { ReactComponent as SmallIcon } from '../../../images/viewer-icons/note-small.svg';
import { ReactComponent as MediumIcon } from '../../../images/viewer-icons/note-medium.svg';
import { ReactComponent as LargeIcon } from '../../../images/viewer-icons/note-large.svg';
import { ReactComponent as UrlNote } from '../../../images/viewer-icons/url.svg';
import { ReactComponent as WarningNote } from '../../../images/viewer-icons/warning.svg';
import { ReactComponent as Info } from '../../../images/viewer-icons/icn-info-inactive.svg';
import './modals.scss';
import { createCustomHotspot, deleteHotspot, updateCustomHotspotNote } from '../../../api-helper/api-hotspots';
import { isURLValid } from '../viewer-canvas-utils/viewer-canvas-helper';
import { getHotspotType } from '../viewer-canvas-utils/hotspot-factory';
import { Trans, TransRenderProps } from '@lingui/react';
import RadioButton from '../../utils/component-button/radio-button';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { EnumHotspotsSize } from '../interaces';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';

interface IProps {
  noteEdit: boolean;
  note: any;
  selectedHotspot: any;
  changeLeftSidebar: (option: string) => void;
  toggleNewNoteEditorMode: (state: boolean) => void;
  getHotspotList: (deleteHotspot?: string) => void;
  toggleSuccessNotification: (message: string) => void;
}

const AddNote: React.FC<IProps> = ({
  noteEdit,
  note,
  selectedHotspot,
  changeLeftSidebar,
  toggleNewNoteEditorMode,
  getHotspotList,
  toggleSuccessNotification,
}) => {
  const { handleLogoutState } = useAuth();
  const [type, setType] = useState<string>('info');
  const [textInfo, setTextInfo] = useState<any>({
    title: '',
    url: '',
    description: '',
  });
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [iconSize, setIconSize] = useState<string>('');
  const [validAll, setValidAll] = useState(false);
  const [isInfoEnable, setIsInfoEnable] = useState<boolean>(true);
  const [isUrlEnable, setIsUrlEnable] = useState<boolean>(false);
  const [isWarningEnable, setIsWarningEnable] = useState<boolean>(false);
  const [isSmallEnable, setIsSmallEnable] = useState<boolean>(true);
  const [isMediumEnable, setIsMediumEnable] = useState<boolean>(false);
  const [isLargeEnable, setIsLargeEnable] = useState<boolean>(false);

  // Pre-populate fields when its edit modal
  useEffect(() => {
    if (noteEdit) {
      if (selectedHotspot) {
        setType(getHotspotType(selectedHotspot));
        setTextInfo({
          title: selectedHotspot.createTooltipArgs?.title || '',
          url: selectedHotspot.url || '',
          description: selectedHotspot.typeFrontViewer === 4 ? '' : selectedHotspot.createTooltipArgs?.text || '',
        });
        setIconSize(selectedHotspot.createTooltipArgs?.iconSize || '');
      }
    }
  }, [noteEdit, selectedHotspot]);

  useEffect(() => {
    // Vérification des champs pour activer/désactiver le bouton "Add Note"
    const isAnyFieldValid = () => {
      if (isUrlEnable) {
        return textInfo.url && isURLValid(textInfo.url); // Si le type est URL, vérifier sa validité
      } else {
        return textInfo.title !== '' || textInfo.description !== ''; // Sinon vérifier title ou description
      }
    };

    setValidAll(isAnyFieldValid());
  }, [textInfo, isUrlEnable]);

  useEffect(() => {
    // Fonction pour vérifier si les champs sont valides
    const validateFields = () => {
      if (isInfoEnable || isWarningEnable) {
        // Pour info et warning, on vérifie title et description
        return (
          textInfo.title &&
          textInfo.title !== '' &&
          textInfo.description &&
          textInfo.description !== '' &&
          (isSmallEnable || isMediumEnable || isLargeEnable) // Vérifie également la taille de l'icône
        );
      } else if (isUrlEnable) {
        // Pour URL, on vérifie url et description
        return (
          textInfo.url &&
          isURLValid(textInfo.url) &&
          textInfo.description &&
          textInfo.description !== '' &&
          (isSmallEnable || isMediumEnable || isLargeEnable) // Vérifie également la taille de l'icône
        );
      }
      return false;
    };

    // Définir la validité générale
    setValidAll(validateFields());

    // Si l'URL est activée, vérifiez la validité de l'URL
    if (isUrlEnable) {
      setIsValidUrl(textInfo.url && isURLValid(textInfo.url) ? true : false);
    }
  }, [textInfo, isInfoEnable, isWarningEnable, isUrlEnable, isSmallEnable, isMediumEnable, isLargeEnable]);

  const resetFields = () => {
    changeLeftSidebar('');
    setType('');
    setTextInfo('');
    setIconSize('');
    toggleNewNoteEditorMode(false);
    setIsValidUrl(false);
  };

  const createNoteHotspot = async () => {
    ReportCustomEvent(EnumCategory.AddNote, EnumEvent.AddNote);
    try {
      const noteHotspot: any = {
        type: type === 'url' ? 4 : 6,
        name: textInfo.title,
        srcId: note.id,
        phi: note.pitch,
        theta: note.yaw,
        attributes: {
          version: 1,
        },
      };

	  debugger
	  if(iconSize && iconSize !== '0') {
		noteHotspot.attributes.iconSize = iconSize;
	  }

      if (type === 'url') {
        noteHotspot.image = 'link';
      } else if (type === 'info') {
        noteHotspot.image = 'info';
      } else {
        noteHotspot.image = 'under_construction';
      }

      if (textInfo.description) {
        noteHotspot.description = textInfo.description;
      }

      if (textInfo.url) {
        noteHotspot.destId = textInfo.url;
      }
      await createCustomHotspot(noteHotspot);
      getHotspotList();
      // toggleSuccessNotification(`${type} has been added.`);
      toggleSuccessNotification('Your note has been added. Double-click on the icon to edit note.');
      resetFields();
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const updateNoteHotspot = async () => {
    try {
      const noteHotspot: any = {
        type: type === 'url' ? 4 : 6,
        name: textInfo.title,
        id: selectedHotspot.id,
        phi: selectedHotspot.pitch,
        theta: selectedHotspot.yaw,
        attributes: {
          iconSize: iconSize,
          version: 1,
        },
      };

      if (type === 'url') {
        noteHotspot.image = 'link';
      } else if (type === 'info') {
        noteHotspot.image = 'info';
      } else {
        noteHotspot.image = 'under_construction';
      }

      if (textInfo.description) {
        noteHotspot.description = textInfo.description;
      }

      if (textInfo.url) {
        noteHotspot.destId = textInfo.url;
      }

      await updateCustomHotspotNote(noteHotspot);
      getHotspotList();
      toggleSuccessNotification('Your note has been updated. Double-click on the icon to edit note.');
      resetFields();
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const deleteCustomHotspot = async () => {
    try {
      await deleteHotspot(selectedHotspot.id);
      getHotspotList(selectedHotspot.id);
      toggleSuccessNotification('Your note has been deleted.');
      resetFields();
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const updateNoteType = (value: string) => {
    setType(value);
    switch (value) {
      case 'info':
        ReportCustomEvent(EnumCategory.NoteType, EnumEvent.Information);
        setIsInfoEnable(!isInfoEnable);
        setIsUrlEnable(false);
        setIsWarningEnable(false);
        setTextInfo({ url: '' });
        break;
      case 'url':
        ReportCustomEvent(EnumCategory.NoteType, EnumEvent.URL);
        setIsUrlEnable(!isUrlEnable);
        setIsWarningEnable(false);
        setIsInfoEnable(false);
        setTextInfo({ title: '' });
        break;
      case 'warning':
        ReportCustomEvent(EnumCategory.NoteType, EnumEvent.Warning);
        setIsWarningEnable(!isWarningEnable);
        setIsInfoEnable(false);
        setIsUrlEnable(false);
        setTextInfo({ url: '' });
        break;
    }
  };

  const updateSizeType = (value: string) => {
    setIconSize(value);
    switch (value) {
      case '30px':
        setIsSmallEnable(!isSmallEnable);
        setIsMediumEnable(false);
        setIsLargeEnable(false);
        break;
      case '40px':
        setIsMediumEnable(!isMediumEnable);
        setIsLargeEnable(false);
        setIsSmallEnable(false);
        break;
      case '60px':
        setIsLargeEnable(!isLargeEnable);
        setIsMediumEnable(false);
        setIsSmallEnable(false);
        break;
    }
  };

  return (
    <Fragment>
      <div className='modal-background-layer'></div>
      <div className='editor-modal-note'>
        <div className='editor-modal-title'>
          <h1 className='editor-modal-header-title'>
            <Trans id='Create note' />
          </h1>
          <button onClick={() => resetFields()} className='close-button btn-remove-default'>
            <CloseButton />
          </button>
        </div>
        <div className='add-notes-subtitle'>
          <p>
            <Trans id='Create your preferred type of note. When you add it, the note icon will appear on the 360° image.' />{' '}
          </p>
        </div>
        <div className='main-container-note'>
          <div className='note-container'>
            <div className='type-note-container'>
              <p className='add-note-label'>
                <Trans id='Choose the type of note' />
              </p>
              <div className='options'>
                <div className='note-option'>
                  <Info />
                  <p>
                    <Trans id='Information' />
                  </p>

                  <RadioButton status={isInfoEnable} size={16} toggleButton={updateNoteType} value={'info'} />
                </div>
                <div className='note-option'>
                  <UrlNote />
                  <p>
                    <Trans id='URL' />
                  </p>

                  <RadioButton status={isUrlEnable} size={16} toggleButton={updateNoteType} value={'url'} />
                </div>
                <div className='note-option'>
                  <WarningNote />
                  <p>
                    <Trans id='Warning' />
                  </p>

                  <RadioButton status={isWarningEnable} size={16} toggleButton={updateNoteType} value={'warning'} />
                </div>
              </div>
            </div>
            <div className='size-container'>
              <p className='add-note-label'>
                <Trans id='Select note size' />
              </p>
              <div className='options'>
                <div className='note-option'>
                  <SmallIcon />
                  <p>
                    <Trans id='Small' />
                  </p>
                  <p>
                    <Trans id='40 x 40px' />
                  </p>
                  <RadioButton status={isSmallEnable} size={16} toggleButton={updateSizeType} value={EnumHotspotsSize.small} />
                </div>
                <div className='note-option'>
                  <MediumIcon />
                  <p>
                    <Trans id='Medium' />
                  </p>
                  <p>
                    <Trans id='56 x 56px' />
                  </p>
                  <RadioButton status={isMediumEnable} size={16} toggleButton={updateSizeType} value={EnumHotspotsSize.medium} />
                </div>
                <div className='note-option'>
                  <LargeIcon />
                  <p>
                    <Trans id='large' />
                  </p>
                  <p>
                    <Trans id='80 x 80px' />
                  </p>
                  <RadioButton status={isLargeEnable} size={16} toggleButton={updateSizeType} value={EnumHotspotsSize.large} />
                </div>
              </div>
            </div>
          </div>
          <div className='input-container-note'>
            <div className='container-note-title'>
              <p className='add-note-label-title'>
                <Trans id='Title' />
              </p>
              <Trans
                id='Write down a title'
                render={({ translation }: TransRenderProps) => (
                  <input
                    value={textInfo.title || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTextInfo({ ...textInfo, title: e.target.value })}
                    type='text'
                    placeholder={translation as string}
                    className={`add-note-input-title ${textInfo.title ? 'valid-input' : ''} ${
                      isUrlEnable ? 'add-note-input-inactive' : ''
                    }`}
                    disabled={isUrlEnable}
                  />
                )}
              />
            </div>
            <div className='container-note-url' style={{ position: 'relative', marginBottom: '10px' }}>
              <p className='add-note-label-url' style={{ color: !isUrlEnable ? '#818AA6' : '' }}>
                <Trans id='URL' />
              </p>
              <Trans
                id='Paste the URL link'
                render={({ translation }: TransRenderProps) => (
                  <input
                    value={textInfo.url || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTextInfo({ ...textInfo, url: e.target.value })}
                    type='text'
                    placeholder={translation as string}
                    className={`add-note-input-url ${textInfo.url ? 'valid-input' : ''} ${!isUrlEnable ? 'add-note-input-inactive' : ''}`}
                    disabled={!isUrlEnable}
                  />
                )}
              />
              {textInfo && textInfo.url && textInfo.url !== '' && (
                <label className='error-wrong-url'>{isUrlEnable && !isValidUrl ? 'Enter a valid URL' : ''}</label>
              )}
            </div>
            <div className='container-note-description'>
              <p className='add-note-label-description'>
                <Trans id='Description' />
              </p>
              <Trans
                id='Shortly describe note content'
                render={({ translation }: TransRenderProps) => (
                  <textarea
                    className={`add-note-text-area ${textInfo.description ? 'valid-input' : ''} ${
                      isUrlEnable ? 'add-note-text-area-disable' : ''
                    }`}
                    style={{ resize: 'none' }}
                    value={textInfo.description || ''}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setTextInfo({ ...textInfo, description: e.target.value })}
                    placeholder={translation as string}
                    name='message'
                    rows={4}
                  />
                )}
              />
            </div>
          </div>
        </div>

        {noteEdit ? (
          <div className='modal-btn-container-note'>
            <>
              <button
                className='editor-modal-btn-delete button-hover  '
                style={{ marginRight: '20px' }}
                onClick={() => deleteCustomHotspot()}
              >
                <Trans id='Delete note' />
              </button>
              <button
                className={` ${validAll ? 'button-hover editor-modal-btn-active ' : 'editor-modal-btn'}`}
                disabled={!validAll}
                onClick={() => updateNoteHotspot()}
              >
                <Trans id='Update note' />
              </button>
            </>
          </div>
        ) : (
          <div className='modal-btn-container-note'>
            <button className='button-cancel button-hover' onClick={() => resetFields()}>
              <Trans id='Cancel' />
            </button>
            <button
              className={` ${validAll ? 'button-hover editor-modal-btn-active ' : 'editor-modal-btn'}`}
              disabled={!validAll}
              onClick={() => createNoteHotspot()}
            >
              <Trans id='Add note' />
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default AddNote;
