import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import history from './components/utils/history';
import { Router } from 'react-router-dom';
import { AuthProvider } from './auth-routes/auth-context';
import { I18nProvider } from '@lingui/react';
import { detect, fromNavigator, fromStorage } from '@lingui/detect-locale';
import { setBrowserLanguage } from './components/utils/lingui-utils/utils-lingui';
import { i18n } from '@lingui/core';

const DEFAULT_FALLBACK = () => 'en';

const result = detect(fromStorage('Language'), fromNavigator(), DEFAULT_FALLBACK) || '';

const locales: any = setBrowserLanguage(result);

// Language configuration
i18n.load(locales.code, locales.messages);
i18n.loadLocaleData(locales.code, { plurals: locales.plurals });
i18n.activate(locales.code);

ReactDOM.render(
  <Router history={history}>
    <AuthProvider>
      <I18nProvider i18n={i18n}>
        <App />
      </I18nProvider>
    </AuthProvider>
  </Router>,
  document.getElementById('root'),
);
