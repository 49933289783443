export interface IPresetValues {
	con?: number;
	sat?: number;
	mod?: number;
	auto?: boolean;
}

export class ImageBuilder {

	public getDynamicEnhancementUrl(imageUrl: string, presetValues?: IPresetValues): string {
		const autoEnhancedUrl = this.getAutoEnhancementUrl(imageUrl, presetValues?.auto);
		const manualEnhancedUrl = this.getManualEnhancementUrl(autoEnhancedUrl, presetValues);
		return manualEnhancedUrl;
	}

	private getAutoEnhancementUrl(imageUrl: string, autoEnhance?: boolean): string {
		// Auto enhancement
		const autoEditionServerUrl = window.ENV.REACT_APP_IMAGE_ENHANCEMENT || process.env.REACT_APP_IMAGE_ENHANCEMENT;
		if (autoEnhance) {
			return `${autoEditionServerUrl}?url=${encodeURIComponent(imageUrl)}`;
		} else {
			return imageUrl;
		}
	}

	private getManualEnhancementUrl(imageUrl: string, presetValues?: IPresetValues): string {
		// Manual enhancement
		const manualEditionServerUrl = window.ENV.REACT_APP_IMAGE_SERVER || process.env.REACT_APP_IMAGE_SERVER;
		const params: string[] = [];
		const con = presetValues?.con || 0;
		const sat = presetValues?.sat || 1;
		const mod = presetValues?.mod || 1;
		if (con !== 0) {
			params.push("con=" + con);
		}
		if (sat !== 1) {
			params.push("sat=" + sat);
		}
		if (mod !== 1) {
			params.push("mod=" + mod);
		}
		if (params.length) {
			params.push(`url=${encodeURIComponent(imageUrl)}`);
			return `${manualEditionServerUrl}/?${params.join("&")}`;
		} else {
			return imageUrl;
		}
	}
}