import { Trans } from '@lingui/react';
import React from 'react';
import Moment from 'react-moment';
import { EnumAccountStatus } from '../../main/account-management/account-management-canvas';
import LoadingSpinner from '../loading-spinner/loading-spinner';
import './data-table.scss';

interface IDataTableProps {
  accounts: any;
  labels: ILabels[];
  firstAction?: (arg: any) => void;
  secondAction?: (arg: any) => void;
  isLoaded?: boolean;
  labelContainerStyles?: string;
  labelStyles?: string;
  dataContainerStyles?: string;
  dataStyles?: string;
}

interface ILabels {
  tableTitle: any;
  value: any;
}

const DataTable: React.FC<IDataTableProps> = ({
  accounts,
  labels,
  firstAction,
  secondAction,
  isLoaded,
  labelContainerStyles,
  labelStyles,
  dataContainerStyles,
  dataStyles,
}) => {
  const compareExpiryDate = (d: Date): string => {
    const today = new Date();
    const nextWeek = new Date();
    const expiryDate = new Date(d);
    nextWeek.setDate(nextWeek.getDate() + 7);
    if (expiryDate < today) {
      return 'test';
    } else if (expiryDate < nextWeek && expiryDate > today) {
      return 'test2';
    } else {
      return '';
    }
  };

  return (
    <>
      <div>
        <div className={!labelContainerStyles ? 'label-container' : labelContainerStyles}>
          {labels.map((label: ILabels, index: number) => (
            <p
              key={index}
              id={index < 2 ? 'am-name-email-table' : 'am-live-tours-created-login-table'}
              className={!labelStyles ? 'label-field' : labelStyles}
              title={label.tableTitle}
            >
              {label.tableTitle}
            </p>
          ))}
        </div>
        <LoadingSpinner loaded={isLoaded} />
        {accounts &&
          accounts.map((account: any, i: number) => (
            <div
              id='data-box'
              className={`${!dataContainerStyles ? 'data-container' : dataContainerStyles} ${
                account.status === 2 && 'data-container-disable'
              }`}
              key={account.id || account.partnerId || i}
            >
              {labels.map((label: ILabels, index: number) =>
                label.value === 'createdAt' ||
                label.value === 'created' ||
                label.value === 'lastLogin' ||
                label.value === 'proExpiration' ? (
                  <div
                    title={account[label.value]}
                    className={`${!dataStyles ? 'data-field' : dataStyles} ${
                      label.value === 'proExpiration' ? compareExpiryDate(account[label.value]) : ''
                    }`}
                    key={index}
                  >
                    {account[label.value] ? <Moment format='DD/MM/YY'>{account[label.value]}</Moment> : 'N/A'}
                  </div>
                ) : label.value === 'fullName' ? (
                  <div
                    title={account[label.value]}
                    id={account[label.value].toLowerCase().replace(/\s/g, '')}
                    className={!dataStyles ? 'data-field' : dataStyles}
                    key={index}
                  >
                    {account[label.value] ? account[label.value] : 'N/A'}
                  </div>
                ) : label.value === 'leadType' ? (
                  <div
                    title={account[label.value]}
                    id={account[label.value]}
                    className={!dataStyles ? 'data-field' : dataStyles}
                    key={index}
                  >
                    {account[label.value] === '3' ? 'Organiser' : 'Invitee'}
                  </div>
                ) : label.value === 'status' ? (
                  <div
                    title={account[label.value]}
                    className={`${!dataStyles ? 'data-field' : dataStyles} ${account.status === 2 ? 'field-disable' : ''}`}
                    key={index}
                  >
                    {account[label.value] === EnumAccountStatus.Pending ? (
                      <Trans id='Pending' />
                    ) : account[label.value] === EnumAccountStatus.Active ? (
                      <Trans id='Active' />
                    ) : account[label.value] === EnumAccountStatus.Disable ? (
                      <Trans id='Disable' />
                    ) : account[label.value] === EnumAccountStatus.Deleted ? (
                      <Trans id='Deleted' />
                    ) : (
                      ''
                    )}
                  </div>
                ) : label.value !== '' ? (
                  <div title={account[label.value]} className={!dataStyles ? 'data-field' : dataStyles} key={index}>
                    {account[label.value] ? account[label.value] : 0}
                  </div>
                ) : (
                  <div key={index} className='connect-edit-btn'>
                    {firstAction && secondAction ? (
                      <>
                        <button
                          id='am-edit-account-btn'
                          className='ic-general ic-edit-inactive'
                          onClick={() => firstAction(account)}
                        ></button>
                        <button
                          id='am-account-dashboard-btn'
                          className='ic-general ic-connect-inactive'
                          onClick={() => secondAction(account)}
                        ></button>
                      </>
                    ) : firstAction && !secondAction ? (
                      <>
                        <button onClick={() => firstAction(account)}></button>
                      </>
                    ) : !firstAction && secondAction ? (
                      <>
                        <button onClick={() => secondAction(account)}>connect</button>
                      </>
                    ) : !firstAction && !secondAction ? (
                      <div></div>
                    ) : null}
                  </div>
                ),
              )}
            </div>
          ))}
      </div>
    </>
  );
};

export default DataTable;
