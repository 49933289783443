import { ClientApi } from '@vva/front';
import { IPrice } from './interface/interfaces';

const clientApi = new ClientApi();

// conditional window ENV for testing with jest
clientApi.setHeaders({
	'X-Api-Key':
		(window.ENV && window.ENV.REACT_APP_API_KEY ? window.ENV.REACT_APP_API_KEY : null) || process.env.REACT_APP_API_KEY
});

export interface ICheckoutResponse {
	success: boolean;
	sessionId?: string;
}

export const createOneTimeCheckoutSession = async (tourId: string, backUrl: string): Promise<any> => {
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/payments/createOneTimeCheckoutSession`,
		{
			tourId,
			backUrl,
		}
	);
	return response.data;
};

export const createSubscriptionPortal = async (currency: string): Promise<any> => {
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/payments/createCustomerPortalSession`,
		{ currency }
	);
	return response.data;
};

export const listAllPrices = async (): Promise<IPrice[]> => {
	const response = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/payments/getProductsPrices`
	);
	return response.data.data;
};
