import { linguiTranslateToString } from "../../utils/lingui-utils/utils-lingui";

export const onChangeSortBy = (option: string): string[] => {
	if (option === 'Alphabetical order') {
		return ['alphabetical', linguiTranslateToString('Alphabetical order')]
	} else if (option === 'Newest - Oldest') {
		return ['newestOldest', linguiTranslateToString('Newest - Oldest')];
	} else if (option === 'Oldest - Newest') {
		return ['oldestNewest', linguiTranslateToString('Oldest - Newest')];
	} else if (option === 'Last login') {
		return ['lastLogin', linguiTranslateToString('Last login')];
	} else if (option === 'Most Live Tours') {
		return ['mostLiveTour', linguiTranslateToString('Most Live Tours')];
	} else {
		return ['newestOldest', linguiTranslateToString('Newest - Oldest')];
	}
};

export const onChangeSortByAdmin = (option: string): string[] => {
	if (option === 'Alphabetical order') {
		return ['alphabetical', linguiTranslateToString('Alphabetical order')]
	} else if (option === 'Newest - Oldest') {
		return ['newestOldest', linguiTranslateToString('Newest - Oldest')];
	} else if (option === 'Oldest - Newest') {
		return ['oldestNewest', linguiTranslateToString('Oldest - Newest')];
	} else if (option === 'Most Accounts') {
		return ['mostAccount', linguiTranslateToString('Most Accounts')];
	} else {
		return ['newestOldest', linguiTranslateToString('Newest - Oldest')];
	}
};

