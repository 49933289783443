export interface IToolTipData {
	element: string;
	intro: string;
	position: string;
	highlightClass?: string;
}

export const tooltipsData = [
	//? tooltip - leftSidebar
	{
		element: '#add-hotspot',
		intro:
			'This allows you to link the rooms together. Double-click on the image, confirm the link position, select the destination image and create the return link.',
		position: 'right',
		// highlightClass: 'left-toolbar-tooltip-highlight-layer',
	},
	{
		element: '#add-blur',
		intro: 'From here you will be able to blur parts of an image, such as personal photographs and car number plates',
		position: 'right',
		// highlightClass: 'left-toolbar-tooltip-highlight-layer',
	},
	{
		element: '#edit-image',
		intro: 'From here you will be able to enhance and edit brightness, contrast and saturation of your 360 images',
		position: 'right',
		// highlightClass: 'left-toolbar-tooltip-highlight-layer',
	},
	{
		element: '#add-media',
		intro:
			'From here you’ll be able to add still images and media to give an extra view of something that may not be caugth in the 360 panorama (electric/gas meter, fuse box, cupboard, etc)',
		position: 'right',
		// highlightClass: 'left-toolbar-tooltip-highlight-layer',
	},
	{
		element: '#add-notes',
		intro: 'From here you can add notes to your image. This can be an information, a warning or a URL',
		position: 'right',
		// highlightClass: 'left-toolbar-tooltip-highlight-layer',
	},
	{
		element: '#add-annotation',
		intro: 'This section will allow you to add an annotation on a certain part of the image',
		position: 'right',
		// highlightClass: 'left-toolbar-tooltip-highlight-layer',
	},
	{
		element: '#links-map',
		intro: 'This allows you to review the links between your images. You’ll also be able to see any missing link and add them',
		position: 'right',
		// highlightClass: 'left-toolbar-tooltip-highlight-layer',
	},
	//? tooltip - header
	{
		element: '#room-label',
		intro: 'Change the room label from here. Start typing to see the list of predefined label or add your own label',
		position: 'bottom',
		// highlightClass: 'header-tooltip-highlight-layer',
	},
	{
		element: '#floor-number',
		intro: 'Enter the floor number from this tab',
		position: 'bottom',
		// highlightClass: 'header-tooltip-highlight-layer',
	},
	{
		element: '#view-360-tour',
		intro: 'Click here to open the virtual tour URL',
		position: 'bottom',
		// highlightClass: 'header-tooltip-highlight-layer',
	},
	{
		element: '#settings-editor',
		intro:
			'This icon will open the settings menu, which will allow you to edit your tour’s details, your branding, the lead generation form and the viewer configuration settings',
		position: 'bottom',
		// highlightClass: 'header-tooltip-highlight-layer',
	},
	{
		element: '#share-tour-editor',
		intro: 'Click here to share the virtual tour via email, social media or embed it into your website',
		position: 'bottom',
		// highlightClass: 'header-tooltip-highlight-layer',
	},
	{
		element: '#close-editor',
		intro: 'Click here to close the “Studio” mode and go back to the dashboard',
		position: 'bottom',
		// highlightClass: 'header-tooltip-highlight-layer',
	},
	//? tooltip - thumbnail slider
	{
		element: '#add-images-slider',
		intro: 'Click here to add a 360 image to your virtual tour',
		position: 'top',
		// highlightClass: 'header-tooltip-highlight-layer',
	},
	{
		element: '#thumbnail-slider',
		intro: 'Drag and drop images to change their order',
		position: 'top',
		// highlightClass: 'header-tooltip-highlight-layer',
	},
	{
		element: '#three-dot-menu-slider',
		intro: 'Click on the 3-dot menu for more options: Select starting images, select starting point and delete image',
		position: 'top',
		// highlightClass: 'header-tooltip-highlight-layer',
	},
];

export const tooltipsDashboard = [
	{
		element: '#dashboard-icon',
		intro: 'From this section you will be able to create new tour and find, edit, archive or delete any existing tours you have created',
		position: 'right',
		highlightClass: 'highlight-icon-dashboard',
	},
	{
		element: '#create-tour',
		intro: 'Click here to start creating a new tour',
		position: 'bottom',
		highlightClass: 'highlight-btn-create-tour',
	},
	{
		element: '#your-live-tour',
		intro: 'Where you will be able to see all of the tours that are currently active on your account',
		position: 'bottom',
		highlightClass: 'highlight-live-tour',
	},
	{
		element: '#archived-tour',
		intro:
			'Where you will be able to find any tours that have been disabled or recently deleted - you will also be able to unarchive tours or recover deleted tours',
		position: 'bottom',
		highlightClass: 'highlight-archived',
	},
	{
		element: '#dots-menu',
		intro:
			'From this menu you’ll be able to: Share your tour via email, social media, embed into your website, Edit your tour: this will bring you to the “Studio” mode where you can edit and enhance your virtual tour Duplicate your tourDownload your tourProtect your tour with a password Archive a tour Delete a tour',
		position: 'bottom',
		highlightClass: 'highlight-dots',
	},
	{
		element: '#analytics-icon',
		intro:
			'From the analytics page, you will be able to track the overall usage and performance data of your account and each of the tours you have created',
		position: 'right',
		highlightClass: 'highlight-analytics',
	},
	{
		element: '#settings-icon',
		intro: 'From the settings section will allow you to edit the configuration of your virtual tours, your branding, and your data/privacy',
		position: 'right',
		highlightClass: 'highlight-settings',
	},
	{
		element: '#profile-icon',
		intro: 'From this section you will be able to add, amend or remove your personal or company details and to change your password',
		position: 'right',
		highlightClass: 'highlight-profile',
	},
	// {
	// 	element: '#logout-icon',
	// 	intro: 'Click here to log out from your account',
	// 	position: 'right',
	// 	highlightClass: 'highlight-logout',
	// },
	{
		element: '#searchbar',
		intro: 'Use the search bar to search for one of your tours by entering any part of the property’s address.',
		position: 'bottom',
		highlightClass: 'highlight-searchbar',
	},
];

export const accountManagementSteps = [
	{
		element: '#am-searchbar',
		intro:
			'Use the search bar to search for a specific account you would like to edit or access. You can search using the name or email address.',
		position: 'right',
	},
	{
		element: '#am-create-account-btn',
		intro: 'Click here to create a new account. Once created, this account will be accessible from the list below.',
		position: 'right',
	},
	{
		element: '#am-name-email-table',
		intro:
			'This section lists some very general information for your accounts. At a glance, you will be able to see each account holder’s name and login email.',
		position: 'right',
		tooltipClass: 'am-name-email-table-tooltip-layer',
		highlightClass: 'am-name-email-table-highlight-layer',
	},
	{
		element: '#am-live-tours-created-login-table',
		intro:
			'You will also be able to see each account’s number of live tours, the date the account was created and the last time they logged in.',
		position: 'left',
		highlightClass: 'am-live-tours-created-login-table-highlight-layer',
	},
	{
		element: '#am-edit-account-btn',
		intro: 'This icon will allow you to edit the details of each of the accounts that your master account manages.',
		position: 'left',
		tooltipClass: 'am-edit-account-btn-tooltip-layer',
		highlightClass: 'am-edit-account-btn-highlight-layer',
	},
	{
		element: '#am-account-dashboard-btn',
		intro:
			'This icon will allow you to access each of these accounts. Clicking this will take you to the dashboard from the account holder’s perspective.',
		position: 'left',
		tooltipClass: 'am-account-dashboard-btn-tooltip-layer',
		highlightClass: 'am-account-dashboard-btn-highlight-layer',
	},
];

export const createAccountSteps = [
	{
		element: '#ca-name-email',
		intro: 'When creating an account, you must enter the name and login email that the account holder will use to access their account.',
		position: 'right',
	},
	{
		element: '#ca-password',
		intro:
			'You will then be asked to twice enter a secure password for this account. This password must consist of at least 8 characters, and must include one upper-case and lower-case letter, as well as a number and a special character.',
		position: 'left',
	},
	{
		element: '#ca-countries',
		intro:
			'Finally, you must select the user’s country from the drop down box, before clicking “Create account” to finalise this change.',
		position: 'right',
	},
];

export const editAccountSteps = [
	{
		element: '#ea-disable',
		intro:
			'From the edit screen, you can choose to temporarily disable an account. This account will then be grayed out in the list of accounts, although the account’s tours will remain live. ',
		position: 'right',
	},
	{
		element: '#ea-delete',
		intro:
			'You will also be able to permanently delete an account from this page. Note, however, that this change is irreversible and that the account’s tours will be lost permanently.',
		position: 'right',
	},
];