import { Trans } from '@lingui/react';
import React, { useEffect, useState } from 'react';
import { updateTourGeneralConfig, getTourConfig } from '../../../api-helper/api-tours';
import { ReactComponent as CloseButton } from '../../../images/viewer-icons/cross.svg';
import { PanelHooks, TabHooks, Tabs } from '../../utils/tabs/tabs';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import './modals.scss';
import './settings-editor.scss';
import MapsModal from '../../utils/modal/maps-modal';
import { getAddressFields } from '../../utils/validations/tour-creation-validation';
import { ValidationUtils } from '../../utils/validations/validations';
import LoadingSpinner from '../../utils/loading-spinner/loading-spinner';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import TourProfile from './settings/tour-profile';
import MeasurementModal from '../../main/tour/measurement-modal';
import proTag from '../../../images/general-icons/pro-tag.svg';
import LeadGeneration from './settings/lead-generation';
import Branding from './settings/branding';
import TourConfiguration from './settings/tour-configuration';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';
import { IBranding, ILeadConfig, IVTConfig } from '../../main/settings/settings';
import { EnumNotificationType } from '../../utils/notifications/notification';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import { ILeftSidebar } from '../viewer-canvas-utils/viewer-canvas-helper';
import { ISidebar } from '../viewer-canvas';
import EditAiFeatures from './edit-ai-features';
import { IPanorama, ITourSettings } from '../../../api-helper/interface/interfaces';

interface IProps {
  sidebar: ISidebar;
  tour: any;
  changeLeftSidebar: (option: string) => void;
  toggleNotification: (type: EnumNotificationType, message?: string, duration?: number) => void;
  leftSidebar: ILeftSidebar;
  panoramaData: any;
  currentPanorama: IPanorama;
  updatePanoramaList: () => void;
}

export enum EnumLogoStatus {
  Active = 2,
  Inactive = 1,
  Deleted = -1,
}

interface ILatLng {
  lat: number;
  lng: number;
}

export interface ITourInfo {
  id: string;
  shareLocation?: boolean;
  name?: string;
  flatNumber?: string;
  longitude?: number;
  latitude?: number;
  furnished?: number;
  reference?: string;
  type?: string;
  address?: string;
  postcode?: string;
  city?: string;
  tourCountry?: string;
  countryCode?: string;
  cameraHeight?: number;
}

export interface IConfig {
  type: number; // always 1
  autoStartTour: boolean;
  enableLeadGenerationForm: boolean;
  shareLocation: boolean;
  ga: string;
}

export enum EnumTourConfigType {
  LeadsGeneration = 0,
  General = 1,
}

export enum EnumTourType {
  House = 'House',
  Flat = 'Flat',
  Commercial = 'Commercial',
  Other = 'Other',
}

export const tourPropertyType = [
  {
    label: 'House',
    value: EnumTourType.House,
  },
  {
    label: 'Flat',
    value: EnumTourType.Flat,
  },
  {
    label: 'Commercial',
    value: EnumTourType.Commercial,
  },
  {
    label: 'Other',
    value: EnumTourType.Other,
  },
];

export interface IState {
  state: string;
}

export interface ICountries {
  country: string;
  latitude: string;
  longitude: string;
  name: string;
}

const Settings: React.FC<IProps> = ({
  sidebar,
  tour,
  changeLeftSidebar,
  toggleNotification,
  leftSidebar,
  panoramaData,
  currentPanorama,
  updatePanoramaList,
}) => {
  // User config context
  const { handleLogoutState } = useAuth();
  const config = useConfig();
  const [tourInfo, setTourInfo] = useState<ITourInfo>({
    latitude: 0,
    longitude: 0,
    shareLocation: false,
    id: tour.id,
  });

  const [countries, setCountries] = useState<ICountries[]>();
  const [mapsModal, setMapsModal] = useState<boolean>(false);
  const [savedChanges, setSavedChanges] = useState<boolean>(false);
  const [autoSearchState, setAutoSearchState] = useState<IState>({ state: 'none' });
  // Google maps states
  const [addressSearch, setAddressSearch] = useState<any>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [tourConfigResponse, setTourConfigResponse] = useState<any>();
  const [leadConfiguration, setLeadConfiguration] = useState<ILeadConfig>({
    enableLeadGenerationForm: false,
    type: EnumTourConfigType.LeadsGeneration,
    email: '',
    leadsConfig: 0,
    leadsType: 0,
    leadsMessage: '',
  });
  const [branding, setBranding] = useState<IBranding>({
    forceBrand: false,
    brandName: '',
    brandAddress: '',
    brandEmail: '',
  });
  const [aiSettings, setAiSettings] = useState<ITourSettings>({
    autoBlur: false,
    tripodRemoved: false,
    autoAligned: false,
    autoEnhance: false,
    multiResolution: false,
    tripodMaskRatio: 0.3,
  });
  const [configSetting, setConfigSetting] = useState<IVTConfig>({
    autoStartTour: false,
    shareLocation: false,
    ga: '',
    measurementUnit: linguiTranslateToString('Centimeters'),
  });

  // Tour id
  const tourId = tour.id;

  const [mapState, setMapState] = useState<google.maps.Map | any>();
  const [geoCoderState, setGeoCoderState] = useState<google.maps.Geocoder | any>();
  const [marker, setMarker] = useState<google.maps.Marker | any>();
  const [onDragTextShowInput, setOnDragTextShowInput] = useState<boolean>(false);
  const [onDragText, setOnDragText] = useState<string>('');
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [measurementModalType, setMeasurementModalType] = useState<boolean>(false);
  const [measurementModal, setMeasurementModal] = useState<boolean>(false);

  useEffect(() => {
    if (leftSidebar.settings.status) {
      getTourConfiguration();
    }
  }, [leftSidebar]);

  useEffect(() => {
    if (config?.countries) {
      setCountries(config.countries);
    }
  }, [config]);

  useEffect(() => {
    if (leadConfiguration.email === '' && leadConfiguration.leadsType === 0 && leadConfiguration.leadsConfig === 0) {
      setIsValidForm(false);
    } else {
      if (leadConfiguration.email !== '') {
        if (ValidationUtils.isEmail(leadConfiguration.email)) {
          setIsValidForm(true);
        }
      }

      if (leadConfiguration.leadsType !== 0 && leadConfiguration.leadsConfig !== 0) {
        setIsValidForm(true);
        if (leadConfiguration.email !== '') {
          if (ValidationUtils.isEmail(leadConfiguration.email)) {
            setIsValidForm(true);
          } else {
            setIsValidForm(false);
          }
        }
      }

      if (leadConfiguration.leadsType !== 0 || leadConfiguration.leadsConfig !== 0) {
        setIsValidForm(true);
        if (leadConfiguration.email !== '') {
          if (ValidationUtils.isEmail(leadConfiguration.email)) {
            setIsValidForm(true);
          } else {
            setIsValidForm(false);
          }
        }
      }

      if (leadConfiguration.leadsMessage !== '') {
        setIsValidForm(true);
      }
    }
  }, [leadConfiguration]);

  useEffect(() => {
    if (tour && countries) {
      const countryName = countries.find(country => country.country === tour.country);
      const tourData: ITourInfo = {
        id: tour.id,
        name: tour.name,
        address: tour.address,
        longitude: tour.longitude,
        latitude: tour.latitude,
        furnished: tour.furnished,
        type: tour.type,
        postcode: tour.zip,
        city: tour.city,
        countryCode: tour.country,
        tourCountry: countryName && countryName.name ? countryName.name : '',
        shareLocation: tour.shareLocation && tour.shareLocation === '1' ? true : false,
      };

      if (tour.number) {
        tourData.flatNumber = tour.number;
      }
      if (tour.reference && tour.reference !== '') {
        tourData.reference = tour.reference;
      }
      const lenseMeasurement = tour.tourOptions?.find((option: any) => option.tourKey === 'CENTER_LENS_MEASUREMENT');

      if (lenseMeasurement) {
        tourData.cameraHeight = Number(lenseMeasurement.tourValue);
      }

      setTimeout(() => {
        initMap(tour);
      }, 100);
      setTourInfo(tourData);
    }
  }, [tour, countries]);

  function initMap(countryData: any): void {
    const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      zoom: 4,
    });

    setMapState(map);
    const geocoder = new google.maps.Geocoder();
    setGeoCoderState(geocoder);

    // Center map using lat and lng from countries service
    map.setCenter({ lat: Number(countryData.latitude), lng: Number(countryData.longitude) });
    map.setZoom(16);

    const newMarker = new google.maps.Marker({
      map: map,
      draggable: true,
      position: { lat: countryData.latitude, lng: countryData.longitude },
    });
    setMarker(newMarker);

    setAutoSearchState({ state: 'success' });

    google.maps.event.addListener(newMarker, 'dragend', (event: any) => {
      const draggedLatLng = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      setOnDragTextShowInput(true);
      geoCodeLatLng(draggedLatLng, geocoder);
      setAutoSearchState({ state: 'success' });
    });
  }

  const searchGeoWithPin = () => {
    geoCodeAddress(geoCoderState, mapState);
  };

  const geoCodeAddress = (geocoder?: google.maps.Geocoder, resultsMap?: google.maps.Map) => {
    const geoCoder = geocoder ? geocoder : geoCoderState;
    const map = resultsMap ? resultsMap : mapState;
    const searchWord = addressSearch.formatted_address;

    geoCoder
      .geocode({ address: searchWord })
      .then(({ results }: any) => {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        setTourInfo({ ...tourInfo, latitude: lat, longitude: lng });
        map.setCenter(results[0].geometry.location);

        // Dynamic zoom depending on search
        map.setZoom(16);

        if (results && results.length) {
          setAddressSearch({
            formatted_address: results[0].formatted_address,
            addressComponents: results[0].address_components,
          });
        }

        marker.setPosition(results[0].geometry.location);
      })
      .catch((e: Error) => {
        setAutoSearchState({ state: 'fail' });
        console.log('Geocode was not successful for the following reason: ' + e);
      });
  };

  // Reverse geocode to get address
  function geoCodeLatLng(latlng: ILatLng, geocoder: google.maps.Geocoder) {
    geocoder
      .geocode({ location: latlng })
      .then((response: any) => {
        if (response && response.results && response.results.length) {
          setAddressSearch({
            formatted_address: response.results[0].formatted_address,
            addressComponents: response.results[0].address_components,
          });
          setOnDragText(response.results[0].formatted_address);
          const lat = response.results[0].geometry.location.lat();
          const lng = response.results[0].geometry.location.lng();
          if (lat && lng) {
            setTourInfo({ ...tourInfo, latitude: lat, longitude: lng });
          }
        }
      })
      .catch((e: Error) => {
        setAutoSearchState({ state: 'fail' });
        console.log('Geocoder failed due to: ' + e);
      });
  }

  const changeAddressMapsSearch = (search: string) => {
    setAddressSearch({ formatted_address: search });
  };

  const confirmAddress = () => {
    const addressFields = getAddressFields(addressSearch);
    if (addressFields) {
      setAutoSearchState({ state: 'success' });
      setTourInfo({
        ...tourInfo,
        address: addressFields.address,
        postcode: addressFields.postcode,
        city: addressFields.city,
        countryCode: addressFields.countryCode,
        tourCountry: addressFields.tourCountry,
      });
      setMapsModal(false);
    }
  };

  const toggleModal = () => {
    setMapsModal(!mapsModal);
  };

  /**
   * Update general configuration
   * @param configSetting
   * @param tourId
   */
  const updateGeneralConfiguration = async (configSetting: IVTConfig, tourId: string) => {
    try {
      await updateTourGeneralConfig(configSetting, tourId);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error);
    }
  };

  const getTourConfiguration = async () => {
    try {
      const response = await getTourConfig(tourId);
      setTourConfigResponse(response);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error);
    }
  };

  useEffect(() => {
    if (tourConfigResponse) {
      // Set Leads configuration
      setLeadConfiguration({ type: EnumTourConfigType.LeadsGeneration, ...tourConfigResponse.leadsGeneration });

      setBranding(tourConfigResponse.branding);

      // Set config settings
      setConfigSetting(tourConfigResponse.tourConfiguration);

      // Ai features config
      setAiSettings(tourConfigResponse.aiFeatures);

      // Set Tour settings
      setTourInfo({
        ...tourInfo,
        address: tourConfigResponse.tourProfile.address,
        postcode: tourConfigResponse.tourProfile.zip,
        city: tourConfigResponse.tourProfile.city,
        countryCode: tourConfigResponse.tourProfile.country,
        flatNumber: tourConfigResponse.tourProfile.flatNumber,
        furnished: tourConfigResponse.tourProfile.furnished,
        reference: tourConfigResponse.tourProfile.reference,
        type: tourConfigResponse.tourProfile.type,
      });
    }
  }, [tourConfigResponse]);

  const onSubmitGeneralConfig = async () => {
    ReportCustomEvent(EnumCategory.Configuration, EnumEvent.SaveChanges);
    try {
      await updateGeneralConfiguration(configSetting, tourId);
      setSavedChanges(!savedChanges);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error);
    }
  };

  useEffect(() => {
    let confirmation = setTimeout(() => {
      setSavedChanges(false);
    }, 4000);
    return () => {
      clearTimeout(confirmation);
    };
  }, [savedChanges]);

  return (
    <>
      {measurementModal && (
        <MeasurementModal
          modalType={measurementModalType}
          setMeasurementModal={setMeasurementModal}
          units={config.userOptions?.measurementUnit || ''}
        />
      )}
      <div className='modal-background-layer'></div>
      <LoadingSpinner loaded={loaded} />
      <div style={{ display: mapsModal ? 'block' : 'none', zIndex: 3 }}>
        <MapsModal
          addressSearch={addressSearch}
          searchGeoWithPin={searchGeoWithPin}
          changeAddressMapsSearch={changeAddressMapsSearch}
          confirmAddress={confirmAddress}
          toggleModal={toggleModal}
          onDragTextShowInput={onDragTextShowInput}
          onDragText={onDragText}
        />
      </div>
      <div className='editor-modal-container-settings'>
        <div className='editor-modal-title'>
          <h1 className='editor-modal-header-title'>
            <Trans id='Settings' />
          </h1>
          <button onClick={() => changeLeftSidebar('')} className='close-button btn-remove-default'>
            <CloseButton />
          </button>
        </div>
        <div className='settings-tabs-container'>
          <Tabs tabIndex={0}>
            <ul className='settings-editor-tabs'>
              <TabHooks id='tab-tour-profile'>
                <div className='settings-editor-tabs-text'>
                  <Trans id='AI-powered features' />
                  <img style={{ marginLeft: '10px' }} src={proTag} alt='pro' />
                </div>
              </TabHooks>
              <TabHooks id='tab-tour-profile'>
                <div className='settings-editor-tabs-text'>
                  <Trans id='Tour details' />
                </div>
              </TabHooks>
              <TabHooks id='tab-branding'>
                <div className='settings-editor-tabs-text'>
                  <Trans id='Branding' />
                  <img style={{ marginLeft: '10px' }} src={proTag} alt='pro' />
                </div>
              </TabHooks>
              <TabHooks id='tab-leads-generation'>
                <div className='settings-editor-tabs-text'>
                  <Trans id='Leads generation' />
                  <img style={{ marginLeft: '10px' }} src={proTag} alt='pro' />
                </div>
              </TabHooks>
              <TabHooks id='tab-configuration'>
                <div className='settings-editor-tabs-text'>
                  <Trans id='Configuration' />
                </div>
              </TabHooks>
            </ul>
            <PanelHooks>
              <EditAiFeatures
                sidebar={sidebar}
                tour={tour}
                aiSettings={aiSettings}
                changeLeftSidebar={changeLeftSidebar}
                toggleNotification={toggleNotification}
                panoramaData={panoramaData}
                setLoaded={setLoaded}
                currentPanorama={currentPanorama}
                updatePanoramaList={updatePanoramaList}
              />
            </PanelHooks>
            <PanelHooks>
              <TourProfile
                tour={tour}
                tourInfo={tourInfo}
                countries={countries}
                mapState={mapState}
                setTourInfo={setTourInfo}
                setAddressSearch={setAddressSearch}
                setAutoSearchState={setAutoSearchState}
                toggleModal={toggleModal}
                autoSearchState={autoSearchState}
                changeLeftSidebar={changeLeftSidebar}
                setMeasurementModalType={setMeasurementModalType}
                setMeasurementModal={setMeasurementModal}
                toggleNotification={toggleNotification}
              />
            </PanelHooks>
            <PanelHooks>
              <Branding
                sidebar={sidebar}
                tour={tour}
                savedChanges={savedChanges}
                setSavedChanges={setSavedChanges}
                setLoaded={setLoaded}
                branding={branding}
                setBranding={setBranding}
                toggleNotification={toggleNotification}
                leftSidebar={leftSidebar}
              />
            </PanelHooks>
            <PanelHooks>
              <LeadGeneration
                sidebar={sidebar}
                tourId={tourId}
                tour={tour}
                leadConfiguration={leadConfiguration}
                setLeadConfiguration={setLeadConfiguration}
                savedChanges={savedChanges}
                setSavedChanges={setSavedChanges}
                isValidForm={isValidForm}
                toggleNotification={toggleNotification}
              />
            </PanelHooks>
            <PanelHooks>
              <TourConfiguration
                tour={tour}
                configSetting={configSetting}
                setConfigSetting={setConfigSetting}
                onSubmitGeneralConfig={onSubmitGeneralConfig}
                savedChanges={savedChanges}
              />
            </PanelHooks>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Settings;
