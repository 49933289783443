import { useState, useEffect } from 'react';

interface IProps {
  firstPassword: string;
  secondPassword?: string;
  requiredLength?: number;
  numberValidation?: boolean;
  lowercaseValidation?: boolean;
  uppercaseValidation?: boolean;
  specialCharacterValidation?: boolean;
  lengthValidation?: boolean;
}

export const usePasswordValidation = (props: IProps) => {
  const [validLength, setValidLength] = useState<boolean>(false);
  const [hasNumber, setHasNumber] = useState<boolean>(false);
  const [upperCase, setUpperCase] = useState<boolean>(false);
  const [lowerCase, setLowerCase] = useState<boolean>(false);
  const [match, setMatch] = useState<boolean>(false);
  const [specialChar, setSpecialChar] = useState(false);
  const validations = [];

  useEffect(() => {
    //Regex objects
    const specialCharacterRegex = new RegExp(/[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]/, 'g');

    const upperCaseRegex = new RegExp(/[A-Z]/);

    const lowerCaseRegex = new RegExp(/[a-z]/);

    const numberRegex = new RegExp(/[0-9]/);

    // Default password length
    const defaultPasswordLength = props.requiredLength ? props.requiredLength : 6;

    // Length Validation
    props.lengthValidation && setValidLength(props.firstPassword.length >= defaultPasswordLength);

    // Upper-case validation
    props.uppercaseValidation && setUpperCase(upperCaseRegex.test(props.firstPassword));

    // Lower-case validation
    props.lowercaseValidation && setLowerCase(lowerCaseRegex.test(props.firstPassword));

    // Number validation
    props.numberValidation && setHasNumber(numberRegex.test(props.firstPassword));

    // Special character validation
    props.specialCharacterValidation && setSpecialChar(specialCharacterRegex.test(props.firstPassword));

    // Match validation
    setMatch(props.firstPassword && props.firstPassword === props.secondPassword ? true : false);
  }, [
    props.firstPassword,
    props.secondPassword,
    props.requiredLength,
    props.lengthValidation,
    props.uppercaseValidation,
    props.lowercaseValidation,
    props.numberValidation,
    props.specialCharacterValidation,
  ]);

  if (props.lengthValidation) {
    validations.push(validLength);
  }

  if (props.numberValidation) {
    validations.push(hasNumber);
  }

  if (props.uppercaseValidation) {
    validations.push(upperCase);
  }

  if (props.lowercaseValidation) {
    validations.push(lowerCase);
  }

  if (props.specialCharacterValidation) {
    validations.push(specialChar);
  }

  if (props.secondPassword) {
    validations.push(match);
  }

  // if (props.numberValidation) {
  //   validations.push(specialChar);
  // }
  // // ! original code ^^^

  return validations;
};
