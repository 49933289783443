import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import vieweet from '../../../images/vieweet-logo/vieweet-logo.svg';
import showPasswordIcon from '../../../images/icons/password-hidden.svg';
import hidePasswordIcon from '../../../images/icons/password-shown.svg';
import { login, authenticate } from '../../../api-helper/api-auth';
import { ValidationUtils } from '../../utils/validations/validations';
import { useAuth } from '../../../auth-routes/auth-context';
import { Trans } from '@lingui/react';
import './login.scss';
import Footer from '../../utils/footer/footer';
import { ReportPageView, ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent, EnumPagesTitles } from '../../../google-analytics-track-list';
import { Helmet } from 'react-helmet';
import { defineMessage } from '@lingui/macro';
import { INewError } from '@vva/front/api-client/interfaces';

interface IForm {
  email: string;
  password: string;
}

const Login = (): JSX.Element => {
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [form, setForm] = useState<IForm>({
    email: '',
    password: '',
  });
  const [detailWrong, setDetailWrong] = useState<boolean>(false);
  const [buttonDisable, setButtonDisable] = useState<boolean>(true);
  const [redirectAfterLogin, setRedirectAfterLogin] = useState<boolean>(false);
  const { handleLoginState, auth } = useAuth();
  const [errorMessage, setErrorMessage] = useState<any>();

  useEffect(() => {
    setButtonDisable(ValidationUtils.isEmail(form.email) && form.password !== '' ? false : true);
  }, [form]);

  useEffect(() => {
    // componentDidMount
    //Google report page
    ReportPageView(EnumPagesTitles.Login);
  }, []);

  const userLogin = async () => {
    try {
      const loginData = await login(form.email, form.password);
      await authenticate(loginData.token);
      setRedirectAfterLogin(true);
      handleLoginState();
    } catch (error) {
      const err = error as INewError;
      if (err.getMessage()) {
        const errorMessage = err.getMessage();
        setErrorMessage(defineMessage({ message: errorMessage }));
      }
      setDetailWrong(true);
    }
  };

  if (redirectAfterLogin === true || auth) {
    return <Redirect to='/live-tours' />;
  }

  return (
    <>
      <Helmet>
        <html lang='en' />
        <title>Login</title>
        <meta name='description' content='Log in to view and edit your existing tours, or to create new tours.' />
      </Helmet>
      <div className='initial-pages-bg'>
        <div className='onboarding-content-layout'>
          <div>
            <a href='https://www.vieweet.com/'>
              <img className='onboarding-topbar-vieweet-logo' src={vieweet} alt='vieweet' />
            </a>
          </div>
          <div className='onboarding-topbar-links-section'>
            <div className='onboarding-topbar-link-border-below'>
              <h3>
                <Trans id='Login' />
              </h3>
            </div>
            <div className='onboarding-topbar-link-no-border-below'>
              <Link
                to='/signup'
                onClick={() => {
                  //Report Custom Category and Event
                  ReportCustomEvent(EnumCategory.Header, EnumEvent.SignUpButtonClicked);
                }}
              >
                <Trans id='Sign up' />
              </Link>
            </div>
          </div>
          <div
            className='login-initial-pages-container'
            style={{
              transform: `${detailWrong ? 'translate(-50%, -45%)' : 'translate(-50%, -50%)'}`,
              transition: 'transform 0.1s linear',
            }}
          >
            <div className='login-initial-form-container'>
              <form
                className='login-form'
                data-testid='login-form'
                onSubmit={(e: React.FormEvent): void => {
                  e.preventDefault();
                  userLogin();
                }}
              >
                <div className='login-input-section'>
                  <label htmlFor='email' className={`label-login ${detailWrong ? 'label-login-error' : ''}`}>
                    <Trans id='Email' />
                  </label>
                  <input
                    className={`login-input ${
                      form.email === '' ? 'input-neutral-state' : !detailWrong ? 'input-valid-state' : 'input-wrong-state'
                    }`}
                    data-testid='email'
                    name='email'
                    id='email'
                    type='text'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setForm({
                        ...form,
                        [e.target.name]: e.target.value,
                      });
                    }}
                    onFocus={() => setDetailWrong(false)}
                  />
                </div>
                <div className='login-input-section' id='last-input-section'>
                  <label htmlFor='password' className={`label-login ${detailWrong ? 'label-login-error' : ''}`}>
                    <Trans id='Password' />
                  </label>
                  <input
                    className={`login-input ${
                      form.password === '' ? 'input-neutral-state' : !detailWrong ? 'input-valid-state' : 'input-wrong-state'
                    }`}
                    data-testid='password'
                    name='password'
                    id='password'
                    type={passwordShown ? 'text' : 'password'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setForm({
                        ...form,
                        [e.target.name]: e.target.value,
                      });
                    }}
                    onFocus={() => setDetailWrong(false)}
                  />
                </div>
                <div className='login-forgot-pwd-link'>
                  <Link
                    className='login-forgot-pwd-link-a'
                    to='/forgot-pwd'
                    onClick={() => {
                      //Report Custom Category and Event
                      ReportCustomEvent(EnumCategory.Main, EnumEvent.ForgotPasswordButtonClicked);
                    }}
                  >
                    <Trans id='Forgot password ?' />
                  </Link>
                </div>
                {detailWrong && <div className='login-error-notification'>{errorMessage.id}</div>}
                <div className='login-container-btn'>
                  <button
                    data-testid='login-button'
                    className={`btn-login  ${buttonDisable ? 'btn-inactive-login' : 'btn-active-login button-hover'}`}
                    disabled={buttonDisable ? true : false}
                    onClick={(e: React.MouseEvent): void => {
                      e.preventDefault();
                      userLogin();
                      //Report Custom Category and Event
                      ReportCustomEvent(EnumCategory.Main, EnumEvent.LoginButton);
                    }}
                  >
                    <Trans id='Log in' />
                  </button>
                </div>
              </form>
              <button
                data-testid='password-toggle'
                onClick={(e: React.MouseEvent): void => {
                  e.preventDefault();
                  setPasswordShown(!passwordShown);
                }}
                className='login-show-password'
              >
                <img className='login-eye' src={passwordShown ? hidePasswordIcon : showPasswordIcon} alt='Show password icon' />
              </button>
            </div>
          </div>
        </div>
        <Footer isPublicRoute={true} />
      </div>
    </>
  );
};

export default Login;
