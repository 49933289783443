export const removeExtension = (fileName: string) => {
	const regex = /\.(?:jpg|.jpeg|.png|.mp3|.mp4|.JPEG|.JPG|.PNG)/g;
	return fileName.replace(regex, '');
}

export const validateImageType = (fileName: string) => {
	const regex = /\.(?:jpg|jpeg|JPEG|JPG)$/g;
	return fileName.match(regex);
}

export const validateImageSize = (height: number, width: number): boolean => {
	const maxHeight = 6000;
	const maxWidth = 12000;
	if (height > maxHeight || width > maxWidth) {
		return false;
	}
	if (height * 2 !== width) {
		// Check 2:1 ratio
		return false;
	}
	return true;
}