import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, TabHooks, PanelHooks } from '../../utils/tabs/tabs';
import vieweet from '../../../images/vieweet-logo/vieweet-logo.svg';
import { Trans } from '@lingui/react';

import './signup-agree-terms.scss';
import Footer from '../../utils/footer/footer';

interface IProps {
  hideTerms: () => void;
}

const AgreeTerms: React.FC<IProps> = ({ hideTerms }) => {
  return (
    <div className='initial-pages-bg'>
      <div className='onboarding-content-layout'>
        <div>
          <a href='https://www.vieweet.com/'>
            <img className='onboarding-topbar-vieweet-logo' src={vieweet} alt='vieweet' />
          </a>
        </div>
        <div className='onboarding-topbar-links-section'>
          <div className='onboarding-topbar-link-no-border-below'>
            <Link to='/login'>
              <Trans id='Login' />
            </Link>
          </div>
          <div className='onboarding-topbar-link-border-below'>
            <h3>
              <Trans id='Sign up' />
            </h3>
          </div>
        </div>
        <div className='signup-initial-pages-container' style={{ transform: 'translate(-50%, -50%)' }}>
          <div className='signup-agree-terms-container'>
            <Tabs tabIndex={0}>
              <ul className='login-signup-agree-terms-tabs'>
                <TabHooks>
                  <div className='login-signup-terms-titles'>
                    <Trans id='Terms of service' />
                  </div>
                </TabHooks>
                <TabHooks>
                  <div className='login-signup-terms-titles'>
                    <Trans id='Privacy policy' />
                  </div>
                </TabHooks>
              </ul>
              <PanelHooks>
                <div className='login-signup-agree-terms-panel'>
                  <div>
                    <h3>
                      <Trans id='1. Introduction' />
                    </h3>
                    <p>
                      <Trans
                        id='1.1 Please read these Terms carefully and make sure that you understand them before you start to use the Website. We
                      recommend that you print a copy of these Terms for future reference. 1.2 These Terms govern: 1.2.1 your access to, and
                      use of, the Website and the content on it; and 1.2.2 your submission of any comments, postings, contributions,
                      designs, words, images or other material to the Website ("Contributions"). 1.3 If you choose to use certain services
                      that are available via the Website your use of such services may be subject to additional terms and conditions which
                      will be notified to you when you access such services. Some of the provisions contained in these Terms may also be
                      superseded or supplemented by additional terms and conditions or notices published elsewhere on the Website. These
                      will be drawn to your attention where applicable to you. In the event that there is any conflict between these Terms
                      and any additional or supplemental terms on the Website ("Additional Terms"), the Additional Terms shall prevail. 1.4
                      By using the Website you confirm that you accept these Terms and that you agree to comply with them. 1.5 We may revise
                      these Terms at any time. You should check this page from time to time to take note of any changes we make, as they are
                      binding on you from the date that we make them. 1.6 These Terms, any Additional Terms and any contract concluded
                      between us via the Website, are only available in the English language.'
                      />
                    </p>
                    <br />
                    <h3>
                      <Trans id='2. Access to the Website' />
                    </h3>
                    <p>
                      <Trans
                        id='2.1 We shall endeavour to provide constant, uninterrupted access to the Website and any content on it, but we cannot
                      and do not guarantee to do so. Access to the Website is permitted on a temporary basis. We may suspend, withdraw,
                      discontinue or change all or any part of the Website without notice. Save where expressly stated otherwise in any
                      Additional Terms, we will not be liable to you if for any reason the Website is unavailable or is withdrawn at any
                      time or for any period. 2.2 You are responsible for making all arrangements necessary for you to have access to the
                      Website.'
                      />
                    </p>
                  </div>
                </div>
              </PanelHooks>
              <PanelHooks>
                <div className='login-signup-agree-terms-panel'>
                  <div>
                    <h3>Vieweet customer privacy notice</h3>
                    <p>Registered name: Virtual View App Limited</p>
                    <p>This privacy notice tells you what to expect us to do with your personal information.</p>
                    <br />
                    <p>
                      • Contact details
                      <br />
                      • What information we collect, use, and why
                      <br />
                      • Lawful bases and data protection rights
                      <br />
                      • Where we get personal information from
                      <br />
                      • How long we keep information
                      <br />
                      • How to complain
                      <br />
                    </p>
                    <br />
                    <h3>
                      Contact details
                      <br />
                    </h3>
                    <p>
                      Email
                      <br />
                      info@vieweet.com
                      <br />
                    </p>
                    <br />
                    <h3>What information we collect, use, and why</h3>
                    <br />
                    <p>
                      We collect or use the following information to <b>provide services and goods, including delivery:</b>
                    </p>
                    <br />
                    <p>• Purchase or account history</p>
                    <br />
                    <h3>Lawful bases and data protection rights</h3>
                    <br />
                    <p>
                      Under UK data protection law, we must have a “lawful basis” for collecting and using your personal information. There
                      is a list of possible lawful bases in the UK GDPR. You can find out more about lawful bases on the ICO’s website.
                      Which lawful basis we rely on may affect your data protection rights which are in brief set out below. You can find
                      out more about your data protection rights and the exemptions which may apply on the ICO’s website:
                    </p>
                    <br />
                    <p>
                      • Your right of access - You have the right to ask us for copies of your personal information. You can request other
                      information such as details about where we get personal information from and who we share personal information with.
                      There are some exemptions which means you may not receive all the information you ask for. You can read more about
                      this right here.
                      <br />
                      • Your right to rectification - You have the right to ask us to correct or delete personal information you think is
                      inaccurate or incomplete. You can read more about this right here.
                      <br />
                      • Your right to erasure - You have the right to ask us to delete your personal information. You can read more about
                      this right here.
                      <br />
                      • Your right to restriction of processing - You have the right to ask us to limit how we can use your personal
                      information. You can read more about this right here.
                      <br />
                      • Your right to object to processing - You have the right to object to the processing of your personal data. You can
                      read more about this right here.
                      <br />
                      • Your right to data portability - You have the right to ask that we transfer the personal information you gave us to
                      another organisation, or to you. You can read more about this right here.
                      <br />• Your right to withdraw consent – When we use consent as our lawful basis you have the right to withdraw your
                      consent at any time. You can read more about this right here.
                    </p>
                    <br />
                    <p>
                      If you make a request, we must respond to you without undue delay and in any event within one month. To make a data
                      protection rights request, please contact us using the contact details at the top of this privacy notice.
                    </p>
                    <br />
                    <h3>Our lawful bases for the collection and use of your data</h3>
                    <br />
                    <p>
                      Our lawful bases for collecting or using personal information to <b>provide services and goods are:</b>
                    </p>
                    <br />
                    <p>
                      • Consent - we have permission from you after we gave you all the relevant information. All of your data protection
                      rights may apply, except the right to object. To be clear, you do have the right to withdraw your consent at any time.
                    </p>
                    <br />
                    <h3>Where we get personal information from</h3>
                    <br />
                    <p>• Directly from you</p>
                    <br />
                    <h3>How long we keep information</h3>
                    <br />
                    <p>• Till the Vieweet account is no longer in use after 18 months</p>
                    <br />
                    <h3>How to complain</h3>
                    <br />
                    <p>
                      If you have any concerns about our use of your personal data, you can make a complaint to us using the contact details
                      at the top of this privacy notice.
                    </p>
                    <br />
                    <p>
                      If you remain unhappy with how we’ve used your data after raising a complaint with us, you can also complain to the
                      ICO.
                    </p>
                    <br />
                    <p>The ICO’s address: </p>
                    <p>
                      Information Commissioner’s Office
                      <br />
                      Wycliffe House
                      <br />
                      Water Lane
                      <br />
                      Wilmslow
                      <br />
                      Cheshire
                      <br />
                      SK9 5AF
                      <br />
                    </p>
                    <br />
                    <p>Helpline number: 0303 123 1113</p>
                    <br />
                    <p>Website: https://www.ico.org.uk/make-a-complaint</p>
                  </div>
                </div>
              </PanelHooks>
            </Tabs>
            <div className='login-signup-container-btn'>
              <button data-testid='agree-terms' className='btn-agree button-hover btn_active ' onClick={() => hideTerms()}>
                <Trans id='I agree' />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer isPublicRoute={true} />
    </div>
  );
};

export default AgreeTerms;
