export const compareArray = (array1: any[], array2: any[]) => {
	if (JSON.stringify(array1) === JSON.stringify(array2)) {
		return true;
	} else {
		return false;
	}
};

export const compareObj = (obj1: any, obj2: any) => {
	if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
		return true;
	} else {
		return false;
	}
};

export const createArrayCopyNoReference = (array: any[]) => {
	return JSON.parse(JSON.stringify(array));
}