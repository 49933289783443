import { getAutoEnhancement } from "../../../api-helper/api-panoramas";
import { IPanorama } from "../../../api-helper/interface/interfaces";

interface IParameters {
	[key: string]: number;
}

export class PanoramaImage {
	private url: URL;
	private arr: URL[];
	private adjustableParameters: IParameters;

	constructor() {
		const imageServerUrl = window.ENV.REACT_APP_IMAGE_SERVER || process.env.REACT_APP_IMAGE_SERVER;
		this.url = new URL(imageServerUrl);
		this.arr = [
			new URL(imageServerUrl),
			new URL(imageServerUrl),
			new URL(imageServerUrl),
			new URL(imageServerUrl),
			new URL(imageServerUrl),
			new URL(imageServerUrl)
		];
		this.adjustableParameters = {};
	}

	private setUrl(newUrl: string) {
		this.url = new URL(newUrl);
		return this;
	}

	private setArr(newUrls: string[]) {

		for (let i = 0; i < newUrls.length; i++) {
			this.arr.push(new URL(newUrls[i]));
		}
		return this;
	}

	private setAdjustableParameters(presetValues: any) {
		// Add incoming edit options to list
		for (const param in presetValues) {
			this.adjustableParameters[param] = presetValues[param];
		}

		return this;
	}

	private addParametersToUrl = (url: string) => {
		// Add options to url params
		for (const param in this.adjustableParameters) {
			this.url.searchParams.append(param, this.adjustableParameters[param].toString());
		}
		this.url.searchParams.append('url', url);
		this.setUrl(this.url.toString());
		return this;
	}

	private addParametersToCubeMapArray = (urls: string[]) => {
		// Add options to url params
		for (const param in this.adjustableParameters) {
			for (const index in this.arr) {
				this.arr[index].searchParams.append(param, this.adjustableParameters[param].toString());
			}
		}

		if (urls && urls.length > 0) {
			// Add url parameter
			let tempArr = this.arr.map((i, index) => {
				i.searchParams.append('url', urls[index]);
				return i;
			});

			// Turn to string
			let toStringArr = tempArr.map(url => String(url));

			this.setArr(toStringArr);
		}

		return this;
	}

	public getImageServerUrl(panorama: IPanorama, presetValues?: any) {
		let panoramaUrl;
		// Panorama url
		if (Array.isArray(panorama)) {
			panoramaUrl = panorama[0].equiUrl;
		} else {
			panoramaUrl = panorama.equiUrl;
		}
		// Add all adjustable parameters
		this.setAdjustableParameters(presetValues);
		// add all set parameter 
		this.addParametersToUrl(panoramaUrl);
		return this.url;
	}

	public getImageServerUrlCubeMap(panorama: IPanorama, presetValues?: any) {
		let cubeMapArray;
		// Panorama url
		if (Array.isArray(panorama)) {
			cubeMapArray = panorama[0].cubeMap;
		} else {
			cubeMapArray = panorama.cubeMap;
		}
		// Add all adjustable parameters
		this.setAdjustableParameters(presetValues);
		// add all set parameter 
		this.addParametersToCubeMapArray(cubeMapArray);

		return this.arr;
	}

	public getAutoEnhancedCubemap(panorama: IPanorama) {
		const imageServerUrl = window.ENV.REACT_APP_IMAGE_SERVER || process.env.REACT_APP_IMAGE_SERVER;
		return panorama.cubeMap.map((cubemap: string) => imageServerUrl + '?url=' + getAutoEnhancement(cubemap));
	}

	public getImageThumbnail(panorama: IPanorama, presetValues?: any) {
		const panoramaUrl = panorama.originalPreviewUrl;

		// Add all adjustable parameters
		this.setAdjustableParameters(presetValues);

		// add all set parameter 
		this.addParametersToUrl(panoramaUrl);
		return this.url;
	}

	public setContrast(value: number) {
		if (value >= -100 && value <= 100) {
			this.adjustableParameters.con = value;
		}
		return this;
	}

	public setWidth(value: number) {
		this.adjustableParameters.w = value;
		return this;
	}

	public setHeight(value: number) {
		this.adjustableParameters.h = value;
		return this;
	}

	public setBrightness(value: number) {
		// -1 decrease - 1+ increase
		this.adjustableParameters.mod = value;
		return this;
	}

	public setSaturation(value: number) {
		// -1 decrease - 1+ increase
		this.adjustableParameters.sat = value;
		return this;
	}

	public setGamma(value: number) {
		// 1 - 3 default = 2.2
		if (value >= 1 && value <= 3) {
			this.adjustableParameters.gam = value;
		}
		return this;
	}
}
