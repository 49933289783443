import React from 'react';
import './loading-spinner.scss';
import vieweet from '../../../images/menu-icons/vieweet-logo.svg';

interface IProps {
  loaded?: boolean;
}

const LoadingSpinnerNoBg: React.FC<IProps> = ({ loaded }) => {
  return (
    <div style={{ display: loaded ? 'none' : 'block' }} id='loading-spinner-no-bg'>
      <div className='image-loading-spinner'>
        <img src={vieweet} alt='spinner' id='img-spinner' />
        <div className='loader'></div>
      </div>
    </div>
  );
};

export default LoadingSpinnerNoBg;
