import { IVTConfig } from "../components/main/settings/settings"
import { ClientApi } from '@vva/front';

const clientApi = new ClientApi();

// conditional window ENV for testing with jest
clientApi.setHeaders({
	'X-Api-Key': (window.ENV && window.ENV.REACT_APP_API_KEY ? window.ENV.REACT_APP_API_KEY : null) || process.env.REACT_APP_API_KEY,
});

const getConfig = async () => {
	const updateConfig = await clientApi.getData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/account/config`);
	return updateConfig.data;
}

const updateConfig = async (updateConfiguration: IVTConfig, global: boolean) => {
	const updateConfig = await clientApi.putData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/account/config`, { ...updateConfiguration, global: global });
	return updateConfig;
}

export { getConfig, updateConfig }