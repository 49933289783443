export enum EnumPagesTitles {
	Login = 'Login-page',
	SignUpPage = 'Sign up page',
	ForgotPasswordPage = 'Forgot-password-page',
	Profile = 'Profile',
	Settings = 'Settings',
	Analytics = 'Analytics',
	AccountManagement = 'Account management',
	Editor = 'Editor',
	Dashboard = 'Dashboard'


}

export enum EnumCategory {
	Header = 'Header',
	ConnectToTour = 'Connect to tour',
	Main = 'Main',
	MainMenu = 'Main menu',
	VtConfiguration = 'VT configuration',
	LanguageSettingDropDown = 'Language setting drop-down',
	FinalButton = 'final button',
	Dollhouse = 'Dollhouse',
	AccountMenu = 'Account Menu',
	DownloadMenu = 'Download menu',
	DropDownMenu = 'Drop-down menu',
	ToolBar = 'Toolbar',
	ThumbnailsBar = 'Thumbnails bar',
	Menu = 'menu',
	LinkCreation = 'Link Creation',
	EditLink = 'Edit link',
	BlurCreation = 'Blur creation',
	Presets = 'Presets',
	Manual = 'Manual',
	UploadStillImage = 'Upload still image',
	UploadMedia = 'Upload media',
	NoteType = 'Note type',
	AddNote = 'Add note',
	CreateAnnotation = 'Create annotation',
	YourLinks = 'Your links',
	TourProfile = 'Tour profile',
	branding = 'Branding',
	LeadGeneration = 'Lead generation',
	Configuration = 'Configuration',
	ShareTour = 'Share tour',
	EmailTour = 'Email tour',
	EmbedTour = 'Embed tour',
	TourInformation = 'Tour information',
	Footer = 'Footer',
	TourMenu = 'Tour menu',
	Sidebar = 'Sidebar',
	ArchivedTourMenu = 'Archived tour menu',
	DeleteTourMenu = 'Delete tour menu',
	Tooltip = 'Tooltip',
	DollHouse = 'Doll House'
}


export enum EnumEvent {
	LoginButton = 'Login button clicked',
	ConnectToTourButton = 'Connected to tour from admin account',
	SignUpButtonClicked = 'Sign up button clicked',
	ForgotPasswordButtonClicked = 'Forgot password button clicked',
	OpenTermsAndPolicy = 'Open terms and policy',
	TriggerPasswordRecoveryEmail = 'Trigger password recovery email',
	ViewSecuritySettings = 'View security settings',
	SaveChanges = 'Save changes',
	OrderDollhouse = 'Order Dollhouse',
	ConfirmeOrderDollhouse = "Confirme Order Dollhouse",
	CancelDollhouseOrder = "Cancel Dollhouse Order",
	CancelChanges = 'Cancel changes',
	ViewYourDetails = 'View your details',
	AIPoweredFeatures = 'AI Powered features',
	YourBrandingSettings = 'Your branding settings',
	SubscriptionSettings = 'Subscription settings',
	ChangeSubscriptionType = 'Change type of subscription',
	YourDataSettings = 'Your data settings',
	EnableAutoStart = 'Enable auto start',
	DisableAutoStart = 'Disable auto start',
	EnableLiveSharing = 'Enable live sharing',
	DisableLiveSharing = 'Disable live sharing',
	EnableShareLocation = 'Enable share location',
	DisableShareLocation = 'Disable share location',
	EnableTripodRemoved = 'Enable tripod removed',
	DisableTripodRemoved = 'Disable tripod removed',
	EnableBlur = 'Enable blur',
	DisableBlur = 'Disable blur',
	EnableEnhance = 'Enable enhance',
	DisableEnhance = 'Disable enhance',
	EnableAutoAligned = 'Enable auto aligned',
	DisableAutoAligned = 'Disable auto aligned',
	EnableLocationSharing = 'Enable location sharing',
	DisableLocationSharing = 'Disable location sharing',
	EnableMultires = 'Enable multi-resolution panoramas',
	DisableMultires = 'Disable multi-resolution panoramas',
	EnableLeadGenerationForm = 'Enable lead generation form',
	DisableLeadGenerationForm = 'Disable lead generation form',
	EnableTripodCapLogo = 'Enable tripod cap logo',
	DisableTripodCapLogo = 'Disable tripod cap logo',
	EnableDisableGoogleAnalyticsIntegration = 'enable/disable Google Analytics integration',
	UploadLogoFile = 'Upload logo file',
	VirtualTourConfigurationMenu = 'Virtual tour configuration menu',
	DownloadAllAccountData = 'Download all account data',
	ConfirmAccountDeletion = 'Confirm account deletion',
	DisplayLeadsGenerated = 'Display leads generated',
	DisplayAnalyticsGenerated = 'Display Analytics generated',
	DisplayLeadsLiveSharingGenerated = 'Display leads live sharing generated',
	DisplayPanoramaInfo = 'Display Panorama Info',
	DownloadStatsAsCSV = 'Download stats as CSV',
	DownloadAllLeads = 'Download all leads',
	DownloadAllLiveSharingLeads = 'Download all leads',
	DownloadAllStats = 'Download all tour stats',
	ViewLeadsForLast24h = 'View leads for last 24 hours',
	ViewLeadsLiveSharingForLast24h = 'View leads for last 24 hours',
	ViewStatsForLast24h = 'View stats for last 24 hours',
	ViewLeadsForCurrentWeek = 'View leads for current week',
	ViewLeadsLiveSharingForCurrentWeek = 'View leads for current week',
	ViewStatsForCurrentWeek = 'View stats for current week',
	ViewLeadsForLastWeek = 'View leads for last week',
	ViewLeadsLiveSharingForLastWeek = 'View leads for last week',
	ViewStatsForLastWeek = 'View stats for last week',
	ViewLeadsForCurrentMonth = 'View leads for current month',
	ViewLeadsLiveSharingForCurrentMonth = 'View leads for current month',
	ViewStatsForCurrentMonth = 'View stats for current month',
	ViewLeadsForLastMonth = 'View leads for last month',
	ViewLeadsLiveSharingForLastMonth = 'View leads for last month',
	ViewStatsForLastMonth = 'View stats for last month',
	ViewLeadsForCurrentYear = 'View leads for current year',
	ViewLeadsLiveSharingForCurrentYear = 'View leads for current year',
	ViewStatsForCurrentYear = 'View stats for current year',
	ViewLeadsForLastYear = 'View leads for last year',
	ViewLeadsLiveSharingForLastYear = 'View leads for last year',
	ViewStatsForLastYear = 'View stats for last year',
	SearchForAccounts = 'Search for accounts',
	CreateNewAccount = 'Create new account',
	EditAccount = 'Edit account',
	AccessAccount = 'Access account',
	DisableAccount = 'Disable account',
	DeleteAccount = 'Delete account',
	CreateAccount = 'Create account',
	AddLink = 'Add link',
	AddBlur = 'Add blur',
	AutoFaceDetection = 'Automatic face detection',
	EnhanceImage = 'Enhance image',
	Add2Dor3DMedia = 'Add 2D or 3D media',
	AddNote = 'Add note',
	AddAnnotation = 'Add annotation',
	AddAudio = 'Add audio',
	SeeLinkMap = 'See linkmap',
	ChangeLabel = 'Change label',
	ChangeFloor = 'Change floor',
	ChangeIndoor = 'Change indoor',
	View360 = 'View 360',
	OpenSettings = 'Open settings',
	ShareTour = 'Share tour',
	BackToDashboard = 'Back to dashboard',
	Add360Image = 'Add 360 image',
	GoTo = 'Go to',
	OpenMenu = 'Open menu',
	SetAsStartingImage = 'Set as starting image',
	SelectStartingPoint = 'Select starting point',
	DeleteImage = 'Delete image',
	CreateLink = 'Create link',
	CreateReturnLink = 'Create return link',
	Changedestination = 'Change destination',
	ChangeIcon = 'Change icon',
	ChangeColor = 'Change color',
	StartBlur = 'Start blur',
	FinishBlur = 'Finish blur',
	ApplyBlur = 'Apply blur',
	UsePresets = 'Use presets',
	EditManually = 'EditManually',
	ResetsToOriginal = 'Resets to original',
	ApplyPresets = 'ApplyPresets',
	AutoEnhance = 'Auto enhance image',
	RemoveAutoEnhance = 'Remove auto enhance',
	ApplyChanges = 'Apply changes',
	UploadStillImage = 'Upload still image',
	UploadMedia = 'Upload media',
	Information = 'Information',
	URL = 'URL',
	Warning = 'Warning',
	StartFinishAnnotation = 'Start/Finish annotation',
	DeleteAnnotation = 'Delete annotation',
	LinkImage = 'Link image',
	ReturnToEditor = 'Return to editor',
	EnableForm = 'Enable form',
	AutoStartTour = 'Auto start tour',
	DisplayLocation = 'Display location',
	AutoTour = 'Auto tour',
	CopyURL = 'Copy url',
	OpenFacebook = 'Open facebook',
	OpenTwitter = 'Open twitter',
	SendEmail = 'Send email',
	CopyEmbedCode = 'Copy embed code',
	ViewArchivedTours = 'View archived tours',
	SearchForTour = 'Search for tour',
	ChangeToListView = 'Change to list view',
	ChangeToGridView = 'Change to grid view',
	OpenTourWithUrl = 'Open tour with URL',
	OpenTourProfile = 'Open tour profile',
	AccessSupportPortal = 'Access support portal',
	ViewHelpGuides = 'View help guides',
	OpenEditor = 'Open editor',
	DuplicateTour = 'Duplicate tour',
	TransferTour = 'Move tour to another account',
	DownloadEntireTour = 'Download entire tour',
	AddPasswordToTour = 'Add password to tour',
	RemovePasswordToTour = 'Remove password',
	ArchiveTour = 'Archive tour',
	UsersLiveTours = 'Users Live Tours',
	DeleteTour = 'Delete tour',
	CreateATour = 'Create a tour',
	MainDashboard = 'Main dashboard',
	Analytics = 'Analytics',
	Settings = 'Settings',
	Profile = 'Profile',
	UnarchiveTour = 'Unarchive tour',
	RestoreTour = 'Restore tour',
	TooltipStudio = 'Tooltip Studio',
	TooltipDashboard = 'Tooltip Dashboard',
	TooltipDownloadTour = 'Tooltip Download tour',
	TooltipEditTour = 'Tooltip edit tour',
	TooltipCreateTour = 'Tooltip edit tour',
	UpdateToProAccount = 'Update to pro account',
}
