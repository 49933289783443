/**
 * Different mutating types to be performed in the reducers dispatch.
 */
export enum ListReducerActionType {
    /** Remove one or many item(s) from state */
    Remove = "REMOVE_ITEM",
    /** Replace or append one or many item(s) of state*/
    Update = "UPDATE_ITEM",
    /** Append one or many item(s) to state*/
    Add = "ADD_ITEM",
    /** Replace one or many item(s) from state if present */
    AddInitial = "ADD_INITIAL",
    /** Reset the whole state */
    Reset = "RESET",
}

/**
 * The conditional type, specifying the type of data depending on the action type
 */
type ActionDataType<T> =
    /** Append one or many item(s) to state*/
    | { type: "ADD_ITEM"; item: T }
    /** Remove one or many item(s) from state */
    | { type: "REMOVE_ITEM"; id: T[keyof T] }
    /** Replace one or many item(s) from state if present */
    | { type: "UPDATE_ITEM"; item: T }
    /** Initialize with the initial items */
    | { type: "ADD_INITIAL"; items: T[] }
    /** Reset the whole state */
    | { type: "RESET" };

/**
 * State interface
 */
interface State<T> {
    data: T[];
}

/**
 * @typeparam `T` type of the reducer state
 * @param {keyof T} key value of `U`
 * @returns {Reducer} React reducer for a stateful list of `T`
 *
 * Can be initiated like this
 * `createListReducer<Entity,id>()`
 * Where `Entity` is the type of the list and `"id"` is a property key on the type
 * that is to be used to find index in the list
 */
export default <T>(key: keyof T) => (
    state: State<T>,
    action: ActionDataType<T>
): State<T> => {
    switch (action.type) {
        case ListReducerActionType.Add: {
            return {
                ...state,
                data: [...state.data, action.item],
            };
        }
        case ListReducerActionType.Remove: {
            const existingRemove = state.data.find(
                (item: T) => item[key] === action.id
            );
            if (existingRemove) {
                const array = state.data.filter(
                    (item: T) => item[key] !== action.id
                );
                const newData = array;
                return {
                    ...state,
                    data: newData,
                };
            }
            return {
                ...state,
            };
        }
        case ListReducerActionType.Update: {
            const elementsIndex = state.data.findIndex(
                (item: T) => item[key] === action.item[key]
            );
            const newArray = state.data;
            newArray[elementsIndex] = action.item;
            return {
                ...state,
                data: newArray,
            };
        }
        case ListReducerActionType.AddInitial: {
            return {
                ...state,
                data: action.items,
            };
        }
        case ListReducerActionType.Reset: {
            return {
                ...state,
                data: [],
            };
        }
        default:
            throw new Error("Action not supported");
    }
};
