import React from 'react';
import { EnvUtils } from '../utils/env-utils';

const TestHealthPage: React.FC = () => {
  const version = EnvUtils.getAppVersion() || 'local';
  const env = EnvUtils.getAppEnv();
  const name = EnvUtils.getAppName();
  const cloud = EnvUtils.getCloudProvider();
  return (
    <div className='ping-test'>
      <div style={{ marginLeft: '43%', textAlign: 'left' }}>
        <h2>Ping Response</h2>
      </div>
      <div style={{ marginLeft: '44.5%', textAlign: 'left' }}>
        <ul style={{ listStyleType: 'inherit', padding: '0' }}>
          <li>app name: {name}</li>
          <li>app version: {version}</li>
          <li>app env: {env}</li>
          <li>cloud provider: {cloud}</li>
        </ul>
      </div>
    </div>
  );
};

export default TestHealthPage;
