import React, { useState, useEffect } from 'react';
import { Trans } from '@lingui/react';
import { ReactComponent as AddressMap } from '../../../images/tours/address-map.svg';
import { ReactComponent as AddressMapRed } from '../../../images/tours/address-map-red.svg';
// import { DropDownCountries } from '@vva/front';
import CustomSelect from '../../utils/custom-select/custom-select';
import { defineMessage } from '@lingui/macro';
import { ITourInfo } from './interfaces';
import { ISelectedCountry } from '../profile/profile';
import { createTour, updateTour } from '../../../api-helper/api-tours';
import MapsModal from '../../utils/modal/maps-modal';
import { fillSearch, getAddressFields, validateAutoSearch, validateInputs } from '../../utils/validations/tour-creation-validation';
import { INewTour, TabStateEnum } from './create-tour';
import { handleError } from '../../../api-helper/api-error-handling';
import { EnumNotificationType } from '../../utils/notifications/notification';
import { useAuth } from '../../../auth-routes/auth-context';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import { getConfig } from '../../../api-helper/api-account';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';
import { tourPropertyType } from '../../editing-viewer/modals/settings';
import DropDownCountries from '../../utils/dropdown-front/drop-down-countries';


interface IProps {
  tourInput: ITourInfo;
  setTourInput: (state: ITourInfo) => void;
  setMeasurementModalType: (state: boolean) => void;
  setMeasurementModal: (state: boolean) => void;
  newTour?: INewTour;
  setNewTour: (state: INewTour) => void;
  toggleNotification: (type: EnumNotificationType, message?: string) => void;
  setLoading: (state: boolean) => void;
  setTabsState: (state: TabStateEnum) => void;
  setIsTooltipActive: (state: boolean) => void;
  tourCreated: boolean;
  setTourCreated: (state: boolean) => void;
}

interface ILongLat {
  lat: number;
  lng: number;
}

interface IAddressSearch {
  formatted_address: string;
  addressComponents?: {
    long_name: string;
    short_name: string;
  }[];
}

export interface IUserOptions {
  autoStartTour?: boolean;
  ga?: string;
  language?: string;
  shareLocation?: boolean;
  measurementUnit?: string;
}

const CreateTourDetails: React.FC<IProps> = ({
  tourInput,
  setTourInput,
  setMeasurementModalType,
  setMeasurementModal,
  newTour,
  setNewTour,
  toggleNotification,
  setLoading,
  setTabsState,
  setIsTooltipActive,
  tourCreated,
  setTourCreated,
}) => {
  const { handleLogoutState } = useAuth();

  const [showCustomDropDownPropertyType, setShowCustomDropDownPropertyType] = useState<boolean>(false);
  const [showCustomDropDownPropertyFurnished, setShowCustomDropDownPropertyFurnished] = useState<boolean>(false);
  const [mapState, setMapState] = useState<google.maps.Map>();
  const [geoCoderState, setGeoCoderState] = useState<google.maps.Geocoder>();
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [autoSearchValidation, setAutoSearchValidation] = useState<boolean>(false);
  const [autoSearchState, setAutoSearchState] = useState({ state: 'none' });
  const [onDragTextShowInput, setOnDragTextShowInput] = useState<boolean>(false);
  const [onDragText, setOnDragText] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(true);
  const [mapsModal, setMapsModal] = useState<boolean>(false);
  const [addressSearch, setAddressSearch] = useState<IAddressSearch>();
  const config = useConfig();
  const [countries, setCountries] = useState();

  useEffect(() => {
    if (validateInputs(tourInput)) {
      setValidated(true);
    } else {
      setValidated(false);
    }
    // Validate inputs
  }, [tourInput]);

  useEffect(() => {
    if (config && config.countries) {
      setCountries(config.countries);
      const userCountryId = config.countryId || 'GB';
      const countryData = config.countries.find((country: ISelectedCountry) => country.country === userCountryId);
      const countryInfo = {
        tourCountry: countryData.name,
        countryCode: countryData.country,
        latitude: Number(countryData.latitude),
        longitude: Number(countryData.longitude),
      };
      setTimeout(() => {
        initMap(countryData);
      }, 100);
      if (!tourCreated) {
        getConfiguration(countryInfo);
      }
    }
  }, [config]);

  const getConfiguration = async (countryInfo: ITourInfo) => {
    try {
      const getUserConfig = await getConfig();
      let tour: ITourInfo = { ...countryInfo };
      if (getUserConfig) {
        let obj: IUserOptions = {};
        for (const config of getUserConfig) {
          const value = config.userValue === '1' ? true : config.userValue;
          if (Object.keys(config.src).length !== 0) {
            obj = { ...obj, [config.src]: value };
          }
        }
        if (obj && obj.shareLocation) {
          tour.shareLocation = obj.shareLocation;
        }
        if (obj && obj.measurementUnit) {
          tour.measurementUnit = obj.measurementUnit;
        } else {
          tour.measurementUnit = linguiTranslateToString('Centimeters');
        }
      }
      setTourInput(tour);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const toggleModal = () => {
    setMapsModal(!mapsModal);
  };

  const changeTypePropertyHandler = (value: string) => {
    setTourInput({ ...tourInput, type: value });
    toggleCustomDropdownPropertyType(false);
  };

  const changeIsFurnishedHandler = (value: string) => {
    const isFurnished = value === 'Yes' ? 1 : 0;
    setTourInput({ ...tourInput, furnished: isFurnished });
    toggleCustomDropdownPropertyFurnished(false);
  };

  const toggleCustomDropdownPropertyType = (option?: boolean) => {
    if (option) {
      setShowCustomDropDownPropertyType(option);
    } else {
      setShowCustomDropDownPropertyType(!showCustomDropDownPropertyType);
    }
  };

  const toggleCustomDropdownPropertyFurnished = (option?: boolean) => {
    if (option) {
      setShowCustomDropDownPropertyFurnished(option);
    } else {
      setShowCustomDropDownPropertyFurnished(!showCustomDropDownPropertyFurnished);
    }
  };

  const handleSelectedLabel = async (selectedValue: ISelectedCountry[]) => {
    if (selectedValue && selectedValue.length) {
      const lat = selectedValue[0].latitude;
      const long = selectedValue[0].longitude;
      const name = selectedValue[0].name;
      const country = selectedValue[0].country;

      // Center map with right country and reset fields
      const map = mapState;

      map?.setCenter({ lat: +lat, lng: +long });

      // Reset fields when country is changed
      setTourInput({ tourCountry: name, countryCode: country, latitude: Number(lat), longitude: Number(long) });
      setAddressSearch({ formatted_address: name });
      setAutoSearchState({ state: 'none' });
    }
  };

  const changeAddressMapsSearch = (search: string) => {
    setAddressSearch({ formatted_address: search });
  };

  const validateAndAutoSearch = () => {
    // Auto add fields to search
    const autoFillSearch = fillSearch(tourInput);
    setAddressSearch({ formatted_address: autoFillSearch });
    if (validateAutoSearch(tourInput)) {
      setAutoSearchValidation(true);
      setAutoSearchState({ state: 'success' });
    } else {
      setAutoSearchValidation(false);
      setAutoSearchState({ state: 'none' });
    }
  };

  const saveTourAsync = async () => {
    setLoading(true);
    try {
      // Build tour name automatically
      const safeTourInput = {
        ...tourInput,
        id: newTour?.id,
        name: getTourName(tourInput),
      };
      await updateTour(safeTourInput);
      setLoading(false);
      setTabsState(TabStateEnum.SelectOptions);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoading(false);
    }
  };

  const createTourAsync = async () => {
    setTabsState(TabStateEnum.SelectOptions);
    setIsTooltipActive(false);
    setLoading(true);
    try {
      // Build tour name automatically
      const safeTourInput = {
        ...tourInput,
        name: getTourName(tourInput),
      };

      const createNewTour = await createTour(safeTourInput);
      setNewTour(createNewTour);
      setTabsState(TabStateEnum.SelectOptions);
      setLoading(false);
      setTourCreated(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoading(false);
    }
  };

  const getInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTourInput({ ...tourInput, [e.target.name]: e.target.value });
  };

  const getTourName = (formatAddress: ITourInfo): string => {
    const nameArray = [];
    if (formatAddress.flatNumber) {
      const safeFlatNumber = formatAddress.flatNumber.toLowerCase().includes('flat')
        ? formatAddress.flatNumber
        : 'Flat ' + formatAddress.flatNumber;
      nameArray.push(safeFlatNumber.trim());
    }
    if (formatAddress.address) {
      nameArray.push(formatAddress.address.trim());
    }
    if (formatAddress.postcode) {
      nameArray.push(formatAddress.postcode.trim());
    }
    if (formatAddress.city) {
      nameArray.push(formatAddress.city.trim());
    }
    if (formatAddress.countryCode) {
      nameArray.push(formatAddress.countryCode.trim());
    }
    return nameArray.filter(n => n.length).join(', ');
  };

  const searchGeoWithPin = () => {
    geoCodeAddress(geoCoderState, mapState);
  };

  useEffect(() => {
    // Refill auto address search when coming back to this tab
    if (tourCreated) {
      setTimeout(() => validateAndAutoSearch(), 1000);
    }
  }, []);

  useEffect(() => {
    if (autoSearchValidation) {
      geoCodeAddress(geoCoderState, mapState);
    }
  }, [autoSearchValidation]);

  /********************* Google Maps *************************/

  function initMap(countryData: ISelectedCountry): void {
    const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      zoom: 4,
    });

    setMapState(map);
    const geocoder = new google.maps.Geocoder();
    setGeoCoderState(geocoder);

    const lat = tourInput?.latitude ? Number(tourInput?.latitude) : +countryData.latitude;
    const long = tourInput?.longitude ? Number(tourInput?.longitude) : +countryData.longitude;

    // Center map using lat and lng from countries service
    map.setCenter({ lat: lat, lng: long });

    if (!tourCreated) {
      setTourInput({ ...tourInput, tourCountry: countryData.name, countryCode: countryData.country });
      setAddressSearch({ formatted_address: countryData.name });
    }
  }

  const geoCodeAddress = (geocoder?: google.maps.Geocoder, resultsMap?: google.maps.Map) => {
    const geoCoder = geocoder ? geocoder : geoCoderState;
    const map = resultsMap ? resultsMap : mapState;
    const searchWord = addressSearch?.formatted_address;

    if (geoCoder) {
      geoCoder
        .geocode({ address: searchWord })
        .then(({ results }: google.maps.GeocoderResponse) => {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          setTourInput({ ...tourInput, latitude: lat, longitude: lng });
          map?.setCenter(results[0].geometry.location);

          // Dynamic zoom depending on search
          map?.setZoom(16);

          let newMarker = null;

          if (results && results.length) {
            setAddressSearch({
              formatted_address: results[0].formatted_address,
              addressComponents: results[0].address_components,
            });
          }

          const position =
            newTour?.latitude && newTour?.longitude ? { lat: newTour.latitude, lng: newTour.longitude } : results[0].geometry.location;

          if (marker) {
            marker.setPosition(position);
          } else {
            // Set the maker first time only with country name
            newMarker = new google.maps.Marker({
              map: map,
              draggable: true,
              position: position,
            });
            setMarker(newMarker);

            setAutoSearchState({ state: 'success' });

            const markerToUse = newMarker ? newMarker : marker;

            if (markerToUse) {
              google.maps.event.addListener(markerToUse, 'dragend', (event: any) => {
                const draggedLatLng = {
                  lat: event.latLng.lat(),
                  lng: event.latLng.lng(),
                };
                setOnDragTextShowInput(true);
                geoCodeLatLng(draggedLatLng);
                setAutoSearchState({ state: 'success' });
              });
            }
          }
        })
        .catch((e: Error) => {
          setAutoSearchState({ state: 'fail' });
          console.log('Geocode was not successful for the following reason: ' + e);
        });
    }
  };

  // Reverse geocode to get address
  const geoCodeLatLng = (latlng: ILongLat) => {
    if (geoCoderState) {
      geoCoderState
        .geocode({ location: latlng })
        .then((response: google.maps.GeocoderResponse) => {
          if (response && response.results && response.results.length) {
            setAddressSearch({
              formatted_address: response.results[0].formatted_address,
              addressComponents: response.results[0].address_components,
            });
            setOnDragText(response.results[0].formatted_address);
            const lat = response.results[0].geometry.location.lat();
            const lng = response.results[0].geometry.location.lng();
            if (lat && lng) {
              setTourInput({ ...tourInput, latitude: lat, longitude: lng });
            }
          }
        })
        .catch((e: Error) => {
          setAutoSearchState({ state: 'fail' });
          const message = 'Geocoder failed due to: ' + e;
          toggleNotification(EnumNotificationType.Error, message);
        });
    }
  };

  const confirmAddress = () => {
    const addressFields = getAddressFields(addressSearch);
    if (addressFields) {
      setAutoSearchState({ state: 'success' });
      setTourInput({
        ...tourInput,
        address: addressFields.address,
        postcode: addressFields.postcode,
        city: addressFields.city,
        countryCode: addressFields.countryCode,
        tourCountry: addressFields.tourCountry,
      });
      setMapsModal(false);
    }
  };

  return (
    <>
      <div className='create-tour-info'>
        <div style={{ display: mapsModal ? 'block' : 'none' }}>
          <MapsModal
            addressSearch={addressSearch}
            searchGeoWithPin={searchGeoWithPin}
            changeAddressMapsSearch={changeAddressMapsSearch}
            confirmAddress={confirmAddress}
            toggleModal={toggleModal}
            onDragTextShowInput={onDragTextShowInput}
            onDragText={onDragText}
          />
        </div>
        <div className='create-tour-input-container' id='tour-input'>
          <div className='create-tour-input'>
            <div className='create-tour-input-labels'>
              <Trans id='Country' render={({ translation }) => <label className='create-tour-labels'>{translation}</label>} />
            </div>
            <div className='profile-countries-container'>
              <DropDownCountries
                selectedProps={tourInput && tourInput.tourCountry ? [tourInput.tourCountry] : ['']}
                dataset={countries ? countries : []}
                allowNew={false}
                isDisabled={false}
                labelKey={'name'}
                handleSelectedItem={handleSelectedLabel}
                IsResultHighlightOnly={false}
                placeHolderValue=''
              />
            </div>
          </div>
          <div className='create-tour-input'>
            <div className='create-tour-input-labels'>
              <Trans id='City' render={({ translation }) => <label className='create-tour-labels'>{translation}</label>} />
            </div>
            <input
              placeholder='London'
              name='city'
              id='city'
              className={`general-field-input-text  ${!tourInput.city || tourInput.city === '' ? 'input-default' : 'input-valid-black'}`}
              onBlur={() => validateAndAutoSearch()}
              type='text'
              value={tourInput.city || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => getInput(e)}
            />
          </div>
          <div className='create-tour-input'>
            <div className='create-tour-input-labels'>
              <Trans id='Street' render={({ translation }) => <label className='create-tour-labels'>{translation}</label>} />
            </div>
            <input
              placeholder='7 Shephers Hill'
              id='address'
              name='address'
              data-testid='address'
              className={`general-field-input-text ${
                !tourInput.address || tourInput.address === '' ? 'input-default ' : 'input-valid-black'
              }`}
              onBlur={() => validateAndAutoSearch()}
              type='text'
              value={tourInput.address || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => getInput(e)}
            />
            <button id='map-tour' onClick={() => toggleModal()} data-testid='maps-btn' className='maps-button button-hover'>
              {autoSearchState.state === 'fail' ? <AddressMapRed /> : autoSearchState.state === 'success' ? <AddressMap /> : <AddressMap />}
            </button>
          </div>
        </div>
        <div className='create-tour-input-container'>
          <div className='create-tour-input'>
            <div className='create-tour-input-labels'>
              <Trans id='Postcode' render={({ translation }) => <label className='create-tour-labels'>{translation}</label>} />
            </div>
            <input
              placeholder='N6 5RD'
              id='postcode'
              name='postcode'
              data-testid='postcode'
              onBlur={() => validateAndAutoSearch()}
              className={`general-field-input-text ${
                !tourInput.postcode || tourInput.postcode === '' ? 'input-default ' : 'input-valid-black'
              }`}
              type='text'
              value={tourInput.postcode || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => getInput(e)}
            />
          </div>
          <div className='create-tour-input'>
            <div className='create-tour-input-labels'>
              <Trans
                id='Flat {optional}'
                values={{ optional: '(optional)' }}
                components={{ 0: <label className='create-tour-labels'></label> }}
              />
            </div>
            <input
              placeholder='E'
              id='flatNumber'
              name='flatNumber'
              className={`general-field-input-text ${
                !tourInput.flatNumber || tourInput.flatNumber === '' ? 'input-default' : 'input-valid-black'
              }`}
              type='text'
              value={tourInput.flatNumber || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => getInput(e)}
            />
          </div>
          <div className='create-tour-input'>
            <div className='create-tour-input-labels'>
              <Trans
                id='Reference code {optional}'
                values={{ optional: '(optional)' }}
                components={{ 0: <label className='create-tour-labels'></label> }}
              />
            </div>
            <input
              id='reference'
              name='reference'
              placeholder=''
              className={`general-field-input-text ${
                !tourInput.reference || tourInput.reference === '' ? 'input-default' : 'input-valid-black'
              }`}
              type='text'
              value={tourInput.reference || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => getInput(e)}
            />
          </div>
        </div>
        <div className='create-tour-input-container'>
          <div className='create-tour-input'>
            <div className='create-tour-input-labels' id='property-type'>
              <Trans
                id='Type of property'
                render={({ translation }) => (
                  <label className={` ${!showCustomDropDownPropertyType ? 'create-tour-labels' : 'create-tour-labels-focus'}`}>
                    {translation}
                  </label>
                )}
              />
            </div>
            <CustomSelect
              value={linguiTranslateToString(tourPropertyType.find(a => a.value === tourInput.type)?.label || '')}
              options={tourPropertyType.map(option => defineMessage({ message: option.label }))}
              changeDropdownValue={changeTypePropertyHandler}
              showCustomDropDown={showCustomDropDownPropertyType}
              setShowCustomDropDown={toggleCustomDropdownPropertyType}
              customClass={` general-field-input ${
                showCustomDropDownPropertyType
                  ? 'general-field-focus'
                  : !tourInput.type || tourInput.type === ''
                  ? 'input-default'
                  : 'input-valid-black'
              }`}
            />
          </div>
          <div className='create-tour-input'>
            <div className='create-tour-input-labels'>
              <Trans
                id='Is this property furnished?'
                render={({ translation }) => (
                  <label className={` ${!showCustomDropDownPropertyFurnished ? 'create-tour-labels' : 'create-tour-labels-focus'}`}>
                    {translation}
                  </label>
                )}
              />
            </div>
            <CustomSelect
              value={tourInput && tourInput.furnished ? linguiTranslateToString('Yes') : linguiTranslateToString('No')}
              options={[defineMessage({ message: 'Yes' }), defineMessage({ message: 'No' })]}
              changeDropdownValue={changeIsFurnishedHandler}
              showCustomDropDown={showCustomDropDownPropertyFurnished}
              setShowCustomDropDown={toggleCustomDropdownPropertyFurnished}
              customClass={`general-field-input ${showCustomDropDownPropertyFurnished ? 'general-field-focus' : ''}`}
              customDropdown='update-tour-input-dropdown'
              customDropdownOption='update-tour-input-dropdown-option'
              customDropdownId='settings-modal-input-property-furnished'
            />
          </div>
          <div className='create-tour-input'>
            <div className='create-tour-input-labels'>
              <Trans
                id='Centre of the lens height {measureUnit}'
                values={{ measureUnit: tourInput.measurementUnit === linguiTranslateToString('Centimeters') ? '(cm)' : '(inches)' }}
              />
            </div>
            <input
              id='reference'
              name='cameraHeight'
              placeholder='145'
              className={`general-field-input-text ${
                !tourInput.cameraHeight || tourInput.cameraHeight === '' ? 'input-default' : 'input-valid-black'
              }`}
              type='number'
              value={tourInput.cameraHeight || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => getInput(e)}
            />
            <div className='measurements-sections'>
              <div>
                <button
                  onClick={() => {
                    setMeasurementModalType(false);
                    setMeasurementModal(true);
                  }}
                  className='measurements-btn-popup'
                >
                  <Trans id='Why I need to enter this?' />
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    setMeasurementModalType(true);
                    setMeasurementModal(true);
                  }}
                  className='measurements-btn-popup'
                >
                  <Trans id='How do I measure this?' />
                </button>
              </div>
            </div>
          </div>
        </div>
        {!tourCreated ? (
          <div className='container-upload-btn'>
            <button
              data-testid='switch-state-button'
              id='switch-state-button'
              disabled={!validated}
              className={`create-tour-btn-size btn-new-design  ${validated ? 'btn-new-active button-hover' : 'btn-new-inactive'}`}
              onClick={() => createTourAsync()}
            >
              <Trans id='Choose settings' />
            </button>
          </div>
        ) : (
          <div className='container-upload-btn'>
            <button
              data-testid='switch-state-button'
              id='switch-state-button'
              disabled={!validated}
              className={`create-tour-btn-size btn-new-design  ${validated ? 'btn-new-active button-hover' : 'btn-new-inactive'}`}
              onClick={() => saveTourAsync()}
            >
              <Trans id='Choose settings' />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default CreateTourDetails;
