
import { IAnnotationResponse, IPanoramaAnnotation } from './interface/interfaces';
import { ClientApi } from '@vva/front';

const clientApi = new ClientApi();

// conditional window ENV for testing with jest
clientApi.setHeaders({
	'X-Api-Key': (window.ENV && window.ENV.REACT_APP_API_KEY ? window.ENV.REACT_APP_API_KEY : null) || process.env.REACT_APP_API_KEY,
});

const createPanoramaAnnotation = async (annotation: any): Promise<IPanoramaAnnotation> => {
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/annotation`, annotation
	);
	return response.data;
};

const updatePanoramaAnnotation = async (annotationId: string, annotation: any): Promise<IAnnotationResponse> => {
	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/annotation/${annotationId}`, annotation
	);
	return response.data;
};

const getPanoramaAnnotations = async (panoramaId: string): Promise<IPanoramaAnnotation[]> => {
	const response = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/annotation/${panoramaId}`,
	);
	return response.data;
};

const deletePanoramaAnnotation = async (panoramaId: string) => {
	const response = await clientApi.deleteData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramas/annotation/${panoramaId}`,
	);
	return response;
};

export { createPanoramaAnnotation, updatePanoramaAnnotation, getPanoramaAnnotations, deletePanoramaAnnotation };
