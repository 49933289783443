import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/react';
import { EnumDollhouseStatus, IPropsTourCards } from './interface';
import { ReactComponent as MenuDots } from '../../../images/dashboard-icons/ic-menu-dots-inactive.svg';
import { ReactComponent as MenuDotsActive } from '../../../images/dashboard-icons/card-icons/btn-hover.svg';
import { ReactComponent as MenuDotsDisable } from '../../../images/dashboard-icons/card-icons/menu-dot-disable.svg';
import defaultImage from '../../../images/empty.svg';
import passwordLock from '../../../images/dashboard-icons/password-lock.svg';
import leadsGeneration from '../../../images/dashboard-icons/lead-generation.svg';
import displayLocation from '../../../images/dashboard-icons/display-location.svg';
import linkIconCard from '../../../images/dashboard-icons/card-icons/link-icon-card.svg';
import locationIcon from '../../../images/dashboard-icons/card-icons/icon-location.svg';
import profileIcon from '../../../images/dashboard-icons/profile-icon.svg';
import iconDollHouse from '../../../images/dashboard-icons/icon-dollhouse-pending.svg';
import iconDollHouseCompleted from '../../../images/dashboard-icons/icon-dollhouse-complete.svg';
import './tour-views-dropdown-menu.scss';
import './tour-rows-new.scss';
import './tour-rows.scss';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { ITours } from '../../../api-helper/interface/interfaces';
import { defineMessage } from '@lingui/macro';
import { linguiTranslateToString } from '../lingui-utils/utils-lingui';
import iconFloorplan from '../../../images/dashboard-icons/icon-floorplan-card.svg';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../notifications/notification';
import { IError } from '@vva/front/api-client/interfaces';
import { downloadFloorplanZip } from '../../../api-helper/api-floorplan';

export enum EnumFloorplanMode {
  PLAIN = 'plain',
  LAYERED = 'layered',
  ALL = 'all',
}

const TourRowsDollhouse: React.FC<IPropsTourCards> = ({
  page,
  keyword,
  tours,
  toursRef,
  showModal,
  orderOptionName,
  orderOption,
  connectToUserGoToStudio,
  master,
  restoreTours,

  type,
  changeDollhouseStatus,
}) => {
  const [onHoverDisplay, setOnHoverDisplay] = useState<string>('');
  const [activeMenu, setActiveMenu] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState<string>('');

  const getStandaloneViewerUrl = (tourReadCode: string, short: boolean) => {
    const standAloneViewerUrl = window.ENV.REACT_APP_STANDALONE_VIEWER_URL || process.env.REACT_APP_STANDALONE_VIEWER_URL;
    const url = standAloneViewerUrl + tourReadCode;

    if (short) {
      const shortUrl = standAloneViewerUrl.replace(/^https:\/\//i, '');
      return shortUrl && tourReadCode ? shortUrl + tourReadCode : '';
    } else {
      return url ? url : '';
    }
  };

  const get3DViewerUrl = (tourId: string, short: boolean) => {
    const standAloneViewerUrl = window.ENV.REACT_APP_3D_VIEWER_URL || process.env.REACT_APP_3D_VIEWER_URL;
    const url = standAloneViewerUrl + '?tour=' + tourId;

    if (short) {
      const shortUrl = standAloneViewerUrl.replace(/^https:\/\//i, '');
      return shortUrl && tourId ? shortUrl + tourId : '';
    } else {
      return url ? url : '';
    }
  };

  const goTo3DEditor = (tourReadCode: string) => {
    const standAloneViewerUrl = window.ENV.REACT_APP_3D_VIEWER_URL || process.env.REACT_APP_3D_VIEWER_URL;
    const url = standAloneViewerUrl + '?tour=' + tourReadCode + '&mode=edit';
    window.open(url, '_blank');
  };

  const addDefaultSrc = (ev: React.ChangeEvent<HTMLImageElement>) => {
    ev.target.src = defaultImage;
  };

  const getDollhouseStatus = (tour: ITours) => {
    let circleColour;
    let status;
    if (tour.status < EnumDollhouseStatus.Pending) {
      circleColour = '#FF0000';
      status = defineMessage({ message: 'Deleted' });
    } else if (tour.status === EnumDollhouseStatus.Pending) {
      circleColour = '#D50057';
      status = defineMessage({ message: 'To do' });
    } else if (tour.status === EnumDollhouseStatus.Active) {
      circleColour = '#FFDD00';
      status = defineMessage({ message: 'Pending' });
    } else if (tour.status === EnumDollhouseStatus.PendingValidation) {
      circleColour = '#00269A';
      status = defineMessage({ message: 'Awaiting Validation' });
    } else if (tour.status === EnumDollhouseStatus.Validated) {
      circleColour = '#13B597';
      status = defineMessage({ message: 'Completed' });
    } else {
      circleColour = '#FF7E00';
      status = defineMessage({ message: 'Unknown' });
    }

    return (
      <div className='info-details-containers'>
        <div className='dots' style={{ background: circleColour }}></div>
        <Trans id={String(status.id)} />
      </div>
    );
  };

  const toggleNotification = (type: EnumNotificationType, message?: string) => {
    setTypeNotification(type);
    setNotificationMessage(message || linguiTranslateToString('There was an error please try again'));
    setShowNotification(true);
  };

  const downloadFloorplan = async (tourId: string, mode: EnumFloorplanMode, name: string) => {
    try {
      const floorplan = await downloadFloorplanZip(tourId, mode);
      // Get file name
      const filename = 'floorplan-' + removeAnySpecialCharacters(name) + '.zip';
      const url = window.URL.createObjectURL(new Blob([floorplan.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      const err = error as IError;
      console.log(err);
      toggleNotification(EnumNotificationType.Error, err.statusText);
    }
  };

  const removeAnySpecialCharacters = (fileName: string) => {
    // eslint-disable-next-line
    return fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<> \{\}\[\]\\\/]/gi, '');
  };

  return (
    <React.Fragment>
      {showNotification && (
        <Notification
          showNotification={showNotification}
          size={EnumNotificationSize.Large}
          type={typeNotification || EnumNotificationType.Info}
          message={notificationMessage}
          setShowNotification={setShowNotification}
          fullScreen={true}
        />
      )}
      {tours?.map((tour: ITours, index: number) => (
        <div id='tours-row' ref={element => (toursRef.current[index] = element)} key={index} className='tour-row'>
          <div
            className='row-image-doll'
            onClick={() => {
              //Report Custom Category and Event
              ReportCustomEvent(EnumCategory.TourInformation, EnumEvent.OpenTourProfile);
            }}
          >
            {!restoreTours && (
              <div className='lock-leads-icons'>
                {tour.protected && (
                  <button style={{ cursor: 'default' }} className=' icon-on-card'>
                    <img title={linguiTranslateToString('Password protected')} src={passwordLock} alt='Padlock password protect' />
                  </button>
                )}
                {tour.shareLocation === '1' && (
                  <button style={{ cursor: 'default' }} className='icon-on-card'>
                    <img title={linguiTranslateToString('Location displayed')} src={displayLocation} alt='Location displayed' />
                  </button>
                )}
                {tour.enableLeadGenerationForm === '1' && (
                  <button style={{ cursor: 'default' }} className='icon-on-card'>
                    <img title={linguiTranslateToString('Leads generation')} src={leadsGeneration} alt='Leads generation' />
                  </button>
                )}
              </div>
            )}
            <div className='edit-link'>
              <Link
                to={{
                  pathname: '/edit-tour/panoramas',
                  search: `?tourId=${tour.tourId}`,
                  state: {
                    ...tour,
                    id: tour.tourId,
                    paginationPage: page,
                    searchWord: keyword,
                    tourIndex: index,
                    displayDashboard: true,
                    orderOptionName: orderOptionName,
                    orderOption: orderOption,
                    dashboardView: 'rows',
                  },
                }}
              >
                <div className='img-icon-card'></div>
                <Trans id='Tour details' />
              </Link>
            </div>
            <img className='img-row-view' onError={addDefaultSrc} src={tour.mainPhotoUrl || defaultImage} alt='Tour preview' />
          </div>
          <div className='info-row-view-doll'>
            <div className='container-title-info-dollhouse'>
              <div id='tour-name' className='tour-row-address'>
                <Link
                  style={{ textDecoration: 'none' }}
                  to={{ pathname: '/edit-tour/panoramas', search: `?tourId=${tour.tourId}`, state: { ...tour, id: tour.tourId } }}
                >
                  {tour.name}
                </Link>
              </div>
              <div className='status-dollhouse'>{getDollhouseStatus(tour)}</div>
            </div>
            <div className='container-bottom-info-dollhouse'>
              <div className='container-left-info'>
                <div className='info-details-location'>
                  <img className='details-icons' src={locationIcon} alt='Tour Location icon' />
                  <p title={tour.name} className='text-info-bottom-doll'>
                    {tour.name}
                  </p>
                </div>
                <div
                  className='info-url'
                  onClick={() => {
                    //Report Custom Category and Event
                    ReportCustomEvent(EnumCategory.TourInformation, EnumEvent.OpenTourWithUrl);
                  }}
                >
                  <img className='details-icons' src={linkIconCard} alt='Tour url icon' />
                  {type === 'archived' || type === 'deleted' ? (
                    <p className='text-info-bottom-doll invalid-link'>{getStandaloneViewerUrl(tour.readCode, false)}</p>
                  ) : (
                    <a
                      title={getStandaloneViewerUrl(tour.readCode, false)}
                      className='text-info-bottom-doll'
                      href={getStandaloneViewerUrl(tour.readCode, false)}
                      rel='noreferrer'
                      target='_blank'
                    >
                      {getStandaloneViewerUrl(tour.readCode, false)}
                    </a>
                  )}
                </div>
              </div>
              <div className='container-right-info-dollhouse'>
                <div className='info-email-detail'>
                  <img className='details-icons' src={profileIcon} alt='Tour Location icon' />
                  <p className='text-info-bottom-doll'> {tour.userEmail} </p>
                </div>

                {tour.status < EnumDollhouseStatus.Validated ? (
                  <>
                    <div className='info-url-doll'>
                      <img className='details-icons' src={iconDollHouse} alt='Tour url icon' />
                      <a
                        title={get3DViewerUrl(tour.readCode ? tour.readCode : '', false)}
                        className='text-info-bottom-invalid-doll'
                        href={get3DViewerUrl(tour.readCode ? tour.readCode : '', false)}
                        rel='noreferrer'
                        target='_blank'
                      >
                        {get3DViewerUrl(tour.readCode ? tour.readCode : '', false)}
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='info-url-doll'>
                      <img className='details-icons' src={iconDollHouseCompleted} alt='Tour url icon' />
                      <a
                        title={get3DViewerUrl(tour.readCode ? tour.readCode : '', false)}
                        className='text-info-bottom-doll'
                        href={get3DViewerUrl(tour.readCode ? tour.readCode : '', false)}
                        rel='noreferrer'
                        target='_blank'
                      >
                        {get3DViewerUrl(tour.readCode ? tour.readCode : '', false)}
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='btns-row-view'>
            <div className='three-dot-options-dollhouse'>
              <div
                className={`circle-container-dot ${
                  onHoverDisplay === tour.id ? 'cirlce-container-focus' : !master ? 'circle-container-disable' : 'circle-container-dot'
                }`}
              >
                <div className='dots-dropdown-parent-row'>
                  <div className='dots-dropdown'>
                    <button
                      className='remove-default'
                      onClick={() => {
                        setActiveMenu(true);
                        setOnHoverDisplay(tour.id);
                      }}
                      onMouseLeave={() => setActiveMenu(false)}
                    >
                      <div id='dots-menu' className='dots-btn'>
                        {onHoverDisplay === tour.id ? <MenuDotsActive /> : !master ? <MenuDotsDisable /> : <MenuDots />}
                      </div>
                    </button>
                    <div
                      onClick={() => {
                        setActiveMenu(!activeMenu);
                        setOnHoverDisplay(tour.id);
                      }}
                      onMouseLeave={() => setOnHoverDisplay('')}
                      className={`dots-dropdown-content ${onHoverDisplay === tour.id ? 'dots-dropdown-content-active' : ''}`}
                    >
                      {master && (
                        <>
                          <button
                            className='remove-default'
                            onMouseOver={() => setOnHoverDisplay(tour.id)}
                            onMouseLeave={() => setOnHoverDisplay('')}
                          >
                            <div id='dots-menu' className='dots-btn'>
                              {onHoverDisplay === tour.id ? <MenuDotsActive /> : <MenuDots />}
                            </div>
                          </button>
                          <div
                            onMouseOver={() => setOnHoverDisplay(tour.id)}
                            onMouseLeave={() => setOnHoverDisplay('')}
                            className={`dots-dropdown-content ${onHoverDisplay === tour.id ? 'dots-dropdown-content-active' : ''}`}
                          >
                            <div className='dots-dropdown-menu'>
                              {tour.status < EnumDollhouseStatus.Active ? (
                                <>
                                  <span>
                                    <button
                                      onClick={() => {
                                        const modalContent = {
                                          id: 'change-dollhouse-status',
                                          title:
                                            tour.status <= EnumDollhouseStatus.Active
                                              ? linguiTranslateToString('Reassign dollhouse')
                                              : linguiTranslateToString('Assign dollhouse'),
                                          status: tour.status,
                                          tourId: tour.tourId,
                                        };
                                        showModal(modalContent);
                                      }}
                                      className='remove-default dots-options assign'
                                    >
                                      <div className='icon-reassign-dollhouse-button' />
                                      {tour.status < EnumDollhouseStatus.Active ? (
                                        <Trans
                                          id='Assign editor'
                                          render={({ translation }) => <div className='dots-options-text-dollhouse'>{translation}</div>}
                                        />
                                      ) : (
                                        <Trans
                                          id='Reassign editor'
                                          render={({ translation }) => <div className='dots-options-text-dollhouse'>{translation}</div>}
                                        />
                                      )}
                                    </button>
                                  </span>
                                </>
                              ) : tour.status === EnumDollhouseStatus.Active ? (
                                <>
                                  <span>
                                    <button
                                      onClick={() => {
                                        const modalContent = {
                                          id: 'change-dollhouse-status',
                                          title:
                                            tour.status <= EnumDollhouseStatus.Active
                                              ? linguiTranslateToString('Reassign dollhouse')
                                              : linguiTranslateToString('Assign dollhouse'),
                                          status: tour.status,
                                          tourId: tour.tourId,
                                        };
                                        showModal(modalContent);
                                      }}
                                      className='remove-default dots-options assign'
                                    >
                                      <div className='icon-reassign-dollhouse-button ' />
                                      {tour.status < EnumDollhouseStatus.Active ? (
                                        <Trans
                                          id='Assign editor'
                                          render={({ translation }) => <div className='dots-options-text-dollhouse'>{translation}</div>}
                                        />
                                      ) : (
                                        <Trans
                                          id='Reassign editor'
                                          render={({ translation }) => <div className='dots-options-text-dollhouse'>{translation}</div>}
                                        />
                                      )}
                                    </button>
                                  </span>
                                  <span>
                                    <button
                                      onClick={() => {
                                        if (changeDollhouseStatus && tour.tourId) {
                                          changeDollhouseStatus(tour.tourId, EnumDollhouseStatus.Validated);
                                        }
                                      }}
                                      className='remove-default dots-options'
                                    >
                                      <div className='icon-validate-dollhouse-button' />
                                      <Trans
                                        id='Validate'
                                        render={({ translation }) => <div className='dots-options-text-dollhouse'>{translation}</div>}
                                      />
                                    </button>
                                  </span>
                                </>
                              ) : tour.status === EnumDollhouseStatus.PendingValidation ? (
                                <>
                                  <span>
                                    <button
                                      onClick={() => {
                                        if (changeDollhouseStatus && tour.tourId) {
                                          changeDollhouseStatus(tour.tourId, EnumDollhouseStatus.Validated);
                                        }
                                      }}
                                      className='remove-default dots-options validate '
                                    >
                                      <div className='icon-validation' />
                                      <Trans
                                        id='Accept validation'
                                        render={({ translation }) => (
                                          <div className='dots-options-text-dollhouse-dollhouse'>{translation}</div>
                                        )}
                                      />
                                    </button>
                                  </span>
                                  <span>
                                    <button
                                      onClick={() => {
                                        if (changeDollhouseStatus && tour.tourId) {
                                          changeDollhouseStatus(tour.tourId, EnumDollhouseStatus.ValidationRejected);
                                        }
                                      }}
                                      className='remove-default dots-options decline'
                                    >
                                      <div className='icon-decline' />
                                      <Trans
                                        id='Decline validation'
                                        render={({ translation }) => (
                                          <div className='dots-options-text-dollhouse-dollhouse'>{translation}</div>
                                        )}
                                      />
                                    </button>
                                  </span>
                                </>
                              ) : tour.status === EnumDollhouseStatus.Validated ? (
                                <span>
                                  <button
                                    onClick={() => {
                                      const modalContent = {
                                        id: 'change-dollhouse-status',
                                        title: linguiTranslateToString('Back to edit and reassign dollhouse'),
                                        status: tour.status,
                                        tourId: tour.tourId,
                                        newStatus: EnumDollhouseStatus.Active,
                                      };
                                      showModal(modalContent);
                                    }}
                                    className='remove-default dots-options back-edit'
                                  >
                                    <div className='go-to-3d-icon'></div>
                                    <Trans
                                      id='Back to edit mode'
                                      render={({ translation }) => <div className='dots-options-text-dollhouse'>{translation}</div>}
                                    />
                                  </button>
                                </span>
                              ) : (
                                <></>
                              )}
                              <span>
                                <button
                                  onClick={() => downloadFloorplan(tour.tourId || '', EnumFloorplanMode.PLAIN, tour.name)}
                                  className='remove-default dots-options back-edit'
                                >
                                  <img
                                    title={linguiTranslateToString('Floor plan')}
                                    className='icon-on-card'
                                    src={iconFloorplan}
                                    alt='Floor plan'
                                  />
                                  <Trans
                                    id='Download plain floorplan'
                                    render={({ translation }) => <div className='dots-options-text-dollhouse'>{translation}</div>}
                                  />
                                </button>
                              </span>
                              <span>
                                <button
                                  onClick={() => downloadFloorplan(tour.tourId || '', EnumFloorplanMode.PLAIN, tour.name)}
                                  className='remove-default dots-options back-edit'
                                >
                                  <img
                                    title={linguiTranslateToString('Floor plan')}
                                    className='icon-on-card'
                                    src={iconFloorplan}
                                    alt='Floor plan'
                                  />
                                  <Trans
                                    id='Download layered floorplan'
                                    render={({ translation }) => <div className='dots-options-text-dollhouse'>{translation}</div>}
                                  />
                                </button>
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {master ? (
              <div className='account-3d-container'>
                <button id='connectGoToStudio' className='connect-goto-studio' onClick={() => goTo3DEditor(tour.readCode)}>
                  <div className='go-to-3d-icon'></div>
                  <Trans id='Go to 3d Editor' />
                </button>
              </div>
            ) : (
              <div className='account-3d-container'>
                <button
                  id='connectGoToStudio'
                  className='connect-goto-studio'
                  onClick={() => {
                    if (connectToUserGoToStudio) {
                      ReportCustomEvent(EnumCategory.ConnectToTour, EnumEvent.ConnectToTourButton);
                      connectToUserGoToStudio(tour.userId, tour.tourId ? tour.tourId : '');
                    }
                  }}
                >
                  <div className='go-to-studio-icon'></div>
                  <Trans id='Go to studio' />
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default TourRowsDollhouse;
