import './mobile-view.scss';
import { ReactComponent as Lock } from '../../../images/icons/lock-people.svg';
import { ReactComponent as Apple } from '../../../images/icons/apple.svg';
import { ReactComponent as PlayStore } from '../../../images/icons/play-store.svg';

export default function MobileView() {
  const googleDeepLink =
    'https://play.google.com/store/apps/details?id=com.vieweet.app&gl=GB&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
  const appleDeepLink = 'https://apps.apple.com/gb/app/vieweet-360/id837173911';

  return (
    <div className='App-mobile'>
      <div className='title-mobile'>
        <p>The Vieweet° web platform is not compatible with mobile</p>
      </div>
      <div className='lock-section'>
        <Lock />
      </div>
      <div className='container-blue'>
        <div className='blue-section'>
          <p className='helper-mobile'>Access Vieweet° on mobile by downloading the app from the buttons below</p>
        </div>
      </div>
      <div className='button-section'>
        <div className='button-apple'>
          <a className='link' href={appleDeepLink}>
            <div className='content-button'>
              <div>
                <Apple />
              </div>
              <div className='text-button'>
                <p className='sub'>Download on the </p>
                <p className='main'>App Store</p>
              </div>
            </div>
          </a>
        </div>
        <div className='button-google'>
          <a className='link' href={googleDeepLink}>
            <div className='content-button'>
              <div>
                <PlayStore />
              </div>
              <div className='text-button'>
                <p className='sub'>GET IT ON </p>
                <p className='main'>Google Play</p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className='end-text'>
        <p>Alternatevely, log into Vieweet° using a laptop or desktop computer</p>
      </div>
    </div>
  );
}
