import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/react';
import ImagePreview from '../../utils/image-preview/image-preview';
import { EnumCompletionFiles, IFilesArray, INewTour } from './create-tour';
import { EnumNotificationType } from '../../utils/notifications/notification';
import {
  changePanoramaName,
  getCompletedElement,
  getFailedElement,
  getPendingElement,
} from '../../utils/image-loading-utils/image-loading-utils';
import { createPanorama, deletePanorama, updatePanorama, uploadPanoramaWithFile } from '../../../api-helper/api-panoramas';
import { removeExtension, validateImageSize, validateImageType } from '../../utils/images-editor/image-utils';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';

interface IProps {
  newTour?: INewTour;
  filesArray: IFilesArray[];
  setFilesArray: (state: IFilesArray[]) => void;
  toggleNotification: (type: EnumNotificationType, message?: string, duration?: number) => void;
}

const UploadPanoramas: React.FC<IProps> = ({ newTour, filesArray, setFilesArray, toggleNotification }) => {
  const { handleLogoutState } = useAuth();
  const [tourPanoramaUploaded, setTourPanoramaUploaded] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  useEffect(() => {
    const findUnCompleted = filesArray.find((panorama: IFilesArray) => panorama.completion < EnumCompletionFiles.Completed);
    if (findUnCompleted || filesArray?.length <= 0) {
      setTourPanoramaUploaded(false);
    } else {
      setTourPanoramaUploaded(true);
    }
  }, [filesArray]);

  const removeLoadedFileFromArray = async (id: string) => {
    try {
      await deletePanorama(id);
      const arr = filesArray.filter((a: IFilesArray, i: number) => a.id !== id);
      setFilesArray(arr);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const changeUploadedPanoramaName = async (index: number, newName: string, updateApi?: boolean) => {
    let tempFilesArray = [...filesArray];
    const changeName = changePanoramaName(tempFilesArray, index, newName);
    setFilesArray(changeName);
    if (updateApi) {
      try {
        const updateBody = {
          name: filesArray[index].name,
        };
        await updatePanorama(filesArray[index].id, updateBody);
      } catch (error) {
        const err = error as Error;
        handleError(err, handleLogoutState);
      }
    }
  };

  const onFileDrop = async (e: any) => {
    setUploading(true);
    const files = e.target.files;
    const arr: IFilesArray[] = [];

    try {
      for (let i = 0; i <= files.length; i++) {
        const file = files[i];
        if (!validateImageType(file.name)) {
          toggleNotification(EnumNotificationType.Error, 'Ooops! Make sure your images are in a compatible format: jpg', 5000);
          continue;
        }
        const panorama = await readUploadedFileAsText(file, i);
        arr.push(panorama);
      }
    } catch (error: any) {
      if (error?.message && error.message === 'Image size not compatible') {
        toggleNotification(EnumNotificationType.Error, 'Ooops! Make sure your images have 2:1 aspect ratio and upload them again.', 5000);
      }
    }

    const joinArrays = filesArray.concat(arr);

    let organiseArray = joinArrays.map((arr, i) => {
      return { ...arr, index: i };
    });

    setFilesArray(organiseArray);

    try {
      for (let i = 0; i <= joinArrays.length; i++) {
        if (organiseArray[i].completion === EnumCompletionFiles.Pending) {
          const uploadFile = await uploadPanorama(organiseArray[i], organiseArray[i].index);
          organiseArray[i].completion = uploadFile.completion;
          organiseArray[i].id = uploadFile.id;
          const tempArray = [...organiseArray];
          setFilesArray(tempArray);
        }
      }
    } catch (error) {}
    setUploading(false);
  };

  const readUploadedFileAsText = (file: File, i: number): Promise<IFilesArray> => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      temporaryFileReader.onload = () => {
        const imageDataUrl = temporaryFileReader.result as string;
        const img = new Image();
        img.onload = () => {
          if (!validateImageSize(img.height, img.width)) {
            reject(new DOMException('Image size not compatible'));
          }
          const fileData = {
            data: imageDataUrl,
            file: file,
            index: i,
            completion: EnumCompletionFiles.Pending,
            name: removeExtension(file.name),
            id: '',
          };
          resolve(fileData);
        };
        img.onerror = () => {
          reject(new DOMException('Problem loading image.'));
        };

        img.src = imageDataUrl;
      };

      temporaryFileReader.readAsDataURL(file);
    });
  };

  const uploadPanorama = async (file: IFilesArray, index: number) => {
    //  Only do this if the upload has not been uploaded yet
    let id;
    try {
      // Create panorama
      const createNewPanorama = await createPanorama(newTour?.id || '', file.name, index);
      id = createNewPanorama.id;
      // Upload panorama to S3
      await uploadPanoramaWithFile(createNewPanorama.id, file.file);
      file.id = id;
      file.completion = EnumCompletionFiles.Completed;
      return file;
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      file.completion = EnumCompletionFiles.Error;
      file.id = id;
      const message = 'Something went wrong. Click on the image to try uploading it again';
      toggleNotification(EnumNotificationType.Error, message);
      return file;
    }
  };

  const retryUploadSinglePanorama = async (index: number) => {
    let tempFilesArray = [...filesArray];
    const panoPending = getPendingElement(tempFilesArray, index);
    setFilesArray(panoPending);
    try {
      const singleUpload = await uploadPanoramaWithFile(tempFilesArray[index].id, tempFilesArray[index].file);
      const panoCompleted = getCompletedElement(tempFilesArray, index, singleUpload.id);
      setFilesArray(panoCompleted);
    } catch (error) {
      const panoCompleted = getFailedElement(tempFilesArray, index);
      setFilesArray(panoCompleted);
      const message = 'Something went wrong. Click on the Image to try uploading it again';
      toggleNotification(EnumNotificationType.Error, message);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
    setFilesArray(tempFilesArray);
  };

  return (
    <>
      {filesArray.length >= 1 ? (
        <div className='help-text-pictures'>
          <Trans id='Click on the pen icon to rename the images' />
        </div>
      ) : null}
      <div className='display-flex flex-center'>
        <div className='upload-images-container'>
          <div id='tour-panorama' className={`${filesArray.length < 1 ? 'file-preview-inactive' : 'files-preview-container'}`}>
            <ImagePreview
              filesArray={filesArray}
              removeLoadedFileFromArray={removeLoadedFileFromArray}
              changeUploadedPanoramaName={changeUploadedPanoramaName}
              retryUploadSinglePanorama={retryUploadSinglePanorama}
            />
          </div>
          <div id='tour-drop' className={`${filesArray.length < 1 ? 'file-drop-zone-big' : 'file-drop-zone'}`}>
            {uploading ? (
              <div className='helper-text'>Images uploading...</div>
            ) : (
              <div className='helper-text'>
                <Trans id='Drop your 360° images here' />
                <div>
                  <Trans id='or' />
                </div>
                <Trans
                  id='select from a folder'
                  render={({ translation }) => <span style={{ borderBottom: '1px solid #0e0333' }}>{translation}</span>}
                />
              </div>
            )}
            <input
              id='upload-multiple'
              className='center-input'
              type='file'
              name='file-drop-zone'
              disabled={uploading}
              multiple
              onDragOver={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFileDrop(e)}
            />
          </div>
        </div>
      </div>
      <div className='upload-panoramas-go-to-studio'>
        {tourPanoramaUploaded ? (
          <Link className='default-link' to={{ pathname: `/editing-viewer/`, search: `?tourId=${newTour?.id}`, state: { tour: newTour } }}>
            <button className='btn-upload-images btn-upload-images-active button-hover '>
              <p>
                <Trans id='Link 360 images' />
              </p>
            </button>
          </Link>
        ) : (
          <button disabled={true} className='btn-upload-images'>
            <Trans id='Link 360 images' />
          </button>
        )}
      </div>
    </>
  );
};

export default UploadPanoramas;
