import { defineMessage } from '@lingui/macro';
import { Trans } from '@lingui/react';
import React, { useState, useEffect } from 'react';
import { getLeadsGeneration } from '../../../../api-helper/api-analytics';
import { ILeadsGenerated } from '../../../../api-helper/interface/interfaces';
import CustomSelect from '../../../utils/custom-select/custom-select';
import DataTable from '../../../utils/data-table/data-table';
import { linguiTranslateToString } from '../../../utils/lingui-utils/utils-lingui';
import Pagination from '../../../utils/pagination/pagination';
import Empty from '../../../../images/empty-states-illustrations/leads-empty.svg';
import { handleError } from '../../../../api-helper/api-error-handling';
import { useAuth } from '../../../../auth-routes/auth-context';

interface IPageSelection {
  selected: number;
}

interface IProps {
  tourId: string;
  tabIndex?: number;
}

const LeadsGeneration: React.FC<IProps> = ({ tourId, tabIndex }) => {
  const { handleLogoutState } = useAuth();
  const [dateRangeSelected, setDateRangeSelected] = useState<string>('This month');
  const [showCustomDropDownDateRange, setShowCustomDropDownDateRange] = useState<boolean>(false);
  const [dateRangeServiceSelected, setDateRangeServiceSelected] = useState<string>('thisMonth');
  const [usersDataLeads, setUsersDataLeads] = useState<ILeadsGenerated[]>();
  const [leadsPage, setLeadsPage] = useState<number>(0);
  const [leadsPageCount, setLeadsPageCount] = useState<number>(0);
  const [isDataLeads, setIsDataLeads] = useState<boolean>(false);

  useEffect(() => {
    if (tourId) {
      if (tabIndex === 4) {
        getLeadsInfo();
      }
    }
  }, [tourId, tabIndex, dateRangeServiceSelected]);

  const getLeadsInfo = async (previousPagination?: number) => {
    try {
      const pagePagination = previousPagination ? previousPagination : leadsPage;
      const data = await getLeadsGeneration(pagePagination, dateRangeServiceSelected, tourId);
      setLeadsPageCount(Math.ceil(data.headers['x-pagination-count']));
      setUsersDataLeads(data.data);
      if (data.data.length > 0) {
        setIsDataLeads(true);
      } else {
        setIsDataLeads(false);
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const dateRangeOptions = [
    { name: 'Last 24 hours', value: 'lastDay' },
    { name: 'This week', value: 'thisWeek' },
    { name: 'Last Week', value: 'lastWeek' },
    { name: 'This month', value: 'thisMonth' },
    { name: 'Last month', value: 'lastMonth' },
    { name: 'This year', value: 'thisYear' },
    { name: 'Last year', value: 'lastYear' },
  ];

  const labels = [
    { tableTitle: 'Property', value: 'name' },
    { tableTitle: 'Name', value: 'fullName' },
    { tableTitle: 'Email', value: 'email' },
    { tableTitle: 'Phone number', value: 'phoneNumber' },
    { tableTitle: 'Created', value: 'createdAt' },
    { tableTitle: 'Type of lead', value: 'formattedLeadType' },
  ];

  const changeDateRangeHandler = (myValue: string) => {
    dateRangeOptions.map(option => {
      if (option.name === myValue) {
        setDateRangeSelected(option.name);
        setDateRangeServiceSelected(option.value);
      }
      return null;
    });
    toggleCustomDropdown(false);
  };

  const toggleCustomDropdown = (option?: boolean) => {
    if (option) {
      setShowCustomDropDownDateRange(option);
    } else {
      setShowCustomDropDownDateRange(!showCustomDropDownDateRange);
    }
  };

  const handleLeadsPaginationSearch = (pageSelected: IPageSelection) => {
    setLeadsPage(pageSelected.selected);
  };

  return (
    <div className='edit-tour-analytics-doughnut'>
      <div className='info-tag-title'>
        <Trans id='Select a date range' />
      </div>
      <div className='controls'>
        <div className='sort-by-date-container'>
          <CustomSelect
            customDropdownId={'analyticsDropdown'}
            value={linguiTranslateToString(dateRangeSelected)}
            options={dateRangeOptions && dateRangeOptions.map(option => defineMessage({ message: option.name }))}
            changeDropdownValue={changeDateRangeHandler}
            showCustomDropDown={showCustomDropDownDateRange}
            setShowCustomDropDown={toggleCustomDropdown}
            customClass={`general-field-input ${showCustomDropDownDateRange ? 'general-field-focus' : ''}`}
          />
        </div>
      </div>
      {isDataLeads ? (
        <>
          <div className='leads-analytics-container'>
            <DataTable
              accounts={usersDataLeads}
              labels={labels}
              isLoaded={true}
              labelContainerStyles={'analytics-dt-label-container'}
              labelStyles={'analytics-dt-label'}
              dataContainerStyles={'analytics-dt-data-container'}
              dataStyles={'analytics-dt-data'}
            />
          </div>
          <div className='pagination-container'>
            {usersDataLeads && usersDataLeads.length !== 0 && (
              <Pagination page={leadsPage} pageCount={leadsPageCount} handlePaginationSearch={handleLeadsPaginationSearch} />
            )}
          </div>
        </>
      ) : (
        <div className='container-empty-lead'>
          <div className='container-text-empty-lead'>
            <div className='title-text-no-leads'>
              <Trans id='No leads have been captured for the selected time frame' />
            </div>
            <div className='sub-text-no-leads'>
              <Trans id='Refine your search and make sure you have enabled the lead generation form in the your settings' />
            </div>
            <button className='button-no-leads'>
              <Trans id='Download all leads' />
            </button>
          </div>
          <img src={Empty} className='empty-lead-img' alt='empty' />
        </div>
      )}
    </div>
  );
};

export default LeadsGeneration;
