import { EnumCompletionFiles, IFilesArray } from "../../main/tour/create-tour";


const getPendingElement = (arr: IFilesArray[], position: number) => {
	const currentElement = arr[position];
	currentElement.completion = EnumCompletionFiles.Pending;
	// Map new array with updated element 
	return arr.map((element, i) => i === position ? currentElement : element);
}

const changePanoramaName = (arr: any[], position: number, newName: string) => {
	const currentElement = arr[position]
	currentElement.name = newName;
	// Map new array with updated element 
	return arr.map((element, i) => i === position ? currentElement : element);
}


const getCompletedElement = (arr: IFilesArray[], position: number, newId?: string) => {
	const currentElement = arr[position];
	currentElement.completion = EnumCompletionFiles.Completed;
	if (newId) {
		currentElement.id = newId;
	}
	return arr.map((element: IFilesArray, i: number) => i === position ? currentElement : element);
}

const getFailedElement = (arr: IFilesArray[], position: number) => {
	const currentElement = arr[position];
	currentElement.completion = EnumCompletionFiles.Error;
	return arr.map((element, i) => i === position ? currentElement : element);
}


export { getPendingElement, changePanoramaName, getCompletedElement, getFailedElement };