import React from 'react';
import { IHotspotCreation } from '../../../api-helper/interface/interfaces';
import { ReactComponent as Close } from '../../../images/viewer-icons/modal-close.svg';
import ListsPointsBlur from '../../annotation/ListPointsBlur';
import { IObjectString } from '../interaces';
import proTag from '../../../images/viewer-icons/pro-tag.svg';
import { Trans } from '@lingui/react';
import { ISidebar } from '../viewer-canvas';

interface IProps {
  sidebar: ISidebar;
  loadingSuggestion: IObjectString;
  blurActive: boolean;
  toggleBlur: (end?: boolean) => void;
  hotspotsBlur: any;
  blurResponse: IHotspotCreation[];
  handleRemoveBlur: (list: IHotspotCreation, id: string) => void;
  changeLeftSidebar: (option: string) => void;
  reprocessPanoramaForBlur: () => void;
  getSuggestionsBlur: () => void;
  objectBlur: any;
}

const AddBlur: React.FC<IProps> = ({
  sidebar,
  loadingSuggestion,
  toggleBlur,
  blurActive,
  hotspotsBlur,
  blurResponse,
  handleRemoveBlur,
  changeLeftSidebar,
  reprocessPanoramaForBlur,
  getSuggestionsBlur,
  objectBlur,
}: IProps) => {
  return (
    <React.Fragment>
      <div className='add-annotation-title'>
        <div className='annotation-title'>
          <Trans id='Blur layer' /> <img style={{ marginLeft: '10px' }} src={proTag} alt='pro' />
        </div>
        <button className='btn-remove-default close-annotation' onClick={() => changeLeftSidebar('')}>
          <Close />
        </button>
      </div>
      <div className='annotation-options-container'>
        <ListsPointsBlur
          sidebar={sidebar}
          blurActive={blurActive}
          hotspotsBlur={hotspotsBlur}
          blurResponse={blurResponse}
          handleRemoveBlur={handleRemoveBlur}
          toggleBlur={toggleBlur}
          reprocessPanoramaForBlur={reprocessPanoramaForBlur}
          getSuggestionsBlur={getSuggestionsBlur}
          loadingSuggestion={loadingSuggestion}
          objectBlur={objectBlur}
        />
      </div>
    </React.Fragment>
  );
};

export default AddBlur;
