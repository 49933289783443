import React, { createContext, useState, useMemo, useContext, useEffect } from 'react';
import useConstant from 'use-constant';

export const TabsState = createContext(null);
const Elements = createContext(null);

interface IChild {
  children: React.ReactChild | React.ReactChild[];
  changeOfTab?: (index: number) => void;
  tabIndex?: number;
}

interface ITabsProps {
  children: React.ReactChild | React.ReactChild[];
  inLineStyle?: React.CSSProperties;
  id?: string;
}

export const Tabs = ({ children, changeOfTab, tabIndex }: IChild) => {
  const innerState: any = useState();
  const elements: any = { tabs: 0, panels: 0 };
  const state: any = innerState;

  useEffect(() => {
    if (tabIndex !== undefined) {
      state[1](tabIndex);
    }
  }, [tabIndex]);

  useEffect(() => {
    if (changeOfTab) {
      changeOfTab(state[0]);
    }
  }, [state[0]]);

  return (
    <Elements.Provider value={elements}>
      <TabsState.Provider value={state}>{children}</TabsState.Provider>
    </Elements.Provider>
  );
};

export const useTabState = () => {
  const [activeIndex, setActive] = useContext<any>(TabsState);
  const elements: any = useContext(Elements);

  const tabIndex = useConstant(() => {
    const currentIndex = elements.tabs;
    elements.tabs += 1;

    return currentIndex;
  });
  const onClick = useConstant(() => () => setActive(tabIndex));

  const state = useMemo(
    () => ({
      isActive: activeIndex === tabIndex,
      onClick,
    }),
    [activeIndex, onClick, tabIndex],
  );

  return state;
};

export const usePanelState = () => {
  const [activeIndex] = useContext<any>(TabsState);
  const elements = useContext<any>(Elements);

  const panelIndex = useConstant(() => {
    const currentIndex = elements.panels;
    elements.panels += 1;

    return currentIndex;
  });

  return panelIndex === activeIndex;
};

export const Panel = ({ active, children }: any) => {
  const isActive = usePanelState();

  return isActive || active ? children : null;
};

// Hooks to use tabs
const cn = (...args: [string | boolean, string | boolean]) => {
  return args.filter(Boolean).join(' ');
};

// Tab custom hook
export const TabHooks = ({ children, id }: ITabsProps) => {
  const { isActive, onClick } = useTabState();
  return (
    <li className={cn('tab-link', isActive && 'current')} onClick={onClick} id={id}>
      {children}
    </li>
  );
};

// Panel custom hook
export const PanelHooks = ({ children, inLineStyle }: ITabsProps) => {
  const isActive = usePanelState();
  return isActive ? <div style={inLineStyle}>{children}</div> : <div style={{ display: 'none' }}>{children}</div>;
};
