import { Trans } from '@lingui/react';
import React, { useState } from 'react';
import './profile.scss';

interface IProps {
  email: string;
  setShowDeleteModal: (state: boolean) => void;
  deleteAccountPermanently: () => void;
}

const DeleteProfileModal: React.FC<IProps> = ({ email, setShowDeleteModal, deleteAccountPermanently }) => {
  const [emailAddress, setEmailAddress] = useState<string>('');

  return (
    <div className='delete-profile-modal-bg'>
      <div>
        <div className='delete-profile-modal-container'>
          <div className='delete-profile-modal-header'>
            <Trans id='Delete account information' />
          </div>
          <div className='delete-profile-modal-body'>
            <div>
              <Trans id='You’re about to delete your account' /> <br />
              <div className='delete-profile-modal-text'>
                <Trans
                  id='This will <0>permanently delete all the virtual tours and data.</0>'
                  components={{ 0: <b style={{ color: '#E74C3C' }}></b> }}
                  render={({ translation }) => <>{translation}</>}
                />
                <br />
                <Trans id='Deleting your account is permanent and cannot be recovered.' /> <br />
              </div>
              <Trans
                id='To confirm, type {accountEmail} below'
                values={{ accountEmail: email }}
                render={({ translation }) => <>{translation}</>}
              />
            </div>
            <input
              type='text'
              style={{ marginTop: '30px' }}
              className='move-tour-input'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailAddress(e.target.value)}
            />
            <div style={{ margin: '40px', display: 'flex', gap: '40px' }}>
              <button
                style={{ width: '173px' }}
                onClick={() => setShowDeleteModal(false)}
                className='btn-new-design button-hover btn-new-cancel'
              >
                <Trans id='Cancel' />
              </button>
              <button
                style={{ width: '173px' }}
                className={`btn-new-design ${email === emailAddress ? 'btn-new-active-red button-hover' : 'btn-new-inactive'}`}
                disabled={email !== emailAddress}
                onClick={() => deleteAccountPermanently()}
              >
                <Trans id='Delete account' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteProfileModal;
