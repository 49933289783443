import React, { useEffect, useState } from 'react';
import './list-points.scss';
import '../editing-viewer/modals/modals.scss';
import { ReactComponent as Empty } from '../../images/viewer-icons/blur-layer.svg';
import { ReactComponent as Plus } from '../../images/viewer-icons/plus.svg';
import { ReactComponent as PlusDisable } from '../../images/viewer-icons/plus-disable.svg';
import { ReactComponent as BlurIconActive } from '../../images/viewer-icons/blur-icon-active.svg';
import { ReactComponent as BlurIconActiveBigger } from '../../images/viewer-icons/blur-icon-active-bigger.svg';
import { ReactComponent as BlurFace } from '../../images/viewer-icons/blur-face.svg';
import findingFaces from '../../images/viewer-icons/finding-faces.svg';
import { ReactComponent as Grey } from '../../images/viewer-icons/blur-grey.svg';
import { ReportCustomEvent } from '../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../google-analytics-track-list';
import { IObjectString } from '../editing-viewer/interaces';
import { IHotspotCreation } from '../../api-helper/interface/interfaces';
import { compareArray } from '../utils/array-utils/array-utils';
import { Trans } from '@lingui/react';
import { ISidebar } from '../editing-viewer/viewer-canvas';
import { useHistory } from 'react-router';

interface IProps {
  sidebar: ISidebar;
  hotspotsBlur: any;
  blurActive: boolean;
  handleRemoveBlur: (list: any, id: string) => void;
  toggleBlur: (end?: boolean) => void;
  blurResponse: IHotspotCreation[];
  reprocessPanoramaForBlur: () => void;
  getSuggestionsBlur: () => void;
  loadingSuggestion: IObjectString;
  objectBlur: any;
}

const ListsPointsBlur = ({
  sidebar,
  hotspotsBlur,
  blurActive,
  handleRemoveBlur,
  toggleBlur,
  blurResponse,
  reprocessPanoramaForBlur,
  getSuggestionsBlur,
  loadingSuggestion,
  objectBlur,
}: IProps) => {
  const [hotspotObject, setHotspotObject] = useState<any>();
  const [applyEnable, setApplyEnable] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    setHotspotObject(hotspotsBlur);
  }, [hotspotsBlur]);

  useEffect(() => {
    // Compare hotspotObject with original response to see if apply should be enabled
    if (compareArray(blurResponse, hotspotObject?.data)) {
      setApplyEnable(false);
    } else if (blurActive) {
      setApplyEnable(false);
    } else {
      setApplyEnable(true);
    }
  }, [blurResponse, hotspotObject, blurActive]);

  return (
    <React.Fragment>
      {hotspotObject && Array.isArray(hotspotObject.data) && hotspotObject.data.length === 0 && blurActive ? (
        <div className='annotation-blur-empty'>
          <Grey />
          <div style={{ marginTop: '15px' }}>
            <div className='blur-explain-first'>
              <Trans id='Add a ' />
              <b>
                <Trans id='minimum' /> 3
              </b>
              <Trans id=' blur points.' />
            </div>
            <div className='blur-explain-first'>
              <Trans id='Outline clockwise the object you want to' /> <br />
              <Trans id='hide and click on ' />
              <b>
                <Trans id='Apply blur ' />
              </b>
              button
            </div>
          </div>
        </div>
      ) : hotspotObject && Array.isArray(hotspotObject.data) && hotspotObject.data.length > 0 ? (
        hotspotObject.data.map((list: any, i: number) => {
          return (
            <div key={i} className='points-list-container'>
              <div className='detected-item-container'>
                <div className='annotation-container' style={{ opacity: list[0].status && list[0].status === -1 ? '0.2' : '1' }}>
                  <div className='annotation-options'>
                    <div className={`annotation-colour annotation-colour-${list[0].hotspotLabel || 'default'}`}>
                      <div>
                        <BlurIconActiveBigger />
                      </div>
                    </div>
                    <div className='annotation-name'>Blur {list[0].index}</div>
                  </div>
                  {list[0].status === -1 ? (
                    <button className='delete-btn-disabled' />
                  ) : (
                    <button onClick={() => handleRemoveBlur(list, String(i))} className='delete-btn' />
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className='annotation-blur-empty'>
          {loadingSuggestion.state === 'loading' ? (
            <div>
              <img src={findingFaces} alt='Finding faces' />
              <br />
              <br />
              <p className='finding-faces-text'>
                <Trans id='Finding faces' />
              </p>
            </div>
          ) : loadingSuggestion.state === 'empty' ? (
            <div>
              <img src={findingFaces} alt='Finding faces' />
              <br />
              <br />
              <p className='finding-faces-text'>
                <Trans id='No faces found' />
              </p>
            </div>
          ) : (
            <>
              <Empty />
              <div className='blur-explain'>
                <Trans id='Add a blurred layer to hide objects in' />
                <br /> <Trans id='your 360° tour following these steps:' />
              </div>
              <div className='blur-explain-points'>
                <p>
                  <Trans id='1. Click on Start blur' />
                </p>
                <p>
                  <Trans id='2. Double-click to outline the object' />
                </p>
                <p>
                  <Trans id='3. Click on Finish Blur' />
                </p>
                <p>
                  <Trans id='4. Repeat if required to hide more objects' />
                </p>
                <p>
                  <Trans id='5. Click on Apply blur' />
                </p>
              </div>
            </>
          )}
        </div>
      )}
      {sidebar.photoEnhancement ? (
        <div className='blur-buttons-box'>
          <button onClick={() => getSuggestionsBlur()} className='autoblur-btn button-hover'>
            <div className='face-blur-icon'>
              <BlurFace />
            </div>
            <Trans id='Automatically detect faces' />
          </button>
          <div className='blur-toggle'>
            {blurActive ? (
              <button
                className={objectBlur.length >= 3 ? 'new-blur-active-button button-hover' : 'new-blur-inactive-button'}
                onClick={() => {
                  ReportCustomEvent(EnumCategory.BlurCreation, EnumEvent.FinishBlur);
                  toggleBlur(true);
                }}
              >
                <div className='start-blur-icon'>{objectBlur.length >= 3 ? <Plus /> : <PlusDisable />}</div>
                <Trans id='Finish blur' />
              </button>
            ) : (
              <button
                className='new-blur-active-button button-hover'
                onClick={() => {
                  ReportCustomEvent(EnumCategory.BlurCreation, EnumEvent.StartBlur);
                  toggleBlur();
                }}
              >
                <div className='start-blur-icon'>
                  <Plus />
                </div>
                <Trans id='Start blur' />
              </button>
            )}
            {applyEnable ? (
              <button
                className='apply-layer-button button-hover apply-layer-active-apply-active'
                onClick={() => {
                  ReportCustomEvent(EnumCategory.BlurCreation, EnumEvent.ApplyBlur);
                  reprocessPanoramaForBlur();
                }}
              >
                <div className='apply-blur-icon'>
                  <BlurIconActive />
                </div>
                <Trans id='Apply blur' />
              </button>
            ) : (
              <button className='apply-layer-button apply-layer-inactive'>
                <div className='apply-blur-icon'>
                  <BlurIconActive />
                </div>
                <Trans id='Apply blur' />
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className='blur-buttons-box'>
          <button
            className='autoblur-btn button-hover'
            style={{ background: '#D50057' }}
            onClick={() => history.push('/manage-subscription')}
          >
            <Trans id='Upgrade to Pro' />
          </button>
          <button className='autoblur-btn blur-btn-inactive'>
            <div className='face-blur-icon'>
              <BlurFace />
            </div>
            <Trans id='Automatically detect faces' />
          </button>
          <div className='blur-toggle'>
            <button
              className='new-blur-active-button blur-btn-inactive'
              onClick={() => {
                ReportCustomEvent(EnumCategory.BlurCreation, EnumEvent.StartBlur);
                toggleBlur();
              }}
            >
              <div className='start-blur-icon'>
                <Plus />
              </div>
              <Trans id='Start blur' />
            </button>
            <button className='apply-layer-button' style={{ border: '1px solid #818AA6' }}>
              <div className='apply-blur-icon apply-blur-icon-inactive'>
                <BlurIconActive />
              </div>
              <Trans id='Apply blur' />
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ListsPointsBlur;
