import React, { useEffect, useRef, useState } from 'react';
import NavSidebar from '../../utils/nav-sidebar/nav-sidebar';
import SmallerLoadingSpinner from '../../utils/loading-spinner/smaller-loading-spinner';
import { ITourInfo } from './interfaces';
import 'intro.js/introjs.css';
import { Steps } from 'intro.js-react';
import './tours.scss';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import MeasurementModal from './measurement-modal';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../../utils/notifications/notification';
import CreateTourDetails from './create-tour-details';
import UploadPanoramas from './upload-panoramas';
import oneActive from '../../../images/tours/one-active.svg';
import oneInactive from '../../../images/tours/one-inactive.svg';
import twoActive from '../../../images/tours/two-active.svg';
import twoInactive from '../../../images/tours/two-inactive.svg';
import threeActive from '../../../images/tours/three-active.svg';
import threeInactive from '../../../images/tours/three-inactive.svg';
import { Trans } from '@lingui/react';
import TourSettings from './tour-settings';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';

export interface IFilesArray {
  completion: EnumCompletionFiles;
  data: string | ArrayBuffer | null;
  file: File;
  id: string;
  name: string;
}

export enum EnumCompletionFiles {
  Default = 0,
  Pending = 1,
  Completed = 5,
  Error = -1,
}

export interface INewTour {
  createdAt: Date;
  enabled: number;
  furnished: boolean;
  id: string;
  latitude: number;
  longitude: number;
  name: string;
  passwordHash: null | string;
  protected: false;
  readCode: string;
  status: 1;
  type: string;
  updatedAt: Date;
  url: string;
  userId: string;
}

export enum TabStateEnum {
  CreateTour = 1,
  SelectOptions = 2,
  UploadImages = 3,
}

const CreateTour: React.FC = () => {
  const tourRef = useRef<HTMLDivElement>(null);
  const [tourInput, setTourInput] = useState<ITourInfo>({
    latitude: 0,
    longitude: 0,
  });
  const [newTour, setNewTour] = useState<INewTour>();
  const [tabState, setTabsState] = useState<TabStateEnum>(TabStateEnum.CreateTour);
  const [filesArray, setFilesArray] = useState<IFilesArray[]>([]);
  const [loading, setLoading] = useState(false);
  const [isTooltipClicked, setIsTooltipClicked] = useState<boolean>(false);
  const [isTooltipActive, setIsTooltipActive] = useState<boolean>(true);
  const [currentTooltipStep, setCurrentTooltipStep] = useState<number>(0);
  const [measurementModal, setMeasurementModal] = useState<boolean>(false);
  const [measurementModalType, setMeasurementModalType] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [tourCreated, setTourCreated] = useState<boolean>(false);
  const [proAccount, setProAccount] = useState<boolean>(false);
  const config = useConfig();
  const [notificationDuration, setNotificationDuration] = useState(2000);
  const steps = [
    {
      element: '#tour-input',
      intro:
        'This section will allow you to enter the address of the property. Select the country from the drop-down list and enter the remaining parts of the address.',
      position: 'bottom',
      highlightClass: 'highlight-tour-input',
    },
    {
      element: '#map-tour',
      intro: 'You can use this icon to open a map, with which you will be able to pinpoint the exact location of the property.',
      position: 'left',
      highlightClass: 'highlight-tour-map',
    },
    {
      element: '#property-type',
      intro:
        'You will be prompted to give further details of the property, such as whether or not it is furnished and whether the property is an apartment, a house or a commercial property.',
      position: 'bottom',
      highlightClass: 'highlight-tour-type',
    },
    {
      element: '#location-display',
      intro:
        'Use this toggle to enable or disable the option to access Google street view from within the virtual tour and to display the tour address at the top of the vrtual tour.',
      position: 'right',
      highlightClass: 'highlight-tour-location',
    },
  ];

  const stepsUpload = [
    {
      element: '#tour-panorama',
      intro:
        'This section will allow you to enter the address of the property. Select the country from the drop-down list and enter the remaining parts of the address.',
      position: 'bottom',
      highlightClass: 'highlight-panorama',
    },
    {
      element: '#tour-drop',
      intro: 'You can add more images by either dragging and dropping them into this field, or by selecting them from a folder.',
      position: 'left',
      highlightClass: 'highlight-drag-drop',
    },
  ];

  useEffect(() => {
    if (config && config.modules) {
      for (const menu of config.modules) {
        const menuKey = Object.keys(menu);
        if (menuKey.includes('aiFeatures')) {
          setProAccount(menu[menuKey[0]]);
        }
      }
    }
  }, [config]);

  const onExit = () => {
    setIsTooltipClicked(false);
  };

  const onStepsCompleted = () => {
    setCurrentTooltipStep(0);
  };

  const stepsInfo = (currentStep: number) => {
    setCurrentTooltipStep(currentStep);
  };

  const activeTooltipHandler = () => {
    //Report Custom Category and Event
    ReportCustomEvent(EnumCategory.Tooltip, EnumEvent.TooltipCreateTour);
    tourRef?.current?.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => setIsTooltipClicked(!isTooltipClicked), 300);
  };

  const toggleNotification = (type: EnumNotificationType, message?: string, duration?: number) => {
    if (duration) {
      setNotificationDuration(duration);
    } else {
      setNotificationDuration(2000);
    }
    setTypeNotification(type);
    setNotificationMessage(message || linguiTranslateToString('There was an error please try again'));
    setShowNotification(true);
  };

  useEffect(() => {
    if (filesArray.length > 0 && tabState === TabStateEnum.CreateTour) {
      setIsTooltipActive(true);
    } else if (filesArray.length === 0 && tabState === TabStateEnum.CreateTour) {
      setIsTooltipActive(false);
    }
  }, [filesArray]);

  return (
    <>
      {showNotification && (
        <Notification
          showNotification={showNotification}
          size={EnumNotificationSize.Large}
          type={typeNotification || EnumNotificationType.Info}
          message={notificationMessage}
          setShowNotification={setShowNotification}
          duration={notificationDuration}
        />
      )}
      {measurementModal && (
        <MeasurementModal
          modalType={measurementModalType}
          setMeasurementModal={setMeasurementModal}
          units={tourInput.measurementUnit || ''}
        />
      )}
      {(tabState === TabStateEnum.CreateTour || tabState === TabStateEnum.UploadImages) && (
        <Steps
          enabled={isTooltipClicked}
          steps={tabState === TabStateEnum.UploadImages ? stepsUpload : steps}
          initialStep={currentTooltipStep}
          onAfterChange={stepsInfo}
          onComplete={onStepsCompleted}
          onExit={onExit}
          options={{ hideNext: false, exitOnOverlayClick: false, showStepNumbers: false, showBullets: false, disableInteraction: true }}
        />
      )}

      {loading && <SmallerLoadingSpinner />}
      <div className='dashboard-layout'>
        <NavSidebar isTooltipActive={isTooltipActive} activeTooltipHandler={activeTooltipHandler} isToolTipClicked={isTooltipClicked} />
        <div className='dashboard' ref={tourRef}>
          <div className='content-wrapper'>
            <div id='upload-images-page' className='create-tour-container'>
              <div className='create-tour-steps'>
                <button
                  style={{ marginRight: '24px', cursor: 'default' }}
                  className='btn-remove-default'
                  onClick={() => {
                    if (tourCreated) {
                      setTabsState(TabStateEnum.CreateTour);
                    }
                  }}
                >
                  <img className='img-align' src={tabState === TabStateEnum.CreateTour ? oneActive : oneInactive} alt='one-green' />
                  <span className={tabState === TabStateEnum.CreateTour ? 'active-font' : 'inactive-font'} style={{ marginLeft: '16px' }}>
                    <Trans id='Enter tour details' />
                  </span>
                </button>
                <button
                  style={{ marginLeft: '24px', cursor: 'default' }}
                  className='btn-remove-default'
                  onClick={() => {
                    if (tourCreated) {
                      setTabsState(TabStateEnum.SelectOptions);
                    }
                  }}
                >
                  <img className='img-align' src={tabState === TabStateEnum.SelectOptions ? twoActive : twoInactive} alt='two-grey' />
                  <span
                    className={tabState === TabStateEnum.SelectOptions ? 'active-font' : 'inactive-font'}
                    style={{ marginLeft: '16px', cursor: 'default' }}
                  >
                    <Trans id='Choose tour settings' />
                  </span>
                </button>
                <button
                  style={{ marginLeft: '24px', cursor: 'default' }}
                  className='btn-remove-default'
                  onClick={() => {
                    if (tourCreated) {
                      setTabsState(TabStateEnum.UploadImages);
                    }
                  }}
                >
                  <img className='img-align' src={tabState === TabStateEnum.UploadImages ? threeActive : threeInactive} alt='two-grey' />
                  <span className={tabState === TabStateEnum.UploadImages ? 'active-font' : 'inactive-font'} style={{ marginLeft: '16px' }}>
                    <Trans id='Upload 360° photos' />
                  </span>
                </button>
              </div>
              {tabState === TabStateEnum.CreateTour && (
                <CreateTourDetails
                  tourInput={tourInput}
                  setTourInput={setTourInput}
                  setMeasurementModalType={setMeasurementModalType}
                  setMeasurementModal={setMeasurementModal}
                  newTour={newTour}
                  setNewTour={setNewTour}
                  toggleNotification={toggleNotification}
                  setLoading={setLoading}
                  setTabsState={setTabsState}
                  setIsTooltipActive={setIsTooltipActive}
                  tourCreated={tourCreated}
                  setTourCreated={setTourCreated}
                />
              )}
              {tabState === TabStateEnum.UploadImages && (
                <UploadPanoramas
                  newTour={newTour}
                  filesArray={filesArray}
                  setFilesArray={setFilesArray}
                  toggleNotification={toggleNotification}
                />
              )}
              {tabState === TabStateEnum.SelectOptions && (
                <TourSettings
                  proAccount={proAccount}
                  setTabsState={setTabsState}
                  toggleNotification={toggleNotification}
                  newTour={newTour}
                  tourCreated={tourCreated}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTour;
