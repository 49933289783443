var CryptoJS = require("crypto-js");

export default class Encryption {
	static encrypt(msg: any, key: any, useIV = false) {
		if (useIV) {
			const iv = CryptoJS.lib.WordArray.random(128 / 8);
			const encrypted = CryptoJS.AES.encrypt(msg, key, {
				iv: iv,
				padding: CryptoJS.pad.Pkcs7,
				mode: CryptoJS.mode.CBC

			});
			// append them to the ciphertext for use  in decryption
			const transitmessage = iv.toString() + encrypted.toString();
			return transitmessage;
		} else {
			return CryptoJS.AES.encrypt(msg, key).toString();
		}
	}

	static decrypt(transitmessage: any, key: any, useIV = false) {
		if (!transitmessage || transitmessage.length < 10) {
			return;
		}
		if (useIV) {
			const iv = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32))
			const encrypted = transitmessage.substring(32);
			const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
				iv: iv,
				padding: CryptoJS.pad.Pkcs7,
				mode: CryptoJS.mode.CBC

			})
			return decrypted.toString(CryptoJS.enc.Utf8);
		} else {
			return CryptoJS.AES.decrypt(transitmessage, key, {
				padding: CryptoJS.pad.Pkcs7,
				mode: CryptoJS.mode.CBC
			}).toString(CryptoJS.enc.Utf8);
		}

	}
}