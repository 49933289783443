
import { ClientApi } from '@vva/front';
import { IAccount, IAccountHeaders } from './interface/interfaces';
import Storage from '../components/utils/connect-utils/Storage'
import { authenticate } from './api-auth';

const clientApi = new ClientApi();

// conditional window ENV for testing with jest
clientApi.setHeaders({
	'X-Api-Key': (window.ENV && window.ENV.REACT_APP_API_KEY ? window.ENV.REACT_APP_API_KEY : null) || process.env.REACT_APP_API_KEY,
});

const getUser = async (): Promise<IAccount> => {
	const loginData = await clientApi.getData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/account?options=true`);
	return loginData.data;
};

const getUserById = async (id: string): Promise<IAccount> => {
	const loginData = await clientApi.getData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/users/${id}`);
	return loginData.data;
};

const getUsers = async (page: number, keyword: string, order: string, userFilterByMasterName?: string): Promise<IAccountHeaders> => {
	const usersData = await clientApi.getDataWithHeaders(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/users?limit=10&page=${page + 1}&keyword=${keyword}&orderBy=${order}&partnerName=${userFilterByMasterName} `
	);
	return { data: usersData.data.data, headers: usersData.headers };
};

const updateUser = async (body: any) => {
	const userData = await clientApi.putData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/account`, body);
	return userData.data
}

const updateUserAccountById = async (id: string, body: any) => {
	const userData = await clientApi.putData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/users/${id}`, body);
	return userData.data
}

const updateSingleInput = async (body: any) => {
	const accountData = await clientApi.patchData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/account`, body);
	return accountData.data
}

const connectToUserNoPassword = async (userId: string) => {
	const accountToken = await clientApi.postData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/users/${userId}/connect`, {});
	return accountToken
}

const createAccount = async (body: any) => {
	const userData = await clientApi.postData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/users`, body);
	return userData.data
}

const connect = (token: string, origin: string) => {
	return new Promise(async (resolve, reject) => {
		if (typeof window !== "undefined") {
			// Keep old jwt
			const oldJwt = localStorage.getItem("Bearer");

			// Authenticate
			await authenticate(token);

			// Save history
			const secret =
				window.ENV.REACT_APP_CLIENT_SECRET ||
				process.env.REACT_APP_CLIENT_SECRET ||
				"";
			Storage.pushJwtHistory(secret, oldJwt, origin);
			resolve(true);
		} else {
			reject(new Error("No window object"));
		}
	});
}

const deleteOwnAccount = async () => {
	const userData = await clientApi.deleteData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/account`,);
	return userData
}

export {
	getUser,
	updateUser,
	updateSingleInput,
	getUsers,
	getUserById,
	updateUserAccountById,
	connectToUserNoPassword,
	connect,
	createAccount,
	deleteOwnAccount
};