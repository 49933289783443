import { defineMessage } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { IToolTipData } from '../tooltips/tooltips-data';
import { en, fr, ja, es, it, de } from 'make-plural/plurals';
import { messages as enMessages } from '../../../locales/en/messages.js'; // English
import { messages as esMessages } from '../../../locales/es/messages.js'; // Spanish
import { messages as itMessages } from '../../../locales/it/messages.js'; // Italian
import { messages as jaMessages } from '../../../locales/ja/messages.js'; // Japanese
import { messages as frMessages } from '../../../locales/fr/messages.js'; // French
import { messages as deMessages } from '../../../locales/de/messages.js'; // german
import { ValidationUtils } from '../validations/validations';

/**
 * Enum use for detect browser
 */
export enum EnumDetectLanguage {
  en = 'en',
  fr = 'fr',
  ja = 'ja',
  it = 'it',
  es = 'es',
  de = 'de',
}
/**
 * Enum use in dropdown for get the option
 */
export enum EnumLanguages {
  English = 'English',
  日本人 = '日本人',
  Français = 'Français',
  Italiano = 'Italiano',
  Español = 'Español',
  German = 'German'
}

//
export enum EnumLanguagesCode {
  English = 'en',
  Français = 'fr',
  日本人 = 'ja',
  Italiano = 'it',
  Español = 'es',
  German = 'de'
}

export const setBrowserLanguage = (language: string = 'en') => {
  if (Object.values(EnumLanguages).includes(language as EnumLanguages)) {
    return setLanguageFromName(language);
  } else {
    return setLanguageFromCode(language);
  }
};

export const setLanguageFromCode = (language: string = 'en') => {
  const languageCode = language.split('-');
  const code = ValidationUtils.isArrayNotEmpty(languageCode) ? languageCode[0] : 'en';
  switch (code) {
    case EnumDetectLanguage.en:
      return { code: 'en', messages: enMessages, plurals: en };
    case EnumDetectLanguage.fr:
      return { code: 'fr', messages: frMessages, plurals: fr };
    case EnumDetectLanguage.it:
      return { code: 'it', messages: itMessages, plurals: it };
    case EnumDetectLanguage.es:
      return { code: 'es', messages: esMessages, plurals: es };
    case EnumDetectLanguage.ja:
      return { code: 'ja', messages: jaMessages, plurals: ja };
    case EnumDetectLanguage.de:
      return { code: 'de', messages: deMessages, plurals: de };
    default:
      return { code: 'en', messages: enMessages, plurals: en };
  }
};

export const setLanguageFromName = (language: string) => {
  switch (language) {
    case EnumLanguages.English:
      return { code: 'en', messages: enMessages, plurals: en };
    case EnumLanguages.Français:
      return { code: 'fr', messages: frMessages, plurals: fr };
    case EnumLanguages.Italiano:
      return { code: 'it', messages: itMessages, plurals: it };
    case EnumLanguages.Español:
      return { code: 'es', messages: esMessages, plurals: es };
    case EnumLanguages.日本人:
      return { code: 'ja', messages: jaMessages, plurals: ja };
    case EnumLanguages.German:
      return { code: 'de', messages: deMessages, plurals: de };
    default:
      return { code: 'en', messages: enMessages, plurals: en };
  }
};

export const setLanguageLocalStorage = (name: string, option: string) => {
	localStorage.setItem(name, option);
}

/**
 * Translate for variable
 * @param id
 * @returns {id} - Translate Id outside Html
 */
export const linguiTranslateToString = (id: string) => {
  return i18n._(defineMessage({ message: id }));
};

/**
 * Translate for array
 * @param values
 * @returns {string[]} - Array of tranlated Id
 */
export const linguiTranslateIdToStringArray = (values: string[]) => {
  return values.map(value => i18n._(defineMessage({ message: value })));
};

/**
 * Used in tooltips data for translate the intro
 * @param values - tooltip values
 * @returns {IToolTipData[]} - Translated the tooltip values
 */
export const linguiTranslateToolTip = (values: IToolTipData[]) => {
  return values.map(value => {
    value.intro = i18n._(defineMessage({ message: value.intro }));
    return value;
  });
};
