export const orderAlphabetically = (list: any, key: string, placeLast?: string) => {
	return list.sort((a: any, b: any) => {
		var textA = a[key].toUpperCase();
		var textB = b[key].toUpperCase();
		const order = textB === placeLast?.toUpperCase() ? -1 : (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
		return order;
	});
}

export const orderNoLinksFirst = (list: any) => {
	return list.sort((a: any, b: any) => {
		var textA = a;
		var textB = b;
		const order = (textA.links.length < textB.links.length) ? -1 : (textA.links.length > textB.links.length) ? 1 : 0;
		return order;
	});
}