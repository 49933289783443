import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { resetPassword } from '../../../api-helper/api-auth';
import { usePasswordValidation } from '../../utils/custom-hooks/usePasswordValidation';
import { Trans } from '@lingui/react';
import recoverPassword from '../../../images/icons/recover-password.svg';
import errorPassword from '../../../images/icons/recover-password-error.svg';
import tick from '../../../images/icons/pwd-check-tick.svg';
import cross from '../../../images/icons/pwd-check-cross.svg';
import vieweet from '../../../images/vieweet-logo/vieweet-logo.svg';
import hidePasswordIcon from '../../../images/icons/password-shown.svg';
import showPasswordIcon from '../../../images/icons/password-hidden.svg';

import './reset-password.scss';
import Footer from '../../utils/footer/footer';
import { isMobileOnly } from 'react-device-detect';

interface IPassword {
  firstPassword: string;
  secondPassword: string;
}

const ResetPassword: React.FC = () => {
  // Password state
  const [password, setPassword] = useState<IPassword>({
    firstPassword: '',
    secondPassword: '',
  });

  // Custom hook
  const [validLength, hasNumber, upperCase, specialChar, match] = usePasswordValidation({
    firstPassword: password.firstPassword,
    secondPassword: password.secondPassword,
    requiredLength: 8,
    numberValidation: true,
    lengthValidation: true,
    uppercaseValidation: true,
    specialCharacterValidation: true,
  });

  /**
   * First password handler
   * @param event
   */
  const setFirst = (event: any) => {
    setPassword({ ...password, firstPassword: event.target.value });
  };

  /**
   * Second password handler
   * @param event
   */
  const setSecond = (event: any) => {
    setPassword({ ...password, secondPassword: event.target.value });
  };

  // Hide and display passwords
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [passwordShownSecond, setPasswordShownSecond] = useState<boolean>(false);

  // Disable form button
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);

  // Displays submission success
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  // Display password requirements
  const [showRequirements, setShowRequirements] = useState<boolean>(false);

  // Service response
  const [token, setToken] = useState<string>('');
  const [isResponse, setIsResponse] = useState(false);

  /**
   * Get token from url
   */
  useEffect(() => {
    // Update the document title using the browser API
    const urlParam = new URLSearchParams(window.location.search);
    const urlToken: any = urlParam.get('token');
    setToken(urlToken);
  }, [token]);

  /**
   * Show password
   * @param password
   */
  const showPassword = (password: string): void => {
    if (password === 'first') {
      setPasswordShown(!passwordShown);
    } else {
      setPasswordShownSecond(!passwordShownSecond);
    }
  };

  /**
   * Update password service
   * @returns password status update
   */
  const updatePassword = async () => {
    try {
      const response = await resetPassword(password.firstPassword, token);
      return response;
    } catch (err: any) {
      console.log(err);
      return false;
    }
  };

  const checkParameters = (validLength: boolean, hasNumber: boolean, upperCase: boolean, hasSpecialChar: boolean, match: boolean) => {
    const isValid = (currentValue: boolean) => currentValue === true;
    const params = [validLength, hasNumber, upperCase, hasSpecialChar, match];
    return params.every(isValid);
  };

  // Validate passwords on change
  useEffect(() => {
    setButtonDisable(!checkParameters(validLength, hasNumber, upperCase, specialChar, match) ? true : false);
  }, [validLength, hasNumber, upperCase, specialChar, match]);

  /**
   * Submit password update from form
   */
  const confirmResetPassword = async () => {
    const response = await updatePassword();
    setShowSuccess(true);
    if (response && response.result === true) {
      setIsResponse(true);
    } else {
      setIsResponse(false);
    }
  };

  const passwordRequirements = (
    <div className='pwd-should-contain-section-reset-password'>
      <div className='pwd-should-contain-reset-title'>
        <h5>
          <Trans id='Your password should be' />
        </h5>
      </div>
      <div className='pwd-should-contain-reset-group'>
        <img className='pwd-check-status-reset' src={!validLength ? cross : tick} alt='tick' />
        <span>
          <Trans id='At least 8 characters' />
        </span>
      </div>
      <div className='pwd-should-contain-reset-group'>
        <img className='pwd-check-status-reset' src={!upperCase ? cross : tick} alt='cross' />
        <span>
          <Trans id='A capital letter' />
        </span>
      </div>
      <div className='pwd-should-contain-reset-group'>
        <img className='pwd-check-status-reset' src={!hasNumber ? cross : tick} alt='empty' />
        <span>
          <Trans id='A number' />
        </span>
      </div>
      <div className='pwd-should-contain-reset-group'>
        <img className='pwd-check-status-reset' src={!specialChar ? cross : tick} alt='tick' />
        <span>
          <Trans id='Contain a special character ' />
          <i>e.g. [!@£$%^&*()]</i>
        </span>
      </div>
    </div>
  );
  const RESET_PASSWORD_VIEW = (
    <div className='reset-initial-pages-container'>
      <div className='reset-form-container'>
        <img className='image' src={recoverPassword} alt='recover-password' />
        <h3 className='title'>
          <Trans id='Reset your password' />
        </h3>
        <p className='text'>
          <Trans id='One more step left to get you back into your account' />
        </p>
        <div className='reset-password-form'>
          <div className='reset-input-section'>
            <label>
              <Trans id='Enter your new password' />
            </label>
            <input
              data-testid='first-password'
              className={`reset-input ${password.firstPassword !== '' ? 'input-populated' : null}`}
              name='password1'
              type={passwordShown ? 'text' : 'password'}
              onChange={setFirst}
              onFocus={() => {
                setShowRequirements(true);
              }}
              onBlur={() => setShowRequirements(false)}
            />
            <button
              data-testid='password-toggle-first'
              onClick={(e: React.MouseEvent): void => {
                e.preventDefault();
                showPassword('first');
              }}
              className='reset-password-show-password-icon-first'
            >
              <img src={passwordShown ? hidePasswordIcon : showPasswordIcon} alt='Show password icon' />
            </button>
          </div>
          <div className='reset-input-section'>
            <label>
              <Trans id='Re enter your password' />
            </label>
            <input
              data-testid='second-password'
              className={`reset-input ${
                password.secondPassword !== '' ? (!match && password.secondPassword !== '' ? 'input-pwd-invalid' : 'input-populated') : null
              }`}
              name='password2'
              type={passwordShownSecond ? 'text' : 'password'}
              onChange={setSecond}
            />
            <button
              data-testid='password-toggle-second'
              onClick={(e: React.MouseEvent): void => {
                e.preventDefault();
                showPassword('second');
              }}
              className='reset-password-show-password-icon-second'
            >
              <img src={passwordShownSecond ? hidePasswordIcon : showPasswordIcon} alt='Show password icon' />
            </button>
          </div>
          {showRequirements && passwordRequirements}
          <div className='confirm-new-password-btn-container'>
            <button
              data-testid='submit-password'
              className={`btn-confirm-password ${buttonDisable ? 'btn-inactive-confirm-password' : 'btn-active-confirm-password'} `}
              onClick={(e: React.MouseEvent): void => {
                e.preventDefault();
                confirmResetPassword();
              }}
              disabled={buttonDisable ? true : false}
            >
              <Trans id='Confirm my new password' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  const PASSWORD_HAS_BEEN_RESET_VIEW = (
    <div className='reset-initial-pages-reset-container'>
      <div className='reset-info-container'>
        <div className='info-container'>
          <img className='image-info' src={recoverPassword} alt='recover-password' />
          <h3 className='title-info' data-testid='success-message'>
            <Trans id='Your password has been reset' />
          </h3>
          <p className='text-info'>
            <Trans id='Log into your account' />
          </p>
        </div>
        <div className='reset-btn-login-container'>
          <Link to='/login'>
            <Trans id='Login' />
          </Link>
        </div>
      </div>
    </div>
  );
  const ERROR_VIEW = (
    <div className='reset-initial-pages-error-container'>
      <div className='reset-error-container'>
        <img className='image-info' src={errorPassword} alt='recover-password' />
        <h3 className='title-info' style={{ color: '#e74c3c' }} data-testid='success-message'>
          <Trans id='Something went wrong' />
        </h3>
        <div className='text-info-container'>
          <p className='text-info'>
            <Trans id='An error has occured while updating your password' />
          </p>
          <p className='text-info'>
            <Trans id='You will need to reset your password again' />
          </p>
        </div>
        <div className='reset-btn-reset-container'>
          <Link to='/forgot-pwd'>
            <Trans id='Reset my password' />
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <div className='initial-pages-bg'>
      <div className='onboarding-content-layout'>
        <div className='initial-header'>
          <a href='https://www.vieweet.com/'>
            <img className='onboarding-topbar-vieweet-logo' src={vieweet} alt='vieweet' />
          </a>
        </div>
        {!isMobileOnly && (
          <div className='onboarding-topbar-links-section'>
            <div className='onboarding-topbar-link-no-border-below'>
              <Link to='/login'>
                <Trans id='Login' />
              </Link>
            </div>
            <div className='onboarding-topbar-link-no-border-below'>
              <Link to='/signup'>
                <Trans id='Sign up' />
              </Link>
            </div>
          </div>
        )}
        {!showSuccess ? RESET_PASSWORD_VIEW : isResponse ? PASSWORD_HAS_BEEN_RESET_VIEW : ERROR_VIEW}
      </div>
      <Footer isPublicRoute={true} />
    </div>
  );
};

export default ResetPassword;
