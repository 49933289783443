import * as EmailValidator from 'email-validator';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
var validator = require('validator');

const specialCharacterRegex = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/g;
const capitalLetterRegex = /[A-Z]/;
const numberRegex = /[0-9]/;

export class ValidationUtils {
  public static pwdMeetRequirements = (password: string): boolean => {
    if (
      password.match(capitalLetterRegex) != null &&
      password.match(numberRegex) != null &&
      password.match(specialCharacterRegex) != null &&
      password.length > 7
    ) {
      return true;
    } else {
      return false;
    }
  };

  public static pwdHasCapitalLetter = (password: string): boolean => {
    if (password.match(capitalLetterRegex) != null) {
      return true;
    } else {
      return false;
    }
  };

  public static pwdHasNumber = (password: string): boolean => {
    if (password.match(numberRegex) != null) {
      return true;
    } else {
      return false;
    }
  };

  public static pwdHasSpecialChar = (password: string): boolean => {
    if (password.match(specialCharacterRegex)) {
      return true;
    } else {
      return false;
    }
  };

  public static pwdLengthCheck = (password: string): boolean => {
    if (password.length > 7) {
      return true;
    } else {
      return false;
    }
  };

  public static isEmail = (email: string): boolean => {
    if (email !== '' && EmailValidator.validate(email)) {
      return true;
    } else {
      return false;
    }
  };

  public static isMatching = (field1: string, field2: string): boolean => {
    if (field1 !== '' && field2 !== '' && field1 === field2) {
      return true;
    } else {
      return false;
    }
  };

  public static isPostCode = (value: string, countryCode: string) => {
    if (!validator.isPostalCode(value, countryCode) && value !== '') {
      return false;
    } else {
      return true;
    }
  };

  public static isArrayNotEmpty<T>(array: T[]): boolean {
    if (Array.isArray(array) && array.length) {
      return true;
    }
    return false;
  }

  public static isValidFullName = (fullName: string) => {
    return fullName !== '' && fullName?.length > 1 ? true : false;
  };

  public static isValidPhone = (telephoneNumber: string, countryId: string) => {
    const phoneNumber = parsePhoneNumberFromString(telephoneNumber, countryId as CountryCode);
    return phoneNumber?.isPossible();
  };
}
