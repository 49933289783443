import React from 'react';
import ReactPaginate from 'react-paginate';
import backArrow from '../../../images/general-icons/back-arrow-pagination.svg';
import forwardArrow from '../../../images/general-icons/forward-arrow-pagination.svg';

interface IProps {
  page: number;
  pageCount: number;
  handlePaginationSearch: any;
}

const Pagination: React.FC<IProps> = ({ page, pageCount, handlePaginationSearch }) => {
  return (
    <ReactPaginate
      previousLabel={<img className='pagination-arrows' src={backArrow} alt='forward' />}
      nextLabel={<img className='pagination-arrows' src={forwardArrow} alt='forward' />}
      breakLabel={'...'}
      breakClassName={'break-me'}
      forcePage={page}
      pageCount={pageCount}
      marginPagesDisplayed={5}
      pageRangeDisplayed={2}
      onPageChange={handlePaginationSearch}
      containerClassName={'pagination'}
      pageClassName={'page-number'}
      activeClassName={'active'}
      previousClassName={'page-prev'}
      nextClassName={'page-next'}
    />
  );
};

export default Pagination;
