import React, { Fragment, useEffect, useState } from 'react';
import { ReactComponent as CloseButton } from '../../../images/viewer-icons/cross.svg';
import './add-note.scss';
import './modals.scss';
import { Trans } from '@lingui/react';
import cross from '../../../images/general-icons/cross.svg';
import imageIcon from '../../../images/viewer-icons/image-drop-icon.svg';
import retryIcon from '../../../images/viewer-icons/retry.svg';
import greenTick from '../../../images/viewer-icons/green-tick.svg';
import { createPanorama, uploadPanoramaWithFile } from '../../../api-helper/api-panoramas';
import { getCompletedElement, getFailedElement, getPendingElement } from '../../utils/image-loading-utils/image-loading-utils';
import UploadingSpinner from '../../utils/loading-spinner/uploading-spinner';
import { removeExtension, validateImageSize, validateImageType } from '../../utils/images-editor/image-utils';
import { EnumUploadState, IFileArray } from '../interaces';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import { EnumNotificationType } from '../../utils/notifications/notification';

interface IProps {
  tour: any;
  panoramaData: any;
  changeLeftSidebar: (option: string) => void;
  getPanoramaList: () => void;
  toggleNotification: (type: EnumNotificationType, message?: string, duration?: number) => void;
}

const AddImage: React.FC<IProps> = ({ tour, panoramaData, changeLeftSidebar, getPanoramaList, toggleNotification }) => {
  const { handleLogoutState } = useAuth();
  const [filesArray, setFilesArray] = useState<any[]>([]);
  const [imagePreview, setImagePreview] = useState<boolean>(false);
  const [disableUploadBtn, setDisableUploadBtn] = useState(false);

  useEffect(() => {
    if (filesArray.length < 1) {
      setImagePreview(false);
    }
  }, [filesArray]);

  const onFileDrop = (e: any) => {
    const file = e.target.files;
    const arr: IFileArray[] = [];
    for (let i = 0; i <= file.length; i++) {
      const fileReader = new FileReader();
      const newFile = file[i];
      if (newFile) {
        if (!validateImageType(newFile.name)) {
          toggleNotification(EnumNotificationType.Error, 'Ooops! Make sure your images are in a compatible format: jpg', 5000);
          continue;
        }
        fileReader.onload = () => {
          const imageDataUrl = fileReader.result as string;
          const img = new Image();
          img.onload = () => {
            if (!validateImageSize(img.height, img.width)) {
              toggleNotification(
                EnumNotificationType.Error,
                'Ooops! Make sure your images have 2:1 aspect ratio and upload them again.',
                5000,
              );
              return;
            }
            const file: IFileArray = {
              data: imageDataUrl,
              file: newFile,
              name: removeExtension(newFile.name),
              completion: EnumUploadState.NotUploaded,
              id: '',
            };
            arr.push(file);
            addLoadedFileToArray(arr);
          };
          img.src = imageDataUrl;
        };
        fileReader.readAsDataURL(newFile);
      }
    }
  };

  const uploadPanorama = async (e: React.MouseEvent) => {
    e.preventDefault();
    setDisableUploadBtn(true);
    for (const panorama in filesArray) {
      if (filesArray[panorama].completion < EnumUploadState.Completed) {
        const order = panoramaData.length + Number(panorama);
        // Change completion to 1 pending
        const panoPending = getPendingElement(filesArray, +panorama);
        setFilesArray(panoPending);
        // Only do this if the upload has not been uploaded yet
        try {
          // Create panorama
          const createNewPanorama = await createPanorama(tour.id, filesArray[panorama].name, order);
          // Upload panorama to S3
          await uploadPanoramaWithFile(createNewPanorama.id, filesArray[panorama].file);
          // Change completion status to 5 and add panorama Id to image object
          const panoCompleted = getCompletedElement(filesArray, +panorama, createNewPanorama.id);
          setFilesArray(panoCompleted);
        } catch (error) {
          const err = error as Error;
          handleError(err, handleLogoutState);
          const panoCompleted = getFailedElement(filesArray, +panorama);
          setFilesArray(panoCompleted);
        }
        if (filesArray.length > 0) {
          changeLeftSidebar('');
          getPanoramaList();
          setFilesArray([]);
        }
      }
    }
    setDisableUploadBtn(false);
  };

  const retryUploadSinglePanorama = async (index: number) => {
    let tempFilesArray = [...filesArray];
    const panoPending = getPendingElement(tempFilesArray, index);
    setFilesArray(panoPending);
    try {
      const singleUpload = await uploadPanoramaWithFile(tempFilesArray[index].id, tempFilesArray[index].file);
      const panoCompleted = getCompletedElement(tempFilesArray, index, singleUpload.id);
      setFilesArray(panoCompleted);
    } catch (error) {
      const panoCompleted = getFailedElement(tempFilesArray, index);
      setFilesArray(panoCompleted);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
    setFilesArray(tempFilesArray);
  };

  const addLoadedFileToArray = (file: any) => {
    const joinArrays = filesArray.concat(file);
    setFilesArray(joinArrays);
    setImagePreview(true);
  };

  const removeLoadedFileFromArray = (index: number) => {
    const arr = filesArray.filter((a: any, i: number) => i !== index);
    setFilesArray(arr);
  };

  return (
    <Fragment>
      <div className='modal-background-layer'></div>
      <div className='editor-modal-container-add'>
        <div className='editor-modal-title'>
          <h1 className='editor-modal-header-title-add'>
            <Trans id='Upload images' />
          </h1>
        </div>
        <button
          onClick={() => {
            changeLeftSidebar('');
          }}
          className='close-button btn-remove-default'
        >
          <CloseButton />
        </button>
        <div className='content-modal-add'>
          <div className='add-image-container-modal'>
            <div className='title-add-modal'>
              <div className='upload-title-modal-image'>
                <Trans id='Upload your 360° images below' />
              </div>
              <div className='upload-title-modal-image-second'>
                <Trans id='The image format must be JPEG. Maximum size is 50 MB.' />
              </div>
            </div>
            <div className='edit-modal-inside-container'>
              {imagePreview && (
                <div className='viewer-upload-preview'>
                  {filesArray.map((file: any, index: number) => (
                    <div
                      id='upload-preview'
                      key={index}
                      style={{ position: 'relative', height: '100px' }}
                      className='preview-images-container-viewer-add'
                    >
                      {file.completion === 0 && (
                        <button
                          id='remove-upload'
                          className='btn-remove-default btn-cross-add'
                          onClick={() => removeLoadedFileFromArray(index)}
                        >
                          <img src={cross} alt='cross' />
                        </button>
                      )}
                      {file.completion === 1 && (
                        <div className='uploading-image-spinner-create-add'>
                          <UploadingSpinner />
                        </div>
                      )}
                      {file.completion === -1 && (
                        <button className='btn-retry-add' onClick={() => retryUploadSinglePanorama(index)}>
                          <img src={retryIcon} alt='cross' />
                        </button>
                      )}
                      {file.completion === 5 && (
                        <button className=' btn-retry-add'>
                          <img src={greenTick} alt='cross' />
                        </button>
                      )}

                      <img className='viewer-preview-image-add' src={file.data} alt='preview' />
                    </div>
                  ))}
                </div>
              )}
              <div className='viewer-drop-zone' style={{ height: imagePreview ? '150px' : '' }}>
                {!imagePreview && <img className='icon-upload-image' src={imageIcon} alt='icon' />}
                <div className='helper-text-image-upload'>
                  <Trans id='Drop one or many 360° images here' />
                  <div className='media-drop-or-add'>
                    <Trans id='or' />
                  </div>
                </div>
                <button className='select-an-image-file'>
                  <Trans id='Upload an image' />
                </button>
                <input
                  style={{ height: imagePreview ? '150px' : '' }}
                  id='upload-multiple'
                  className='center-input'
                  type='file'
                  name='file-drop-zone'
                  multiple
                  onDragOver={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onDrop={(e: any) => onFileDrop(e)}
                  onChange={(e: any) => onFileDrop(e)}
                />
              </div>
            </div>
          </div>
          <div className='modal-btn-container-add'>
            {disableUploadBtn ? (
              <button disabled={true} className='upload-images-editor-modal-btn'>
                <Trans id='Upload panoramas' />
              </button>
            ) : (
              <button
                onClick={(e: React.MouseEvent) => uploadPanorama(e)}
                disabled={filesArray.length < 1 ? true : false}
                className={`upload-images-editor-modal-btn ${filesArray.length < 1 ? '' : ' editor-modal-btn-active-add button-hover'}`}
              >
                <Trans id='Upload panoramas' />
              </button>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddImage;
