import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router';
import LoadingSpinner from '../components/utils/loading-spinner/loading-spinner';
import { useAuth } from './auth-context';

interface IProps {
  exact?: boolean;
  loading?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

const PrivateRoute = (props: IProps | any) => {
  const { auth } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return loading ? (
    <LoadingSpinner />
  ) : auth ? (
    <Route exact {...props} path={props.path} component={props.component} />
  ) : (
    <Redirect to='/login' />
  );
};

export default PrivateRoute;
