import React from 'react';
import './loading-spinner.scss';
import vieweet from '../../../images/menu-icons/vieweet-logo.svg';

interface IProps {
  loaded?: boolean;
}

const LoadingSpinner: React.FC<IProps> = ({ loaded }) => {
  return (
    <div className='bg-loader-smaller' style={{ display: loaded ? 'none' : 'block' }}>
      <div className='image-loading-spinner'>
        <img src={vieweet} alt='spinner' />
        <div className='loader'></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
