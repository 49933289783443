import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { reSendVerificationEmail } from '../../../api-helper/api-auth';
import { RouteComponentProps } from 'react-router';
import { Trans } from '@lingui/react';
import vieweet from '../../../images/vieweet-logo/vieweet-logo.svg';
import accountActive from '../../../images/icons/email-activated.svg';
import activateAccount from '../../../images/empty-states-illustrations/activate-your-account.svg';

import './activate-account.scss';
import Footer from '../../utils/footer/footer';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';

interface IProps {
  email: string;
}

const ActivateAccount = ({ location }: RouteComponentProps<IProps>) => {
  const { handleLogoutState } = useAuth();
  const [activeAccount] = useState<boolean>(false);
  const form: any = location.state;

  const reSendEmail = async () => {
    try {
      await reSendVerificationEmail(form.form.email);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  return (
    <div className='initial-pages-bg'>
      <div className='onboarding-content-layout'>
        <div>
          <a href='https://www.vieweet.com/'>
            <img className='onboarding-topbar-vieweet-logo' src={vieweet} alt='vieweet' />
          </a>
        </div>
        <div className='onboarding-topbar-links-section'>
          <div className='onboarding-topbar-link-no-border-below'>
            <Link to='/login'>
              <Trans id='Login' />
            </Link>
          </div>
          <div className='onboarding-topbar-link-border-below'>
            <h3>
              <Trans id='Sign up' />
            </h3>
          </div>
        </div>

        <div className='activate-account-initial-pages-container'>
          <img src={activateAccount} alt='illustration' className='forget-password-img' />
          <div className='activate-account-container'>
            {activeAccount ? (
              <React.Fragment>
                <img className='image-activated' src={accountActive} alt='Tick successful' />
                <h3>
                  <Trans id='Your account is activated' />
                </h3>
                <div className='message'>
                  <p>
                    <Trans id='Access your account to start creating 360° tours' />
                  </p>
                </div>
                <div className='a-ac-btn-container'>
                  <button className='btn-activate-account'>
                    <Trans id='Access my account' />
                  </button>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div data-testid='not-activated-text' className='title-activate-account'>
                  <Trans id='Activate your account' />
                </div>
                <div className='message'>
                  <div className='sub-text-activate-account'>
                    <Trans id='We have sent an email to activate your account to:' />{' '}
                  </div>
                  <p className='name-activate-account' data-testid='email'>
                    {form.form.email}
                  </p>
                </div>
                <Link to='/login'>
                  <div className='a-ac-btn-container-link button-hover'>
                    <Trans id='Login' />
                  </div>
                </Link>

                <a
                  className='no-received-email'
                  href='/'
                  onClick={(e: React.MouseEvent): void => {
                    e.preventDefault();
                    reSendEmail();
                  }}
                >
                  <Trans id="I haven't received an email yet" />
                </a>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <Footer isPublicRoute={true} />
    </div>
  );
};

export default ActivateAccount;
