import { Trans } from '@lingui/react';
import './footer.scss';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { useConfig } from '../user-config-context/user-config-context';
import { PopupButton } from '@typeform/embed-react';
import { useState, useEffect } from 'react';

interface IFooter {
  isPublicRoute?: boolean;
}

const Footer: React.FC<IFooter> = ({ isPublicRoute }) => {
  const config = useConfig();
  const [userEmail, setUserEmail] = useState<string>('');

  useEffect(() => {
    if (config && config.userEmail) {
      setUserEmail(config.userEmail);
    }
  }, [config]);
  return (
    <div className={`bottom-help-text ${isPublicRoute ? 'footer-fixed' : ''}`}>
      <span>
        <a
          className='footer-link'
          href='https://smartviewing.atlassian.net/servicedesk/customer/portal/5'
          target='_blank'
          rel='noreferrer'
          onClick={() => {
            //Report Custom Category and Event
            ReportCustomEvent(EnumCategory.Footer, EnumEvent.AccessSupportPortal);
          }}
        >
          <Trans id='Contact support' />
        </a>
        <span className='link-divider'> | </span>
        <a
          className='footer-link'
          href='https://onboarding.vieweet.com/'
          target='_blank'
          rel='noreferrer'
          onClick={() => {
            //Report Custom Category and Event
            ReportCustomEvent(EnumCategory.Footer, EnumEvent.ViewHelpGuides);
          }}
        >
          <Trans id='Help guides' />
        </a>
        <span className='link-divider'> | </span>
        <PopupButton id='i1LwCXAg' hidden={{ email: userEmail }} style={{ fontSize: '14px' }} className='btn-remove-default survey-btn'>
          <Trans id='Feedback' />
        </PopupButton>
      </span>
    </div>
  );
};

export default Footer;
