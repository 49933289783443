import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import backArrow from '../../../images/icons/modal-icons/back-arrow.svg';
import locationIcon from '../../../images/dashboard-icons/card-icons/icon-location.svg';
import passwordLock from '../../../images/dashboard-icons/password-lock.svg';
import leadsGeneration from '../../../images/dashboard-icons/lead-generation.svg';
// import { formatBytes } from '../../utils/bytes-converter/bytes-converter';
import displayLocation from '../../../images/dashboard-icons/display-location.svg';
import imageIconCard from '../../../images/dashboard-icons/card-icons/icon-img-card.svg';
import noImages from '../../../images/empty-states-illustrations/no-image-state.svg';
import defaultImage from '../../../images/empty.svg';
import { ReactComponent as MenuDots } from '../../../images/dashboard-icons/ic-menu-dots-inactive.svg';
import { ReactComponent as MenuDotsActive } from '../../../images/dashboard-icons/card-icons/btn-hover.svg';
import { Trans } from '@lingui/react';
import { Link } from 'react-router-dom';
import { archiveTour, deleteTour, duplicateTour, getTour, shareTour, updateTour } from '../../../api-helper/api-tours';
import LoadingSpinner from '../../utils/loading-spinner/loading-spinner';
import NavSidebar from '../../utils/nav-sidebar/nav-sidebar';
import Modal from '../../utils/modal/modal';
import Footer from '../../utils/footer/footer';
import 'intro.js/introjs.css';
import { Steps } from 'intro.js-react';
import './tours.scss';
import '../../utils/tour-views/tour-rows-new.scss';
import { downloadAllPanorama, downloadPanorama, downloadPanoramaDynamic, getPanoramas } from '../../../api-helper/api-panoramas';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { IModalContent } from '../../utils/tour-views/interface';
import timeIconCard from '../../../images/dashboard-icons/card-icons/icon-img-time.svg';
import editPen from '../../../images/viewer-icons/edit-image-name.svg';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../../utils/notifications/notification';
import { PanelHooks, TabHooks, Tabs } from '../../utils/tabs/tabs';
import DropUpMenuEditTour from '../../editing-viewer/viewer-canvas-utils/dropup-menu/dropup-menu-edit-tour';
import DownloadSingleModal from '../../utils/modal/download-single-modal';
import LinksMap from './tabs/links-map';
import Analytics from './tabs/analytics';
import LeadsGeneration from './tabs/leads-generation';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import Floorplan from './tabs/floorplan';
import linkIconCard from '../../../images/dashboard-icons/card-icons/link-icon-card.svg';
import Moment from 'react-moment';
import viewsIconCard from '../../../images/dashboard-icons/card-icons/icon-view-card.svg';
import { formatBytes } from '../../utils/bytes-converter/bytes-converter';
import iconDollhouse from '../../../images/dashboard-icons/icon-dollhouse-card.svg';
import iconFloorplan from '../../../images/dashboard-icons/icon-floorplan-card.svg';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';

const EditTour = ({ location }: RouteComponentProps<any>) => {
  const history = useHistory();
  const { handleLogoutState } = useAuth();
  const previousSearchHistory: any = location.state;
  const tour: any = location.state;
  const [currentTour, setCurrentTour] = useState<any>();
  const [onHoverDisplay, setOnHoverDisplay] = useState<string>('');
  const [activeMenu, setActiveMenu] = useState<boolean>(false);
  const tourRef = useRef<any>(null);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [panoramas, setPanoramas] = useState<any>();
  const [previousWordSearch, setPreviousWordSearch] = useState<string>('');
  const [previousPaginationPage, setPreviousPaginationPage] = useState<number>(0);
  const [previousTourIndex, setPreviousTourIndex] = useState<number>(0);
  const [dashboardView, setDashboardView] = useState('');
  const [modalContent, setModalContent] = useState<IModalContent>({ id: '' });
  const [apiCallLoaded, setApiCallLoaded] = useState<boolean>(true);
  const [onImageLoaded, setOnImageLoaded] = useState<boolean>(false);
  const [tourHasPanoramas, setTourHasPanoramas] = useState<boolean>(false);
  const [isTooltipClicked, setIsTooltipClicked] = useState<boolean>(false);
  const [currentTooltipStep, setCurrentTooltipStep] = useState<number>(0);
  const [orderOption, setOrderOption] = useState<string>('');
  const [orderOptionName, setOrderOptionName] = useState<string>('');
  const [comingFromAnalytics, setComingFromAnalytics] = useState<boolean>();
  const inputRef = useRef<any>(null);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [tourName, setTourName] = useState<string>();
  const previewPanoramaWrapper = useRef<HTMLDivElement>(null);
  const [downloadModal, setDownloadModal] = useState<boolean>(false);
  const [currentPanorama, setCurrentPanorama] = useState<any>();
  const [tabIndex, setTabIndex] = useState<number>();

  const steps = [
    {
      element: '#tour-card',
      intro:
        'This box displays some useful information about the tour you are viewing, including the tour name, address, the number of images and file size, the number of views and the virtual tour URL. It also lists the date of creation and last update.',
      position: 'bottom',
      highlightClass: 'highlight-edit-card',
    },
    {
      element: '#edit-panoramas',
      intro: 'If you hover over a thumbnail, two buttons will appear to allow you to either edit or view the image that you have selected.',
      position: 'right',
      highlightClass: 'highlight-edit-panorama',
    },
  ];

  const onExit = () => {
    setIsTooltipClicked(false);
  };

  const onStepsCompleted = () => {
    setCurrentTooltipStep(0);
  };

  const stepsInfo = (currentStep: number) => {
    setCurrentTooltipStep(currentStep);
  };

  const activeTooltipHandler = () => {
    //Report Custom Category and Event
    ReportCustomEvent(EnumCategory.Tooltip, EnumEvent.TooltipEditTour);
    tourRef.current.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => setIsTooltipClicked(!isTooltipClicked), 500);
  };

  useEffect(() => {
    if (tabIndex === 0 || !currentTour || currentTour.analytics) {
      getPanoramaImages();
    }
  }, [tour, tabIndex]);

  useEffect(() => {
    if (panoramas && panoramas.length) {
      setTourHasPanoramas(true);
    }
    if (panoramas) {
      setOnImageLoaded(true);
    }
  }, [panoramas]);

  useEffect(() => {
    const urlPath = window.location.pathname;
    onChangeUrl(urlPath);
  }, []);

  useEffect(() => {
    // Changes tab ONLY when back button is pressed
    window.onpopstate = (e: any) => {
      onChangeUrl(e.currentTarget.location.pathname);
    };
  }, []);

  // Only called when tab in manually changed
  const changeOfTab = (index: number) => {
    setTabIndex(index);
    const params = new URLSearchParams(window.location.search);
    const tourIdFromParams = params.get('tourId');
    const imageEnv = params.get('imageEnv');
    if (index === 0 && !window.location.pathname.includes('panoramas')) {
      history.push(`/edit-tour/panoramas?tourId=${tourIdFromParams}${imageEnv ? '&imageEnv=' + imageEnv : ''}`);
    }
    if (index === 1 && !window.location.pathname.includes('links')) {
      history.push(`/edit-tour/links?tourId=${tourIdFromParams}${imageEnv ? '&imageEnv=' + imageEnv : ''}`);
    }
    if (index === 2 && !window.location.pathname.includes('floorplan')) {
      history.push(`/edit-tour/floorplan?tourId=${tourIdFromParams}${imageEnv ? '&imageEnv=' + imageEnv : ''}`);
    }
    if (index === 3 && !window.location.pathname.includes('analytics')) {
      history.push(`/edit-tour/analytics?tourId=${tourIdFromParams}${imageEnv ? '&imageEnv=' + imageEnv : ''}`);
    }
    if (index === 4 && !window.location.pathname.includes('leads-generated')) {
      history.push(`/edit-tour/leads-generated?tourId=${tourIdFromParams}${imageEnv ? '&imageEnv=' + imageEnv : ''}`);
    }
  };

  const onChangeUrl = (urlPath: string) => {
    if (urlPath.includes('panoramas')) {
      setTabIndex(0);
    } else if (urlPath.includes('links')) {
      setTabIndex(1);
    } else if (urlPath.includes('floorplan')) {
      setTabIndex(2);
    } else if (urlPath.includes('analytics')) {
      setTabIndex(3);
    } else if (urlPath.includes('leads-generated')) {
      setTabIndex(4);
    }
  };

  const getPanoramaImages = async () => {
    try {
      if (tour && tour.id) {
        setPreviousPaginationPage(tour.paginationPage);
        setPreviousWordSearch(tour.searchWord);
        setPreviousTourIndex(tour.tourIndex);
        setOrderOptionName(tour.orderOptionName);
        setOrderOption(tour.orderOption);
        setDashboardView(tour.dashboardView);
        setCurrentTour(tour);
        setTourName(tour.name);
        const panoramasResponse = await getPanoramas(tour.id);
        setPanoramas(panoramasResponse);
        setApiCallLoaded(true);
        setOrderOptionName(previousSearchHistory.orderOptionName);
        setOrderOption(previousSearchHistory.orderOption);
        setComingFromAnalytics(tour.analytics);
        if (tour.analytics) {
          // Set analytics tabs if coming from analytics page
          setTabIndex(3);
        }
      } else {
        // get from url param
        const params = new URLSearchParams(window.location.search);
        const tourIdFromParams: any = params.get('tourId');
        const getTourFromId = await getTour(tourIdFromParams || '');
        setCurrentTour(getTourFromId);
        setTourName(getTourFromId.name);
        const panoramasResponse = await getPanoramas(tourIdFromParams);
        setPanoramas(panoramasResponse);
        setApiCallLoaded(true);
      }
    } catch (error) {
      setApiCallLoaded(true);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const toggleModalHide = (): void => {
    setDisplayModal(false);
  };

  const toggleModalShow = (modalContent: IModalContent) => {
    try {
      setModalContent(modalContent);
      setDisplayModal(true);
    } catch (error) {
      toggleNotification(EnumNotificationType.Error);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const toggleNotification = (type: EnumNotificationType, message?: string) => {
    setTypeNotification(type);
    setNotificationMessage(message || linguiTranslateToString('There was an error please try again'));
    setShowNotification(true);
  };

  const deleteTourMatch = async () => {
    try {
      await deleteTour(currentTour.id);
      history.push('/', { displayDeleted: true });
    } catch (error) {
      toggleNotification(EnumNotificationType.Error);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
    toggleModalHide();
  };

  const archiveTours = async (archive: boolean) => {
    try {
      await archiveTour(currentTour.id, archive);
      history.push('/', { displayArchive: true });
    } catch (error) {
      toggleNotification(EnumNotificationType.Error);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const duplicateATour = async (tourId: string) => {
    setApiCallLoaded(false);
    const response = await duplicateService(tourId);
    if (response && response.id) {
      const message = 'Your tour has successfully been duplicated';
      toggleNotification(EnumNotificationType.Success, message);
      setApiCallLoaded(true);
    } else {
      toggleNotification(EnumNotificationType.Error);
    }
  };

  const duplicateService = async (tourId: string) => {
    try {
      const response = await duplicateTour(tourId);
      return response;
    } catch (error) {
      toggleNotification(EnumNotificationType.Error);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const shareTourLink = async (tourId: string, recipient: string[]) => {
    try {
      await shareTour(tourId, recipient);
    } catch (error) {
      toggleNotification(EnumNotificationType.Error);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const getStandaloneViewerUrl = () => {
    const standAloneViewerUrl = window.ENV.REACT_APP_STANDALONE_VIEWER_URL || process.env.REACT_APP_STANDALONE_VIEWER_URL;
    const url = standAloneViewerUrl + currentTour?.readCode;

    return url ? url : '';
  };

  const addDefaultSrc = (ev: any) => {
    ev.target.src = defaultImage;
  };

  const updateTourName = async () => {
    if (currentTour.name === tourName) {
      return;
    }
    const safeTourInput = {
      ...currentTour,
      name: tourName,
    };
    try {
      await updateTour(safeTourInput);
      const message = 'Your tour name was successfully updated';
      toggleNotification(EnumNotificationType.Success, message);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      const message = 'There was an error updating your tour name. Please try again';
      toggleNotification(EnumNotificationType.Error, message);
    }
  };

  const removeAnySpecialCharacters = (fileName: string) => {
    // eslint-disable-next-line
    return fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<> \{\}\[\]\\\/]/gi, '');
  };

  const downloadAllPanoramas = async () => {
    setApiCallLoaded(false);
    try {
      const responseData = await downloadAllPanorama(currentTour.id);
      // Get file name
      const filename = removeAnySpecialCharacters(currentTour.name) + '.zip';
      const url = window.URL.createObjectURL(new Blob([responseData.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setApiCallLoaded(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setApiCallLoaded(true);
    }
  };

  const downloadSingle2D = async (panoramaId: string, coordinates: number[]) => {
    setApiCallLoaded(false);
    try {
      const responseData = await downloadPanoramaDynamic(panoramaId, coordinates[0], coordinates[1], coordinates[2]);
      // Getting file name from header
      const filename = responseData.headers['content-disposition'].split('filename=')[1];
      const url = window.URL.createObjectURL(new Blob([responseData.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setApiCallLoaded(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setApiCallLoaded(true);
    }
  };

  const downloadSingle360 = async (panoramaId: string) => {
    setApiCallLoaded(false);
    try {
      const responseData = await downloadPanorama(panoramaId);
      // Getting file name from header
      const filename = responseData.headers['content-disposition'].split('filename=')[1];
      const url = window.URL.createObjectURL(new Blob([responseData.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setApiCallLoaded(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setApiCallLoaded(true);
    }
  };

  const toggleModal = (panorama?: any) => {
    setCurrentPanorama(panorama);
    setDownloadModal(!downloadModal);
  };

  return (
    <React.Fragment>
      {showNotification && (
        <Notification
          showNotification={showNotification}
          size={EnumNotificationSize.Medium}
          type={typeNotification || EnumNotificationType.Info}
          message={notificationMessage}
          setShowNotification={setShowNotification}
        />
      )}
      <Steps
        enabled={isTooltipClicked}
        steps={steps}
        initialStep={currentTooltipStep}
        onAfterChange={stepsInfo}
        onComplete={onStepsCompleted}
        onExit={onExit}
        options={{ hideNext: false, exitOnOverlayClick: false, showStepNumbers: false, showBullets: false, disableInteraction: true }}
      />
      {downloadModal && (
        <DownloadSingleModal
          tour={currentTour}
          currentPanorama={currentPanorama}
          toggleModal={toggleModal}
          downloadSingle2D={downloadSingle2D}
        />
      )}
      <NavSidebar isTooltipActive={tourHasPanoramas} activeTooltipHandler={activeTooltipHandler} isToolTipClicked={isTooltipClicked} />
      <div className='dashboard-layout'>
        <LoadingSpinner loaded={apiCallLoaded} />
        <div ref={tourRef} className='dashboard'>
          <div className='content content-wrapper'>
            {displayModal && (
              <Modal
                hideModal={toggleModalHide}
                modalContent={modalContent}
                deleteTourMatch={deleteTourMatch}
                shareTourLink={shareTourLink}
              />
            )}
            <div className='arrow-back'>
              <Link
                to={{
                  pathname: comingFromAnalytics ? '/analytics' : '/',
                  state: {
                    tourIndex: previousTourIndex,
                    pagination: previousPaginationPage,
                    wordSearch: previousWordSearch,
                    orderOptionName: orderOptionName,
                    orderOption: orderOption,
                    dashboardView: dashboardView,
                  },
                }}
              >
                <div className='go-back-edit-tour'>
                  <img className='back-icon' src={backArrow} alt='back-arrow' />
                  <Trans
                    id='Back to previous page'
                    render={({ translation }) => <div className='back-edit-tour-text'>{translation}</div>}
                  />
                </div>
              </Link>
            </div>
            <div className='contain-arrow'>
              <div className='tour-row-edit' id='tour-card'>
                <div className='row-image'>
                  {currentTour && (
                    <div className='lock-leads-icons'>
                      {currentTour?.dollhouseExist && (
                        <button style={{ cursor: 'default' }} className='icon-on-card'>
                          <img title={linguiTranslateToString('Dollhouse exist')} src={iconDollhouse} alt='Dollhouse exist' />
                        </button>
                      )}
                      {currentTour?.shareLocation === '1' && (
                        <button style={{ cursor: 'default' }} className='icon-on-card'>
                          <img title={linguiTranslateToString('Location displayed')} src={displayLocation} alt='Location displayed' />
                        </button>
                      )}

                      {currentTour?.floorplanExist && (
                        <button style={{ cursor: 'default' }} className='icon-on-card'>
                          <img title={linguiTranslateToString('Floor plan')} src={iconFloorplan} alt='Floor plan' />
                        </button>
                      )}
                      {currentTour?.protected && (
                        <button style={{ cursor: 'default' }} className=' icon-on-card'>
                          <img title={linguiTranslateToString('Password protected')} src={passwordLock} alt='Padlock password protect' />
                        </button>
                      )}

                      {currentTour?.enableLeadGenerationForm === '1' && (
                        <button style={{ cursor: 'default' }} className='icon-on-card'>
                          <img title={linguiTranslateToString('Leads generation')} src={leadsGeneration} alt='Leads generation' />
                        </button>
                      )}
                    </div>
                  )}
                  <img className='img-row-view' onError={addDefaultSrc} src={currentTour?.mainPhotoUrl || defaultImage} alt='main' />
                </div>
                <div className='info-row-view'>
                  <div className='container-title-info'>
                    <div id='tour-name' className='tour-row-address-edit'>
                      <input
                        type='text'
                        style={{
                          width: `${tourName && tourName?.length}ch`,
                        }}
                        ref={inputRef}
                        className='edit-tour-name tour-row-address'
                        value={tourName || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTourName(e.target.value)}
                        onBlur={() => updateTourName()}
                      />
                      <button
                        className='btn-remove-default'
                        onClick={() => {
                          inputRef?.current.focus();
                        }}
                      >
                        <img src={editPen} alt='pen' />
                      </button>
                    </div>
                    <div className='container-info-tour'>
                      <div className='card-sub-info' style={{ paddingLeft: '0' }}>
                        <img className='img-icon' src={imageIconCard} alt='Tour number of images icon' />

                        <Trans
                          id='{numImages} images ({tourSize})'
                          values={{ numImages: currentTour?.panoramaCount, tourSize: formatBytes(currentTour?.tourSize || 0) }}
                          render={({ translation }) => <p className='text-sub-info'>{translation}</p>}
                        />
                      </div>
                      <div className='card-sub-info' style={{ borderRight: '0.5px solid #757575', borderLeft: '0.5px solid #757575' }}>
                        <img className='time-icon' src={timeIconCard} alt='last updated' />
                        <span className='text-sub-info'>
                          <Trans
                            id=' <0>{updatedTour}</0> at <1>{updatedTour}</1>'
                            values={{ updatedTour: currentTour?.updatedAt }}
                            components={{ 0: <Moment format='DD/MM/YY'></Moment>, 1: <Moment format='HH:mm'></Moment> }}
                          />
                        </span>
                      </div>
                      <div className='card-sub-info'>
                        <img className='view-icon' src={viewsIconCard} alt='Tour number of views icon' />
                        <Trans
                          id='{numViews} views'
                          values={{ numViews: currentTour?.views }}
                          render={({ translation }) => <p className='text-sub-info'>{translation}</p>}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='container-bottom-info'>
                    <div className='container-left-info-edit'>
                      <div className='info-details-location'>
                        <img className='details-icons' src={locationIcon} alt='location' />
                        <div className='text-info-bottom-edit'>{currentTour?.name}</div>
                      </div>
                      <div className='info-url'>
                        <img className='details-icons' src={linkIconCard} alt='location' />
                        <a
                          title={getStandaloneViewerUrl()}
                          className='text-info-bottom-edit'
                          href={getStandaloneViewerUrl()}
                          rel='noreferrer'
                          target='_blank'
                        >
                          {getStandaloneViewerUrl()}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'btns-row-view'}>
                  <div className='three-dot-options-dollhouse'>
                    <div className={!onHoverDisplay ? 'circle-container-dot' : 'cirlce-container-focus'}>
                      <div className='dots-dropdown-parent-row'>
                        <div className='dots-dropdown'>
                          <button
                            className='remove-default'
                            onClick={() => {
                              setActiveMenu(true);
                              setOnHoverDisplay(currentTour?.id);
                            }}
                            onMouseLeave={() => setActiveMenu(false)}
                          >
                            <div id='dots-menu' className='dots-btn'>
                              {onHoverDisplay === currentTour?.id ? <MenuDotsActive /> : <MenuDots />}
                            </div>
                          </button>
                          <div
                            onClick={() => {
                              setActiveMenu(!activeMenu);
                              setOnHoverDisplay(currentTour?.id);
                            }}
                            onMouseLeave={() => setOnHoverDisplay('')}
                            className={`dots-dropdown-content ${onHoverDisplay === currentTour?.id ? 'dots-dropdown-content-active' : ''}`}
                          >
                            <div className='dots-dropdown-menu'>
                              <span>
                                <button
                                  id='share-edit'
                                  onClick={() => {
                                    const modalContent = {
                                      id: 'share-tour',
                                      title: linguiTranslateToString('Share your virtual tour'),
                                      tour: currentTour ? currentTour : '',
                                    };
                                    toggleModalShow(modalContent);
                                  }}
                                  className='remove-default dots-options'
                                >
                                  <div className='icon-share-dropdown' />
                                  <Trans id='Share' render={({ translation }) => <p className='dots-options-text'>{translation}</p>} />
                                </button>
                              </span>
                              <span>
                                <button className='remove-default dots-options' onClick={() => duplicateATour(currentTour?.id)}>
                                  <div className='icon-duplicate-dropdown' />
                                  <Trans id='Duplicate' render={({ translation }) => <p className='dots-options-text'>{translation}</p>} />
                                </button>
                              </span>
                              <span>
                                <button className='remove-default dots-options' onClick={() => downloadAllPanoramas()}>
                                  <div className='icon-download-dropdown' />
                                  <Trans
                                    id='Download tour'
                                    render={({ translation }) => <p className='dots-options-text'>{translation}</p>}
                                  />
                                </button>
                              </span>
                              <span>
                                {!currentTour?.protected ? (
                                  <button
                                    id='password-protected-edit'
                                    className='remove-default dots-options'
                                    onClick={() => {
                                      const modalContent = {
                                        id: 'password-protect',
                                        title: linguiTranslateToString('Protect with password'),
                                        tourId: currentTour?.id ? currentTour.id : '',
                                        tourName: currentTour?.name ? currentTour.name : '',
                                      };

                                      toggleModalShow(modalContent);
                                    }}
                                  >
                                    <div className='icon-lock-dropdown' />
                                    <Trans
                                      id='Password protect'
                                      render={({ translation }) => <p className='dots-options-text'>{translation}</p>}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    id='password-none-edit'
                                    className='remove-default dots-options'
                                    onClick={() => {
                                      const modalContent = {
                                        id: 'remove-password-protect',
                                        title: linguiTranslateToString('Remove password'),
                                        tourId: currentTour?.id ? currentTour.id : '',
                                        tourName: currentTour?.name ? currentTour.name : '',
                                      };
                                      toggleModalShow(modalContent);
                                    }}
                                  >
                                    <div className='icon-open-lock-dropdown' />
                                    <Trans
                                      id='Remove password'
                                      render={({ translation }) => <p className='dots-options-text'>{translation}</p>}
                                    />
                                  </button>
                                )}
                              </span>
                              <span>
                                <button className='remove-default dots-options' onClick={() => archiveTours(true)}>
                                  <div className='icon-archive-dropdown' />
                                  <Trans
                                    id='Archive tour'
                                    render={({ translation }) => <p className='dots-options-text'>{translation}</p>}
                                  />
                                </button>
                              </span>
                              <span>
                                <button
                                  id='delete-edit'
                                  className='remove-default dots-options'
                                  onClick={() => {
                                    const modalContent = {
                                      id: 'delete-tour',
                                      title: linguiTranslateToString('Delete tour'),
                                      tourId: currentTour?.id ? currentTour.id : '',
                                      tourName: currentTour?.name ? currentTour.name : '',
                                    };
                                    toggleModalShow(modalContent);
                                  }}
                                >
                                  <div className='icon-delete-dropdown' />
                                  <Trans
                                    id='Delete tour'
                                    render={({ translation }) => <p className='dots-options-text'>{translation}</p>}
                                  />
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='go-to-studio-big'>
                    <Link
                      className='default-link link-style-adjustment'
                      to={{
                        pathname: `/editing-viewer/`,
                        search: `?tourId=${currentTour?.id}`,
                        state: { tour: currentTour, displayEditTour: true },
                      }}
                    >
                      <button
                        id='connectGoToStudio'
                        className='go-to-studio-btn'
                        onClick={() => {
                          ReportCustomEvent(EnumCategory.TourMenu, EnumEvent.OpenEditor);
                        }}
                      >
                        <div className='go-to-studio-icon'></div>
                        <Trans id='Go to studio' />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <Tabs changeOfTab={changeOfTab} tabIndex={tabIndex}>
              <div className='edit-tour-edit-images-container'>
                <div style={{ padding: '10px 34px', display: 'flex' }}>
                  <div className='edit-tour-header-tabs-container'>
                    <div style={{ cursor: 'pointer' }}>
                      <TabHooks>
                        <Trans id='Your images' />
                      </TabHooks>
                    </div>
                  </div>
                  <div className='edit-tour-header-tabs-container' id='links'>
                    <div style={{ cursor: 'pointer' }}>
                      <TabHooks>
                        <Trans id='Links map' />
                      </TabHooks>
                    </div>
                  </div>
                  <div className='edit-tour-header-tabs-container' id='floorplan'>
                    <div style={{ cursor: 'pointer' }}>
                      <TabHooks>
                        <Trans id='Floorplan' />
                      </TabHooks>
                    </div>
                  </div>
                  <div className='edit-tour-header-tabs-container' id='tour-analytics'>
                    <div style={{ cursor: 'pointer' }}>
                      <TabHooks>
                        <Trans id='Tour Analytics' />
                      </TabHooks>
                    </div>
                  </div>
                  <div className='edit-tour-header-tabs-container' id='leads-geneated'>
                    <div style={{ cursor: 'pointer' }}>
                      <TabHooks>
                        <Trans id='Leads generated' />
                      </TabHooks>
                    </div>
                  </div>
                </div>
                <PanelHooks>
                  <div className='container-download-images'>
                    {apiCallLoaded && panoramas && panoramas.length > 0 ? (
                      panoramas.map((selectedPanorama: any, i: number) => (
                        <div id='edit-panoramas' key={i} className='image-for-download-container'>
                          <Link
                            to={{
                              pathname: `/editing-viewer/`,
                              search: `?tourId=${currentTour?.id}&panoramaId=${selectedPanorama.id}`,
                              state: { tour: currentTour, displayEditTour: true },
                            }}
                          >
                            <img
                              className='image-for-download'
                              onError={addDefaultSrc}
                              src={selectedPanorama.thumbnailPreviewUrl || defaultImage}
                              alt='panoramas'
                            />
                          </Link>
                          <div className='img-label'>{selectedPanorama.name}</div>
                          <div style={{ position: 'absolute', top: '0px', left: '30px' }}>
                            <DropUpMenuEditTour
                              tour={currentTour}
                              previewPanoramaWrapper={previewPanoramaWrapper}
                              index={i}
                              panorama={selectedPanorama}
                              downloadSingle360={downloadSingle360}
                              toggleModal={toggleModal}
                            />
                          </div>
                        </div>
                      ))
                    ) : panoramas?.length === 0 && apiCallLoaded ? (
                      <div className='container-no-images'>
                        <div className='container-text-no-images'>
                          <div className='title-text-no-images'>
                            <Trans id="This tour hasn't got any images yet!" />
                          </div>
                          <div className='sub-text-no-images'>
                            <Trans
                              id='Click on the <0>Add images</0> button to upload images to your tour.'
                              components={{ 0: <strong className='text-strong'></strong> }}
                            />
                          </div>
                          <button className='button-no-images button-hover'>
                            <Link
                              className='default-link link-style-adjustment'
                              to={{
                                pathname: `/editing-viewer/`,
                                search: `?tourId=${currentTour?.id}`,
                                state: { tour: currentTour, displayEditTour: true },
                              }}
                            >
                              <Trans id='Add images' />
                            </Link>
                          </button>
                        </div>
                        <img src={noImages} className='no-images-state' alt='empty' />
                      </div>
                    ) : (
                      <LoadingSpinner loaded={onImageLoaded} />
                    )}
                  </div>
                </PanelHooks>
                <PanelHooks>
                  <div className='container-download-images'>
                    <LinksMap tour={currentTour} panoramas={panoramas} tabIndex={tabIndex} />
                  </div>
                </PanelHooks>
                <PanelHooks>
                  <Floorplan
                    setCurrentTour={setCurrentTour}
                    currentTour={currentTour}
                    panoramas={panoramas}
                    setApiCallLoaded={setApiCallLoaded}
                    toggleNotification={toggleNotification}
                  />
                </PanelHooks>
                <PanelHooks>
                  <Analytics tourId={currentTour?.id} tabIndex={tabIndex} />
                </PanelHooks>
                <PanelHooks>
                  <LeadsGeneration tourId={currentTour?.id} tabIndex={tabIndex} />
                </PanelHooks>
              </div>
            </Tabs>
          </div>
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditTour;
