import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import vieweet from '../../../images/vieweet-logo/vieweet-logo.svg';
import forgetPassword from '../../../images/empty-states-illustrations/forgot-password.svg';
import { sendResetPasswordEmail } from '../../../api-helper/api-auth';
import { ValidationUtils } from '../../utils/validations/validations';
import { Trans } from '@lingui/react';
import Footer from '../../utils/footer/footer';
import { ReportPageView, ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent, EnumPagesTitles } from '../../../google-analytics-track-list';
import './forgot-password.scss';
import { Helmet } from 'react-helmet';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';

const ForgotPassword: React.FC = () => {
  const { handleLogoutState } = useAuth();
  const [form, setForm] = useState<string>('');
  const [userInputValue, setUserInputValue] = useState<string>('');
  const [buttonDisable, setButtonDisable] = useState<boolean>(true);
  const [validationStatus, setValidationStatus] = useState<string>('none');

  useEffect(() => {
    // componentDidMount
    //Google report page
    ReportPageView(EnumPagesTitles.ForgotPasswordPage);
  }, []);

  const recoverPassword = async () => {
    if (ValidationUtils.isEmail(form)) {
      try {
        await sendResetPasswordEmail(form);
        setValidationStatus('success');
      } catch (error) {
        const err = error as Error;
        handleError(err, handleLogoutState);
        setValidationStatus('fail');
      }
    }
  };

  const handleChangeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm(e.target.value);
    setUserInputValue(e.target.value);
    setButtonDisable(ValidationUtils.isEmail(e.target.value) ? false : true);
  };

  return (
    <>
      <Helmet>
        <html lang='en' />
        <title>Forgot password</title>
        <meta
          name='description'
          content='If you have forgotten your password, you can easily reset it here so that you can access your account.'
        />
      </Helmet>
      <div className='initial-pages-bg'>
        <div className='onboarding-content-layout'>
          <div className='initial-header'>
            <a href='https://www.vieweet.com/'>
              <img className='onboarding-topbar-vieweet-logo' src={vieweet} alt='vieweet' />
            </a>
          </div>
          <div className='onboarding-topbar-links-section'>
            <div className='onboarding-topbar-link-no-border-below'>
              <Link to='/login'>
                <Trans id='Login' />
              </Link>
            </div>
            <div className='onboarding-topbar-link-no-border-below'>
              <Link to='/signup'>
                <Trans id='Sign up' />
              </Link>
            </div>
          </div>
          <div className='password-initial-pages-container'>
            <img src={forgetPassword} alt='illustration' className='forget-password-img' />
            <div className='password-form-container'>
              <form className='forgot-pwd-form'>
                <div className='forgot-password-input-section'>
                  <div className='title-forgot-password'>
                    <Trans id='Reset your password' />
                  </div>
                  <div className='sub-text-forgot-password'>
                    <Trans id='We will send you an email to reset your password' />
                  </div>
                  <label htmlFor='email' className={`label-login ${validationStatus === 'fail' ? 'label-password-error' : ''}`}>
                    <Trans id='Email' />
                  </label>
                  <input
                    value={userInputValue}
                    data-testid='email'
                    className={`login-input ${userInputValue !== '' ? 'forgot-input-active' : null} ${
                      validationStatus === 'success' ? 'forgot-input-validate' : validationStatus === 'fail' ? 'forgot-input-invalid' : ''
                    }`}
                    id='email'
                    name='email'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeForm(e);
                    }}
                    type='text'
                    onFocus={() => setValidationStatus('none')}
                  />
                </div>
                {validationStatus === 'fail' && (
                  <div className='error-email-forgot-pwd'>
                    <Trans id='your email or password is incorrect' />{' '}
                  </div>
                )}
                {validationStatus === 'success' && (
                  <div className='success-forgot-password'>
                    <Trans id='the recovery email has been sent' />
                  </div>
                )}
                <div className='btn-forgot-pwd-container'>
                  <button
                    data-testid='recover-email-button'
                    className={`btn-forgot-pwd ${buttonDisable ? 'btn-inactive-password' : 'btn-active-password button-hover'}`}
                    disabled={buttonDisable}
                    onClick={(e: React.MouseEvent): void => {
                      e.preventDefault();
                      recoverPassword();
                      ReportCustomEvent(EnumCategory.Main, EnumEvent.TriggerPasswordRecoveryEmail);
                    }}
                  >
                    <Trans id='Send recovery email' />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer isPublicRoute={true} />
      </div>
    </>
  );
};

export default ForgotPassword;
