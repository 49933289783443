export { cssClasses, hotspotMethods, EnumHotspotsSize, EnumIconStyle, EnumIcons, tripodCabs, getCssClass, getTripodCabURl, getIcon, getHotspotMethods };

enum cssClasses {
  dynamic = 'pnlm-dynamic-hotspot',
  default = 'pnlm-default-hotspot',
  door = 'pnlm-door-hotspot',
  window = 'pnlm-window-hotspot',
  wall_corner = 'pnlm-wall-corner-hotspot',
  stairs = 'pnlm-stairs-hotspot',
  tripod = 'tripod-cover',
  link = 'custom-hotspot-room',
  media = 'custom-hotspot-media',
  icon = 'custom-hotspot-icon',
  text = 'custom-hotspot-text',
  video = 'custom-hotspot-video'
}

enum hotspotMethods {
  media = 'customHotspotMediaFunc',
  icon = 'customHotspotIconFunc',
  link = 'customHotspotRoomFunc',
  tripod = 'customTripodCoverFunc',
  text = 'customHotspotTextFunc',
  video = 'customHotspotVideoFunc'
}

enum tripodCabs {
  vieweet = 'https://vieweet360-viewer.s3.eu-west-2.amazonaws.com/tripod-cap/vieweet-tripod-cap.svg',
  smartViewing = 'https://vieweet360-viewer.s3.eu-west-2.amazonaws.com/tripod-cap/smartviewing-tripod-cap.svg',
  default = 'https://vieweet360-viewer.s3.eu-west-2.amazonaws.com/tripod-cap/default-cab.svg'
}

enum EnumIcons {
  applynow = 'fa-check-square',
  arrow_3d = 'fa-arrow-alt-circle-up',
  attention = 'fa-exclamation-triangle',
  barcode = 'fa-barcode',
  bathroom = 'fa-male',
  bathroom_2 = 'fa-female',
  broken = 'fa-unlink',
  businessbanking = 'fa-building',
  cart_in = 'fa-cart-arrow-down',
  championship = 'fa-trophy',
  cracked = 'fa-unlink',
  creditcard = 'fa-credit-card',
  cricket = 'fa-genderless',
  cycling = 'fa-bicycle',
  dirty = 'fa-recycle',
  exit = 'fa-sign-out',
  floor = 'fa-window-minimize',
  football = 'fa-futbol',
  garage = 'fa-car',
  info = 'fa-info-circle',
  livechat = 'fa-comments',
  missing = 'fa-question-circle',
  mortgagecalculator = 'fa-home',
  notavailable = 'fa-times-circle',
  personalbanking = 'fa-id-card',
  premierbanking = 'fa-diamond',
  racing = 'fa-flag-checkered',
  road = 'fa-road',
  rugby = 'fa-futbol',
  security = 'fa-shield',
  ski = 'fa-snowflake',
  smoking_free = 'fa-ban',
  tag = 'fa-tag',
  tags = 'fa-tags',
  target = 'fa-crosshairs',
  tennis = 'fa-tennis-ball',
  under_construction = 'fa-exclamation-triangle',
  urgent = 'fa-calendar',
  link = 'fa-link',
  camera = 'fa-video',
  image = 'fa-images',
  arrow_1 = 'fa-arrow-alt-circle-right',
  arrow_2 = 'fa-arrow-alt-circle-left',
  arrow_3 = 'fa-arrow-alt-circle-down',
  office_1 = 'fa-briefcase',
  library = 'fa-books',
  garage_2 = 'fa-garage-car',
  office = 'fa-desktop',
  fuse_box = 'fa-bolt',
  cinema = 'fa-film',
  cinema_1 = 'fa-camera-movie',
  bathroom_3 = 'fa-bath',
  hot_tub = 'fa-hot-tub',
  washroom = 'fa-washer',
  swimming_pool = 'fa-swimmer',
  living_room = 'fa-couch',
  bar = 'fa-beer',
  gym = 'fa-dumbbell',
  tool_cupboard = 'fa-tools',
  tool_cupboard_1 = 'fa-toolbox',
  bedroom = 'fa-bed',
  dining_room = 'fa-utensils',
  golf_course = 'fa-golf-club',
  double_arrow = 'fa-angle-double-up'
}

enum EnumHotspotsSize {
  small = '30px',
  medium = '40px',
  large = '60px'
}

enum EnumIconStyle {
  solid = 'fas',
  regular = 'far',
  light = 'fal',
  duotone = 'fad'
}



function getCssClass(value: string) {
  return getEnumValueByEnumKey(cssClasses, value);
}

function getTripodCabURl(value: string) {
  return getEnumValueByEnumKey(tripodCabs, value);
}

function getIcon(value: string) {
  return getEnumValueByEnumKey(EnumIcons, value);
}

function getHotspotMethods(value: string) {
  return getEnumValueByEnumKey(hotspotMethods, value);
}

/*** Generic */

function getEnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | null {
  const keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : null;
}

function getEnumValueByEnumKey<T extends { [index: string]: string }>(myEnum: T, enumKey: string): keyof T | null {
  const keys = Object.keys(myEnum).filter(x => x == enumKey);
  return keys.length > 0 ? myEnum[keys[0]] : null;
}
