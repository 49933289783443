import { ILocalHotspotCreation } from "../editing-viewer/interaces";

type Action<T, R> =
	| { type: 'ADD_ITEM'; item: T }
	| { type: 'REMOVE_ITEM'; id: R }
	| { type: 'RESET_ITEM'; }
	| { type: 'REMOVE_SHAPE'; item: any }
	| { type: 'UPDATE_ITEM'; id: R; item: T }
	| { type: 'UPDATE_HOTSPOT_VALUE'; index: R; newColour: R; cssClass: R; hotspotColor?: R; }
	| { type: 'ADD_INITIAL'; items: Array<T> };

interface State<T> {
	data: any[T];
}

/**
 * @typeparam `T` type of the reducer state
 * @param {keyof T} key value of `U`
 * @returns {Reducer} React reducer for a stateful list of `T`
 *
 * Can be initiated like this
 * `createListReducer<Entity,id>()`
 * Where `Entity` is the type of the list and `"id"` is a property key on the type
 * that is to be used to find index in the list
 */
const listReducerAnnotationsBlur = <T, R>() => (state: State<T>, action: Action<T, R>): State<T> => {
	switch (action.type) {
		case 'ADD_ITEM':
			return {
				...state,
				data: [...state.data, action.item]
			};
		case 'REMOVE_SHAPE': {
			let filteredArray;
			const findItem = state.data.find((item: ILocalHotspotCreation[]) => item[0].id === action.item[0].id);
			// If has status 1 comes from server not remove but lower opacity
			if (findItem && findItem[0].status === 1) {
				filteredArray = state.data.map((item: ILocalHotspotCreation[]) => {
					if (item[0].id !== action.item[0].id) {
						return item;
					} else {
						let addDeleteStatus = [...item]
						addDeleteStatus[0].status = -1;
						return addDeleteStatus;
					}
				});
			} else {
				// Completely remove from list
				filteredArray = state.data.filter((item: ILocalHotspotCreation[]) => item[0].id !== action.item[0].id);
			}
			return {
				...state,
				data: filteredArray
			};
		}
		case 'RESET_ITEM': {
			return { data: [] }
		}
		case 'UPDATE_ITEM': {
			const elementsIndex = state.data.findIndex((item: any) => item.id === action.id);
			const newArray = state.data;
			newArray[elementsIndex] = action.item;
			return {
				...state,
				data: newArray
			};
		}
		case 'UPDATE_HOTSPOT_VALUE': {
			const arrayTest = state;
			arrayTest.data[action.index][0].hotspotLabel = action.newColour;
			arrayTest.data[action.index][0].cssClass = action.cssClass;
			if (action.hotspotColor) {
				arrayTest.data[action.index][0].hotspotColor = action.hotspotColor;
			}
			return arrayTest;
		}
		case 'ADD_INITIAL':
			return {
				...state,
				data: action.items
			};
		default:
			throw new Error('Action not supported');
	}
};

export { listReducerAnnotationsBlur as newCreateListReducerBlur };
