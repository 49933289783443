import React, { useEffect, useState } from 'react';
import { Tabs, TabHooks, PanelHooks } from '../../utils/tabs/tabs';
import { Trans } from '@lingui/react';
import NavSidebar from '../../utils/nav-sidebar/nav-sidebar';
import Footer from '../../utils/footer/footer';
import {
  getTopToursAnalytics,
  getViewsByCountries,
  getViewsByDevices,
  getViewsBySource,
  getTourStatsCSV,
} from '../../../api-helper/api-analytics';
import CustomSelect from '../../utils/custom-select/custom-select';
import LoadingSpinner from '../../utils/loading-spinner/loading-spinner';
import eyeViews from '../../../images/general-icons/small-eye-v2.svg';
import noAnalyticsLarge from '../../../images/empty-states-illustrations/no-analytics-large.svg';
import noLeadsLarge from '../../../images/empty-states-illustrations/no-leads-large.svg';
import './analytics.scss';
import fileDownload from 'js-file-download';
import { ReportPageView, ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent, EnumPagesTitles } from '../../../google-analytics-track-list';
import { ICountriesViews, IDevicesViews, ISourceViewedFrom, ITopTours } from '../../../api-helper/interface/interfaces';
import { Doughnut } from 'react-chartjs-2';
import { Link, useHistory } from 'react-router-dom';
import { defineMessage } from '@lingui/macro';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../../utils/notifications/notification';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import proTag from '../../../images/general-icons/pro-tag.svg';
import LeadsGenerated from './leads-generated';
import { ISidebar } from '../../editing-viewer/viewer-canvas';
import LiveSharingLeads from './live-sharing-leads';

export interface IPageSelection {
  selected: number;
}

const colours = [
  '#00269A',
  '#44BDE3',
  '#D50057',
  '#FFC400',
  '#FF7E00',
  '#AB0051',
  '#0071A9',
  '#2B2D66',
  '#3657D0',
  '#EE5C8C',
  '#005B3F',
  '#FF8164',
  '#81D5C2',
  '#304FC6',
  '#7AD0EB',
  '#F38BAD',
  '#8D90AE',
  '#81D5C2',
];

const defaultChartData = {
  datasets: [
    {
      data: [''],
      backgroundColor: colours,
      borderColor: ['#ffffff'],
      borderWidth: 1,
    },
  ],
};

export interface ILabels {
  tableTitle: JSX.Element;
  value: string;
}

const Analytics: React.FC = () => {
  const history = useHistory();
  const { handleLogoutState } = useAuth();
  const [leadsPage, setLeadsPage] = useState<number>(0);
  const [usersDataTours, setUsersDataTours] = useState<ITopTours[]>();
  const [isDataTours, setIsDataTours] = useState<boolean>(false);
  const [countriesViews, setCountriesViews] = useState<ICountriesViews[]>();
  const [devicesViews, setDevicesViews] = useState<IDevicesViews[]>();
  const [sourceViews, setSourceViews] = useState<ISourceViewedFrom[]>();
  const [noDataViews, setNoDataViews] = useState<boolean>(false);
  const [loadedContent, setLoadedContent] = useState<boolean>(true);
  const [dateRangeSelected, setDateRangeSelected] = useState<string>(linguiTranslateToString('This month'));
  const [dateRangeServiceSelected, setDateRangeServiceSelected] = useState<string>('thisMonth');
  const [showCustomDropDownDateRange, setShowCustomDropDownDateRange] = useState<boolean>(false);
  const [showCustomDropDownSortBy, setShowCustomDropDownSortBy] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>('');
  const [countriesDonutData, setCountriesDonutData] = useState(defaultChartData);
  const [devicesDonutData, setDevicesDonutData] = useState(defaultChartData);
  const [sourcesDonutData, setSourcesDonutData] = useState(defaultChartData);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState('');
  const [showCustomDropDownSortByLive, setShowCustomDropDownSortByLive] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>();
  const config = useConfig();
  const [modules, setModules] = useState<ISidebar>({
    leadGeneration: false,
    liveShare: false,
  });

  useEffect(() => {
    if (config && config.modules) {
      let sidebarTemp = { ...modules };
      for (const menu of config.modules) {
        const menuKey = Object.keys(menu);
        const key = modules[menuKey[0]];
        // if there is a config for a left sidebar option add it
        if (key !== undefined) {
          sidebarTemp[menuKey[0]] = menu[menuKey[0]];
        }
        setModules(sidebarTemp);
      }
    }
  }, [config]);

  const analyticsPanelHooksStyles = {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: '2',
  };

  const toggleCustomDropdown = (option?: boolean) => {
    if (option) {
      setShowCustomDropDownDateRange(option);
    } else {
      setShowCustomDropDownDateRange(!showCustomDropDownDateRange);
    }
  };

  const toggleCustomDropdownSortBy = (option?: boolean) => {
    if (option) {
      setShowCustomDropDownSortBy(option);
    } else {
      setShowCustomDropDownSortBy(!showCustomDropDownSortBy);
    }
  };

  const labels = [
    { tableTitle: <Trans id='Property' />, value: 'property' },
    { tableTitle: <Trans id='Name' />, value: 'name' },
    { tableTitle: <Trans id='Email' />, value: 'email' },
    { tableTitle: <Trans id='Phone number' />, value: 'phoneNumber' },
    { tableTitle: <Trans id='Created' />, value: 'created' },
    { tableTitle: <Trans id='Type of lead' />, value: 'leadType' },
  ];

  const dateRangeOptions = [
    { name: defineMessage({ message: 'This month' }), value: 'thisMonth' },
    { name: defineMessage({ message: 'Last month' }), value: 'lastMonth' },
    { name: defineMessage({ message: 'This year' }), value: 'thisYear' },
    { name: defineMessage({ message: 'Last year' }), value: 'lastYear' },
  ];

  enum EnumSelectedType {
    thisMonth = 'thisMonth',
    lastMonth = 'lastMonth',
    thisYear = 'thisYear',
    lastYear = 'lastYear',
  }

  useEffect(() => {
    // componentDidMount
    //Google report page
    ReportPageView(EnumPagesTitles.Analytics);
  }, []);

  useEffect(() => {
    // Sets tab on load
    const urlPath = window.location.pathname;
    onChangeUrl(urlPath);
  }, []);

  useEffect(() => {
    // Changes tab ONLY when back button is pressed
    window.onpopstate = (e: any) => {
      onChangeUrl(e.currentTarget.location.pathname);
    };
  }, []);

  // Only called when tab in manually changed
  const changeOfTab = (index: number) => {
    if (tabIndex !== undefined) {
      if (index === 0 && !window.location.pathname.includes('analytics')) {
        history.push('/analytics');
        setTabIndex(0);
      }
      if (index === 1 && !window.location.pathname.includes('leads-generated')) {
        history.push('/leads-generated');
        setTabIndex(1);
      }
      if (index === 2 && !window.location.pathname.includes('leads-live-sharing')) {
        history.push('/leads-live-sharing');
        setTabIndex(2);
      }
    }
  };

  const onChangeUrl = (urlPath: string) => {
    if (urlPath.includes('analytics')) {
      setTabIndex(0);
    } else if (urlPath.includes('leads-generated')) {
      setTabIndex(1);
    } else if (urlPath.includes('leads-live-sharing')) {
      setTabIndex(2);
    }
  };

  const getToursAnalyticsInfo = async () => {
    try {
      const data = await getTopToursAnalytics(dateRangeServiceSelected);
      setUsersDataTours(data.data);
      if (data.data.length > 0) {
        setIsDataTours(true);
      } else {
        toggleNotification(EnumNotificationType.Info, linguiTranslateToString('No data to display for the selected time frame'));
        setIsDataTours(false);
      }
      setLoadedContent(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoadedContent(true);
    }
  };

  const getCountriesViewedFromInfo = async () => {
    try {
      const data = await getViewsByCountries(dateRangeServiceSelected);
      setCountriesViews(data);
      const getValuesAsNumber = data.map(a => Number(a.viewsFromCountry));
      const percentageData = getDataInPercentage(getValuesAsNumber);
      const donutPieData = {
        datasets: [
          {
            data: percentageData,
            backgroundColor: colours,
            borderColor: ['#ffffff'],
            borderWidth: 1,
          },
        ],
      };
      setCountriesDonutData(donutPieData);

      setLoadedContent(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoadedContent(true);
    }
  };

  const getDataInPercentage = (values: number[]) => {
    const getSum = values.reduce((a, b) => a + b, 0);
    const percentages = values.map(a => (a / getSum) * 100);
    const getSumOfPercentages = percentages.reduce((a, b) => Math.round(a) + Math.round(b), 0);
    if (getSumOfPercentages !== 100) {
      const difference = 100 - getSumOfPercentages;
      if (difference > 0) {
        return percentages.map((a, i) => (percentages.length - 1 === i ? String(Math.round(a) + difference) : String(Math.round(a))));
      } else {
        return percentages.map((a: any, i: number) =>
          percentages.length - 1 === i ? String(Math.round(a) - difference) : String(Math.round(a)),
        );
      }
    } else {
      return percentages.map((a: any) => String(Math.round(a)));
    }
  };

  const getDevicesViewedFromInfo = async () => {
    try {
      const data = await getViewsByDevices(dateRangeServiceSelected);
      setDevicesViews(data);
      const getValuesAsNumber = data.map(a => Number(a.viewsFromDevice));
      const percentageData = getDataInPercentage(getValuesAsNumber);
      const donutPieData = {
        datasets: [
          {
            data: percentageData,
            backgroundColor: colours,
            borderColor: ['#ffffff'],
            borderWidth: 1,
          },
        ],
      };
      setDevicesDonutData(donutPieData);
      setLoadedContent(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoadedContent(true);
    }
  };

  const getSourceViewedFromInfo = async () => {
    try {
      const data = await getViewsBySource(dateRangeServiceSelected);
      setSourceViews(data);
      const getValuesAsNumber = data.map(a => Number(a.viewsFromSource));
      const percentageData = getDataInPercentage(getValuesAsNumber);
      const donutPieData = {
        datasets: [
          {
            data: percentageData,
            backgroundColor: colours,
            borderColor: ['#ffffff'],
            borderWidth: 1,
          },
        ],
      };
      setSourcesDonutData(donutPieData);
      setLoadedContent(true);
      setNoDataViews(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoadedContent(true);
    }
  };

  const downloadStatsCsv = async (e: React.MouseEvent) => {
    e.preventDefault();
    setLoadedContent(false);
    try {
      const response = await getTourStatsCSV(dateRangeServiceSelected);
      if (response && response.data) {
        const fileName =
          response.headers['content-type'] && response.headers['content-type'].indexOf('application/vnd.ms-excel') > -1
            ? 'tour_analytics.xls'
            : 'tour_analytics.csv';
        fileDownload(response.data, fileName);
      }
      setLoadedContent(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoadedContent(true);
    }
  };

  useEffect(() => {
    if (tabIndex === 0) {
      setLoadedContent(false);
      getToursAnalyticsInfo();
      getCountriesViewedFromInfo();
      getDevicesViewedFromInfo();
      getSourceViewedFromInfo();
    }
    setShowNotification(false);
  }, [dateRangeServiceSelected, leadsPage, tabIndex]);

  const googleAnalyticsStatsSelected = (selectedValue: string) => {
    switch (selectedValue) {
      case EnumSelectedType.thisMonth:
        if (currentTab === 'Leads generated') {
          ReportCustomEvent(EnumCategory.DropDownMenu, EnumEvent.ViewLeadsForCurrentMonth);
        } else if (currentTab === 'Tours Analytics') {
          ReportCustomEvent(EnumCategory.DropDownMenu, EnumEvent.ViewStatsForCurrentMonth);
        } else {
          ReportCustomEvent(EnumCategory.DropDownMenu, EnumEvent.ViewLeadsLiveSharingForCurrentMonth);
        }
        break;
      case EnumSelectedType.lastMonth:
        if (currentTab === 'Leads generated') {
          ReportCustomEvent(EnumCategory.DropDownMenu, EnumEvent.ViewLeadsForLastMonth);
        } else if (currentTab === 'Tours Analytics') {
          ReportCustomEvent(EnumCategory.DropDownMenu, EnumEvent.ViewStatsForLastMonth);
        } else {
          ReportCustomEvent(EnumCategory.DropDownMenu, EnumEvent.ViewLeadsLiveSharingForLastMonth);
        }
        break;
      case EnumSelectedType.thisYear:
        if (currentTab === 'Leads generated') {
          ReportCustomEvent(EnumCategory.DropDownMenu, EnumEvent.ViewLeadsForCurrentYear);
        } else if (currentTab === 'Tours Analytics') {
          ReportCustomEvent(EnumCategory.DropDownMenu, EnumEvent.ViewStatsForCurrentYear);
        } else {
          ReportCustomEvent(EnumCategory.DropDownMenu, EnumEvent.ViewLeadsLiveSharingForCurrentYear);
        }
        break;
      case EnumSelectedType.lastYear:
        if (currentTab === 'Leads generated') {
          ReportCustomEvent(EnumCategory.DropDownMenu, EnumEvent.ViewLeadsForLastYear);
        } else if (currentTab === 'Tours Analytics') {
          ReportCustomEvent(EnumCategory.DropDownMenu, EnumEvent.ViewStatsForLastYear);
        } else {
          ReportCustomEvent(EnumCategory.DropDownMenu, EnumEvent.ViewLeadsLiveSharingForLastYear);
        }
        break;
    }
  };

  const changeDateRangeHandler = (myValue: string) => {
    dateRangeOptions.map(option => {
      if (option.name.id === myValue) {
        setDateRangeSelected(linguiTranslateToString(String(option.name.id)));
        setDateRangeServiceSelected(option.value);
        if (loadedContent) {
          googleAnalyticsStatsSelected(option.value);
        }
      }
      return null;
    });
    toggleCustomDropdownSortBy(false);
    toggleCustomDropdown(false);
    toggleCustomDropdownSortByLive(false);
  };

  const toggleCustomDropdownSortByLive = (option?: boolean) => {
    if (option) {
      setShowCustomDropDownSortByLive(option);
    } else {
      setShowCustomDropDownSortByLive(!showCustomDropDownSortByLive);
    }
  };

  const handleLeadsPaginationSearch = (pageSelected: IPageSelection) => {
    setLeadsPage(pageSelected.selected);
  };

  const toggleNotification = (type: EnumNotificationType, message?: string) => {
    if (type === EnumNotificationType.Error) {
      setError(!error);
    }

    setLoadedContent(true);
    setTypeNotification(type);
    setNotificationMessage(message || linguiTranslateToString('There was an error please try again'));
    setShowNotification(true);
  };

  const notificationNoData = (type: string) => {
    if (type === 'tours') {
      return (
        <>
          {showNotification && (
            <Notification
              showNotification={showNotification}
              size={EnumNotificationSize.Large}
              type={typeNotification || EnumNotificationType.Info}
              message={notificationMessage}
              setShowNotification={setShowNotification}
            />
          )}

          <img src={noAnalyticsLarge} alt='illustration' className='no-analytics-large-img' />
        </>
      );
    }
    if (type === 'leads') {
      return (
        <>
          {showNotification && (
            <Notification
              showNotification={showNotification}
              size={EnumNotificationSize.Large}
              type={typeNotification || EnumNotificationType.Info}
              message={notificationMessage}
              setShowNotification={setShowNotification}
            />
          )}
          <img src={noLeadsLarge} alt='illustration' className='no-leads-large-img' />
        </>
      );
    }

    if (type === 'leadsSharing') {
      return (
        <>
          {showNotification && (
            <Notification
              showNotification={showNotification}
              size={EnumNotificationSize.Large}
              type={typeNotification || EnumNotificationType.Info}
              message={notificationMessage}
              setShowNotification={setShowNotification}
            />
          )}
          <img src={noLeadsLarge} alt='illustration' className='no-leads-large-img' />
        </>
      );
    }
  };

  const alwaysShowTooltip = {
    id: 'alwaysShowTooltip ',
    afterDraw(chart: any) {
      const { ctx } = chart;
      ctx.save();
      chart.data.datasets.forEach((dataset: any, i: number) => {
        chart.getDatasetMeta(i).data.forEach((datapoint: any, i: number) => {
          const { x, y } = datapoint.tooltipPosition();
          const label = chart.data.datasets[0].data[i];
          if (label > 2) {
            const formatted = `${label}%`;
            // label
            ctx.fillText(formatted, x - 10, y);
            ctx.font = '14px Inter';
            ctx.fillStyle = '#fff';
          }
        });
      });
    },
  };

  const getBarWidth = (index: number) => {
    if (usersDataTours) {
      const getHighest = Number(usersDataTours[0].viewsCount);
      const getPercentage = (Number(usersDataTours[index].viewsCount) / getHighest) * 100;
      return `${getPercentage}%`;
    }
  };

  return (
    <div className='dashboard-layout'>
      <NavSidebar />
      <LoadingSpinner loaded={loadedContent} />
      <div className='dashboard'>
        <div className='content-wrapper-analytics'>
          <Tabs changeOfTab={changeOfTab} tabIndex={tabIndex}>
            <div className='analytics-header'>
              <span />
              <div className='analytics-header-tabs-container'>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setCurrentTab('Tours Analytics');
                    //Report Custom Category and Event
                    ReportCustomEvent(EnumCategory.Header, EnumEvent.DisplayAnalyticsGenerated);
                  }}
                >
                  <TabHooks>
                    <div className={tabIndex === 0 ? 'btn-dashboard-active' : 'btn-dashboard-inactive'}>
                      <div className='btn-dashboard-text'>
                        <Trans id='Tours Analytics' />
                      </div>
                    </div>
                  </TabHooks>
                </div>
                <div
                  className='lead-panel'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setCurrentTab('Leads generated');
                    //Report Custom Category and Event
                    ReportCustomEvent(EnumCategory.Header, EnumEvent.DisplayLeadsGenerated);
                  }}
                >
                  <TabHooks>
                    <div className={tabIndex === 1 ? 'btn-dashboard-active' : 'btn-dashboard-inactive'}>
                      <div className='btn-dashboard-text'>
                        <Trans id='360 Tour Leads' />
                        <img style={{ marginLeft: '10px' }} src={proTag} alt='pro' />
                      </div>
                    </div>
                  </TabHooks>
                </div>
                <div
                  className='lead-panel'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setCurrentTab('Live-Sharing leads');
                    //Report Custom Category and Event
                    ReportCustomEvent(EnumCategory.Header, EnumEvent.DisplayLeadsLiveSharingGenerated);
                  }}
                >
                  <TabHooks>
                    <div className={tabIndex === 2 ? 'btn-dashboard-active' : 'btn-dashboard-inactive'}>
                      <div className='btn-dashboard-text'>
                        <Trans id='Live-Sharing leads' />
                        <img style={{ marginLeft: '10px' }} src={proTag} alt='pro' />
                      </div>
                    </div>
                  </TabHooks>
                </div>
              </div>
            </div>
            <PanelHooks inLineStyle={analyticsPanelHooksStyles}>
              <div className='analytics-tours-analytics-container'>
                <div className='container-custom-select'>
                  <div className='info-tag-title'>
                    <Trans id='Select a date range' />
                  </div>
                  <button
                    className={isDataTours ? 'button-download-stats button-hover' : 'button-download-stats-invalide'}
                    onClick={(e: React.MouseEvent) => {
                      downloadStatsCsv(e);
                      ReportCustomEvent(EnumCategory.DownloadMenu, EnumEvent.DownloadAllStats);
                    }}
                  >
                    <Trans id='Download all stats' />
                  </button>
                  <div className='controls'>
                    <div className='sort-by-date-container'>
                      <CustomSelect
                        customDropdownId={'analyticsDropdown'}
                        value={dateRangeSelected}
                        options={[
                          defineMessage({ message: 'This month' }),
                          defineMessage({ message: 'Last month' }),
                          defineMessage({ message: 'This year' }),
                          defineMessage({ message: 'Last year' }),
                        ]}
                        changeDropdownValue={changeDateRangeHandler}
                        showCustomDropDown={showCustomDropDownDateRange}
                        setShowCustomDropDown={toggleCustomDropdown}
                        customClass={`general-field-input ${showCustomDropDownDateRange ? 'general-field-focus' : ''}`}
                      />
                    </div>
                  </div>
                </div>
                {!isDataTours && loadedContent ? (
                  <div>{notificationNoData('tours')}</div>
                ) : (
                  <div className='columns'>
                    <h3 className='info-tag'>{isDataTours && <Trans id='Top 10 virtual tours' />}</h3>
                    {usersDataTours &&
                      usersDataTours.map((tour: ITopTours, index: number) => {
                        return (
                          <div key={index}>
                            <div className='item-views-info'>
                              <div style={{ flex: 2 }}>
                                <Link
                                  className='default-link link-style-adjustment'
                                  to={{
                                    pathname: '/edit-tour/panoramas',
                                    search: `?tourId=${tour.tourId}`,
                                    state: { id: tour.tourId, analytics: true },
                                  }}
                                >
                                  <div className='tour-item-text text-specs' title={tour.name}>
                                    {tour.name}
                                  </div>
                                </Link>
                              </div>
                              <div style={{ flex: 3 }} className='item-views'>
                                <img className='views-eyes' src={eyeViews} alt='Views' />
                                <div className='views-number'>
                                  <div style={{ width: '50px' }} className='text-specs'>
                                    {tour.viewsCount}
                                  </div>
                                  <Trans id='views' />
                                </div>
                                <div style={{ width: '100%' }}>
                                  <div style={{ width: getBarWidth(index) }} className='bar-chart-analytics'></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {countriesViews && loadedContent && (
                      <div className='countries-views-container'>
                        <h3 className='info-tag'>
                          <Trans id='Top 10 countries' />
                        </h3>
                        <div className='display-flex flex-space-between'>
                          <div className='countries-views'>
                            {countriesViews.map((country: ICountriesViews, index: number) => (
                              <div className='item-views-info-countries' key={index}>
                                <p className='item-text text-specs'>
                                  <span
                                    style={{ background: index >= colours.length ? colours[index - colours.length] : colours[index] }}
                                    className='dot'
                                  ></span>
                                  {country && country.userCountryName ? country.userCountryName : 'N/A'}
                                </p>
                                <div className='item-views'>
                                  <img src={eyeViews} alt='Views' className='views-eyes' />
                                  <div className='views-number'>
                                    <div style={{ width: '50px' }} className='text-specs'>
                                      {country.viewsFromCountry}
                                    </div>
                                    <Trans id='views' />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div>
                            <Doughnut
                              className='relative-position'
                              data={countriesDonutData ? countriesDonutData : defaultChartData}
                              height={300}
                              width={300}
                              plugins={[alwaysShowTooltip]}
                              options={{
                                plugins: {
                                  tooltip: {
                                    enabled: false,
                                  },
                                  legend: {
                                    display: true,
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {devicesViews && loadedContent && (
                      <div className='countries-views-container'>
                        <h3 className='info-tag'>
                          <Trans id='Which device' />
                        </h3>
                        <div className='display-flex flex-space-between'>
                          <div className='countries-views'>
                            {devicesViews.map((device: IDevicesViews, index: number) => (
                              <div className='item-views-info-countries' key={index}>
                                <p className='item-text text-specs'>
                                  <span style={{ background: colours[index] }} className='dot'></span>
                                  {device && device.device ? device.device : 'N/A'}
                                </p>
                                <div className='item-views'>
                                  <img src={eyeViews} alt='Views' className='views-eyes' />
                                  <div className='views-number'>
                                    <div style={{ width: '50px' }} className='text-specs'>
                                      {device.viewsFromDevice}
                                    </div>
                                    <Trans id='views' />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div>
                            <Doughnut
                              className='relative-position'
                              data={devicesDonutData ? devicesDonutData : defaultChartData}
                              height={300}
                              width={300}
                              plugins={[alwaysShowTooltip]}
                              options={{
                                plugins: {
                                  tooltip: {
                                    enabled: false,
                                  },
                                  legend: {
                                    display: true,
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {sourceViews && noDataViews && loadedContent && (
                      <div className='countries-views-container'>
                        <h3 className='info-tag'>
                          <Trans id='Top 10 sources' />
                        </h3>
                        <div className='display-flex flex-space-between'>
                          <div className='countries-views'>
                            {sourceViews.map((source: ISourceViewedFrom, index: number) => (
                              <div className='item-views-info-countries' key={index}>
                                <p className='item-text text-specs'>
                                  <span style={{ background: colours[index] }} className='dot'></span>
                                  {source && source.source ? source.source.replace(/(^\w+:|^)\/\//, '') : 'N/A'}
                                </p>
                                <div className='item-views'>
                                  <img src={eyeViews} alt='Views' className='views-eyes' />
                                  <div className='views-number'>
                                    <div style={{ width: '50px' }} className='text-specs'>
                                      {source.viewsFromSource}
                                    </div>
                                    <Trans id='views' />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div>
                            <Doughnut
                              className='relative-position'
                              data={sourcesDonutData ? sourcesDonutData : defaultChartData}
                              height={300}
                              width={300}
                              plugins={[alwaysShowTooltip]}
                              options={{
                                plugins: {
                                  tooltip: {
                                    enabled: false,
                                  },
                                  legend: {
                                    display: true,
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </PanelHooks>
            <PanelHooks inLineStyle={analyticsPanelHooksStyles}>
              <LeadsGenerated
                tabIndex={tabIndex}
                proAccount={modules.leadGeneration}
                dateRangeSelected={dateRangeSelected}
                changeDateRangeHandler={changeDateRangeHandler}
                showCustomDropDownSortBy={showCustomDropDownSortBy}
                toggleCustomDropdownSortBy={toggleCustomDropdownSortBy}
                loadedContent={loadedContent}
                notificationNoData={notificationNoData}
                labels={labels}
                leadsPage={leadsPage}
                handleLeadsPaginationSearch={handleLeadsPaginationSearch}
                dateRangeServiceSelected={dateRangeServiceSelected}
                setLoadedContent={setLoadedContent}
                toggleNotification={toggleNotification}
              />
            </PanelHooks>
            <PanelHooks inLineStyle={analyticsPanelHooksStyles}>
              <LiveSharingLeads
                tabIndex={tabIndex}
                proAccount={modules.liveShare}
                setLoadedContent={setLoadedContent}
                setShowNotification={setShowNotification}
                dateRangeServiceSelected={dateRangeServiceSelected}
                toggleNotification={toggleNotification}
                dateRangeSelected={dateRangeSelected}
                changeDateRangeHandler={changeDateRangeHandler}
                loadedContent={loadedContent}
                showCustomDropDownSortByLive={showCustomDropDownSortByLive}
                toggleCustomDropdownSortByLive={toggleCustomDropdownSortByLive}
                notificationNoData={notificationNoData}
                labels={labels}
              />
            </PanelHooks>
          </Tabs>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Analytics;
