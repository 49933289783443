export const parseBoolean = (str: string) => {
	if (str === null || str === undefined) {
		return false;
	}
	else if (typeof (str) === "boolean") {
		return str;
	}
	else {
		switch (str.toLowerCase().trim()) {
			case "true":
			case "yes":
			case "1": return true;
			case "false":
			case "no":
			case "0": return false;
			default: return Boolean(str);
		}
	}
}