import { ClientApi } from '@vva/front';
import { IAccountHeadersMasters, IAccountMaster } from './interface/interfaces';
import { IAdminAccount } from '../components/main/account-management/create-edit-account-master';

const clientApi = new ClientApi();

// conditional window ENV for testing with jest
clientApi.setHeaders({
	'X-Api-Key': (window.ENV && window.ENV.REACT_APP_API_KEY ? window.ENV.REACT_APP_API_KEY : null) || process.env.REACT_APP_API_KEY,
});

const searchPartnerByKeyword = async (keyword: string) => {
	const response = await clientApi.getData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/partner/admin/list?keyword=${keyword}`);
	return response.data;
};

const getMasters = async (page: number, keyword: string, order: string): Promise<IAccountHeadersMasters> => {
	const masterData = await clientApi.getDataWithHeaders(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/partner/admin/accounts?limit=10&page=${page + 1}&keyword=${keyword}&orderBy=${order}`
	);
	return { data: masterData.data.data, headers: masterData.headers };
};

const getMaster = async (adminId: string): Promise<IAccountMaster> => {
	const masterData = await clientApi.getDataWithHeaders(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/partner/admin?adminId=${adminId}`
	);
	return masterData.data.data;
};


const updateAdminAccount = async (id: string, body: any) => {
	const userData = await clientApi.putData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/partner/admin/${id}`, body);
	return userData.data
}

const createAdminAccount = async (file: any, body?: IAdminAccount) => {
	var formData = new FormData();
	formData.append("file", file, file.name);
	if(body?.adminName) formData.append("adminName", body.adminName);
	if(body?.email) formData.append("email", body.email);
	if(body?.password) formData.append("password", body.password);
	if(body?.countryId) formData.append("countryId", body.countryId);
	if(body?.apiMaxUsers !== undefined) formData.append("apiMaxUsers", String(body.apiMaxUsers));
	const userData = await clientApi.postData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/partner/admin`, formData);
	return userData.data
}

export { searchPartnerByKeyword, getMasters, getMaster, updateAdminAccount, createAdminAccount }