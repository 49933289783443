import React, { useRef, useState } from 'react';
import './image-preview.scss';
import { EnumCompletionFiles, IFilesArray } from '../../main/tour/create-tour';
import UploadingSpinner from '../loading-spinner/uploading-spinner';
import redCross from '../../../images/viewer-icons/delete-red.svg';
import greyCross from '../../../images/viewer-icons/delete-grey.svg';
import retryIcon from '../../../images/viewer-icons/retry.svg';
import greenTick from '../../../images/viewer-icons/green-tick.svg';
import editPen from '../../../images/viewer-icons/edit-image-name.svg';

interface IProps {
  filesArray: IFilesArray[];
  removeLoadedFileFromArray: (index: string) => void;
  changeUploadedPanoramaName: (index: number, e: string, updateApi?: boolean) => void;
  retryUploadSinglePanorama: (index: number) => void;
}

interface IToggleInput {
  index: null | number;
}

const ImagePreview: React.FC<IProps> = ({
  filesArray,
  removeLoadedFileFromArray,
  changeUploadedPanoramaName,
  retryUploadSinglePanorama,
}) => {
  const [deleteImage, setDeleteImage] = useState({ status: false, id: '' });
  const [hoverCross, setHoverCross] = useState({ status: false, id: '' });
  const [toggleInput, setToggleInput] = useState<IToggleInput>({ index: null });
  const toursRef = useRef<any>([]);

  return (
    <>
      {filesArray.map((file: IFilesArray, index: number) => (
        <div
          id='upload-preview'
          key={index}
          className='container-image-preview'
          onMouseEnter={() => setDeleteImage({ status: true, id: file.id })}
          onMouseLeave={() => setDeleteImage({ status: false, id: '' })}
        >
          {file.completion === EnumCompletionFiles.Error && (
            <>
              <button
                className='btn-remove-default btn-retry-general'
                onClick={() => {
                  retryUploadSinglePanorama(index);
                }}
              >
                <div className='image-error-container'></div>
                <img src={retryIcon} className='image-retry-icon' alt='cross' />
              </button>
              <button
                className='btn-remove-default btn-error-general'
                onClick={() => {
                  //   Delete panorama
                  removeLoadedFileFromArray(file.id);
                }}
              >
                {deleteImage.status && deleteImage.id === file.id ? (
                  <img src={redCross} className='image-error-icon' alt='cross' />
                ) : (
                  <img src={greyCross} className='image-error-icon' alt='cross' />
                )}
              </button>
            </>
          )}
          {file.completion === EnumCompletionFiles.Pending && (
            <div className='image-pending-container'>
              <UploadingSpinner />
            </div>
          )}
          {file.completion === EnumCompletionFiles.Completed && (
            <button
              onMouseEnter={() => setHoverCross({ status: true, id: file.id })}
              onMouseLeave={() => setHoverCross({ status: false, id: '' })}
              className='btn-remove-default btn-error-general'
              onClick={() => {
                // Delete panorama
                removeLoadedFileFromArray(file.id);
              }}
            >
              {deleteImage.status && deleteImage.id === file.id ? (
                <>
                  {hoverCross.status && hoverCross.id === file.id ? (
                    <img src={redCross} className='image-error-icon' alt='cross' />
                  ) : (
                    <img src={greyCross} className='image-error-icon' alt='cross' />
                  )}
                </>
              ) : (
                <img src={greenTick} className='image-error-icon' alt='cross' />
              )}
            </button>
          )}
          <div className='image-name-container'>
            <div>
              <img className='viewer-preview-image-upload' src={String(file.data)} alt='preview' />
            </div>
            <div className='display-flex flex-center'>
              <div className='edit-name-container'>
                <input
                  type='text'
                  ref={element => (toursRef.current[index] = element)}
                  value={file.name}
                  className='preview-image-name'
                  readOnly={toggleInput.index === index ? false : true}
                  style={{
                    width: `${
                      file.name.length < 3 ? file.name.length + 1 : file.name.length > 12 ? file.name.length - 1 : file.name.length
                    }ch`,
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeUploadedPanoramaName(index, e.target.value)}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => changeUploadedPanoramaName(index, e.target.value, true)}
                />
                {file.completion === EnumCompletionFiles.Completed && (
                  <button
                    onClick={() => {
                      toursRef?.current[index].focus();
                      setToggleInput({ index: index });
                    }}
                    className='btn-remove-default pen-edit-btn'
                  >
                    <img src={editPen} alt='pen' />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ImagePreview;
