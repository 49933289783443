import React from 'react';
import './radio-button.scss';

interface IProps {
  size?: number; // small 16 x 16 && 8 x 8 - big 26 x 26 && 13 x 13
  toggleButton: (value?: any) => void;
  typeNote?: string;
  status: boolean;
  value?: any;
}

const RadioButton: React.FC<IProps> = (props: IProps) => {
  const radioCircleHeight = props.size || 16;
  const radioCircleWidth = props.size || 16;
  const radioButtonHeight = (props.size && props.size / 2) || 8;
  const radioButtonWidth = (props.size && props.size / 2) || 8;
  return (
    <div
      className='radio-circle'
      style={{ width: `${radioCircleWidth}px`, height: `${radioCircleHeight}px` }}
      onClick={() => {
        if (props.value) {
          props.toggleButton(props.value);
        } else {
          props.toggleButton();
        }
      }}
    >
      <div
        className={` ${props.status ? 'radio-button' : ''}`}
        style={{ width: `${radioButtonWidth}px`, height: `${radioButtonHeight}px` }}
      ></div>
    </div>
  );
};

export default RadioButton;
