import React, { useEffect } from 'react';
import { getAuth, logout } from '../../api-helper/api-auth';
import { ConfigProvider } from '../utils/user-config-context/user-config-context';
import PrivateRoute from '../../auth-routes/private-routes';
import Dashboard from './dashboard/dashboard';
import Profile from './profile/profile';
import Analytics from './analytics/analytics';
import Settings from './settings/settings';
import EditTour from './tour/edit-tour';
import DownloadTour from './tour/download-tour';
import CreateTour from './tour/create-tour';
import EditingViewer from '../editing-viewer/viewer-canvas';
import AccountManagement from './account-management/account-management-canvas';
import { useAuth } from '../../auth-routes/auth-context';
import CreateEditAccountMaster from './account-management/create-edit-account-master';
import CreateEditAccount from './account-management/create-edit-account';

const Main: React.FC = () => {
  const { handleLogoutState } = useAuth();
  // Validate token
  useEffect(() => {
    const onLoadValidate = async () => {
      await validate();
    };
    onLoadValidate();
  }, []);

  // authenticate and logout if it fails
  const validate = async () => {
    try {
      await getAuth();
    } catch (error) {
      await logout();
      handleLogoutState();
    }
  };

  return (
    <ConfigProvider>
      <PrivateRoute path='/create-tour' component={CreateTour} />
      <PrivateRoute path={['/', '/live-tours', '/users-tours', '/dollhouse-tours', '/archived-tours']} component={Dashboard} />
      <PrivateRoute path={['/profile', '/security-and-account']} component={Profile} />
      <PrivateRoute path={['/analytics', '/leads-generated', '/leads-live-sharing']} component={Analytics} />
      <PrivateRoute path={['/ai-features', '/settings', '/branding', '/lead-generation', '/manage-subscription']} component={Settings} />
      <PrivateRoute
        path={['/edit-tour/panoramas', '/edit-tour/links', '/edit-tour/floorplan', '/edit-tour/analytics', '/edit-tour/leads-generated']}
        component={EditTour}
      />
      <PrivateRoute path='/edit-admin' component={CreateEditAccountMaster} />
      <PrivateRoute path='/create-admin' component={CreateEditAccountMaster} />
      <PrivateRoute path='/edit-user' component={CreateEditAccount} />
      <PrivateRoute path='/create-user' component={CreateEditAccount} />
      <PrivateRoute path='/download-tour' component={DownloadTour} />
      <PrivateRoute path={['/admin-management', '/users-management']} component={AccountManagement} />
      <PrivateRoute path={['/editing-viewer/:tourId?', '/editing-viewer']} component={EditingViewer} />
    </ConfigProvider>
  );
};

export default Main;
