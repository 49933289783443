import React, { useEffect, useState } from 'react';
import { PanelHooks, TabHooks, Tabs } from '../../utils/tabs/tabs';
import presetContrast from '../../../images/viewer-icons/preset-contrast.svg';
import imageEnhancement from '../../../images/viewer-icons/auto-enhance.svg';
import manualEditing from '../../../images/viewer-icons/manual-editing.svg';
import presetBrightness from '../../../images/viewer-icons/preset-brightness.svg';
import presetSaturation from '../../../images/viewer-icons/preset-saturation.svg';
import { PanoramaImage } from '../../utils/classes/panorama-image';
import greenTick from '../../../images/viewer-icons/green-tick.svg';
import { ReactComponent as Close } from '../../../images/viewer-icons/modal-close.svg';
import hidePresets from '../../../images/viewer-icons/hide-presets.svg';
import showPresets from '../../../images/viewer-icons/show-presets.svg';
import proTag from '../../../images/viewer-icons/pro-tag.svg';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { IPanorama } from '../../../api-helper/interface/interfaces';
import { ImageBuilder, IPresetValues } from '../../utils/classes/image-builder';
import { compareObj } from '../../utils/array-utils/array-utils';
import GetActiveTab from './get-active-tab';
import { Trans } from '@lingui/react';
import { ISidebar } from '../viewer-canvas';
import { useHistory } from 'react-router';

interface IProps {
  sidebar: ISidebar;
  imageEditStatus: boolean;
  editedImage?: string[];
  currentPanorama: IPanorama;
  resetPresets: () => void;
  reprocessAfterImageEditing: () => void;
  changeLeftSidebar: (option: string) => void;
  presetValues: IPresetValues;
  setPresetValues: (values: IPresetValues) => void;
  imageOptionsResponse: IPresetValues;
  renderImagePresets: boolean;
  setRenderImagePresets: (state: boolean) => void;
}

interface IRenderPresets {
  con?: boolean;
  mod?: boolean;
  sat?: boolean;
}

const EditImage: React.FC<IProps> = ({
  sidebar,
  imageEditStatus,
  editedImage,
  currentPanorama,
  resetPresets,
  reprocessAfterImageEditing,
  changeLeftSidebar,
  presetValues,
  setPresetValues,
  imageOptionsResponse,
  renderImagePresets,
  setRenderImagePresets,
}) => {
  const [preview, setPreview] = useState<string>();
  const [togglePreview, setTogglePreview] = useState<boolean>(false);
  const panoramaBuilder = new ImageBuilder();
  const [applyEnable, setApplyEnable] = useState<boolean>(false);
  const [resetEnable, setResetEnable] = useState<boolean>(false);
  const [presets, setPresets] = useState<IPresetValues>({});
  const [activeTab, setActiveTab] = useState<number>();
  const [renderedPresets, setRenderedPresets] = useState<IRenderPresets>({});
  const defaultSnailTrail = { left: '0px', right: '0px', width: '4px' };
  const [snailTrailWidthContrast, setSnailTrailWidthContrast] = useState(defaultSnailTrail);
  const [snailTrailWidthSat, setSnailTrailWidthSat] = useState(defaultSnailTrail);
  const [snailTrailWidthBrightness, setSnailTrailWidthBrightness] = useState(defaultSnailTrail);
  const history = useHistory();

  useEffect(() => {
    if (renderImagePresets) {
      if (activeTab === 1) {
        setRenderedPresets({ ...renderedPresets, con: true });
      } else if (activeTab === 2) {
        setRenderedPresets({ ...renderedPresets, mod: true });
      } else if (activeTab === 3) {
        setRenderedPresets({ ...renderedPresets, sat: true });
      }
    } else {
      setRenderedPresets({});
    }
  }, [renderImagePresets, activeTab]);

  useEffect(() => {
    //   Compare image options with original response to see if apply should be enabled
    if (compareObj(presetValues, imageOptionsResponse)) {
      setApplyEnable(false);
    } else {
      setApplyEnable(true);
    }

    if (Object.keys(imageOptionsResponse).length === 0) {
      setResetEnable(false);
    } else if (
      imageOptionsResponse &&
      Number(imageOptionsResponse.con) === 0 &&
      Number(imageOptionsResponse.mod) === 1 &&
      Number(imageOptionsResponse.sat) === 1
    ) {
      setResetEnable(false);
    } else {
      setResetEnable(true);
    }
  }, [presetValues, imageOptionsResponse]);

  useEffect(() => {
    // Slider snail trail feel
    if (presetValues.con) {
      const dimensionsContrast = getPositionsForSnailTrails(-50, 50, presetValues.con);
      setSnailTrailWidthContrast(dimensionsContrast);
    }
    if (presetValues.sat) {
      const dimensionsContrast = getPositionsForSnailTrails(0, 2, presetValues.sat);
      setSnailTrailWidthSat(dimensionsContrast);
    }
    if (presetValues.mod) {
      const dimensionsContrast = getPositionsForSnailTrails(0.5, 1.5, presetValues.mod);
      setSnailTrailWidthBrightness(dimensionsContrast);
    }
  }, [presetValues]);

  useEffect(() => {
    if (imageOptionsResponse) {
      setPresets(imageOptionsResponse);
    }
  }, [imageOptionsResponse]);

  useEffect(() => {
    if (imageEditStatus) {
      // Render the presets only when tab is opened
      setRenderImagePresets(true);
    }
  }, [imageEditStatus]);

  useEffect(() => {
    if (currentPanorama && currentPanorama.originalPreviewUrl) {
      setPreview(panoramaBuilder.getDynamicEnhancementUrl(currentPanorama.originalPreviewUrl, presetValues));
    }
  }, [currentPanorama, presets]);

  const applyChanges = () => {
    ReportCustomEvent(EnumCategory.Presets, EnumEvent.ApplyPresets);
    reprocessAfterImageEditing();
  };

  const autoEnhance = () => {
    if (presetValues.auto) {
      //Report Custom Category and Event
      ReportCustomEvent(EnumCategory.Presets, EnumEvent.RemoveAutoEnhance);
      setPresetValues({ ...presetValues, auto: false });
      setPresets({ ...presetValues, auto: false });
      if (!imageOptionsResponse.auto) {
        let tempPresets = { ...presetValues };
        delete tempPresets['auto'];
        setPresetValues(tempPresets);
        setPresets(tempPresets);
      }
    } else {
      //Report Custom Category and Event
      ReportCustomEvent(EnumCategory.Presets, EnumEvent.AutoEnhance);
      setPresetValues({ ...presetValues, auto: true });
      setPresets({ ...presetValues, auto: true });
    }
  };

  const resetPanel = () => {
    changeLeftSidebar('');
    let tempPresets = { ...imageOptionsResponse };
    setPresetValues(tempPresets);

    // check con
    setSnailTrailWidthContrast(tempPresets.con ? getPositionsForSnailTrails(-50, 50, tempPresets.con) : defaultSnailTrail);
    // check mod
    setSnailTrailWidthBrightness(tempPresets.mod ? getPositionsForSnailTrails(0.5, 1.5, tempPresets.mod) : defaultSnailTrail);
    // check sat
    setSnailTrailWidthSat(tempPresets.sat ? getPositionsForSnailTrails(0, 2, tempPresets.sat) : defaultSnailTrail);
  };

  const smallerPresetImage = (presets: IPresetValues) => {
    const panoramaImageBuilderSmaller = new PanoramaImage();

    const presetKey = Object.keys(presets)[0] as keyof IPresetValues;
    const presetValue = Object.values(presets)[0];

    if (renderImagePresets && editedImage !== undefined && currentPanorama?.originalPreviewUrl) {
      return (
        <button
          className='presets-btn-remove-default'
          onClick={() => {
            if (sidebar.photoEnhancement) {
              if (presetValues[presetKey] === presetValue) {
                let tempPresets: any = { ...presetValues };
                if (imageOptionsResponse[presetKey]) {
                  tempPresets[presetKey] = imageOptionsResponse[presetKey];
                } else {
                  delete tempPresets[presetKey];
                  setSnailTrailWidthContrast(tempPresets.con ? getPositionsForSnailTrails(-50, 50, tempPresets.con) : defaultSnailTrail);
                  setSnailTrailWidthBrightness(tempPresets.mod ? getPositionsForSnailTrails(0.5, 1.5, tempPresets.mod) : defaultSnailTrail);
                  setSnailTrailWidthSat(tempPresets.sat ? getPositionsForSnailTrails(0, 2, tempPresets.sat) : defaultSnailTrail);
                }
                setPresetValues(tempPresets);
                setPresets(tempPresets);
              } else {
                setPresetValues({ ...presetValues, [presetKey]: presetValue });
                setPresets({ ...presetValues, [presetKey]: presetValue });
              }
            }
          }}
        >
          {presetValues && presetValues[presetKey] === presetValue && <img className='viewer-tick' src={greenTick} alt='tick' />}
          <img
            className='preset-image'
            src={String(panoramaImageBuilderSmaller.setHeight(64).getImageThumbnail(currentPanorama, presets))}
            alt='preset'
          />
        </button>
      );
    }
  };

  const getPositionsForSnailTrails = (min: number, max: number, value: number) => {
    const middleNumber = (max + min) / 2;

    if (value < middleNumber) {
      // Increase width to the left
      const widthPercentage = Math.abs(((value - middleNumber) / (max - min)) * 100);
      return { left: `0`, right: `${widthPercentage}%`, width: `${widthPercentage}%` };
    } else {
      // Increase width to the right
      const widthPercentage = ((value - middleNumber) / (max - min)) * 100;
      return { left: `${widthPercentage}%`, right: `0`, width: `${widthPercentage}%` };
    }
  };

  return (
    <React.Fragment>
      <div className='add-annotation-title'>
        <div className='annotation-title'>
          <Trans id='Image Enhancement' />
          <img style={{ marginLeft: '16px' }} src={proTag} alt='pro' />
        </div>
        <button className='btn-remove-default close-annotation' onClick={() => resetPanel()}>
          <Close />
        </button>
      </div>
      <Tabs tabIndex={0}>
        <ul className='edit-image-tabs'>
          <>
            <div
              className='edit-image-tabs-presets'
              onClick={() => {
                //Report Custom Category and Event
                ReportCustomEvent(EnumCategory.Manual, EnumEvent.EditManually);
              }}
            >
              <TabHooks>
                <img title='Auto enhance' src={imageEnhancement} alt='auto-enhancement' />
                <div className='image-options-active' />
              </TabHooks>
            </div>
            <div className='presets-separator'></div>
            <div
              className='edit-image-tabs-presets'
              onClick={() => {
                //Report Custom Category and Event
                ReportCustomEvent(EnumCategory.Presets, EnumEvent.UsePresets);
              }}
            >
              <TabHooks>
                <img title='Contrast' src={presetContrast} alt='contrast' />
                <div className='image-options-active' />
              </TabHooks>
              <TabHooks>
                <img title='Brightness' src={presetBrightness} alt='brightness' />
                <div className='image-options-active' />
              </TabHooks>
              <TabHooks>
                <img title='Saturation' src={presetSaturation} alt='saturation' />
                <div className='image-options-active' />
              </TabHooks>
            </div>
            <div className='presets-separator'></div>
            <div
              className='edit-image-tabs-presets'
              onClick={() => {
                //Report Custom Category and Event
                ReportCustomEvent(EnumCategory.Manual, EnumEvent.EditManually);
              }}
            >
              <TabHooks>
                <img title='Manual editing' src={manualEditing} alt='manual' />
                <div className='image-options-active' />
              </TabHooks>
            </div>
          </>
        </ul>
        <div className='display-flex flex-center' style={{ position: 'relative' }}>
          {togglePreview ? (
            <img className='preview-edit' src={currentPanorama.originalPreviewUrl} alt='no-preview' />
          ) : (
            <img className='preview-edit' src={preview} alt='preset' />
          )}
          <button className='btn-remove-default preset-preview-toggle' onClick={() => setTogglePreview(!togglePreview)}>
            {togglePreview ? <img src={hidePresets} alt='show' /> : <img src={showPresets} alt='show' />}
          </button>
        </div>
        <>
          <GetActiveTab setActiveTab={setActiveTab} renderImagePresets={renderImagePresets} />
          <PanelHooks>
            {sidebar.photoEnhancement ? (
              <div className='auto-enhancement-container-tab'>
                {presetValues.auto ? (
                  <button onClick={() => autoEnhance()} className='auto-enhance-image button-hover'>
                    <Trans id='Remove auto enhance' />
                  </button>
                ) : (
                  <button onClick={() => autoEnhance()} className='auto-enhance-image button-hover'>
                    <Trans id='Auto enhance image' />
                  </button>
                )}
              </div>
            ) : (
              <div className='auto-enhancement-container-tab'>
                <button className='auto-enhance-image blur-btn-inactive'>
                  <Trans id='Auto enhance image' />
                </button>
              </div>
            )}
          </PanelHooks>
          <PanelHooks>
            <div style={{ fontSize: '12px', textAlign: 'center', color: '#757575', margin: '15px' }}>
              <Trans id='Select your preset below' />
            </div>
            {renderedPresets.con ? (
              <div className='preset-container'>
                {smallerPresetImage({ con: -15 })}
                {smallerPresetImage({ con: -10 })}
                {smallerPresetImage({ con: -5 })}
                {smallerPresetImage({ con: 5 })}
                {smallerPresetImage({ con: 10 })}
                {smallerPresetImage({ con: 15 })}
              </div>
            ) : (
              <></>
            )}
          </PanelHooks>
          <PanelHooks>
            <div style={{ fontSize: '12px', textAlign: 'center', color: '#757575', margin: '15px' }}>
              <Trans id='Select your preset below' />
            </div>
            {renderedPresets.mod ? (
              <div className='preset-container'>
                {smallerPresetImage({ mod: 0.5 })}
                {smallerPresetImage({ mod: 0.7 })}
                {smallerPresetImage({ mod: 0.9 })}
                {smallerPresetImage({ mod: 1.1 })}
                {smallerPresetImage({ mod: 1.3 })}
                {smallerPresetImage({ mod: 1.5 })}
              </div>
            ) : (
              <></>
            )}
          </PanelHooks>
          <PanelHooks>
            <div style={{ fontSize: '12px', textAlign: 'center', color: '#757575', margin: '15px' }}>
              <Trans id='Select your preset below' />
            </div>
            {renderedPresets.sat ? (
              <div className='preset-container'>
                {smallerPresetImage({ sat: 1.1 })}
                {smallerPresetImage({ sat: 1.2 })}
                {smallerPresetImage({ sat: 1.4 })}
                {smallerPresetImage({ sat: 1.6 })}
                {smallerPresetImage({ sat: 1.8 })}
                {smallerPresetImage({ sat: 2.0 })}
              </div>
            ) : (
              <></>
            )}
          </PanelHooks>
          <PanelHooks>
            <div className='edit-panel'>
              <div className='manual-editing'>
                <div className='label-editing-image'>
                  <div>
                    <Trans id='Contrast' />
                  </div>
                  <div className='manual-image-edit'>{presetValues?.con !== undefined ? presetValues.con : 0}</div>
                </div>
                <input
                  value={presetValues?.con !== undefined ? presetValues.con : 0}
                  type='range'
                  min='-50'
                  max='50'
                  className='input-con'
                  onMouseUp={(e: React.ChangeEvent<any>) => {
                    const contrast = +e.target.value;
                    if (imageOptionsResponse.con) {
                      setPresets({ ...presetValues, con: contrast });
                    } else {
                      if (contrast === 0) {
                        let tempPresets = { ...presetValues };
                        delete tempPresets['con'];
                        setPresetValues(tempPresets);
                      } else {
                        setPresetValues({ ...presetValues, con: contrast });
                      }
                    }
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (sidebar.photoEnhancement) {
                      const dimensions = getPositionsForSnailTrails(
                        Number(e.target.min),
                        Number(e.target.max),
                        Number(e.target.valueAsNumber),
                      );
                      setSnailTrailWidthContrast(dimensions);

                      const contrast = +e.target.value;
                      setPresetValues({ ...presetValues, con: contrast });
                    }
                  }}
                />
                <div className='range-custom'></div>
                <div
                  className='range-custom-snail-trail'
                  style={{ width: snailTrailWidthContrast.width, left: snailTrailWidthContrast.left, right: snailTrailWidthContrast.right }}
                ></div>
              </div>
              <div className='manual-editing'>
                <div className='label-editing-image'>
                  <div>
                    <Trans id='Brightness' />
                  </div>
                  <div className='manual-image-edit'>{presetValues?.mod !== undefined ? presetValues.mod : 1}</div>
                </div>
                <input
                  type='range'
                  min='0.5'
                  max='1.5'
                  step='0.1'
                  className='input-mod'
                  value={presetValues?.mod !== undefined ? presetValues.mod : 1}
                  onMouseUp={(e: React.ChangeEvent<any>) => {
                    const brightness = +e.target.value;
                    if (imageOptionsResponse.mod) {
                      setPresets({ ...presetValues, mod: brightness });
                    } else {
                      if (brightness === 1) {
                        let tempPresets = { ...presetValues };
                        delete tempPresets['mod'];
                        setPresetValues(tempPresets);
                      } else {
                        setPresetValues({ ...presetValues, mod: brightness });
                      }
                    }
                    setPresets({ ...presetValues, mod: brightness });
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (sidebar.photoEnhancement) {
                      const dimensions = getPositionsForSnailTrails(
                        Number(e.target.min),
                        Number(e.target.max),
                        Number(e.target.valueAsNumber),
                      );
                      setSnailTrailWidthBrightness(dimensions);

                      const brightness = +e.target.value;
                      setPresetValues({ ...presetValues, mod: brightness });
                    }
                  }}
                />

                <div className='range-custom'></div>
                <div
                  className='range-custom-snail-trail'
                  style={{
                    width: snailTrailWidthBrightness.width,
                    left: snailTrailWidthBrightness.left,
                    right: snailTrailWidthBrightness.right,
                  }}
                ></div>
              </div>
              <div className='manual-editing'>
                <div className='label-editing-image'>
                  <div>
                    <Trans id='Saturation' />
                  </div>
                  <div className='manual-image-edit'>{presetValues?.sat !== undefined ? presetValues.sat : 1}</div>
                </div>
                <input
                  type='range'
                  min='0'
                  max='2'
                  step='0.1'
                  className='input-sat'
                  value={presetValues?.sat !== undefined ? presetValues.sat : 1}
                  onMouseUp={(e: React.ChangeEvent<any>) => {
                    const saturation = +e.target.value;
                    if (imageOptionsResponse.sat) {
                      setPresets({ ...presetValues, sat: saturation });
                    } else {
                      if (saturation === 1) {
                        let tempPresets = { ...presetValues };
                        delete tempPresets['sat'];
                        setPresetValues(tempPresets);
                      } else {
                        setPresetValues({ ...presetValues, sat: saturation });
                      }
                    }
                    setPresets({ ...presetValues, sat: saturation });
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (sidebar.photoEnhancement) {
                      const dimensions = getPositionsForSnailTrails(
                        Number(e.target.min),
                        Number(e.target.max),
                        Number(e.target.valueAsNumber),
                      );
                      setSnailTrailWidthSat(dimensions);

                      const saturation = +e.target.value;
                      setPresetValues({ ...presetValues, sat: saturation });
                    }
                  }}
                />
                <div className='range-custom'></div>
                <div
                  className='range-custom-snail-trail'
                  style={{ width: snailTrailWidthSat.width, left: snailTrailWidthSat.left, right: snailTrailWidthSat.right }}
                ></div>
              </div>
            </div>
          </PanelHooks>
        </>
      </Tabs>
      {sidebar.photoEnhancement ? (
        <div className='blur-toggle-image' style={{ position: 'absolute', bottom: '25px' }}>
          {resetEnable ? (
            <button
              className='apply-layer-button button-hover apply-layer-active-apply-active'
              onClick={() => {
                ReportCustomEvent(EnumCategory.Presets, EnumEvent.ResetsToOriginal);
                resetPresets();
                setPresets({});
                setSnailTrailWidthContrast(defaultSnailTrail);
                setSnailTrailWidthBrightness(defaultSnailTrail);
                setSnailTrailWidthSat(defaultSnailTrail);
              }}
            >
              <Trans id='Reset to original' />
            </button>
          ) : (
            <button style={{ cursor: 'default' }} className='apply-layer-button-inactive '>
              <Trans id='Reset to original' />
            </button>
          )}
          {applyEnable ? (
            <button className='apply-layer-button button-hover apply-layer-active-apply-active' onClick={() => applyChanges()}>
              <Trans id='Save changes' />
            </button>
          ) : (
            <button style={{ cursor: 'default' }} className='apply-layer-button apply-layer-disable'>
              <Trans id='Save changes' />
            </button>
          )}
        </div>
      ) : (
        <>
          <div className='blur-toggle-image' style={{ position: 'absolute', bottom: '71px' }}>
            <button
              className='autoblur-btn button-hover'
              style={{ background: '#D50057' }}
              onClick={() => history.push('/manage-subscription')}
            >
              <Trans id='Upgrade to Pro' />
            </button>
          </div>
          <div className='blur-toggle-image' style={{ position: 'absolute', bottom: '25px' }}>
            <button className='apply-layer-button '>
              <Trans id='Reset to original' />
            </button>
            <button className='apply-layer-button blur-btn-inactive'>
              <Trans id='Save changes' />
            </button>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default EditImage;
