import { IDollhouseOrder, ITourDollhouse, IToursHeaders } from './interface/interfaces';
import { ClientApi } from '@vva/front';
import { EnumDollhouseStatus } from '../components/utils/tour-views/interface';

const clientApi = new ClientApi();

// conditional window ENV for testing with jest
clientApi.setHeaders({
	'X-Api-Key': (window.ENV && window.ENV.REACT_APP_API_KEY ? window.ENV.REACT_APP_API_KEY : null) || process.env.REACT_APP_API_KEY,
});

export const getDollhouseTours = async (page: number, keyword: string, order: string): Promise<IToursHeaders> => {
	const params = new URLSearchParams(window.location.search);
	const imageEnv = params.get('imageEnv');

	const response = await clientApi.getDataWithHeaders(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/dollhouses?limit=9&page=${page + 1}&keyword=${keyword}&orderBy=${order}&imageEnv=${imageEnv}`,
	);
	return { data: response.data, headers: response.headers };
};

export const reassignDollhouse = async (tourId: string, email: string): Promise<ITourDollhouse> => {
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/dollhouses/${tourId}/assign`, {
		email,
	}
	);
	return response.data;
};

export const manageDollhouseStatus = async (tourId: string, status: EnumDollhouseStatus): Promise<ITourDollhouse> => {
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/dollhouses/${tourId}/manageStatus`, {
		status,
	}
	);
	return response.data;
};


export const orderDollhouse = async (tourId: string, backUrl: string, currency: string): Promise<IDollhouseOrder> => {
	const userData = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/dollhouses/${tourId}/order`,
		{ backUrl, currency });
	return userData.data;
}