import './custom-select.scss';
import arrow from '../../../images/dashboard-icons/select-arrow.svg';
import whiteArrow from '../../../images/dashboard-icons/white-arrow.svg';
import arrowDisabled from '../../../images/dashboard-icons/dropdown-arrow-disabled.svg';
import { useEffect, useRef, useState } from 'react';
import { Trans } from '@lingui/react';

interface IProps {
  value: string | number;
  options: any[];
  changeDropdownValue: (value: any) => void;
  showCustomDropDown: boolean;
  setShowCustomDropDown: (option?: boolean) => void;
  customClass?: string;
  arrowStyle?: string;
  customDropdown?: string;
  customDropdownOption?: string;
  customArrow?: string;
  customZIndex?: number;
  customDropdownId?: string;
  placeholder?: string;
  showWhiteArrow?: boolean;
  master?: boolean;
  disableArrow?: boolean;
}

const CustomSelect: React.FC<IProps> = ({
  value,
  options,
  changeDropdownValue,
  showCustomDropDown,
  setShowCustomDropDown,
  customClass,
  arrowStyle,
  customDropdown,
  customDropdownOption,
  customArrow,
  customZIndex,
  customDropdownId,
  placeholder,
  showWhiteArrow,
  master,
  disableArrow,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [customIndexZ, setCustomIndexZ] = useState<number>(2);

  useEffect(() => {
    if (customZIndex !== undefined) {
      setCustomIndexZ(customZIndex);
    }
  }, [customZIndex]);

  useEffect(() => {
    const onClick = (e: { target: any }) => {
      // If the active element exists and is clicked outside of
      if (wrapperRef.current !== null && !wrapperRef.current.contains(e.target)) {
        setShowCustomDropDown(false);
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (showCustomDropDown) {
      window.addEventListener('click', onClick);
    }

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [showCustomDropDown, wrapperRef]);

  return (
    <div ref={wrapperRef} style={{ position: 'relative', zIndex: customIndexZ }}>
      <button
        id={customDropdownId ? customDropdownId : 'custom-select'}
        className={customClass ? customClass : 'custom-select'}
        style={{ boxShadow: showCustomDropDown ? '' : '' }}
        onMouseDown={(e: any) => {
          e.preventDefault();
          setShowCustomDropDown();
        }}
      >
        {value || placeholder || ''}
        <img
          src={showWhiteArrow ? whiteArrow : !master ? arrowDisabled : disableArrow ? arrowDisabled : arrow}
          style={{ top: arrowStyle ? arrowStyle : '' }}
          alt='Arrow'
          className={customArrow ? customArrow : 'custom-select-arrow'}
        />
      </button>
      <div>
        <div
          className={customDropdown ? customDropdown : 'custom-dropdown'}
          style={{ visibility: showCustomDropDown ? 'visible' : 'hidden' }}
        >
          {options.map((option, index) => {
            return (
              <button
                id={option?.id ? option.id.toLowerCase().replace(/\s/g, '') : option}
                key={index}
                style={{ cursor: showCustomDropDown ? 'pointer' : 'default' }}
                className={customDropdownOption ? customDropdownOption : 'custom-dropdown-option'}
                onMouseDown={(e: any) => {
                  e.preventDefault();
                  changeDropdownValue(option?.id || option);
                }}
              >
                {option?.id ? <Trans id={option?.id} /> : option}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CustomSelect;
