
import { ClientApi } from '@vva/front';

const clientApi = new ClientApi();
// conditional window ENV for testing with jest
clientApi.setHeaders({
	'X-Api-Key':
		(window.ENV && window.ENV.REACT_APP_API_KEY ? window.ENV.REACT_APP_API_KEY : null) || process.env.REACT_APP_API_KEY
});

const getHotspots = async (panoramaId: string): Promise<any> => {
	const response = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/hotspots?panoramaId=${panoramaId}`
	);
	return response.data;
};

const getHotspotsPlatform = async (panoramaId: string): Promise<any> => {
	const response = await clientApi.getData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/hotspots/${panoramaId}/platform`
	);
	return response.data;
};

const updateHotspots = async (panoramaData: any): Promise<any> => {
	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/hotspots/edit/${panoramaData.id}`, panoramaData
	);
	return response.data;
};

const updateHotspotsDestination = async (oldHotspotId: string, panoramaData: any): Promise<any> => {
	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/hotspots/${oldHotspotId}/destination`, panoramaData
	);
	return response.data;
};


const createHotspot = async (hotspotBody: any): Promise<any> => {
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/hotspots`, hotspotBody);
	return response.data;
};

const createCustomHotspot = async (hotspotBody: any): Promise<any> => {
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/hotspots/createCustom`, hotspotBody);
	return response.data;
};

const updateCustomHotspotNote = async (hotspotBody: any): Promise<any> => {
	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/hotspots/updateCustomMediaNote`, hotspotBody);
	return response.data;
};

const updateCustomHotspotMedia = async (hotspotBody: any, file: any): Promise<any> => {
	var formData = new FormData();
	if (file && file.name) {
		formData.append("file", file, file.name);
	}
	formData.append("id", hotspotBody.id);
	formData.append("type", hotspotBody.type);
	formData.append("name", hotspotBody.name);
	formData.append("image", hotspotBody.image);
	formData.append("description", hotspotBody.description);
	formData.append("attributes", JSON.stringify(hotspotBody.attributes));

	const response = await clientApi.putData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/hotspots/updateCustomMedia`, formData);
	return response.data;
};

const createCustomMediaHotspot = async (hotspotBody: any, file: any): Promise<any> => {
	var formData = new FormData();
	formData.append("file", file, file.name);
	formData.append("type", hotspotBody.type);
	formData.append("name", hotspotBody.name);
	formData.append("image", hotspotBody.image);
	formData.append("description", hotspotBody.description);
	formData.append("srcId", hotspotBody.srcId);
	formData.append("phi", hotspotBody.phi);
	formData.append("theta", hotspotBody.theta);
	formData.append("attributes", JSON.stringify(hotspotBody.attributes));
	formData.append("destId", hotspotBody.destId);
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/hotspots/createCustom`, formData);
	return response.data;
};

const deleteHotspot = async (hotpostId: string): Promise<any> => {
	const response = await clientApi.deleteData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/hotspots/${hotpostId}`
	);
	return response.data;
};


export {
	getHotspots,
	getHotspotsPlatform,
	updateHotspots,
	createHotspot,
	deleteHotspot,
	createCustomHotspot,
	updateCustomHotspotNote,
	updateCustomHotspotMedia,
	createCustomMediaHotspot,
	updateHotspotsDestination,
}