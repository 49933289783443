import React, { useState, useEffect } from 'react';
import { Trans } from '@lingui/react';
import CrossClose from '../../../images/icons/close-button.svg';
import './modal-account-management.scss';

interface IModalAccountManagementProps {
  hideModal: () => void;
  modalAction: string;
  statusAccount: (arg1: string, arg2?: string) => void;
  email: string;
}

const ModalAccountManagement: React.FC<IModalAccountManagementProps> = ({ hideModal, modalAction, statusAccount, email }) => {
  const [emailToConfirm, setEmailToConfirm] = useState<string>('');
  const [modalButtonDisabled, setModalButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (emailToConfirm === email) {
      setModalButtonDisabled(false);
    } else {
      setModalButtonDisabled(true);
    }
  }, [emailToConfirm, email]);

  const modalBackgroundLayer = <div className='full-screen' onClick={() => hideModal()}></div>;

  const disableAccount = () => {
    return (
      <React.Fragment>
        <div className='am-modal-header'>
          <Trans id='Disable account confirmation' render={({ translation }) => <p className='am-modal-header-title'>{translation}</p>} />
          <button onClick={() => hideModal()} className='remove-default am-header-close-icon'>
            <img src={CrossClose} alt='Close Modal' />
          </button>
        </div>
        <div className='am-modal-body'>
          <div>
            <Trans
              id='Are you sure you want to disable the account?'
              render={({ translation }) => <p className='am-modal-body-text'>{translation}</p>}
            />
            <Trans
              id='The account will be deactivated and user won’t be able to access the account
              anymore. You can reactivate the account anytime.'
              render={({ translation }) => <p className='am-modal-body-text'>{translation}</p>}
            />
          </div>
          <div className='modal-body-buttons'>
            <button onClick={() => hideModal()} className='remove-default modal-button button-hover'>
              Cancel
            </button>
            <button onClick={() => statusAccount(modalAction)} className='remove-default modal-button confirm-button button-hover'>
              Confirm
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const reactivateAccount = () => {
    return (
      <React.Fragment>
        <div className='am-modal-header'>
          <Trans
            id='Reactivate account confirmation'
            render={({ translation }) => <p className='am-modal-header-title'>{translation}</p>}
          />
          <button onClick={() => hideModal()} className='remove-default am-header-close-icon'>
            <img src={CrossClose} alt='Close Modal' />
          </button>
        </div>
        <div className='am-modal-body'>
          <div>
            <Trans
              id='Are you sure you want to reactivate the account?'
              render={({ translation }) => <p className='modal-body-text'>{translation}</p>}
            />
            <Trans
              id='The account will be reactivated and user will have access to the tours. You
              can disable the account again anytime.'
              render={({ translation }) => <p className='modal-body-text'>{translation}</p>}
            />
          </div>
          <div className='modal-body-buttons'>
            <button onClick={() => hideModal()} className='remove-default modal-button'>
              Cancel
            </button>
            <button onClick={() => statusAccount(modalAction)} className='remove-default modal-button confirm-button'>
              Confirm
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const deleteAccount = () => {
    return (
      <React.Fragment>
        <div className='am-modal-header'>
          <Trans id='Delete the account' render={({ translation }) => <p className='am-modal-header-title'>{translation}</p>} />
          <button onClick={() => hideModal()} className='remove-default am-header-close-icon'>
            <img src={CrossClose} alt='Close Modal' />
          </button>
        </div>
        <div className='am-modal-body-delete'>
          <div>
            <Trans
              id='Your’re about to delete this account.'
              render={({ translation }) => <p className='am-modal-body-text'>{translation}</p>}
            />
          </div>
          <div>
            <Trans
              id='This will <0>permanently delete all the virtual tours and data.</0> Deleting an account is permanent and cannot be recovered.'
              components={{ 0: <b style={{ color: 'red' }}></b> }}
              render={({ translation }) => <p className='am-modal-body-text'>{translation}</p>}
            />
          </div>
          <div>
            <Trans
              id='To confirm, type &#60;{confirmEmail}&#62; below'
              values={{ confirmEmail: email }}
              components={{ 0: <b style={{ color: 'red' }}></b> }}
              render={({ translation }) => <p className='am-modal-body-text'>{translation}</p>}
            />
          </div>
          {/* <div> */}
          <input
            id='input-delete-account-modal'
            type='text'
            value={emailToConfirm}
            className={`confirm-email-input ${modalButtonDisabled ? 'input-default' : 'input-black'}`}
            onChange={e => {
              setEmailToConfirm(e.target.value);
            }}
          />
          {/* </div> */}
          <button
            onClick={() => statusAccount(modalAction, email)}
            disabled={modalButtonDisabled}
            className={`remove-default modal-button ${modalButtonDisabled ? 'disable-button' : 'delete-button button-hover'}`}
          >
            Delete Account
          </button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {modalBackgroundLayer}
      {/* <div className={`am-modal ${modalAction === 'delete' ? 'am-modal-delete' : ''}`}> */}
      <div className='am-modal'>
        {modalAction === 'disable'
          ? disableAccount()
          : modalAction === 'reactivate'
          ? reactivateAccount()
          : modalAction === 'delete'
          ? deleteAccount()
          : null}
      </div>
    </React.Fragment>
  );
};

export default ModalAccountManagement;
