export class EnvUtils {
  static isLocal() {
    const env = EnvUtils.getAppEnv();
    return env === 'local';
  }
  static isTest() {
    const env = EnvUtils.getAppEnv();
    return env === 'dev' || env === 'test';
  }
  static isDev() {
    const env = EnvUtils.getAppEnv();
    return env === 'dev' || env === 'local';
  }
  static isProd() {
    const env = EnvUtils.getAppEnv();
    return env === 'production' || env === 'staging';
  }
  static getAppEnv() {
    return EnvUtils.getEnvVariable('REACT_APP_ENV') || 'production';
  }
  static getAppVersion() {
    return EnvUtils.getEnvVariable('REACT_APP_VERSION') || '0.0.0';
  }
  static getCloudProvider() {
    return EnvUtils.getEnvVariable('REACT_APP_CLOUD_PROVIDER') || 'unknown';
  }
  static getAppName() {
    return EnvUtils.getEnvVariable('REACT_APP_NAME') || 'unknown';
  }
  static getEnvVariable(name: string) {
    return window.ENV[name] || process.env[name];
  }
}
