import { Trans } from '@lingui/react';
import React from 'react';
import { IHotspotCreation } from '../../../api-helper/interface/interfaces';
import { ReactComponent as Close } from '../../../images/viewer-icons/modal-close.svg';
import ListsPoints from '../../annotation/ListsPoints';

interface IProps {
  annotateActive: boolean;
  toggleAnnotation: () => void;
  hotspots: any;
  handleRemove: (list: IHotspotCreation, id: string) => void;
  handleUpdateHotspotColour: (index: number, newColour: string) => void;
  changeLeftSidebar: (option: string) => void;
}

const AddAnnotation: React.FC<IProps> = ({
  toggleAnnotation,
  annotateActive,
  hotspots,
  handleRemove,
  handleUpdateHotspotColour,
  changeLeftSidebar,
}) => {
  return (
    <React.Fragment>
      <div className='add-annotation-title'>
        <div className='annotation-title'>
          <Trans id='Annotation' />
        </div>
        <button className='btn-remove-default close-annotation' onClick={() => changeLeftSidebar('')}>
          <Close />
        </button>
      </div>
      <div className='annotation-options-container'>
        <ListsPoints
          annotateActive={annotateActive}
          hotspots={hotspots}
          handleRemove={handleRemove}
          handleUpdateHotspotColour={handleUpdateHotspotColour}
          toggleAnnotation={toggleAnnotation}
        />
      </div>
    </React.Fragment>
  );
};

export default AddAnnotation;
