import { FunctionComponent } from 'react';
import accountNotFound from '../../../images/empty-states-illustrations/account-not-found.svg';
import noAccount from '../../../images/empty-states-illustrations/no-account.svg';
import { Trans } from '@lingui/react';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';

interface IPropsaccountNotFoundCard {
  itHasData?: boolean;
  totalNumAccounts: number;
  loadedView: boolean;
  goToEditCreateAccount: () => void;
}
export const AccountNotFoundCard: FunctionComponent<IPropsaccountNotFoundCard> = (props: IPropsaccountNotFoundCard) => {
  if (!props.itHasData && props.totalNumAccounts !== 0) {
    return (
      <div className='account-not-found-container'>
        <div className='container-text-account-not-found'>
          <div className='title-account-not-found'>
            <Trans id='We cannot find your account' />
          </div>
          <div className='sub-text-account-not-found'>
            <Trans id='Refine your search from the search tab! ' />
          </div>
        </div>
        <img src={accountNotFound} alt='test' className='account-not-found-image' />
      </div>
    );
  }
  if (!props.itHasData && props.totalNumAccounts === 0) {
    return (
      <div className='no-account-container'>
        <img src={noAccount} alt='test' className='no-account-img' />
        <div className='container-text-no-account'>
          <div className='title-no-account'>
            <Trans id='You have no accounts' />
          </div>
          <div className='sub-text-no-account'>
            <Trans id='Click on the button below to start creating new accounts' />
          </div>
          <button
            className='btn-create-new-account-modal'
            onClick={() => {
              props.goToEditCreateAccount();
              ReportCustomEvent(EnumCategory.Header, EnumEvent.CreateNewAccount);
            }}
          >
            <Trans id='Create a new user' render={({ translation }) => <p className='btn-am-text'>{translation}</p>} />
          </button>
        </div>
      </div>
    );
  }
  return <></>;
};
