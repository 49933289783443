import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { IUserAccount } from '../../main/account-management/create-edit-account';
import { monthMap } from './constant';
import './date-picker.scss';
import { IDay } from './interfaces';
import { getMonthDetails } from './util-time';

interface IDatePicker {
  accountUserData?: IUserAccount;
  setAccountUserData: (state: IUserAccount) => void;
  onChange: (timestamp: string | number) => void;
  customDate?: Date;
  versionPickerRef?: MutableRefObject<HTMLDivElement>;
  setShowDatePicker: (state: boolean) => void;
  showDatePicker: boolean;
  master: boolean;
}

const DatePicker = (props: IDatePicker): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [year, setYear] = useState<number>();
  const [month, setMonth] = useState<number>();
  const [currentDate, setCurrentDate] = useState('');
  const [monthDetails, setMonthDetails] = useState<any[]>();

  useEffect(() => {
    let date = props.customDate ? new Date(props.customDate) : new Date();
    setYear(date.getFullYear());
    setMonth(date.getMonth());
  }, [props.customDate]);

  useEffect(() => {
    if (year !== undefined && month !== undefined) {
      setMonthDetails(getMonthDetails(year, month));
    }
  }, [year, month]);

  useEffect(() => {
    if (props?.customDate) {
      const expiryDateProp = new Date(props.customDate);
      const timestamp = Number(expiryDateProp.getTime());
      const dateString = getDateStringFromTimestamp(timestamp);
      setCurrentDate(dateString);
    }
  }, [props]);

  useEffect(() => {
    const toggleDatePicker = (event: Event & { target: HTMLInputElement }): void => {
      event.preventDefault();
      // If the active element exists and is clicked outside of
      const wrapper = props.versionPickerRef ? props.versionPickerRef : wrapperRef;
      if (wrapper.current !== null && !wrapper.current.contains(event.target)) {
        if (props.showDatePicker) {
          props.setShowDatePicker(false);
        }
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (props.showDatePicker) {
      window.addEventListener('click', toggleDatePicker as EventListener);
    }

    return () => {
      window.removeEventListener('click', toggleDatePicker as EventListener);
    };
  }, [props.showDatePicker, wrapperRef, props.versionPickerRef]);

  const handleShowDatePicker = () => {
    props.setShowDatePicker(!props.showDatePicker);
  };

  const getMonthStr = (month: number) => {
    return monthMap[month] ?? 'Month';
  };

  const getDateStringFromTimestamp = (timestamp: number | Date) => {
    const dateObject = new Date(timestamp);
    const month = monthMap[dateObject.getMonth()];
    const date = dateObject.getDate();

    return date + ' ' + month + ' ' + dateObject.getFullYear();
  };

  const setDateToInput = (timestamp: number) => {
    const dateString = getDateStringFromTimestamp(timestamp);
    setCurrentDate(dateString);
    props.setAccountUserData({ ...props.accountUserData, proExpiration: new Date(timestamp) });
  };

  const onDateClick = (day: IDay) => {
    setDateToInput(day.timestamp);
    props.setShowDatePicker(!props.showDatePicker);
  };

  const setMonthHelper = (offset: number) => {
    // const todayDate = new Date();
    if (month !== undefined && year !== undefined) {
      let auxYear = year;
      let auxMonth = month + offset;
      if (auxMonth === -1) {
        auxMonth = 11;
        auxYear--;
      } else if (auxMonth === 12) {
        auxMonth = 0;
        auxYear++;
      }

      setYear(auxYear);
      setMonth(auxMonth);
      setMonthDetails(getMonthDetails(auxYear, auxMonth));
    }
  };

  const renderCalendar = () => {
    let days = monthDetails?.map((day: IDay, index: number) => {
      if (day.month === 0) {
        return (
          <div className='c-day-container ' key={index}>
            <div className='cdc-day'>
              <span onClick={() => onDateClick(day)}>{day.date}</span>
            </div>
          </div>
        );
      } else {
        return (
          <div className='c-day-container-next-month' key={index}>
            <div className='cdc-day-next-month'>
              <span
                onClick={() => {
                  if (day.month === 1) {
                    setMonthHelper(1);
                  } else if (day.month === -1) {
                    setMonthHelper(-1);
                  }
                  setDateToInput(day.timestamp);
                }}
              >
                {day.date}
              </span>
            </div>
          </div>
        );
      }
    });

    return (
      <div className='c-container'>
        <div className='cc-head'>
          {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map((d, i) => (
            <div key={i} className='cch-name'>
              {d}
            </div>
          ))}
        </div>
        <div className='cc-body'>{days}</div>
      </div>
    );
  };

  return (
    <div
      ref={props.versionPickerRef ? props.versionPickerRef : wrapperRef}
      className={!props.master ? 'MyDatePickerDisabled' : 'MyDatePicker'}
    >
      <div
        className={`${
          !props.master
            ? 'general-field-disable'
            : 'general-field-input-text-date'
            ? props.showDatePicker
            : 'date-picker-active general-field-input-text-date-focus'
            ? 'date-picker-inactive'
            : ''
        }`}
        style={{ width: '100%' }}
        onClick={() => {
          handleShowDatePicker();
        }}
      >
        <div className={props.master ? 'calendar-click-disabled' : 'calendar-click'}>
          <i className='fal fa-calendar-alt calendar-icon'></i>
        </div>
        <div className='current-date'>{currentDate}</div>
      </div>
      {props.showDatePicker && (
        <div className='mdp-container'>
          <div className='mdpc-head'>
            <div className='mdpch-button'>
              <div className='mdpchb-inner' onClick={() => setMonthHelper(-1)}>
                <i className='far fa-chevron-left'></i>
              </div>
            </div>
            <div className='mdpch-container'>
              <div className='mdpchc-month'>{month !== undefined && getMonthStr(month)}</div>
              <div className='mdpchc-year'>{year}</div>
            </div>
            <div className='mdpch-button'>
              <div className='mdpchb-inner' onClick={() => setMonthHelper(1)}>
                <i className='far fa-chevron-right'></i>
              </div>
            </div>
          </div>
          <div className='mdpc-body'>{renderCalendar()}</div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
