import { IHotspot } from "@vva/pannellum-viewer/lib/interfaces/IHotspot";
import { customHotspotRoomFunc, customHotspotIconFunc } from '@vva/pannellum-viewer';

export enum EnumPanoramaSource {
	NewMobileApp = 10,
	MobileApp = 0,
	WebApp = 2,
	NewWebApp = 11
}

export enum EnumPanoramaVersion {
	OldVieweetMobileApp = 0,
	OldPixeetMobileApp = 2.03,
	WebApp = 5,
	NewWebApp = 11
}

export interface IAttributes {
	version?: number;
	mediaPath?: string;
	iconSize?: number;
	enableAutoplay?: boolean;
	isMuted?: boolean;
	enableControls?: boolean;
}

export function getHotspotType(hotspot: any) {
	if (hotspot.typeFrontViewer === 6) {
		if (hotspot.createTooltipArgs.iconType === "fa-info-circle") {
			return 'info';
		} else {
			return 'warning';
		}
	} else {
		return 'url';
	}
}

export function getHotspotLinkObject(pitch: number, yaw: number, panoramaId: number) {
	//Args
	const args: any = {
		iconColor: '#FFFFFF',
		backgroundColor: '#48C9B0',
		iconType: 'fa-angle-double-up',
		roomName: 'untitled',
	};
	//Hotspot object
	const hotspot: IHotspot = {
		pitch,
		yaw,
		type: 'info',
		id: panoramaId,
		hotspotLabel: 'link',
		cssClass: 'custom-hotspot-room',
		createTooltipFunc: customHotspotRoomFunc,
		createTooltipArgs: args,
	};

	return hotspot;
}


export function getHotspotLinkObjectNoToolTip(pitch: number, yaw: number, panoramaId: number) {
	//Args
	const args: any = {
		iconColor: '#000000',
		borderColor: '#C4C4C4',
		iconSize: '35px',
		iconType: 'fa-angle-double-up',
	};
	//Hotspot object
	const hotspot: IHotspot = {
		pitch,
		yaw,
		type: 'info',
		id: panoramaId,
		hotspotLabel: 'link',
		cssClass: 'custom-hotspot-icon',
		createTooltipFunc: customHotspotIconFunc,
		createTooltipArgs: args,
	};

	return hotspot;
}

export function getHotspotNoteObject(pitch: number, yaw: number, panoramaId: number) {
	//Args
	const args: any = {
		iconColor: '#FFFFFF',
		backgroundColor: '#48C9B0',
		iconType: 'fa-info-circle',
		roomName: 'info',
	};
	//Hotspot object
	const hotspot: IHotspot = {
		pitch,
		yaw,
		type: 'info',
		id: panoramaId,
		hotspotLabel: 'link',
		cssClass: 'custom-hotspot-room',
		// TODO - change for text function with other arguments
		createTooltipFunc: customHotspotRoomFunc,
		createTooltipArgs: args,
	};

	return hotspot;
}

export function getHotspotImageObject(pitch: number, yaw: number, panoramaId: number) {
	//Args
	const args: any = {
		iconColor: '#FFFFFF',
		backgroundColor: '#48C9B0',
		iconType: 'fa-picture-o',
		roomName: 'image',
	};
	//Hotspot object
	const hotspot: IHotspot = {
		pitch,
		yaw,
		type: 'info',
		id: panoramaId,
		hotspotLabel: 'link',
		cssClass: 'custom-hotspot-room',
		createTooltipFunc: customHotspotRoomFunc,
		createTooltipArgs: args,
	};

	return hotspot;
}

export function getHotspotAnnotationObject(pitch: number, yaw: number, id: number) {

	//Hotspot object
	const hotspot: IHotspot = {
		pitch: pitch,
		yaw: yaw,
		type: 'info',
		text: `pitch: ${pitch} - yaw: ${yaw}`,
		id: id,
		cssClass: 'pnlm-default-hotspot',
		hotspotLabel: 'default',
	};

	return hotspot;
}

export function getDestName(destId: string, panoramaList: any) {
	const findDestId = panoramaList.find((pano: any) => pano.id === destId);
	return findDestId && findDestId.name ? findDestId.name : '';
}

export function getCoordinates(currentPanorama: any, hotspotData: any) {
	let theta = hotspotData.theta;
	let phi = hotspotData.phi;

	const versionCheck = hotspotData.attributes && hotspotData.attributes.version ? hotspotData.attributes.version : null;
	if (currentPanorama.source === EnumPanoramaSource.NewMobileApp || currentPanorama.source === EnumPanoramaSource.MobileApp) {
		if (!versionCheck) {
			theta -= 90;
		}
	}

	if (currentPanorama.source === EnumPanoramaSource.WebApp) {
		if (!versionCheck) {
			phi -= phi;
		}
	}
	return [theta, phi];
}

export function getWrongYaw(panoramaVersion: EnumPanoramaVersion, yaw: number) {
	if (panoramaVersion <= EnumPanoramaVersion.OldPixeetMobileApp) {
		return yaw + 90;
	} else {
		return yaw;
	}
}

export function getCorrectedYaw(panoramaVersion: EnumPanoramaVersion, yaw: number) {
	if (panoramaVersion <= EnumPanoramaVersion.OldPixeetMobileApp) {
		return yaw - 90;
	} else {
		return yaw;
	}
}








