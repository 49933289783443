
export const subscriptionOptions = [
	{
		"option": 'Unlimited tours',
		"free": true,
		"pro": true,
	},
	{
		"option": 'Google Maps and Street View',
		"free": true,
		"pro": true,
	},
	{
		"option": 'Share your virtual tour via email or social media',
		"free": true,
		"pro": true,
	},
	{
		"option": 'Archive, delete and restore your virtual tours',
		"free": true,
		"pro": true,
	},
	{
		"option": 'Extract high-resolution 2D photos from your 360 ones',
		"free": true,
		"pro": true,
	},
	{
		"option": 'Access analytics on your tours',
		"free": true,
		"pro": true,
	},
	{
		"option": "Auto-face blurring to protect people's privacy (AI)",
		"free": false,
		"pro": true,
	},
	{
		"option": 'Auto-photo enhancement (AI)',
		"free": false,
		"pro": true,
	},
	{
		"option": 'Auto-tripod removal (AI)',
		"free": false,
		"pro": true,
	},
	{
		"option": 'Auto-image alignment (AI)',
		"free": false,
		"pro": true,
	},
	{
		"option": 'Manual blur',
		"free": false,
		"pro": true,
	},
	{
		"option": 'Manual enhancement',
		"free": false,
		"pro": true,
	},
	{
		"option": 'Your own branding or multiple branding',
		"free": false,
		"pro": true,
	},
	{
		"option": 'Lead generation form to capture customer info',
		"free": false,
		"pro": true,
	},
]
