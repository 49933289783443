import { Viewer } from "./viewer/viewer";
import { IViewerConfig } from "./interfaces/IViewerConfig";
import { IHotspot } from "./interfaces/IHotspot";
import { IPannellumViewer } from "./interfaces/IPannellumViewer";
import listReducer from "./reducer/listReducer";
import { GenerateUUID } from "./utils/string-utils";
import {customHotspotRoomFunc, customHotspotMediaFunc,customHotspotIconFunc, customTripodCoverFunc, customHotspotTextFunc, customHotspotVideoFunc} from "./hotspotMethods/hotspotMethods";
import {mapHotspotValues, mapHotspotValuesPlatform} from "./hotspotMethods/mapper";
import { IViewer } from "./interfaces/IViewer";

export { Viewer,IViewer, IPannellumViewer, IViewerConfig, IHotspot, listReducer, GenerateUUID, customHotspotRoomFunc, customHotspotMediaFunc,customHotspotIconFunc, customTripodCoverFunc,customHotspotTextFunc,customHotspotVideoFunc, mapHotspotValues, mapHotspotValuesPlatform};
