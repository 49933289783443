import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AgreeTerms from './agree-terms';
import vieweet from '../../../images/vieweet-logo/vieweet-logo.svg';
import showPasswordIcon from '../../../images/icons/password-hidden.svg';
import hidePasswordIcon from '../../../images/icons/password-shown.svg';
import cross from '../../../images/icons/pwd-check-cross.svg';
import tick from '../../../images/icons/pwd-check-tick.svg';
import { usePasswordValidation } from '../../utils/custom-hooks/usePasswordValidation';
import { ValidationUtils } from '../../utils/validations/validations';
import { logout, signup } from '../../../api-helper/api-auth';
import { Trans } from '@lingui/react';
import { ReportPageView, ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import './signup-agree-terms.scss';
import Footer from '../../utils/footer/footer';
import { EnumCategory, EnumEvent, EnumPagesTitles } from '../../../google-analytics-track-list';
import { Helmet } from 'react-helmet';
import { useAuth } from '../../../auth-routes/auth-context';
import { defineMessage } from '@lingui/macro';

interface IForm {
  name: string;
  email: string;
  password: string;
  terms: boolean;
}

interface IMessage {
  id?: string;
}

const SignUp: React.FC = () => {
  const { handleLogoutState } = useAuth();
  const [activateAccount, setActivateAccount] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [detailWrong, setDetailWrong] = useState<boolean>(false);
  const [showRequirements, setShowRequirements] = useState<boolean>(false);
  const [showAgreeTerms, setShowAgreeTerms] = useState<boolean>(false);
  const [form, setForm] = useState<IForm>({
    name: '',
    email: '',
    password: '',
    terms: false,
  });
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<IMessage>();

  const [validLength, hasNumber, upperCase, specialChar] = usePasswordValidation({
    firstPassword: form.password,
    requiredLength: 8,
    numberValidation: true,
    lengthValidation: true,
    uppercaseValidation: true,
    specialCharacterValidation: true,
  });

  useEffect(() => {
    // componentDidMount
    //Google report page
    ReportPageView(EnumPagesTitles.SignUpPage);
  }, []);

  const pwdShouldContainSection = (
    <div className='pwd-should-contain-section-signup'>
      <div className='pwd-should-contain-title-signup'>
        <h5>
          <Trans id='Your password should be' />
        </h5>
      </div>
      <div className='pwd-should-contain-signup'>
        <img className='pwd-check-status-signup' src={!validLength ? cross : tick} alt='tick' />
        <span>
          <Trans id='At least 8 characters' />
        </span>
      </div>
      <div className='pwd-should-contain-signup'>
        <img className='pwd-check-status-signup' src={!upperCase ? cross : tick} alt='cross' />
        <span>
          <Trans id='A capital letter' />
        </span>
      </div>
      <div className='pwd-should-contain-signup'>
        <img className='pwd-check-status-signup' src={!hasNumber ? cross : tick} alt='empty' />
        <span>
          <Trans id='A number' />
        </span>
      </div>
      <div className='pwd-should-contain-signup'>
        <img className='pwd-check-status-signup' src={!specialChar ? cross : tick} alt='tick' />
        <span>
          <Trans id='Contain a special character' /> <i>e.g. [!@£$%^&*()]</i>
        </span>
      </div>
    </div>
  );

  const ErrorMessageContainer = <div className='signup-error-message'>{errorMessage?.id}</div>;

  const showPassword = (): void => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    if (
      form.name !== '' &&
      ValidationUtils.isEmail(form.email) &&
      ValidationUtils.pwdHasSpecialChar(form.password) &&
      ValidationUtils.pwdHasCapitalLetter(form.password) &&
      ValidationUtils.pwdHasNumber(form.password) &&
      ValidationUtils.pwdLengthCheck(form.password) &&
      form.terms
    ) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [form]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const hideTerms = () => {
    setShowAgreeTerms(false);
    setForm({ ...form, terms: true });
  };

  const registerUser = async () => {
    setLoading(true);
    try {
      await signup(form.email, form.name, form.password);
      setActivateAccount(true);
      setLoading(false);
    } catch (error: any) {
      if (error.getStatusCode) {
        const errorCode = error.getStatusCode();
        if (errorCode === 401) {
          // if unauthorized log out
          await logout();
          handleLogoutState();
        } else if (errorCode === 409) {
          setErrorMessage(defineMessage({ message: 'This email already exists' }));
          setDetailWrong(true);
        } else {
          setErrorMessage(defineMessage({ message: 'There was an error creating the user' }));
        }
      }
      setLoading(false);
    }
  };

  if (activateAccount === true) {
    return (
      <Redirect
        to={{
          pathname: '/activate-account',
          state: { form: form },
        }}
      />
    );
  }

  if (!showAgreeTerms) {
    return (
      <>
        <Helmet>
          <html lang='en' />
          <title>Sign up</title>
          <meta name='description' content='Create a free Vieweet account and start creating your first tours.' />
        </Helmet>
        <div className='initial-pages-bg'>
          <div className='onboarding-content-layout'>
            <div>
              <a href='https://www.vieweet.com/'>
                <img className='onboarding-topbar-vieweet-logo' src={vieweet} alt='vieweet' />
              </a>
            </div>
            <div className='onboarding-topbar-links-section'>
              <div className='onboarding-topbar-link-no-border-below'>
                <Link to='/login'>
                  <Trans id='Login' />
                </Link>
              </div>
              <div className='onboarding-topbar-link-border-below'>
                <h3>
                  <Trans id='Sign up' />
                </h3>
              </div>
            </div>
            <div
              className='signup-initial-pages-container'
              style={{
                transform: `${detailWrong ? 'translate(-50%, -45%)' : 'translate(-50%, -50%)'}`,
                transition: 'transform 0.1s linear',
              }}
            >
              <div className='signup-form-container'>
                <div className='signup-form'>
                  <div className='signup-input-section'>
                    <label htmlFor='name' className={`label-login ${detailWrong ? 'label-login-error' : ''}`}>
                      <Trans id='Name' />
                    </label>
                    <input
                      id='name'
                      data-testid='name'
                      className={`login-input ${
                        form.name === '' ? 'input-neutral-state' : !detailWrong ? 'input-valid-state' : 'input-wrong-state'
                      }`}
                      name='name'
                      type='text'
                      value={form.name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        handleInputChange(e);
                      }}
                    />
                  </div>
                  <div className='signup-input-section'>
                    <label htmlFor='email' className={`label-login ${detailWrong ? 'label-login-error' : ''}`}>
                      Email
                    </label>
                    <input
                      id='email'
                      data-testid='email'
                      className={`login-input ${
                        form.email === '' ? 'input-neutral-state' : !detailWrong ? 'input-valid-state' : 'input-wrong-state'
                      }`}
                      name='email'
                      type='text'
                      value={form.email}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        handleInputChange(e);
                      }}
                    />
                  </div>
                  <div className='signup-input-section' id='last-input-section-signup'>
                    <label
                      htmlFor='password'
                      className={`label-login ${
                        form.password !== '' && !validLength && !hasNumber && !upperCase && !specialChar
                          ? 'label-signup-error'
                          : 'label-login'
                      }`}
                    >
                      <Trans id='Password' />
                    </label>
                    <input
                      id='password'
                      data-testid='password'
                      className={`login-input ${
                        form.password === ''
                          ? 'input-pwd-valid'
                          : validLength && hasNumber && upperCase && specialChar
                          ? 'input-populated'
                          : 'input-pwd-invalid'
                      }`}
                      name='password'
                      type={passwordShown ? 'text' : 'password'}
                      value={form.password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        handleInputChange(e);
                        setDetailWrong(false);
                      }}
                      onFocus={() => {
                        setShowRequirements(true);
                        setDetailWrong(false);
                      }}
                      onBlur={() => setShowRequirements(false)}
                    />
                    <button
                      data-testid='password-toggle'
                      onClick={(e: React.MouseEvent): void => {
                        e.preventDefault();
                        showPassword();
                      }}
                      className='login-input-show-password-icon'
                    >
                      <img className='password-eye' src={passwordShown ? hidePasswordIcon : showPasswordIcon} alt='show-pwd' />
                    </button>
                  </div>
                  {showRequirements && pwdShouldContainSection}
                  {detailWrong && ErrorMessageContainer}
                  <div className='signup-agree-terms-section'>
                    <input
                      id='agree-terms'
                      data-testid='check-box'
                      type='checkbox'
                      checked={form.terms}
                      onChange={() => setForm({ ...form, terms: !form.terms })}
                    />
                    <span className='custom-checkbox'></span>
                    <label htmlFor='agree-terms' style={{ color: '#0e333' }}>
                      <div>
                        <Trans id="I agree to Vieweet's " />
                        <span>
                          <a
                            data-testid='link-terms'
                            href='/'
                            onClick={(e: React.MouseEvent) => {
                              e.preventDefault();
                              setShowAgreeTerms(true);
                              ReportCustomEvent(EnumCategory.Main, EnumEvent.OpenTermsAndPolicy);
                            }}
                          >
                            <Trans id=' Terms & Privacy Policy' />.
                          </a>
                        </span>
                      </div>
                    </label>
                  </div>
                  {buttonDisable ? (
                    <div className='login-signup-container-btn'>
                      <button
                        data-testid='submit-disabled'
                        className='btn-signup btn_inactive'
                        onClick={(e: React.MouseEvent) => {
                          e.preventDefault();
                        }}
                      >
                        <Trans id='Sign up' />
                      </button>
                    </div>
                  ) : (
                    <div className='login-signup-container-btn'>
                      <Link
                        onClick={(e: React.MouseEvent) => {
                          e.preventDefault();
                          registerUser();
                        }}
                        to={{
                          pathname: '/activate-account',
                          state: { form: form },
                        }}
                      >
                        <button
                          onClick={(e: React.MouseEvent) => e.preventDefault()}
                          data-testid='submit-enabled'
                          className='btn-signup  button-hover'
                        >
                          <Trans id='Sign up' />
                        </button>
                      </Link>

                      {loading && (
                        <div className='register-loading'>
                          <i className='fa fa-spinner fa-spin fa-2x fa-fw'></i>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer isPublicRoute={true} />
        </div>
      </>
    );
  } else {
    return <AgreeTerms hideTerms={hideTerms} />;
  }
};

export default SignUp;
