type Action<T, R> =
	| { type: 'ADD_ITEM'; item: T }
	| { type: 'REMOVE_ITEM'; id: R }
	| { type: 'REMOVE_SHAPE'; item: any }
	| { type: 'UPDATE_ITEM'; id: R; item: T }
	| { type: 'UPDATE_HOTSPOT_VALUE'; index: number; newColour: R; cssClass: R; hotspotColor?: R; }
	| { type: 'ADD_INITIAL'; items: Array<T> };

interface State<T> {
	data: any[T];
}

/**
 * @typeparam `T` type of the reducer state
 * @param {keyof T} key value of `U`
 * @returns {Reducer} React reducer for a stateful list of `T`
 *
 * Can be initiated like this
 * `createListReducer<Entity,id>()`
 * Where `Entity` is the type of the list and `"id"` is a property key on the type
 * that is to be used to find index in the list
 */
const listReducerAnnotations = <T, R>() => (state: State<T>, action: Action<T, R>): State<T> => {
	switch (action.type) {
		case 'ADD_ITEM':
			return {
				...state,
				data: [...state.data, action.item]
			};
		case 'REMOVE_ITEM': {
			const existingRemove = state.data.find((item: any) => item.id === action.id);
			if (existingRemove) {
				const array = state.data.filter((item: any) => item.id !== action.id);
				const newData = array;
				return {
					...state,
					data: newData
				};
			}
			return {
				...state
			};
		}
		case 'REMOVE_SHAPE': {
			const filteredArray = state.data.filter((item: any) => item[0].id !== action.item[0].id);
			return {
				...state,
				data: filteredArray
			};
		}
		case 'UPDATE_ITEM': {
			const elementsIndex = state.data.findIndex((item: any) => item.id === action.id);
			const newArray = state.data;
			newArray[elementsIndex] = action.item;
			return {
				...state,
				data: newArray
			};
		}
		case 'UPDATE_HOTSPOT_VALUE': {
			const arrayTest = state;
			arrayTest.data[action.index][0].hotspotLabel = action.newColour;
			arrayTest.data[action.index][0].cssClass = action.cssClass;
			if (action.hotspotColor) {
				arrayTest.data[action.index][0].hotspotColor = action.hotspotColor;
			}
			return arrayTest;
		}
		case 'ADD_INITIAL':
			return {
				...state,
				data: action.items
			};
		default:
			throw new Error('Action not supported');
	}
};

export { listReducerAnnotations as newCreateListReducer };
