import { ITourInfo } from "../../main/tour/interfaces";

interface IFormatAddress {
	address?: string;
	postcode?: string;
	city?: string;
	tourCountry?: string;
	countryCode?: string;
}
const validateAutoSearch = (tourInput: ITourInfo) => {
	if (
		tourInput &&
		tourInput.tourCountry && tourInput.tourCountry.trim().length &&
		tourInput.city && tourInput.city.trim().length &&
		tourInput.address && tourInput.address.trim().length &&
		tourInput.postcode && tourInput.postcode.trim().length
	) {
		return true;
	} else {
		return false;
	}
}

const validateInputs = (tourInput: ITourInfo) => {
	if (
		tourInput &&
		tourInput.tourCountry && tourInput.tourCountry.trim().length &&
		tourInput.city && tourInput.city.trim().length &&
		tourInput.postcode && tourInput.postcode.trim().length &&
		tourInput.type && tourInput.type.trim().length
	) {
		return (true);
	} else {
		return (false);
	}
}

const fillSearch = (tourInput: any) => {
	let search = []

	if (tourInput && tourInput.address) {
		search.push(tourInput.address);
	}
	if (tourInput && tourInput.postcode) {
		search.push(tourInput.postcode);
	}
	if (tourInput && tourInput.city) {
		search.push(tourInput.city);
	}
	if (tourInput && tourInput.tourCountry) {
		search.push(tourInput.tourCountry);
	}
	return search.join(', ');
}

const getAddressFields = (searchAddress: any) => {
	const addressComponents = searchAddress.addressComponents;
	if (!addressComponents) {
		return;
	}
	const formatAddress: IFormatAddress = {};
	const doorNumber = addressComponents.find((address: any) => address.types.includes('street_number'));
	const streetName = addressComponents.find((address: any) => address.types.includes('route'));
	const addressArray = [];
	if (doorNumber) {
		addressArray.push(doorNumber.short_name);
	}
	if (streetName) {
		addressArray.push(streetName.long_name);
	}
	formatAddress.address = addressArray.join(" ");
	const postCode = addressComponents.find((zip: any) => zip.types.includes('postal_code'));
	if (postCode) {
		formatAddress.postcode = postCode.short_name;
	}

	const city = addressComponents.find((town: any) => town.types.includes('postal_town') || town.types.includes('locality'));
	if (city) {
		formatAddress.city = city.short_name
	}

	const country = addressComponents.find((country: any) => country.types.includes('country'));
	if (country) {
		formatAddress.tourCountry = country.long_name
		formatAddress.countryCode = country.short_name
	}
	return formatAddress;
}

export { validateAutoSearch, validateInputs, fillSearch, getAddressFields }