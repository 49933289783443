import React, { useEffect, useRef, useState } from 'react';
import { Tabs, TabHooks, PanelHooks } from '../../utils/tabs/tabs';
import { Trans } from '@lingui/react';
import NavSidebar from '../../utils/nav-sidebar/nav-sidebar';
import { updateConfig, getConfig } from '../../../api-helper/api-account';
import { deleteTripodLogo, getTripodCapLogos, newDefaultTripod, uploadLogoTripod } from '../../../api-helper/api-panoramas';
import { EnumProPlan, EnumProUserStatus, IReadAllPanoramaLogos } from '../../../api-helper/interface/interfaces';
import LoadingSpinner from '../../utils/loading-spinner/loading-spinner';
import Footer from '../../utils/footer/footer';
import { ReportPageView, ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent, EnumPagesTitles } from '../../../google-analytics-track-list';
import './settings.scss';
import proTag from '../../../images/general-icons/pro-tag.svg';
import VirtualTourConfig from './virtual-tour-config';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../../utils/notifications/notification';
import Branding from './branding';
import LeadsGeneration from './leads-generation';
import { EnumTourConfigType } from '../../editing-viewer/modals/settings';
import ManageSubscription from './manage-subscription';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';
import { useAuth } from '../../../auth-routes/auth-context';
import { handleError } from '../../../api-helper/api-error-handling';
import { useHistory } from 'react-router';
import { getUser } from '../../../api-helper/api-user';
import { ISidebar } from '../../editing-viewer/viewer-canvas';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import AiFeaturesConfig from './ai-features-config';
import { EnumShape } from './modal-optimise-tripod-global';

export interface IVTConfig {
  autoStartTour?: boolean;
  // autoTour?: boolean;
  shareLocation?: boolean;
  enableLeadGenerationForm?: boolean;
  leadsType?: number;
  language?: string;
  ga?: string;
  forceBrand?: boolean;
  measurementUnit?: string;
  email?: string;
  leadsConfig?: string;
  leadsMessage?: string;
  autoEnhance?: boolean;
  autoBlur?: boolean;
  tripodRemoved?: boolean;
  autoAligned?: boolean;
  multiResolution?: boolean;
  autoTripodRemoval?: boolean;
  autoAlignment?: boolean;
  liveSharing?: boolean;
  tripodMaskRatio?: number;
  tripodMaskShape?: EnumShape;
  viewerDefaultIcon?: string;
  viewerDefaultIconSize?: string;
  viewerDefaultIconColour?: string;
}

export interface ILeadConfig {
  email: string;
  leadsConfig: any;
  leadsType: any;
  type: number; // always 0
  leadsMessage: string;
  enableLeadGenerationForm: boolean;
}

export enum EnumLogoStatus {
  Active = 2,
  Inactive = 1,
  Deleted = -1,
}

export interface IFiles {
  data: string;
  defaultLogo: boolean;
  id: string;
  name?: string;
}

export enum EnumLeadsType {
  None = 0,
  Compulsory = 1,
  Optional = 2,
}

export enum EnumTabIndex {
  AiFeatures = 0,
  Settings = 1,
  Branding = 2,
  LeadGeneration = 3,
  ManageSubscription = 4,
}

export interface IBranding {
  forceBrand: boolean;
  brandName?: string;
  brandAddress?: string;
  brandEmail?: string;
}

export interface IUserSubscription {
  userId: string;
  proPlan: EnumProPlan;
  proUser: EnumProUserStatus;
  proExpiration: Date;
}

const Settings: React.FC = () => {
  const history = useHistory();
  const { handleLogoutState } = useAuth();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [files, setFiles] = useState<IFiles[]>([]);
  const [defaultLogo, setDefaultLogo] = useState<string>();
  const [response, setResponse] = useState<IReadAllPanoramaLogos[]>();
  const [deletedLogos, setDeletedLogos] = useState<string[]>([]);
  const [virtualTourConfigurationForm, setVirtualTourConfigurationForm] = useState<IVTConfig>({
    autoStartTour: false,
    shareLocation: false,
    language: localStorage.getItem('Language') || 'English',
    ga: '',
    measurementUnit: linguiTranslateToString('Centimeters'),
    autoEnhance: false,
    autoBlur: false,
    tripodRemoved: false,
    autoAlignment: false,
    multiResolution: false,
    liveSharing: false,
    tripodMaskRatio: 0.3,
    tripodMaskShape: EnumShape.Circle,
  });
  const [branding, setBranding] = useState<IBranding>({
    forceBrand: false,
    brandName: '',
    brandAddress: '',
    brandEmail: '',
  });
  const ref = useRef<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [leadConfiguration, setLeadConfiguration] = useState<ILeadConfig>({
    enableLeadGenerationForm: false,
    type: EnumTourConfigType.LeadsGeneration,
    email: '',
    leadsConfig: 0,
    leadsType: 0,
    leadsMessage: '',
  });
  const [showApplyAllModal, setShowApplyAllModal] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>();
  const [user, setUser] = useState<IUserSubscription>();
  const [loadedManageSubscription, setLoadedManageSubscription] = useState(false);
  const config = useConfig();
  const [sidebar, setSidebar] = useState<ISidebar>({
    tripodBranding: false,
    leadGeneration: false,
    managementSubscriptionTab: false,
    aiFeatures: false,
    autoEnhance: false,
    autoBlur: false,
    autoTripodRemoval: false,
    autoAlignment: false,
    multiRes: false,
  });
  const [sidebarLoaded, setSidebarLoaded] = useState(false);

  useEffect(() => {
    if (config && config.modules) {
      let sidebarTemp = { ...sidebar };
      for (const menu of config.modules) {
        const menuKey = Object.keys(menu);
        const key = sidebar[menuKey[0]];
        // if there is a config for a left sidebar option add it
        if (key !== undefined) {
          sidebarTemp[menuKey[0]] = menu[menuKey[0]];
        }
        setSidebar(sidebarTemp);
        setSidebarLoaded(true);
      }
    }
  }, [config]);

  useEffect(() => {
    // Sets tab on load
    const urlPath = window.location.pathname;
    onChangeUrl(urlPath);
  }, []);

  useEffect(() => {
    // Changes tab ONLY when back button is pressed
    window.onpopstate = (e: any) => {
      onChangeUrl(e.currentTarget.location.pathname);
    };
  }, []);

  // Only called when tab in manually changed
  const changeOfTab = (index: number) => {
    setTabIndex(index);
    if (index === EnumTabIndex.AiFeatures && !window.location.pathname.includes('ai-features')) {
      history.push('/ai-features');
    }
    if (index === EnumTabIndex.Settings && !window.location.pathname.includes('settings')) {
      history.push('/settings');
    }
    if (index === EnumTabIndex.Branding && !window.location.pathname.includes('branding')) {
      getTripodLogos();
      history.push('/branding');
    }
    if (index === EnumTabIndex.LeadGeneration && !window.location.pathname.includes('lead-generation')) {
      history.push('/lead-generation');
    }
    if (index === EnumTabIndex.ManageSubscription && !window.location.pathname.includes('manage-subscription')) {
      history.push('/manage-subscription');
    }
  };

  const onChangeUrl = (urlPath: string) => {
    if (urlPath.includes('ai-features')) {
      setTabIndex(EnumTabIndex.AiFeatures);
    } else if (urlPath.includes('settings')) {
      setTabIndex(EnumTabIndex.Settings);
    } else if (urlPath.includes('branding')) {
      getTripodLogos();
      setTabIndex(EnumTabIndex.Branding);
    } else if (urlPath.includes('lead-generation')) {
      setTabIndex(EnumTabIndex.LeadGeneration);
    } else if (urlPath.includes('manage-subscription')) {
      setTabIndex(EnumTabIndex.ManageSubscription);
    }
  };

  const getUserSubscriptionData = async () => {
    try {
      const userData = await getUser();
      setUser({
        userId: userData.id,
        proPlan: userData.proPlan,
        proUser: userData.proUser,
        proExpiration: userData.proExpiration,
      });
      setLoadedTrue();
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error, linguiTranslateToString('There was an issue getting subscription data'));
      setLoadedTrue();
    }
  };

  const setLoadedTrue = () => {
    setTimeout(() => setLoadedManageSubscription(true), 400);
  };

  const panelHooksStyle = {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'visible',
  };

  useEffect(() => {
    getUserSubscriptionData();
    getConfiguration();
  }, []);

  useEffect(() => {
    ReportPageView(EnumPagesTitles.Settings);
  }, []);

  const getTripodLogos = async () => {
    try {
      const tripodsLogos = await getTripodCapLogos();
      setResponse(tripodsLogos);
      let tripodArray: IFiles[] = [];
      if (tripodsLogos) {
        tripodArray = tripodsLogos.map((tripodLogo: IReadAllPanoramaLogos) => {
          if (tripodLogo.status === EnumLogoStatus.Active) {
            setDefaultLogo(tripodLogo.id);
          }
          return {
            data: tripodLogo.cubeUrl,
            defaultLogo: tripodLogo.status === EnumLogoStatus.Active ? true : false,
            id: tripodLogo.id,
          };
        });
      }
      if (tripodArray.length > 0) {
        setFiles(tripodArray);
      }
      setLoaded(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoaded(true);
    }
  };

  const getConfiguration = async () => {
    setLoaded(false);
    try {
      const getUserConfig = await getConfig();

      if (getUserConfig) {
        let obj = { ...virtualTourConfigurationForm };
        let brandTemp: IBranding = { ...branding };
        for (const config of getUserConfig) {
          const value = config.userValue;
          if (config.src === 'forceBrand') {
            brandTemp.forceBrand = value;
          }
          if (config.src === 'brandName') {
            brandTemp.brandName = value;
          }
          if (config.src === 'brandAddress') {
            brandTemp.brandAddress = value;
          }
          if (config.src === 'brandEmail') {
            brandTemp.brandEmail = value;
          }

          if (Object.keys(config.src).length !== 0) {
            obj = { ...obj, [config.src]: value };
          }
        }
        setBranding(brandTemp);
        if (Object.keys(obj).length !== 0) {
          setVirtualTourConfigurationForm(obj);
        }
        const leadGeneration = {
          enableLeadGenerationForm: obj.enableLeadGenerationForm || false,
          type: EnumTourConfigType.LeadsGeneration,
          email: obj.email || '',
          leadsConfig: obj.leadsConfig || 0,
          leadsType: obj.leadsType || 0,
          leadsMessage: obj.leadsMessage || '',
        };
        setLeadConfiguration(leadGeneration);
        setLoaded(true);
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoaded(true);
    }
  };

  const onFileDrop = async (e: any) => {
    if (e.target.files.length > 0) {
      setLoaded(false);
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i] || e.dataTransfer.files[i];
        try {
          const message = linguiTranslateToString('We are uploading your logo');
          toggleNotification(EnumNotificationType.Info, message);
          await uploadLogoTripod(file);
        } catch (error) {
          const err = error as Error;
          handleError(err, handleLogoutState);
          toggleNotification(EnumNotificationType.Error);
        }
      }
      if (ref && ref.current) {
        ref.current.value = '';
      }
      getTripodLogos();
    }
  };

  const deleteSelectedLogo = (imageIndex: number) => {
    const logoId = files[imageIndex].id ? files[imageIndex].id : null;
    if (logoId) {
      setDeletedLogos([...deletedLogos, logoId]);
    }
    let arrayOfImages = [...files];
    arrayOfImages.splice(imageIndex, 1);
    setFiles(arrayOfImages);
  };

  const selectDefaultLogo = async (selectedIndex: number) => {
    setDefaultLogo(files[selectedIndex].id);
    let arrayOfFiles = [...files].map((file, index) => {
      if (index === selectedIndex) {
        file.defaultLogo = true;
      } else {
        file.defaultLogo = false;
      }
      return file;
    });
    setFiles(arrayOfFiles);
  };

  const toggleNotification = (type: EnumNotificationType, message?: string) => {
    setTypeNotification(type);
    setNotificationMessage(message || linguiTranslateToString('There was an error please try again'));
    setShowNotification(true);
  };

  const updateConfiguration = async (global: boolean) => {
    try {
      await updateConfig(virtualTourConfigurationForm, global);
      let message;
      if (global) {
        message = linguiTranslateToString('The changes have been saved and applied to all tours');
        setShowApplyAllModal(false);
      } else {
        message = linguiTranslateToString(
          "Your changes have been saved and will be applied to your tours. Local settings won't be overwritten",
        );
      }

      toggleNotification(EnumNotificationType.Success, message);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error);
    }
  };

  const tripodEnable = async (global: boolean) => {
    try {
      await updateConfig(branding, global);
      let message;
      if (global) {
        message = linguiTranslateToString('The changes have been saved and applied to all tours');
        setShowApplyAllModal(false);
      } else {
        message = linguiTranslateToString(
          "Your changes have been saved and will be applied to your tours. Local settings won't be overwritten",
        );
      }
      toggleNotification(EnumNotificationType.Success, message);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error);
    }
  };

  const saveChangesBranding = async (global: boolean) => {
    setLoaded(false);
    // Check if default logo changed
    const oldDefaultImage = response?.find(logos => logos.status === EnumLogoStatus.Active);
    try {
      if (!oldDefaultImage || oldDefaultImage.id !== defaultLogo) {
        if (defaultLogo && defaultLogo !== '') {
          await newDefaultTripod(defaultLogo);
        }
      }

      // Delete any deleted logos
      if (deletedLogos.length > 0) {
        for (const logo of deletedLogos) {
          await deleteTripodLogo(logo);
        }
      }

      // Update tripod enable option
      await tripodEnable(global);

      let message;
      if (global) {
        message = linguiTranslateToString('The changes have been saved and applied to all tours');
        setShowApplyAllModal(false);
      } else {
        message = linguiTranslateToString(
          "Your changes have been saved and will be applied to your tours. Local settings won't be overwritten",
        );
      }
      toggleNotification(EnumNotificationType.Success, message);
      setLoaded(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error);
      setLoaded(true);
    }
  };

  const onSaveLeadsGeneration = async (global: boolean) => {
    try {
      await updateConfig(leadConfiguration, global);
      let message;
      if (global) {
        message = linguiTranslateToString('The changes have been saved and applied to all tours');
        setShowApplyAllModal(false);
      } else {
        message = linguiTranslateToString(
          "Your changes have been saved and will be applied to your tours. Local settings won't be overwritten",
        );
      }
      toggleNotification(EnumNotificationType.Success, message);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error);
    }
  };

  /************************ Confirm changes modal ***********************/

  function onSaveTourConfiguration(global: boolean) {
    setShowApplyAllModal(false);
    updateConfiguration(global);
    ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.SaveChanges);
  }

  /************************ Exit editor modal ***********************/

  return (
    <div className='dashboard-layout'>
      {showNotification && (
        <Notification
          showNotification={showNotification}
          size={EnumNotificationSize.Large}
          type={typeNotification || EnumNotificationType.Info}
          message={notificationMessage}
          setShowNotification={setShowNotification}
        />
      )}
      <LoadingSpinner loaded={loaded} />
      <NavSidebar />
      {sidebarLoaded && (
        <>
          <div className='dashboard'>
            {sidebar.managementSubscriptionTab ? (
              <div className='content-wrapper-settings'>
                <Tabs changeOfTab={changeOfTab} tabIndex={tabIndex}>
                  <div className='settings-header'>
                    <span />
                    <div className='settings-header-tabs-container'>
                      <div
                        id='ai-features'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          //Report Custom Category and Event
                          ReportCustomEvent(EnumCategory.Header, EnumEvent.AIPoweredFeatures);
                        }}
                      >
                        <TabHooks>
                          <div className={tabIndex === 0 ? 'btn-dashboard-active' : 'btn-dashboard-inactive'}>
                            <div className='btn-dashboard-text'>
                              <Trans id='AI-powered features' />
                              <img style={{ marginLeft: '10px' }} src={proTag} alt='pro' />
                            </div>
                          </div>
                        </TabHooks>
                      </div>

                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          //Report Custom Category and Event
                          ReportCustomEvent(EnumCategory.Header, EnumEvent.VirtualTourConfigurationMenu);
                        }}
                      >
                        <TabHooks>
                          <div className={tabIndex === 1 ? 'btn-dashboard-active' : 'btn-dashboard-inactive'}>
                            <div className='btn-dashboard-text'>
                              <Trans id='Virtual Tour Configuration' />
                            </div>
                          </div>
                        </TabHooks>
                      </div>
                      <div
                        id='branding'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          //Report Custom Category and Event
                          ReportCustomEvent(EnumCategory.Header, EnumEvent.YourBrandingSettings);
                        }}
                      >
                        <TabHooks>
                          <div className={tabIndex === 2 ? 'btn-dashboard-active' : 'btn-dashboard-inactive'}>
                            <div className='btn-dashboard-text'>
                              <Trans id='Branding' />
                              <img style={{ marginLeft: '10px' }} src={proTag} alt='pro' />
                            </div>
                          </div>
                        </TabHooks>
                      </div>
                      <div
                        id='lead-generation'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          //Report Custom Category and Event
                          ReportCustomEvent(EnumCategory.Header, EnumEvent.SubscriptionSettings);
                        }}
                      >
                        <TabHooks>
                          <div className={tabIndex === 3 ? 'btn-dashboard-active' : 'btn-dashboard-inactive'}>
                            <div className='btn-dashboard-text'>
                              <Trans id='Lead generation' />
                              <img style={{ marginLeft: '10px' }} src={proTag} alt='pro' />
                            </div>
                          </div>
                        </TabHooks>
                      </div>
                      <div
                        id='manage-subscription'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          //Report Custom Category and Event
                          ReportCustomEvent(EnumCategory.Header, EnumEvent.YourDataSettings);
                        }}
                      >
                        <TabHooks>
                          <div className={tabIndex === 4 ? 'btn-dashboard-active' : 'btn-dashboard-inactive'}>
                            <div className='btn-dashboard-text'>
                              <div className='btn-dashboard-text'>
                                <Trans id='Manage subscription' />
                              </div>
                            </div>
                          </div>
                        </TabHooks>
                      </div>
                    </div>
                  </div>
                  <PanelHooks inLineStyle={panelHooksStyle}>
                    <AiFeaturesConfig
                      sidebar={sidebar}
                      virtualTourConfigurationForm={virtualTourConfigurationForm}
                      setVirtualTourConfigurationForm={setVirtualTourConfigurationForm}
                      itemModalOpen={showApplyAllModal}
                      onSaveTourConfiguration={onSaveTourConfiguration}
                      toggleModal={setShowApplyAllModal}
                    />
                  </PanelHooks>
                  <PanelHooks inLineStyle={panelHooksStyle}>
                    <VirtualTourConfig
                      virtualTourConfigurationForm={virtualTourConfigurationForm}
                      setVirtualTourConfigurationForm={setVirtualTourConfigurationForm}
                      itemModalOpen={showApplyAllModal}
                      onSaveTourConfiguration={onSaveTourConfiguration}
                      toggleModal={setShowApplyAllModal}
                    />
                  </PanelHooks>
                  <PanelHooks inLineStyle={panelHooksStyle}>
                    <Branding
                      sidebar={sidebar}
                      branding={branding}
                      files={files}
                      setBranding={setBranding}
                      fileDropRef={ref}
                      onFileDrop={onFileDrop}
                      deleteSelectedLogo={deleteSelectedLogo}
                      selectDefaultLogo={selectDefaultLogo}
                      toggleModal={setShowApplyAllModal}
                      itemModalOpen={showApplyAllModal}
                      onSaveBranding={saveChangesBranding}
                    />
                  </PanelHooks>
                  <PanelHooks inLineStyle={panelHooksStyle}>
                    <LeadsGeneration
                      sidebar={sidebar}
                      leadConfiguration={leadConfiguration}
                      setLeadConfiguration={setLeadConfiguration}
                      toggleModal={setShowApplyAllModal}
                      itemModalOpen={showApplyAllModal}
                      onSaveLeads={onSaveLeadsGeneration}
                    />
                  </PanelHooks>
                  <PanelHooks inLineStyle={panelHooksStyle}>
                    <ManageSubscription
                      user={user}
                      loadedManageSubscription={loadedManageSubscription}
                      toggleNotification={toggleNotification}
                    />
                  </PanelHooks>
                </Tabs>
              </div>
            ) : (
              <div className='content-wrapper-settings'>
                <Tabs changeOfTab={changeOfTab} tabIndex={tabIndex}>
                  <div className='settings-header'>
                    <span />

                    <div className='settings-header-tabs-container'>
                      <div
                        id='ai-features'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          //Report Custom Category and Event
                          ReportCustomEvent(EnumCategory.Header, EnumEvent.AIPoweredFeatures);
                        }}
                      >
                        <TabHooks>
                          <>
                            <Trans id='AI-powered features' />
                            <img style={{ marginLeft: '10px' }} src={proTag} alt='pro' />
                          </>
                        </TabHooks>
                      </div>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          //Report Custom Category and Event
                          ReportCustomEvent(EnumCategory.Header, EnumEvent.VirtualTourConfigurationMenu);
                        }}
                      >
                        <TabHooks>
                          <Trans id='Virtual Tour Configuration' />
                        </TabHooks>
                      </div>
                      <div
                        id='branding'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          //Report Custom Category and Event
                          ReportCustomEvent(EnumCategory.Header, EnumEvent.YourBrandingSettings);
                        }}
                      >
                        <TabHooks>
                          <>
                            <Trans id='Branding' />
                            <img style={{ marginLeft: '10px' }} src={proTag} alt='pro' />
                          </>
                        </TabHooks>
                      </div>
                      <div
                        id='lead-generation'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          //Report Custom Category and Event
                          ReportCustomEvent(EnumCategory.Header, EnumEvent.SubscriptionSettings);
                        }}
                      >
                        <TabHooks>
                          <Trans id='Lead generation' />
                          <img style={{ marginLeft: '10px' }} src={proTag} alt='pro' />
                        </TabHooks>
                      </div>
                    </div>
                  </div>
                  <PanelHooks inLineStyle={panelHooksStyle}>
                    <AiFeaturesConfig
                      sidebar={sidebar}
                      virtualTourConfigurationForm={virtualTourConfigurationForm}
                      setVirtualTourConfigurationForm={setVirtualTourConfigurationForm}
                      itemModalOpen={showApplyAllModal}
                      onSaveTourConfiguration={onSaveTourConfiguration}
                      toggleModal={setShowApplyAllModal}
                    />
                  </PanelHooks>
                  <PanelHooks inLineStyle={panelHooksStyle}>
                    <VirtualTourConfig
                      virtualTourConfigurationForm={virtualTourConfigurationForm}
                      setVirtualTourConfigurationForm={setVirtualTourConfigurationForm}
                      itemModalOpen={showApplyAllModal}
                      onSaveTourConfiguration={onSaveTourConfiguration}
                      toggleModal={setShowApplyAllModal}
                    />
                  </PanelHooks>
                  <PanelHooks inLineStyle={panelHooksStyle}>
                    <Branding
                      sidebar={sidebar}
                      branding={branding}
                      files={files}
                      setBranding={setBranding}
                      fileDropRef={ref}
                      onFileDrop={onFileDrop}
                      deleteSelectedLogo={deleteSelectedLogo}
                      selectDefaultLogo={selectDefaultLogo}
                      toggleModal={setShowApplyAllModal}
                      itemModalOpen={showApplyAllModal}
                      onSaveBranding={saveChangesBranding}
                    />
                  </PanelHooks>
                  <PanelHooks inLineStyle={panelHooksStyle}>
                    <LeadsGeneration
                      sidebar={sidebar}
                      leadConfiguration={leadConfiguration}
                      setLeadConfiguration={setLeadConfiguration}
                      toggleModal={setShowApplyAllModal}
                      itemModalOpen={showApplyAllModal}
                      onSaveLeads={onSaveLeadsGeneration}
                    />
                  </PanelHooks>
                </Tabs>
              </div>
            )}
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default Settings;
