import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import backArrow from '../../../images/icons/modal-icons/back-arrow.svg';
import imageIcon from '../../../images/dashboard-icons/card-icons/icon-images.svg';
import linkIcon from '../../../images/dashboard-icons/card-icons/icon-link.svg';
import locationIcon from '../../../images/dashboard-icons/card-icons/icon-location.svg';
import viewsIcon from '../../../images/dashboard-icons/card-icons/icon-views.svg';
import defaultImage from '../../../images/empty.svg';
import Moment from 'react-moment';
import { ReactComponent as MenuDots } from '../../../images/dashboard-icons/ic-menu-dots-inactive.svg';
import { ReactComponent as MenuDotsActive } from '../../../images/dashboard-icons/card-icons/btn-hover.svg';
import { ReactComponent as Share } from '../../../images/dashboard-icons/card-icons/options-icons/share-icon.svg';
import { ReactComponent as Edit } from '../../../images/dashboard-icons/card-icons/options-icons/edit-icon.svg';
import { ReactComponent as Duplicate } from '../../../images/dashboard-icons/card-icons/options-icons/duplicate-icon.svg';
import { ReactComponent as Lock } from '../../../images/dashboard-icons/card-icons/options-icons/lock-icon.svg';
import { ReactComponent as OpenLock } from '../../../images/dashboard-icons/card-icons/options-icons/open-lock-icon.svg';
import { ReactComponent as Archive } from '../../../images/dashboard-icons/card-icons/options-icons/archive-icon.svg';
import { ReactComponent as Delete } from '../../../images/dashboard-icons/card-icons/options-icons/delete-icon.svg';
import { Trans } from '@lingui/react';
import { Link } from 'react-router-dom';
import { archiveTour, deleteTour, duplicateTour, getTour, shareTour } from '../../../api-helper/api-tours';
import LoadingSpinner from '../../utils/loading-spinner/loading-spinner';
import NavSidebar from '../../utils/nav-sidebar/nav-sidebar';
import { formatBytes } from '../../utils/bytes-converter/bytes-converter';
import passwordLock from '../../../images/dashboard-icons/password-lock.svg';
import leadsGeneration from '../../../images/dashboard-icons/lead-generation.svg';
import { downloadPanorama, downloadAllPanorama, downloadPanoramaDynamic, getPanoramas } from '../../../api-helper/api-panoramas';
import DownloadSingleModal from '../../utils/modal/download-single-modal';
import Modal from '../../utils/modal/modal';
import './tours.scss';
import 'intro.js/introjs.css';
import { Steps } from 'intro.js-react';
import Footer from '../../utils/footer/footer';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { IModalContent } from '../../utils/tour-views/interface';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';

const DownloadTour = ({ location }: RouteComponentProps<any>) => {
  const { handleLogoutState } = useAuth();
  const history = useHistory();
  const tour: any = location.state;
  const [panoramas, setPanoramas] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadModal, setDownloadModal] = useState<boolean>(false);
  const [currentPanorama, setCurrentPanorama] = useState<any>();
  const [currentTour, setCurrentTour] = useState<any>({
    name: '',
    updatedAt: '',
    mainPhotoUrl: '',
    tourSize: '',
    readCode: '',
  });
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const tourRef = useRef<any>(null);
  const [previousWordSearch, setPreviousWordSearch] = useState<string>('');
  const [previousPaginationPage, setPreviousPaginationPage] = useState<number>(0);
  const [previousTourIndex, setPreviousTourIndex] = useState<number>(0);
  const [dashboardView, setDashboardView] = useState('');
  const [onHoverDownload, setOnHoverDownload] = useState<string>('');
  const [modalContent, setModalContent] = useState<IModalContent>({ id: '' });
  const [apiCallLoaded, setApiCallLoaded] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [displayWarning, setDisplayWarning] = useState<boolean>(false);
  const [isTooltipClicked, setIsTooltipClicked] = useState<boolean>(false);
  const [currentTooltipStep, setCurrentTooltipStep] = useState<number>(0);
  const [orderOption, setOrderOption] = useState<string>('');
  const [orderOptionName, setOrderOptionName] = useState<string>('');

  const steps = [
    {
      element: '#tour-card',
      intro:
        'This box displays some useful information about the tour you are viewing, including the tour name, address, the number of images and file size, the number of views and the virtual tour URL. It also lists the date of creation and last update.',
      position: 'bottom',
      highlightClass: 'highlight-edit-card',
    },
    {
      element: '#download-panoramas',
      intro:
        'If you hover over a thumbnail on this page, two buttons will appear. The ‘Equi’ button will allow you to download the 360 version of this image, while the ‘2D’ button will trigger the download of the 2D image.',
      position: 'right',
      highlightClass: 'highlight-edit-panorama',
    },
  ];

  const onExit = () => {
    setIsTooltipClicked(false);
  };

  const onStepsCompleted = () => {
    setCurrentTooltipStep(0);
  };

  const stepsInfo = (currentStep: number) => {
    setCurrentTooltipStep(currentStep);
  };

  const activeTooltipHandler = () => {
    //Report Custom Category and Event
    ReportCustomEvent(EnumCategory.Tooltip, EnumEvent.TooltipDownloadTour);
    tourRef.current.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => setIsTooltipClicked(!isTooltipClicked), 500);
  };

  useEffect(() => {
    getPanoramaImages();
  }, [tour]);

  const getPanoramaImages = async () => {
    try {
      if (tour && tour.id) {
        setPreviousPaginationPage(tour.paginationPage);
        setPreviousWordSearch(tour.searchWord);
        setPreviousTourIndex(tour.tourIndex);
        setOrderOptionName(tour.orderOptionName);
        setOrderOption(tour.orderOption);
        setDashboardView(tour.dashboardView);
        history.push(`/download-tour/?tourId=${tour.id}`);
        const panoramasResponse = await getPanoramas(tour.id);
        setPanoramas(panoramasResponse);
        setCurrentTour(tour);
      } else {
        // get from url param
        const params = new URLSearchParams(window.location.search);
        const tourIdFromParams: any = params.get('tourId');
        const getTourFromId = await getTour(tourIdFromParams || '');
        setCurrentTour(getTourFromId);
        const panoramasResponse = await getPanoramas(tourIdFromParams);
        setPanoramas(panoramasResponse);
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const getStandaloneViewerUrl = (short: boolean) => {
    const standAloneViewerUrl = window.ENV.REACT_APP_STANDALONE_VIEWER_URL || process.env.REACT_APP_STANDALONE_VIEWER_URL;
    const url = standAloneViewerUrl + currentTour.readCode;

    if (short) {
      const shortUrl = standAloneViewerUrl.replace(/^https:\/\//i, '');
      return shortUrl && currentTour.readCode ? shortUrl + currentTour.readCode : '';
    } else {
      return url ? url : '';
    }
  };

  const downloadAllPanoramas = async () => {
    setLoading(true);
    try {
      const responseData = await downloadAllPanorama(currentTour.id);
      // Get file name
      const filename = removeAnySpecialCharacters(currentTour.name) + '.zip';
      const url = window.URL.createObjectURL(new Blob([responseData.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoading(false);
    }
  };

  const downloadSingle = async (panorama: any) => {
    setLoading(true);
    try {
      const responseData = await downloadPanorama(panorama.id);
      // Getting file name from header
      const filename = responseData.headers['content-disposition'].split('filename=')[1];
      const url = window.URL.createObjectURL(new Blob([responseData.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoading(false);
    }
  };

  const toggleModal = (panorama?: any) => {
    setCurrentPanorama(panorama);
    setDownloadModal(!downloadModal);
  };

  const downloadSingle2D = async (panoramaId: string, coordinates: number[]) => {
    setLoading(true);
    try {
      const responseData = await downloadPanoramaDynamic(panoramaId, coordinates[0], coordinates[1], coordinates[2]);
      // Getting file name from header
      const filename = responseData.headers['content-disposition'].split('filename=')[1];
      const url = window.URL.createObjectURL(new Blob([responseData.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoading(false);
    }
  };

  const removeAnySpecialCharacters = (fileName: string) => {
    // eslint-disable-next-line
    return fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<> \{\}\[\]\\\/]/gi, '');
  };

  const toggleModalHide = (): void => {
    setDisplayModal(false);
  };

  const toggleModalShow = (modalContent: IModalContent) => {
    try {
      setModalContent(modalContent);
      setDisplayModal(true);
    } catch (error) {
      toggleError();
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const deleteTourMatch = async () => {
    try {
      await deleteTour(currentTour.id);
      history.push('/', { displayDeleted: true });
    } catch (error) {
      toggleError();
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
    toggleModalHide();
  };

  const archiveTours = async (archive: boolean) => {
    try {
      await archiveTour(currentTour.id, archive);
      history.push('/', { displayArchive: true });
    } catch (error) {
      toggleError();
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const duplicateATour = async (tourId: string) => {
    setApiCallLoaded(false);
    try {
      await duplicateService(tourId);
      setDisplayWarning(true);
      setApiCallLoaded(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleError();
    }
  };

  const duplicateService = async (tourId: string) => {
    try {
      const response = await duplicateTour(tourId);
      return response;
    } catch (error) {
      toggleError();
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const toggleError = (): void => {
    setError(!error);
    setApiCallLoaded(true);
  };

  const shareTourLink = async (tourId: string, recipient: string[]) => {
    try {
      await shareTour(tourId, recipient);
    } catch (error) {
      toggleError();
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const addDefaultSrc = (ev: any) => {
    ev.target.src = defaultImage;
  };

  return (
    <React.Fragment>
      <Steps
        enabled={isTooltipClicked}
        steps={steps}
        initialStep={currentTooltipStep}
        onAfterChange={stepsInfo}
        onComplete={onStepsCompleted}
        onExit={onExit}
        options={{ hideNext: false, exitOnOverlayClick: false, showStepNumbers: false, showBullets: false, disableInteraction: true }}
      />
      <NavSidebar isTooltipActive={true} activeTooltipHandler={activeTooltipHandler} isToolTipClicked={isTooltipClicked} />
      {error && <div className='edit-error'>{/* <Error display={error} toggleError={toggleError} /> */}</div>}
      {displayWarning && (
        <div className='edit-error'>{/* <Warning type={typeNotification} display={displayWarning} toggleWarning={toggleWarning} /> */}</div>
      )}

      <div className='dashboard-layout'>
        {!loading && <LoadingSpinner loaded={apiCallLoaded} />}
        <div ref={tourRef} className='dashboard'>
          <div className='content-wrapper'>
            {downloadModal && (
              <DownloadSingleModal
                tour={currentTour}
                currentPanorama={currentPanorama}
                toggleModal={toggleModal}
                downloadSingle2D={downloadSingle2D}
              />
            )}
            {displayModal && (
              <Modal
                hideModal={toggleModalHide}
                modalContent={modalContent}
                deleteTourMatch={deleteTourMatch}
                shareTourLink={shareTourLink}
              />
            )}
            <div className='contain-arrow'>
              <div className='arrow-back'>
                <Link
                  to={{
                    pathname: '/',
                    state: {
                      tourIndex: previousTourIndex,
                      pagination: previousPaginationPage,
                      wordSearch: previousWordSearch,
                      orderOptionName: orderOptionName,
                      orderOption: orderOption,
                      dashboardView: dashboardView,
                    },
                  }}
                >
                  <img className='back-icon' src={backArrow} alt='back-arrow' />
                </Link>
              </div>
              <div className='edit-tour-container' id='tour-card'>
                <div className='display-flex'>
                  <div className='show-btn' style={{ position: 'relative' }}>
                    <img
                      className='download-tour-main-image'
                      src={currentTour.mainPhotoUrl || defaultImage}
                      onError={addDefaultSrc}
                      alt='main'
                    />
                    <div className='on-hover-hide-download-image'>
                      <button className='edit-btn-hover-download-all' onClick={() => downloadAllPanoramas()}>
                        <Trans id='Download all 360 Equi images' />
                      </button>
                    </div>
                  </div>

                  <div className='row-info'>
                    <div className='row-info-content'>
                      <div className='row-info-top'>
                        <div className='row-title-updated'>
                          <div className='dots-dropdown-parent-row'>
                            <div className='dots-dropdown'>
                              <button
                                className='remove-default'
                                onMouseOver={() => setOnHoverDownload(currentTour.id)}
                                onMouseLeave={() => setOnHoverDownload('')}
                              >
                                <div className='dots-btn'>{onHoverDownload === '' ? <MenuDots /> : <MenuDotsActive />}</div>
                              </button>
                              <div
                                onMouseOver={() => setOnHoverDownload(currentTour.id)}
                                onMouseLeave={() => setOnHoverDownload('')}
                                className={`dots-dropdown-content ${
                                  onHoverDownload === currentTour.id ? 'dots-dropdown-content-active' : ''
                                }`}
                              >
                                <div className='dots-dropdown-menu-edit'>
                                  <span>
                                    <button
                                      id='share-download'
                                      onClick={() => {
                                        const modalContent = {
                                          id: 'share-tour',
                                          title: linguiTranslateToString('Share your virtual tour'),
                                          tour: currentTour ? currentTour : '',
                                        };
                                        toggleModalShow(modalContent);
                                      }}
                                      className='remove-default dots-options'
                                    >
                                      <Share className='dots-options-icons' />
                                      <Trans
                                        id='Share tour'
                                        render={({ translation }) => <p className='dots-options-text'>{translation}</p>}
                                      />
                                    </button>
                                  </span>
                                  <span>
                                    <button className='remove-default dots-options'>
                                      <Link
                                        className='default-link link-style-adjustment'
                                        to={{
                                          pathname: '/editing-viewer/',
                                          search: `?tourId=${currentTour.id}`,
                                          state: { ...currentTour, orderOptionName: orderOptionName, orderOption: orderOption },
                                        }}
                                      >
                                        <Edit className='dots-options-icons ' />
                                        <Trans
                                          id='Edit tour'
                                          render={({ translation }) => <p className='dots-options-text'>{translation}</p>}
                                        />
                                      </Link>
                                    </button>
                                  </span>
                                  <span>
                                    <button
                                      id='duplicate-download'
                                      className='remove-default dots-options'
                                      onClick={() => duplicateATour(currentTour.id)}
                                    >
                                      <Duplicate className='dots-options-icons' />
                                      <Trans
                                        id='Duplicate tour'
                                        render={({ translation }) => <p className='dots-options-text'>{translation}</p>}
                                      />
                                    </button>
                                  </span>
                                  <span>
                                    {!currentTour.protected ? (
                                      <button
                                        id='password-protected-download'
                                        className='remove-default dots-options'
                                        onClick={() => {
                                          const modalContent = {
                                            id: 'password-protect',
                                            title: linguiTranslateToString('Protect with password'),
                                            tourId: currentTour.id ? currentTour.id : '',
                                            tourName: currentTour.name ? currentTour.name : '',
                                          };
                                          toggleModalShow(modalContent);
                                        }}
                                      >
                                        <Lock className='dots-options-icons' />
                                        <Trans
                                          id='Password protect'
                                          render={({ translation }) => <p className='dots-options-text'>{translation}</p>}
                                        />
                                      </button>
                                    ) : (
                                      <button
                                        className='remove-default dots-options'
                                        id='password-none-download'
                                        onClick={() => {
                                          const modalContent = {
                                            id: 'remove-password-protect',
                                            title: linguiTranslateToString('Remove password'),
                                            tourId: currentTour.id ? currentTour.id : '',
                                            tourName: currentTour.name ? currentTour.name : '',
                                          };
                                          toggleModalShow(modalContent);
                                        }}
                                      >
                                        <OpenLock className='dots-options-icons' />
                                        <Trans
                                          id='Remove password'
                                          render={({ translation }) => <p className='dots-options-text'>{translation}</p>}
                                        />
                                      </button>
                                    )}
                                  </span>
                                  <span>
                                    <button className='remove-default dots-options' onClick={() => archiveTours(true)}>
                                      <Archive className='dots-options-icons' />
                                      <Trans
                                        id='Archive tour'
                                        render={({ translation }) => <p className='dots-options-text'>{translation}</p>}
                                      />
                                    </button>
                                  </span>
                                  <span>
                                    <button
                                      id='delete-download'
                                      className='remove-default dots-options'
                                      onClick={() => {
                                        const modalContent = {
                                          id: 'delete-tour',
                                          title: linguiTranslateToString('Delete tour'),
                                          tourId: currentTour.id ? currentTour.id : '',
                                          tourName: currentTour.name ? currentTour.name : '',
                                        };
                                        toggleModalShow(modalContent);
                                      }}
                                    >
                                      <Delete className='dots-options-icons' />
                                      <Trans
                                        id='Delete tour'
                                        render={({ translation }) => <p className='dots-options-text'>{translation}</p>}
                                      />
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h3 id='tour-name' className='tour-row-address'>
                            {currentTour.name}
                          </h3>
                          <span className='updated-info'>
                            <Trans id='last updated on' /> <Moment format='DD/MM/YY'>{currentTour.updatedAt}</Moment> at{' '}
                            <Moment format='HH:mm'>{currentTour.updatedAt}</Moment>
                          </span>
                        </div>
                      </div>
                      <div className='row-info-bottom'>
                        <div className='left-half-info'>
                          <div className='info-details-containers'>
                            <img className='details-icons' src={locationIcon} alt='location' />
                            <p title={currentTour.name} className='details-text-down'>
                              {currentTour.name}
                            </p>
                          </div>
                          <div className='info-details-containers'>
                            <img className='details-icons' src={linkIcon} alt='location' />
                            <a
                              title={getStandaloneViewerUrl(false)}
                              className='details-text-down'
                              href={getStandaloneViewerUrl(false)}
                              rel='noreferrer'
                              target='_blank'
                            >
                              {getStandaloneViewerUrl(false)}
                            </a>
                            <div className='lock-leads-icons'>
                              {currentTour.enableLeadGenerationForm && (
                                <button className='remove-default'>
                                  <img src={leadsGeneration} alt='Padlock password protect' />
                                </button>
                              )}
                              {currentTour.protected && (
                                <button className='remove-default'>
                                  <img src={passwordLock} alt='Padlock password protect' />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='right-half-info'>
                          <div className='info-details-containers'>
                            <img className='details-icons' src={viewsIcon} alt='location' />
                            <p className='details-text-down'>
                              {currentTour.views} <Trans id='views' />
                            </p>
                          </div>
                          <div className='info-details-containers'>
                            <img className='details-icons' src={imageIcon} alt='location' />
                            <p className='details-text-down'>
                              {currentTour.panoramaCount || 0} <Trans id='images' /> - <i>{formatBytes(currentTour.tourSize)}</i>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='edit-tour-edit-images-container'>
              <div>
                <h3 className='blue-text'>
                  <Trans id='Download single images' />
                </h3>
                <div className='container-download-images'>
                  {panoramas ? (
                    panoramas.map((panorama: any, i: number) => (
                      <div id='download-panoramas' key={i} className='image-for-download-container'>
                        <img
                          className='image-for-download'
                          onError={addDefaultSrc}
                          src={panorama.thumbnailPreviewUrl || defaultImage}
                          alt='panoramas'
                        />
                        <div className='img-label'>{panorama.name}</div>
                        <div className='on-hover-download-single'>
                          <button className='download-equi-btn' onClick={() => downloadSingle(panorama)}>
                            <Trans id='Equi' />
                          </button>
                        </div>
                        <div className='on-hover-download-single'>
                          <button className='download-2d-btn' onClick={() => toggleModal(panorama)}>
                            <Trans id='2D' />
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <LoadingSpinner />
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DownloadTour;
