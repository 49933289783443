import { getCssClass, getHotspotMethods, getIcon, hotspotMethods } from '../enumerators/enumerators';
import {
	customHotspotMediaFunc,
	customHotspotRoomFunc,
	customTripodCoverFunc,
	customHotspotIconFunc,
	customHotspotTextFunc,
	customHotspotVideoFunc
} from './hotspotMethods';


/**
 * Map hotspot values to enumerator
 */
export const mapHotspotValues = (panoramas: any) => {
	for (const id in panoramas) {
		const hotspots = panoramas[id].hotSpots;
		const hotSpotsMapped = [];
		for (const index in hotspots) {
			const hotspot = hotspots[index];

			if (
				hotspot.cssClass === 'link' ||
				hotspot.cssClass === 'icon' ||
				hotspot.cssClass === 'text' ||
				hotspot.cssClass === 'video' ||
				hotspot.cssClass === 'media'
			) {
				hotspot.createTooltipArgs.iconType = getIcon(hotspot.createTooltipArgs.iconType);
			}

			hotspot.cssClass = getCssClass(hotspot.cssClass);

			const methodValue: any = getHotspotMethods(hotspot.createTooltipFunc);

			if (methodValue === hotspotMethods.link) {
				hotspot.createTooltipFunc = customHotspotRoomFunc;
			}
			if (methodValue === hotspotMethods.icon) {
				hotspot.createTooltipFunc = customHotspotIconFunc;
			}
			if (methodValue === hotspotMethods.media) {
				hotspot.createTooltipFunc = customHotspotMediaFunc;
			}
			if (methodValue === hotspotMethods.tripod) {
				hotspot.createTooltipFunc = customTripodCoverFunc;
			}
			if (methodValue === hotspotMethods.text) {
				hotspot.createTooltipFunc = customHotspotTextFunc;
			}
			if (methodValue === hotspotMethods.video) {
				hotspot.createTooltipFunc = customHotspotVideoFunc;
			}
			hotSpotsMapped.push(hotspot);
		}
		panoramas[id].hotSpots = hotSpotsMapped;
	}
	return panoramas;
};


export const mapHotspotValuesPlatform = (hotspots: any) => {
	const hotSpotsMapped = [];
	for (const index in hotspots) {
		const hotspot = hotspots[index];

		if (
			hotspot.cssClass === 'link' ||
			hotspot.cssClass === 'icon' ||
			hotspot.cssClass === 'text' ||
			hotspot.cssClass === 'video' ||
			hotspot.cssClass === 'media'
		) {
			hotspot.createTooltipArgs.iconType = getIcon(hotspot.createTooltipArgs.iconType);
		}

		hotspot.cssClass = getCssClass(hotspot.cssClass);

		const methodValue: any = getHotspotMethods(hotspot.createTooltipFunc);

		if (methodValue === hotspotMethods.link) {
			hotspot.createTooltipFunc = customHotspotRoomFunc;
		}
		if (methodValue === hotspotMethods.icon) {
			hotspot.createTooltipFunc = customHotspotIconFunc;
		}
		if (methodValue === hotspotMethods.media) {
			hotspot.createTooltipFunc = customHotspotMediaFunc;
		}
		if (methodValue === hotspotMethods.tripod) {
			hotspot.createTooltipFunc = customTripodCoverFunc;
		}
		if (methodValue === hotspotMethods.text) {
			hotspot.createTooltipFunc = customHotspotTextFunc;
		}
		if (methodValue === hotspotMethods.video) {
			hotspot.createTooltipFunc = customHotspotVideoFunc;
		}
		hotSpotsMapped.push(hotspot);
	}
	return hotSpotsMapped;
};