import React from 'react';
import './modal-dashboard.scss';

const Grid: React.FC = () => {
  return (
    <>
      <div className='grid-container'>
        <div style={{ width: '15%' }} className='outside-area'></div>
        <div style={{ width: '70%' }}>
          <div className='grid' style={{ width: '70%' }}>
            <div className='grid-box'></div>
            <div className='grid-box'></div>
            <div className='grid-box'></div>
            <div className='grid-box'></div>
            <div className='grid-box'></div>
            <div className='grid-box'></div>
            <div className='grid-box'></div>
            <div className='grid-box'></div>
            <div className='grid-box'></div>
          </div>
        </div>
        <div style={{ width: '15%' }} className='outside-area'></div>
      </div>
      {/* <div className='download-size-select'>
        <select className='download-select-grid'>
          <option>Square</option>
          <option>3:2</option>
          <option>4:3</option>
          <option>16:9</option>
        </select>
      </div> */}
    </>
  );
};

export default Grid;
