const newIcons = [
	{ name: 'applynow', icon: 'fa-check-square' },
	{ name: 'arrow_3d', icon: 'fa-arrow-alt-circle-up' },
	{ name: 'attention', icon: 'fa-exclamation-triangle' },
	{ name: 'barcode', icon: 'fa-barcode' },
	{ name: 'bathroom', icon: 'fa-male' },
	{ name: 'bathroom_2', icon: 'fa-female' },
	{ name: 'broken', icon: 'fa-unlink' },
	{ name: 'businessbanking', icon: 'fa-building' },
	{ name: 'cart_in', icon: 'fa-cart-arrow-down' },
	{ name: 'championship', icon: 'fa-trophy' },
	{ name: 'cracked', icon: 'fa-unlink' },
	{ name: 'creditcard', icon: 'fa-credit-card' },
	{ name: 'cricket', icon: 'fa-genderless' },
	{ name: 'cycling', icon: 'fa-bicycle' },
	{ name: 'dirty', icon: 'fa-recycle' },
	{ name: 'exit', icon: 'fa-sign-out' },
	{ name: 'floor', icon: 'fa-window-minimize' },
	{ name: 'football', icon: 'fa-futbol' },
	{ name: 'garage', icon: 'fa-car' },
	{ name: 'info', icon: 'fa-info-circle' },
	{ name: 'livechat', icon: 'fa-comments' },
	{ name: 'missing', icon: 'fa-question-circle' },
	{ name: 'mortgagecalculator', icon: 'fa-home' },
	{ name: 'notavailable', icon: 'fa-times-circle' },
	{ name: 'personalbanking', icon: 'fa-id-card' },
	{ name: 'premierbanking', icon: 'fa-diamond' },
	{ name: 'racing', icon: 'fa-flag-checkered' },
	{ name: 'road', icon: 'fa-road' },
	{ name: 'rugby', icon: 'fa-futbol' },
	{ name: 'security', icon: 'fa-shield' },
	{ name: 'ski', icon: 'fa-snowflake' },
	{ name: 'smoking_free', icon: 'fa-ban' },
	{ name: 'tag', icon: 'fa-tag' },
	{ name: 'tags', icon: 'fa-tags' },
	{ name: 'target', icon: 'fa-crosshairs' },
	{ name: 'tennis', icon: 'fa-tennis-ball' },
	{ name: 'under_construction', icon: 'fa-exclamation-triangle' },
	{ name: 'urgent', icon: 'fa-calendar' },
	{ name: 'link', icon: 'fa-link' },
	{ name: 'camera', icon: 'fa-video' },
	{ name: 'image', icon: 'fa-images' },
	{ name: 'arrow_1', icon: 'fa-arrow-alt-circle-right' },
	{ name: 'arrow_2', icon: 'fa-arrow-alt-circle-left' },
	{ name: 'arrow_3', icon: 'fa-arrow-alt-circle-down' },
	{ name: 'office_1', icon: 'fa-briefcase' },
	{ name: 'library', icon: 'fa-books' },
	{ name: 'garage_2', icon: 'fa-garage-car' },
	{ name: 'office', icon: 'fa-desktop' },
	{ name: 'fuse_box', icon: 'fa-bolt' },
	{ name: 'cinema', icon: 'fa-film' },
	{ name: 'cinema_1', icon: 'fa-camera-movie' },
	{ name: 'bathroom_3', icon: 'fa-bath' },
	{ name: 'hot_tub', icon: 'fa-hot-tub' },
	{ name: 'washroom', icon: 'fa-washer' },
	{ name: 'swimming_pool', icon: 'fa-swimmer' },
	{ name: 'living_room', icon: 'fa-couch' },
	{ name: 'bar', icon: 'fa-beer' },
	{ name: 'gym', icon: 'fa-dumbbell' },
	{ name: 'tool_cupboard', icon: 'fa-tools' },
	{ name: 'tool_cupboard_1', icon: 'fa-toolbox' },
	{ name: 'bedroom', icon: 'fa-bed' },
	{ name: 'dining_room', icon: 'fa-utensils' },
	{ name: 'golf_course', icon: 'fa-golf-club' },
	{ name: 'double_arrow', icon: 'fa-angle-double-up' },
]

const colours = [
	{ colour: '#FFFFFF' },
	{ colour: '#DADADA' },
	{ colour: '#0e0333' },
	{ colour: '#E30613' },
	{ colour: '#FFED00' },
	{ colour: '#312783' },
	{ colour: '#E6007E' },
	{ colour: '#F39200' },
	{ colour: '#260DFE' },
	{ colour: '#662483' },
	{ colour: '#009640' },
	{ colour: '#009FE3' },
	{ colour: '#52E2C0' },
	{ colour: '#CA9E67' },
	{ colour: '#8969D3' },
	{ colour: '#FF5FA0' },
	{ colour: '#EEFF22' },
	{ colour: '#87E873' },
	{ colour: '#F4C8E3' },
	{ colour: '#F3EFA1' },
	{ colour: '#99E6D8' },
	{ colour: '#FFE471' },
	{ colour: '#9CDCED' },
	{ colour: '#FA656E' },
];

export { newIcons, colours };










