import { Trans } from '@lingui/react';
import React from 'react';
import '../profile/profile.scss';
import howTo from '../../../images/tours/how-to-illustration.svg';
import howToInches from '../../../images/tours/how-to-illustration-inches.svg';
import tick from '../../../images/tours/white-tick.svg';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';

interface IProps {
  modalType: boolean;
  setMeasurementModal: (state: boolean) => void;
  units: string;
}

const MeasurementModal: React.FC<IProps> = ({ modalType, setMeasurementModal, units }) => {
  return (
    <div className='delete-profile-modal-bg'>
      <div>
        <div className='delete-profile-modal-container'>
          <div className='delete-profile-modal-header'>
            {modalType ? <Trans id='How do I measure this?' /> : <Trans id='Why do I need to enter this?' />}
            <button className='btn-remove-default' onClick={() => setMeasurementModal(false)}>
              <img className='how-to-measure-tick' src={tick} alt='tick' />
            </button>
          </div>
          <div className='delete-profile-modal-body' style={{ padding: '15px' }}>
            <div style={{ margin: '15px 40px' }}>
              {modalType ? (
                <>
                  <Trans
                    id='Position the laser meter at the same height of the centre of the 360 camera lens and measure the distance from there to the floor.'
                    render={({ translation }) => <div>{translation}</div>}
                  />
                  <Trans id='Remember to enter the value in centimeters' render={({ translation }) => <div>{translation}</div>} />
                </>
              ) : (
                <>
                  <Trans
                    id='This value is used to generate the dollhouse model of your virtual tour, to automatically create the floorplan and to produce precise measurements of rooms, walls, doors and windows.'
                    render={({ translation }) => <div>{translation}</div>}
                  />
                  <Trans
                    id='Make sure the value is precise by following the instrutions below.'
                    render={({ translation }) => <div>{translation}</div>}
                  />
                </>
              )}
            </div>
            <img src={units === linguiTranslateToString('Centimeters') ? howTo : howToInches} alt='howTo' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeasurementModal;
