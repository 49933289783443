import React, { useContext, useEffect } from 'react';
import { TabsState } from '../../utils/tabs/tabs';

interface IProps {
  setActiveTab: (tab: any) => void;
  renderImagePresets: boolean;
}

const GetActiveTab: React.FC<IProps> = ({ setActiveTab, renderImagePresets }) => {
  const [activeIndex, setActive] = useContext<any>(TabsState);

  useEffect(() => {
    if (!renderImagePresets) {
      setActive(0);
    }
  }, [renderImagePresets]);
  useEffect(() => {
    setActiveTab(activeIndex);
  }, [activeIndex]);
  return <div></div>;
};

export default GetActiveTab;
