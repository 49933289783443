export interface ITour {
	createdAt: string;
	enabled: number;
	furnished: null
	id: string;
	latitude: number;
	longitude: number;
	mainPhotoUrl: string;
	name: string;
	numberOfRooms: number;
	panoramaCount: number;
	passwordHash: null
	protected: false
	readCode: string;
	reference: string;
	status: number;
	tourOptions: ITourOptions[];
	tourSize: number;
	type: number | null;
	updatedAt: Date;
	url: string;
	userId: string;
	views: 0
}

export interface IEditOptions {
	panoramaId: string;
	panoramaKey: string;
	panoramaValue: string;
}

export interface ITourOptions {
	tourId: string;
	tourKey: string;
	tourValue: string;
}

export enum EnumHotspotsSize {
	small = '30px',
	medium = '40px',
	large = '60px'
}

export interface IAttributes {
	version?: number;
	mediaPath?: string;
	iconSize?: number;
	enableAutoplay?: boolean;
	isMuted?: boolean;
	enableControls?: boolean;
}

export interface IAnnotation {
	cssClass: string;
	id: string;
	label: string;
	points: IPoints;
}

export interface IPoints {
	pitch: number;
	yaw: number;
}

export interface IFileArray {
	data: any;
	file?: any;
	name?: string;
	completion: EnumUploadState;
	id: string;
}

export enum EnumUploadState {
	Completed = 5,
	Pending = 1,
	NotUploaded = 0,
	Failed = -1,
}

export interface IObjectString {
	[key: string]: string;
}

export interface ILocalHotspotCreation {
	status?: number;
	pitch: number;
	yaw: number;
	type: string;
	text?: string;
	id: number;
	cssClass: string;
	hotspotLabel: string;
	hotspotColor: string;
	index?: number;
}

export interface IReducerHotspot {
	data: ILocalHotspotCreation[];
}