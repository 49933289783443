import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
// import { DropDownCountries } from '@vva/front';
import selectArrow from '../../../../images/dashboard-icons/select-arrow.svg';
import { ReactComponent as AddressMap } from '../../../../images/tours/address-map.svg';
import { ReactComponent as AddressMapFailed } from '../../../../images/tours/address-map-tour-failed.svg';
import { linguiTranslateToString } from '../../../utils/lingui-utils/utils-lingui';
import { EnumCategory, EnumEvent } from '../../../../google-analytics-track-list';
import { updateTour } from '../../../../api-helper/api-tours';
import { ReportCustomEvent } from '../../../../GoogleAnalyticsConfig';
import CustomSelect from '../../../utils/custom-select/custom-select';
import { defineMessage } from '@lingui/macro';
import { ICountries, IState, ITourInfo, tourPropertyType } from '../settings';
import { EnumNotificationType } from '../../../utils/notifications/notification';
import { getConfig } from '../../../../api-helper/api-account';
import { handleError } from '../../../../api-helper/api-error-handling';
import { useAuth } from '../../../../auth-routes/auth-context';
import { IUserOptions } from '../../../main/tour/create-tour-details';
import DropDownCountries from '../../../utils/dropdown-front/drop-down-countries';

interface IProps {
  tour: any;
  tourInfo: ITourInfo;
  countries?: ICountries[];
  mapState: google.maps.Map;
  setTourInfo: (info: ITourInfo) => void;
  setAddressSearch: any;
  setAutoSearchState: (state: IState) => void;
  toggleModal: () => void;
  autoSearchState: IState;
  changeLeftSidebar: (option: string) => void;
  setMeasurementModalType: (state: boolean) => void;
  setMeasurementModal: (state: boolean) => void;
  toggleNotification: (type: EnumNotificationType, message?: string) => void;
}

const TourProfile: React.FC<IProps> = ({
  tour,
  tourInfo,
  countries,
  mapState,
  setTourInfo,
  setAddressSearch,
  setAutoSearchState,
  toggleModal,
  autoSearchState,
  changeLeftSidebar,
  setMeasurementModalType,
  setMeasurementModal,
  toggleNotification,
}) => {
  const { handleLogoutState } = useAuth();
  const [showCustomDropDownPropertyType, setShowCustomDropDownPropertyType] = useState<boolean>(false);
  const [showCustomDropDownPropertyFurnished, setShowCustomDropDownPropertyFurnished] = useState<boolean>(false);
  const [measurementUnit, setMeasurementUnit] = useState(linguiTranslateToString('Centimeters'));

  useEffect(() => {
    getConfiguration();
  }, []);

  const getConfiguration = async () => {
    try {
      const getUserConfig = await getConfig();
      if (getUserConfig) {
        let obj: IUserOptions = {};
        for (const config of getUserConfig) {
          const value = config.userValue === '1' ? true : config.userValue;
          if (Object.keys(config.src).length !== 0) {
            obj = { ...obj, [config.src]: value };
          }
        }

        if (obj && obj.measurementUnit) {
          setMeasurementUnit(obj.measurementUnit);
        }
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const getTourName = (formatAddress: ITourInfo): string => {
    const nameArray = [];
    if (formatAddress.flatNumber) {
      const safeFlatNumber = formatAddress.flatNumber.toLowerCase().includes('flat')
        ? formatAddress.flatNumber
        : 'Flat ' + formatAddress.flatNumber;
      nameArray.push(safeFlatNumber.trim());
    }
    if (formatAddress.address) {
      nameArray.push(formatAddress.address.trim());
    }
    if (formatAddress.postcode) {
      nameArray.push(formatAddress.postcode.trim());
    }
    if (formatAddress.city) {
      nameArray.push(formatAddress.city.trim());
    }
    if (formatAddress.countryCode) {
      nameArray.push(formatAddress.countryCode.trim());
    }
    return nameArray.filter(n => n.length).join(', ');
  };

  const updateCurrentTour = async () => {
    ReportCustomEvent(EnumCategory.TourProfile, EnumEvent.SaveChanges);
    try {
      // Build tour name automatically
      const safeTourInput = {
        ...tourInfo,
        name: getTourName(tourInfo) || tour.name,
      };
      await updateTour(safeTourInput);
      toggleNotification(EnumNotificationType.Success, 'Tour has been updated');
      changeLeftSidebar('');
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error);
    }
  };

  const handleSelectedLabel = async (selectedValue: any) => {
    if (selectedValue && selectedValue.length) {
      const lat = selectedValue[0].latitude;
      const long = selectedValue[0].longitude;
      const name = selectedValue[0].name;
      const country = selectedValue[0].country;

      // Center map with right country and reset fields
      const map = mapState;
      map.setCenter({ lat: +lat, lng: +long });

      // Reset fields when country is changed
      setTourInfo({ tourCountry: name, countryCode: country, latitude: lat, longitude: long, id: tour.id });
      setAddressSearch({ formatted_address: name });
      setAutoSearchState({ state: 'none' });
    }
  };

  const onChangeType = (option: string) => {
    setTourInfo({ ...tourInfo, type: option });
    toggleCustomDropdownPropertyType(false);
  };

  const onChangeFurnished = (option: string) => {
    const isFurnished = option === 'Yes' ? 1 : 0;
    setTourInfo({ ...tourInfo, furnished: isFurnished });
    toggleCustomDropdownPropertyFurnished(false);
  };

  const toggleCustomDropdownPropertyType = (option?: boolean) => {
    if (option) {
      setShowCustomDropDownPropertyType(option);
    } else {
      setShowCustomDropDownPropertyType(!showCustomDropDownPropertyType);
    }
  };

  const toggleCustomDropdownPropertyFurnished = (option?: boolean) => {
    if (option) {
      setShowCustomDropDownPropertyFurnished(option);
    } else {
      setShowCustomDropDownPropertyFurnished(!showCustomDropDownPropertyFurnished);
    }
  };

  return (
    <>
      <div className='settings-modal-container-profile'>
        <div className='update-tour-container'>
          <div className='display-flex'>
            <div className='address-input-setting' style={{ position: 'relative' }}>
              <div className='create-tour-input-labels'>
                <Trans id='Country' />
              </div>
              <div className='countries-input'>
                <DropDownCountries
                  selectedProps={tourInfo && tourInfo.tourCountry ? [tourInfo.tourCountry] : ['']}
                  dataset={countries ? countries : []}
                  allowNew={false}
                  isDisabled={false}
                  labelKey={'name'}
                  handleSelectedItem={handleSelectedLabel}
                  IsResultHighlightOnly={false}
                  placeHolderValue=''
                />
              </div>
              <img className='arrow-country-settings' src={selectArrow} alt='Drop down menu arrow country' />
            </div>
            <div className='address-input-setting'>
              <div className='create-tour-input-labels'>
                <Trans id='City' />
              </div>
              <input
                onChange={(e: React.ChangeEvent<any>) => {
                  setTourInfo({ ...tourInfo, city: e.target.value });
                }}
                value={tourInfo && tourInfo.city ? tourInfo.city : ''}
                type='text'
                placeholder=' '
                className={`general-field-input-text ${tourInfo && tourInfo.city !== '' ? 'valid-input' : ''}`}
                id='settings-modal-input-city'
              />
            </div>
            <div className='address-input-setting' style={{ position: 'relative' }}>
              <div className='create-tour-input-labels'>
                <Trans id='Street and house number' />
              </div>
              <input
                onChange={(e: React.ChangeEvent<any>) => {
                  setTourInfo({ ...tourInfo, address: e.target.value });
                }}
                value={tourInfo && tourInfo.address ? tourInfo.address : ''}
                type='text'
                style={{ width: '90%' }}
                className={`general-field-input-text ${tourInfo && tourInfo.address !== '' ? 'valid-input' : ''}`}
                id='settings-modal-input-street-house-number'
              />
              <button onClick={() => toggleModal()} data-testid='maps-btn' className='maps-btn-settings'>
                {autoSearchState.state === 'fail' ? (
                  <AddressMapFailed />
                ) : autoSearchState.state === 'success' ? (
                  <AddressMap />
                ) : (
                  <AddressMap />
                )}
              </button>
            </div>
          </div>
          <div className='display-flex'>
            <div className='address-input-setting'>
              <div className='create-tour-input-labels'>
                <Trans id='Postcode' />
              </div>
              <input
                onChange={(e: React.ChangeEvent<any>) => {
                  setTourInfo({ ...tourInfo, postcode: e.target.value });
                }}
                value={tourInfo && tourInfo.postcode ? tourInfo.postcode : ''}
                type='text'
                placeholder=' '
                className={`general-field-input-text ${tourInfo && tourInfo.postcode !== '' ? 'valid-input' : ''}`}
                id='settings-modal-input-postcode'
              />
            </div>
            <div className='address-input-setting'>
              <div className='create-tour-input-labels'>
                <Trans id='Flat (optional)' />
              </div>
              <input
                onChange={(e: React.ChangeEvent<any>) => setTourInfo({ ...tourInfo, flatNumber: e.target.value })}
                type='text'
                placeholder=' '
                value={tourInfo && tourInfo.flatNumber ? tourInfo.flatNumber : ''}
                className={`general-field-input-text ${tourInfo && tourInfo.flatNumber !== '' ? 'valid-input' : ''}`}
                id='settings-modal-input-flat'
              />
            </div>
            <div className='address-input-setting'>
              <div className='create-tour-input-labels'>
                <Trans id='Reference code (optional)' />
              </div>
              <input
                value={tourInfo && tourInfo.reference ? tourInfo.reference : ''}
                onChange={(e: React.ChangeEvent<any>) => setTourInfo({ ...tourInfo, reference: e.target.value })}
                type='text'
                placeholder=' '
                className={`general-field-input-text ${tourInfo && tourInfo.reference !== '' ? 'valid-input' : ''}`}
                id='settings-modal-input-ref-code'
              />
            </div>
          </div>
          <div className='display-flex'></div>
          <div className='display-flex'>
            <div className='address-input-setting'>
              <div className={!showCustomDropDownPropertyType ? 'create-tour-input-labels' : ' create-tour-input-labels-focus'}>
                <Trans id='Type of property' />
              </div>
              <CustomSelect
                value={linguiTranslateToString(tourPropertyType.find(a => a.value === tourInfo.type)?.label || '')}
                options={tourPropertyType.map(option => defineMessage({ message: option.label }))}
                changeDropdownValue={onChangeType}
                showCustomDropDown={showCustomDropDownPropertyType}
                setShowCustomDropDown={toggleCustomDropdownPropertyType}
                customClass={`general-field-input ${showCustomDropDownPropertyType ? 'general-field-focus' : ''}`}
                customDropdown='update-tour-input-dropdown'
                customDropdownOption='update-tour-input-dropdown-option'
                customDropdownId='settings-modal-input-property-type'
              />
            </div>
            <div className='address-input-setting'>
              <div className={!showCustomDropDownPropertyFurnished ? 'create-tour-input-labels' : ' create-tour-input-labels-focus'}>
                <Trans id='Is this property furnished?' />
              </div>
              <CustomSelect
                value={tourInfo && tourInfo.furnished ? linguiTranslateToString('Yes') : linguiTranslateToString('No')}
                options={[defineMessage({ message: 'Yes' }), defineMessage({ message: 'No' })]}
                changeDropdownValue={onChangeFurnished}
                showCustomDropDown={showCustomDropDownPropertyFurnished}
                setShowCustomDropDown={toggleCustomDropdownPropertyFurnished}
                customClass={`general-field-input ${showCustomDropDownPropertyFurnished ? 'general-field-focus' : ''}`}
                customDropdown='update-tour-input-dropdown'
                customDropdownOption='update-tour-input-dropdown-option'
                customDropdownId='settings-modal-input-property-furnished'
              />
            </div>
            <div className='address-input-setting'>
              <div className='create-tour-input-labels'>
                <Trans
                  id='Centre of the lens height {measureUnit}'
                  values={{ measureUnit: measurementUnit === linguiTranslateToString('Centimeters') ? '(cm)' : '(inches)' }}
                />
              </div>
              <input
                id='reference'
                name='cameraHeight'
                placeholder=' '
                className={`general-field-input-text ${tourInfo.cameraHeight !== 0 ? 'valid-input' : ''}`}
                type='number'
                value={tourInfo.cameraHeight || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTourInfo({ ...tourInfo, cameraHeight: Number(e.target.value) })}
              />
              <div className='measurements-sections'>
                <div>
                  <button
                    onClick={() => {
                      setMeasurementModalType(false);
                      setMeasurementModal(true);
                    }}
                    className='measurements-btn-popup'
                  >
                    <Trans id=' Why I need to enter this?' />
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => {
                      setMeasurementModalType(true);
                      setMeasurementModal(true);
                    }}
                    className='measurements-btn-popup'
                  >
                    <Trans id='How do I measure this?' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-btn-container-profile'>
          <button onClick={() => updateCurrentTour()} className='editor-modal-btn-profile editor-modal-btn-active-profile button-hover'>
            <Trans id='Save changes' />
          </button>
        </div>
      </div>
    </>
  );
};

export default TourProfile;
