import { defineMessage } from '@lingui/macro';
import { Trans } from '@lingui/react';
import React, { useEffect, useState } from 'react';
import CustomSelect from '../../../utils/custom-select/custom-select';
import eyeViews from '../../../../images/general-icons/small-eye-v2.svg';
import noAnalytics from '../../../../images/empty-states-illustrations/no-analytics.svg';
import {
  getPanoramaViews,
  getTopToursAnalytics,
  getViewsByCountries,
  getViewsByDevices,
  getViewsBySource,
} from '../../../../api-helper/api-analytics';
import { ICountriesViews, IDevicesViews, IPanoramaViews, ISourceViewedFrom } from '../../../../api-helper/interface/interfaces';
import '../tours.scss';
import { Doughnut } from 'react-chartjs-2';
import { linguiTranslateToString } from '../../../utils/lingui-utils/utils-lingui';
import { handleError } from '../../../../api-helper/api-error-handling';
import { useAuth } from '../../../../auth-routes/auth-context';

interface IProps {
  tourId: string;
  tabIndex?: number;
}

const colours = [
  '#00269A',
  '#44BDE3',
  '#D50057',
  '#FFC400',
  '#FF7E00',
  '#AB0051',
  '#0071A9',
  '#2B2D66',
  '#3657D0',
  '#EE5C8C',
  '#005B3F',
  '#FF8164',
  '#81D5C2',
  '#304FC6',
  '#7AD0EB',
  '#F38BAD',
  '#8D90AE',
  '#81D5C2',
];

const defaultChartData = {
  datasets: [
    {
      data: [''],
      backgroundColor: colours,
      borderColor: ['#ffffff'],
      borderWidth: 1,
    },
  ],
};

const Analytics: React.FC<IProps> = ({ tourId, tabIndex }) => {
  const { handleLogoutState } = useAuth();
  const [dateRangeSelected, setDateRangeSelected] = useState<string>(linguiTranslateToString('This month'));
  const [showCustomDropDownDateRange, setShowCustomDropDownDateRange] = useState<boolean>(false);
  const [dateRangeServiceSelected, setDateRangeServiceSelected] = useState<string>('thisMonth');
  const [panoramaViews, setPanoramaViews] = useState<IPanoramaViews[]>();
  const [tourViews, setTourViews] = useState<number>(0);
  const [countriesViews, setCountriesViews] = useState<ICountriesViews[]>();
  const [countriesDonutData, setCountriesDonutData] = useState(defaultChartData);
  const [devicesDonutData, setDevicesDonutData] = useState(defaultChartData);
  const [sourcesDonutData, setSourcesDonutData] = useState(defaultChartData);
  const [devicesViews, setDevicesViews] = useState<IDevicesViews[]>();
  const [sourceViews, setSourceViews] = useState<ISourceViewedFrom[]>();

  const dateRangeOptions = [
    { name: 'This month', value: 'thisMonth' },
    { name: 'Last month', value: 'lastMonth' },
    { name: 'This year', value: 'thisYear' },
    { name: 'Last year', value: 'lastYear' },
  ];

  useEffect(() => {
    if (tourId && tabIndex === 3) {
      getAllPanoramaViews();
      getTourToursAnalyticsAsync();
      getCountriesViewedFromInfo();
      getDevicesViewedFromInfo();
      getSourceViewedFromInfo();
    }
  }, [tourId, dateRangeServiceSelected, tabIndex]);

  const getTourToursAnalyticsAsync = async () => {
    try {
      const data = await getTopToursAnalytics(dateRangeServiceSelected, tourId);
      if (data.data && data.data[0]) {
        setTourViews(Number(data.data[0].viewsCount));
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const getAllPanoramaViews = async () => {
    try {
      const data = await getPanoramaViews(tourId, dateRangeServiceSelected);
      setPanoramaViews(data);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const getDataInPercentage = (values: number[]) => {
    const getSum = values.reduce((a, b) => a + b, 0);
    const percentages = values.map(a => (a / getSum) * 100);
    const getSumOfPercentages = percentages.reduce((a, b) => Math.round(a) + Math.round(b), 0);
    if (getSumOfPercentages !== 100) {
      const difference = 100 - getSumOfPercentages;
      if (difference > 0) {
        return percentages.map((a, i) => (percentages.length - 1 === i ? String(Math.round(a) + difference) : String(Math.round(a))));
      } else {
        return percentages.map((a: any, i: number) =>
          percentages.length - 1 === i ? String(Math.round(a) - difference) : String(Math.round(a)),
        );
      }
    } else {
      return percentages.map((a: any) => String(Math.round(a)));
    }
  };

  const getCountriesViewedFromInfo = async () => {
    try {
      const data = await getViewsByCountries(dateRangeServiceSelected, tourId);
      setCountriesViews(data);
      const getValuesAsNumber = data.map(a => Number(a.viewsFromCountry));
      const percentageData = getDataInPercentage(getValuesAsNumber);
      const donutPieData = {
        datasets: [
          {
            data: percentageData,
            backgroundColor: colours,
            borderColor: ['#ffffff'],
            borderWidth: 1,
          },
        ],
      };
      setCountriesDonutData(donutPieData);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const getDevicesViewedFromInfo = async () => {
    try {
      const data = await getViewsByDevices(dateRangeServiceSelected, tourId);
      setDevicesViews(data);
      const getValuesAsNumber = data.map(a => Number(a.viewsFromDevice));
      const percentageData = getDataInPercentage(getValuesAsNumber);
      const donutPieData = {
        datasets: [
          {
            data: percentageData,
            backgroundColor: colours,
            borderColor: ['#ffffff'],
            borderWidth: 1,
          },
        ],
      };
      setDevicesDonutData(donutPieData);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const getSourceViewedFromInfo = async () => {
    try {
      const data = await getViewsBySource(dateRangeServiceSelected, tourId);
      setSourceViews(data);
      const getValuesAsNumber = data.map(a => Number(a.viewsFromSource));
      const percentageData = getDataInPercentage(getValuesAsNumber);
      const donutPieData = {
        datasets: [
          {
            data: percentageData,
            backgroundColor: colours,
            borderColor: ['#ffffff'],
            borderWidth: 1,
          },
        ],
      };
      setSourcesDonutData(donutPieData);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const changeDateRangeHandler = (myValue: string) => {
    dateRangeOptions.map(option => {
      if (option.name === myValue) {
        setDateRangeSelected(option.name);
        setDateRangeServiceSelected(option.value);
        setDateRangeSelected(linguiTranslateToString(option.name));
        // googleAnalyticsStatsSelected(option.value);
      }
      return null;
    });
    toggleCustomDropdown(false);
  };

  const toggleCustomDropdown = (option?: boolean) => {
    if (option) {
      setShowCustomDropDownDateRange(option);
    } else {
      setShowCustomDropDownDateRange(!showCustomDropDownDateRange);
    }
  };

  const getBarWidth = (panoramaViews: number) => {
    const getPercentage = (panoramaViews / tourViews) * 100;
    return `${getPercentage}%`;
  };

  const alwaysShowTooltip = {
    id: 'alwaysShowTooltip ',
    afterDraw(chart: any) {
      const { ctx } = chart;
      ctx.save();
      chart.data.datasets.forEach((dataset: any, i: number) => {
        chart.getDatasetMeta(i).data.forEach((datapoint: any, i: number) => {
          const { x, y } = datapoint.tooltipPosition();
          const label = chart.data.datasets[0].data[i];
          if (label > 2) {
            const formatted = `${label}%`;
            // label
            ctx.fillText(formatted, x - 10, y);
            ctx.font = '14px Inter';
            ctx.fillStyle = '#fff';
          }
        });
      });
    },
  };
  if (panoramaViews && panoramaViews.length === 0) {
    return (
      <div className='empty-analytics'>
        <div className='no-analytics-container'>
          <div className='info-tag-title'>
            <Trans id='Select a date range' />
          </div>
          <div className='controls'>
            <div className='sort-by-date-container'>
              <CustomSelect
                customDropdownId={'analyticsDropdown'}
                value={dateRangeSelected}
                options={[
                  defineMessage({ message: 'This month' }),
                  defineMessage({ message: 'Last month' }),
                  defineMessage({ message: 'This year' }),
                  defineMessage({ message: 'Last year' }),
                ]}
                changeDropdownValue={changeDateRangeHandler}
                showCustomDropDown={showCustomDropDownDateRange}
                setShowCustomDropDown={toggleCustomDropdown}
                customClass={`general-field-input ${showCustomDropDownDateRange ? 'general-field-focus' : ''}`}
              />
            </div>
          </div>
          <div className='title-text-no-analytics'>
            <Trans id='No data to display for the selected time frame' />
          </div>
          <div className='sub-text-no-analytics'>
            <Trans id='Refine your search in order to display and download all stats ' />
          </div>
          <button className='button-no-analytics'>
            <Trans id='Download all stats' />
          </button>
        </div>
        <img src={noAnalytics} className='no-links-state' alt='empty' />
      </div>
    );
  } else {
    return (
      <div className='edit-tour-analytics'>
        <div className='info-tag-title'>
          <Trans id='Select a date range' />
        </div>
        <div className='controls'>
          <div className='sort-by-date-container'>
            <CustomSelect
              customDropdownId={'analyticsDropdown'}
              value={dateRangeSelected}
              options={[
                defineMessage({ message: 'This month' }),
                defineMessage({ message: 'Last month' }),
                defineMessage({ message: 'This year' }),
                defineMessage({ message: 'Last year' }),
              ]}
              changeDropdownValue={changeDateRangeHandler}
              showCustomDropDown={showCustomDropDownDateRange}
              setShowCustomDropDown={toggleCustomDropdown}
              customClass={`general-field-input ${showCustomDropDownDateRange ? 'general-field-focus' : ''}`}
            />
          </div>
        </div>
        <div className='edit-panorama-views' style={{ paddingRight: '20px' }}>
          <div className='item-views-info'>
            <div style={{ flex: 2 }}>
              <div className='info-tag-title'>
                <Trans id='Total views' />
              </div>
            </div>
            <div style={{ flex: 4, marginLeft: '5%' }} className='item-views'>
              <img className='views-eyes' src={eyeViews} alt='Views' />
              <div className='views-number'>
                <div style={{ width: '35px' }} className='text-specs'>
                  {tourViews}
                </div>
                <Trans id='views' />
              </div>
              <div style={{ width: '100%' }}>
                <div style={{ width: '100%' }} className='bar-chart-analytics'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='edit-panorama-views analytics-panorama-views'>
          {panoramaViews?.map((panorama, index) => (
            <div className='item-views-info' style={{ paddingRight: '20px', background: '' }} key={index}>
              <div style={{ flex: 2 }}>
                <div className='tour-item-text-edit text-specs'>{panorama.name}</div>
              </div>
              <div style={{ flex: 4, marginLeft: '5%' }} className='item-views'>
                <img className='views-eyes' src={eyeViews} alt='Views' />
                <div className='views-number'>
                  <div style={{ width: '35px' }} className='text-specs'>
                    {panorama.panoramaViews}
                  </div>
                  <Trans id='views' />
                </div>
                <div style={{ width: '100%' }}>
                  <div
                    style={{ width: getBarWidth(Number(panorama.panoramaViews)), background: '#D50057' }}
                    className='bar-chart-analytics'
                  ></div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {countriesViews && (
          <div className='edit-tour-analytics-doughnut'>
            <div className='info-tag-title'>
              <Trans id='Top 10 countries' />
            </div>
            <div className='display-flex flex-space-between'>
              <div className='countries-views'>
                {countriesViews.map((country: ICountriesViews, index: number) => (
                  <div className='item-views-info-countries' key={index}>
                    <p className='item-text text-specs'>
                      <span
                        style={{ background: index >= colours.length ? colours[index - colours.length] : colours[index] }}
                        className='dot'
                      ></span>
                      {country && country.userCountryName ? country.userCountryName : 'N/A'}
                    </p>
                    <div className='item-views'>
                      <img src={eyeViews} alt='Views' />
                      <div className='views-number'>
                        <div style={{ width: '35px' }} className='text-specs'>
                          {country.viewsFromCountry}
                        </div>
                        <Trans id='views' />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ width: '300px', height: '300px' }}>
                <Doughnut
                  data={countriesDonutData ? countriesDonutData : defaultChartData}
                  height={300}
                  width={300}
                  plugins={[alwaysShowTooltip]}
                  options={{
                    plugins: {
                      tooltip: {
                        enabled: false,
                      },
                      legend: {
                        display: true,
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {devicesViews && (
          <div className='edit-tour-analytics-doughnut'>
            <div className='info-tag-title'>
              <Trans id='Which device' />
            </div>
            <div className='display-flex flex-space-between'>
              <div className='countries-views'>
                {devicesViews.map((device: IDevicesViews, index: number) => (
                  <div className='item-views-info-countries' key={index}>
                    <p className='item-text text-specs'>
                      <span style={{ background: colours[index] }} className='dot'></span>
                      {device && device.device ? device.device : 'N/A'}
                    </p>
                    <div className='item-views'>
                      <img src={eyeViews} alt='Views' />
                      <div className='views-number'>
                        <div style={{ width: '35px' }} className='text-specs'>
                          {device.viewsFromDevice}
                        </div>
                        <Trans id='views' />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ width: '300px', height: '300px' }}>
                <Doughnut
                  data={devicesDonutData ? countriesDonutData : defaultChartData}
                  height={300}
                  width={300}
                  plugins={[alwaysShowTooltip]}
                  options={{
                    plugins: {
                      tooltip: {
                        enabled: false,
                      },
                      legend: {
                        display: true,
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {sourceViews && (
          <div className='edit-tour-analytics-doughnut'>
            <div className='info-tag-title'>
              <Trans id='Top 10 sources' />
            </div>
            <div className='display-flex flex-space-between'>
              <div className='countries-views'>
                {sourceViews.map((source: ISourceViewedFrom, index: number) => (
                  <div className='item-views-info-countries' key={index}>
                    <p className='item-text text-specs'>
                      <span style={{ background: colours[index] }} className='dot'></span>
                      {source && source.source ? source.source.replace(/(^\w+:|^)\/\//, '') : 'N/A'}
                    </p>
                    <div className='item-views'>
                      <img src={eyeViews} alt='Views' />
                      <div className='views-number'>
                        <div style={{ width: '35px' }} className='text-specs'>
                          {source.viewsFromSource}
                        </div>
                        <Trans id='views' />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <Doughnut
                  data={sourcesDonutData ? sourcesDonutData : defaultChartData}
                  height={300}
                  width={300}
                  plugins={[alwaysShowTooltip]}
                  options={{
                    plugins: {
                      tooltip: {
                        enabled: false,
                      },
                      legend: {
                        display: true,
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default Analytics;
