import React from 'react';
import './loading-spinner.scss';

interface IProps {
  loaded?: boolean;
}

const UploadingSpinner: React.FC<IProps> = () => {
  return <div className='uploading-loader'></div>;
};

export default UploadingSpinner;
