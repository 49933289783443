interface ICreateTourBody {
	name: string,
	address: string,
	city: string,
	furnished: number,
	latitude: string,
	longitude: string,
	type: string,
	zip: string,
	country: string,
	shareLocation: boolean,
	id?: string;
	reference?: string,
	number?: number
	cameraHeight?: string;
}

const createTourBody = (tour: any) => {
	const newTour: ICreateTourBody = {
		name: tour.name,
		address: tour.address,
		furnished: tour.furnished === 'Yes' || (typeof tour.furnished == 'number' && tour.furnished) ? 1 : 0,
		latitude: tour.latitude,
		longitude: tour.longitude,
		type: tour.type,
		zip: tour.postcode,
		country: tour.countryCode,
		city: tour.city,
		shareLocation: tour.shareLocation,
		cameraHeight: tour.cameraHeight ? tour.cameraHeight : 145,
	}
	if (tour.reference && tour.reference !== '') {
		newTour.reference = tour.reference;
	}
	if (tour.flatNumber && tour.flatNumber !== '') {
		newTour.number = tour.flatNumber;
	}
	return newTour;
}

export { createTourBody }