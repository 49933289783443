import { defineMessage } from '@lingui/macro';
import { Trans, useLingui } from '@lingui/react';
import React, { useEffect, useState } from 'react';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import CustomSelect from '../../utils/custom-select/custom-select';
import { linguiTranslateToString, setLanguageFromName, setLanguageLocalStorage } from '../../utils/lingui-utils/utils-lingui';
import ModalConfirmApplyAll from './modal-confirm-apply-all';
import { IVTConfig } from './settings';
import './settings.scss';
import { updateConfig } from '../../../api-helper/api-account';

interface IProps {
  virtualTourConfigurationForm: IVTConfig;
  setVirtualTourConfigurationForm: (config: IVTConfig) => void;
  itemModalOpen: boolean;
  onSaveTourConfiguration: (global: boolean) => void;
  toggleModal: (state: boolean) => void;
}

const VirtualTourConfig: React.FC<IProps> = ({
  virtualTourConfigurationForm,
  setVirtualTourConfigurationForm,
  itemModalOpen,
  onSaveTourConfiguration,
  toggleModal,
}) => {
  const [showLanguageCustomDropDown, setShowLanguageCustomDropDown] = useState<boolean>(false);
  const [showMeasurementCustomDropDown, setShowMeasurementCustomDropDown] = useState<boolean>(false);
  const [measurementValue, setMeasurementValue] = useState<string>(linguiTranslateToString('Centimeters'));
  const { i18n } = useLingui();

  const browserLanguage = navigator.language.includes('-') ? navigator.language.split('-')[0] : navigator.language;

  const languageMap: { [key: string]: string } = {
    en: 'English',
    fr: 'Français',
    es: 'Español',
    de: 'German',
    it: 'Italiano',
    ja: '日本人',
  };

  const defaultLanguage = languageMap[browserLanguage] || 'English';
  const [languageValue, setLanguageValue] = useState<string>(virtualTourConfigurationForm.language || defaultLanguage);

  const dynamicActivate = (language: string) => {
    console.log(language, 'LANGUAGE');
    const locales: any = setLanguageFromName(language);
    i18n.load(locales.code, locales.messages);
    i18n.loadLocaleData(locales.code, { plurals: locales.plurals });
    i18n.activate(locales.code);
  };

  useEffect(() => {
    if (virtualTourConfigurationForm) {
      setMeasurementValue(virtualTourConfigurationForm.measurementUnit || linguiTranslateToString('Centimeters'));
      setLanguageValue(virtualTourConfigurationForm.language || defaultLanguage);
    }
  }, [virtualTourConfigurationForm]);

  const toggleMeasurementDropdown = (option?: boolean) => {
    if (option) {
      setShowMeasurementCustomDropDown(option);
    } else {
      setShowMeasurementCustomDropDown(!showMeasurementCustomDropDown);
    }
  };

  const toggleLanguageDropdown = (option?: boolean) => {
    if (option) {
      setShowLanguageCustomDropDown(option);
    } else {
      setShowLanguageCustomDropDown(!showLanguageCustomDropDown);
    }
  };

  const onChangeLanguage = async (option: string) => {
	try {
		setVirtualTourConfigurationForm({ ...virtualTourConfigurationForm, language: option });
		setLanguageValue(option);
		await updateConfig({ language: option }, false);
		setLanguageLocalStorage('Language', option);
		toggleLanguageDropdown();
		dynamicActivate(option);
	} catch (error) {
		console.log(error);
	}    
  };

  const onChangeMeasurement = (option: string) => {
    setVirtualTourConfigurationForm({ ...virtualTourConfigurationForm, measurementUnit: linguiTranslateToString(option) });
    toggleMeasurementDropdown();
  };

  return (
    <div className='settings-information-container'>
      {itemModalOpen && <ModalConfirmApplyAll setShowDeleteModal={toggleModal} applyAll={onSaveTourConfiguration} />}
      <div className='settings-information-layout'>
        <div className='panelhook-container'>
          <div>
            <div>
              <div className='settings-config-block'>
                <div className='settings-config-option'>
                  <div style={{ cursor: 'default' }}>
                    <Trans id='Display location' render={({ translation }) => <div className='field-text'>{translation}</div>} />
                    <Trans
                      id='Enable Google street view and full address'
                      render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                    />
                  </div>
                  <div className='field-input-container'>
                    <label className='switch-settings'>
                      <input
                        id='shareLocation'
                        type='checkbox'
                        checked={virtualTourConfigurationForm.shareLocation}
                        onClick={() => {
                          if (virtualTourConfigurationForm.shareLocation) {
                            //Report Custom Category and Event
                            ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableLocationSharing);
                          } else {
                            //Report Custom Category and Event
                            ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableLocationSharing);
                          }
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setVirtualTourConfigurationForm({
                            ...virtualTourConfigurationForm,
                            shareLocation: e.target.checked === true ? true : false,
                          })
                        }
                      />
                      <span id='slider-share-location' className='slider-settings round-settings'>
                        <span className={`slider-text-${virtualTourConfigurationForm.shareLocation ? 'on' : 'off'}`}>
                          {virtualTourConfigurationForm.shareLocation ? 'ON' : 'OFF'}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
                <div className='settings-config-option'>
                  <div style={{ cursor: 'default' }}>
                    <Trans
                      id='Measurements'
                      render={({ translation }) => (
                        <div className={` ${!showMeasurementCustomDropDown ? 'field-text' : 'field-text-label'}`}>{translation}</div>
                      )}
                    />
                    <Trans
                      id='Select the unit of measurements'
                      render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                    />
                  </div>
                  <div className='field-input-container input-mod'>
                    <CustomSelect
                      value={linguiTranslateToString(measurementValue)}
                      options={[defineMessage({ message: 'Centimeters' }), defineMessage({ message: 'Inches' })]}
                      changeDropdownValue={onChangeMeasurement}
                      showCustomDropDown={showMeasurementCustomDropDown}
                      setShowCustomDropDown={toggleMeasurementDropdown}
                      customZIndex={2}
                      customClass={`${showMeasurementCustomDropDown ? 'general-field-focus' : 'general-field-input'}`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='settings-config-block'>
              <div className='settings-config-option'>
                <div style={{ cursor: 'default' }}>
                  <Trans id='Autostart virtual tours' render={({ translation }) => <div className='field-text'>{translation}</div>} />
                  <Trans
                    id='The rotation of your tours will start automatically'
                    render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                  />
                </div>
                <div className='field-input-container'>
                  <label className='switch-settings'>
                    <input
                      id='autoStartTour'
                      type='checkbox'
                      checked={virtualTourConfigurationForm.autoStartTour}
                      onClick={() => {
                        if (virtualTourConfigurationForm.autoStartTour) {
                          //Report Custom Category and Event
                          ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableAutoStart);
                        } else {
                          //Report Custom Category and Event
                          ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableAutoStart);
                        }
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setVirtualTourConfigurationForm({
                          ...virtualTourConfigurationForm,
                          autoStartTour: e.target.checked === true ? true : false,
                        })
                      }
                    />
                    <span id='slider-auto-start-tour' className='slider-settings round-settings'>
                      <span className={`slider-text-${virtualTourConfigurationForm.autoStartTour ? 'on' : 'off'}`}>
                        {virtualTourConfigurationForm.autoStartTour ? 'ON' : 'OFF'}
                      </span>
                    </span>
                  </label>
                </div>
              </div>
              <div className='settings-config-option'>
                <div style={{ cursor: 'default' }}>
                  <Trans
                    id='Language'
                    render={({ translation }) => (
                      <div className={` ${!showLanguageCustomDropDown ? 'field-text' : 'field-text-label'}`}>{translation}</div>
                    )}
                  />
                  <Trans
                    id='Change language across the platform'
                    render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                  />
                </div>
                <div className='field-input-container input-mod'>
                  <CustomSelect
                    value={languageValue}
                    options={[
                      { id: 'English' },
                      { id: 'Español' },
                      { id: 'Français' },
                      { id: 'German' },
                      { id: 'Italiano' },
                      { id: '日本人' },
                    ]}
                    changeDropdownValue={onChangeLanguage}
                    showCustomDropDown={showLanguageCustomDropDown}
                    setShowCustomDropDown={toggleLanguageDropdown}
                    customZIndex={1}
                    customClass={` ${showLanguageCustomDropDown ? 'general-field-focus' : 'general-field-input'}`}
                  />
                </div>
              </div>
            </div>
            <div className='settings-config-block'>
              <div className='settings-config-option'></div>
              <div className='settings-config-option'>
                <div style={{ cursor: 'default' }}>
                  <Trans id='Google Analytics' render={({ translation }) => <div className='field-text'>{translation}</div>} />
                  <Trans
                    id='Add your own analytics into the platform'
                    render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                  />
                </div>
                <div className='field-input-container input-mod'>
                  <Trans
                    id={'Copy your analytics url here'}
                    render={({ translation }) => (
                      <input
                        id='ga'
                        type='text'
                        name='ga'
                        onClick={() => {
                          //Report Custom Category and Event
                          ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableDisableGoogleAnalyticsIntegration);
                        }}
                        placeholder={String(translation)}
                        value={virtualTourConfigurationForm.ga}
                        className={`general-field-input-text-branding ${virtualTourConfigurationForm.ga !== '' ? 'valid-input' : ''}`}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setVirtualTourConfigurationForm({ ...virtualTourConfigurationForm, ga: e.target.value });
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='save-information-button-container'>
            <div style={{ cursor: 'default' }}>
              <button
                id='apply-all-virtual-tour'
                className='save-information-button button-white button-hover'
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  toggleModal(true);
                }}
              >
                <Trans id='Apply to all' />
              </button>
            </div>
            <div>
              <button
                id='save-btn-virtual-tour-configuration'
                className='save-information-button button-active button-hover'
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  const global = false;
                  onSaveTourConfiguration(global);
                }}
              >
                <Trans id='Save changes' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualTourConfig;
