import React, { useEffect, CSSProperties } from 'react';
import { ReactComponent as CloseButton } from '../../../images/viewer-icons/cross.svg';
// Viewer
import { Viewer } from '@vva/pannellum-viewer';
// import { IViewerConfig } from '@vva/pannellum-viewer/lib/interfaces/IViewerConfig'
import Grid from './grid';
import '../../utils/modal/modal-dashboard.scss';
import { Trans } from '@lingui/react';
import { IViewerConfig } from '@vva/pannellum-viewer/lib/interfaces/IViewerConfig';

interface IProps {
  tour: any;
  currentPanorama: any;
  toggleModal: () => void;
  downloadSingle2D: (panoramaId: string, coordinates: number[]) => void;
}

// Viewer object
let miniViewer: any;

const DownloadSingleModal: React.FC<IProps> = ({ tour, currentPanorama, toggleModal, downloadSingle2D }) => {
  const style: CSSProperties = {
    height: '60vh',
  };

  useEffect(() => {
    // componentDidMount - Initialize Viewer
    if (currentPanorama) {
      initViewer();
    }

    // componentWillUnmount - Destroy Viewer
    return () => {
      miniViewer && miniViewer.destroyViewer();
    };
  }, []);

  const initViewer = () => {
    // code to run on component mount
    const config: IViewerConfig = {
      type: 'equirectangular',
      panorama: currentPanorama.originalUrl,
      autoLoad: true,
      showControls: false,
      crossOrigin: 'anonymous',
      doubleClickZoom: false,
      hfov: 120,
    };
    miniViewer = new Viewer('mini-panorama', config);
  };

  const downloadDynamic2D = async () => {
    const coordinates = miniViewer.getCoordinates();
    coordinates[2] = coordinates[2] - 20;
    downloadSingle2D(currentPanorama.id, coordinates);
  };

  return (
    <div className='editor-modal-container-download'>
      <div className='editor-modal-title'>
        <Trans
          id='Download 2D image for {tourName}'
          values={{ tourName: tour.name }}
          render={({ translation }) => <h1 className='editor-modal-header-title'>{translation}</h1>}
        />
      </div>
      <button onClick={() => toggleModal()} className='close-button btn-remove-default'>
        <CloseButton />
      </button>
      <div>
        <h1 className='edit-modal-subtitle-download'>
          <Trans id='Select the best view for the 2D download' />
        </h1>
        <div className='edit-modal-inside-container-download'>
          {/* Viewer */}
          <div id='mini-panorama' style={style}>
            <Grid />
          </div>
          {/* Viewer */}
        </div>
        <div className='modal-btn-container-download'>
          <button onClick={() => downloadDynamic2D()} className='editor-modal-btn editor-modal-btn-active'>
            <Trans id='Download 2D image' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadSingleModal;
