import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './auth-routes/private-routes';
import Login from './components/general/login/login';
import ResetPassword from './components/general/reset-password/reset-password';
import SignUp from './components/general/signup/signup';
import ActivateAccount from './components/general/activate-account/activate-account';
import ForgotPasswordTest from './components/general/forgot-password/forgot-password';
import Main from './components/main/main';
import TestHealthPage from './ping-test/test-page-health';
import ConfirmAccount from './components/general/confirm-account/confirm-account';
import useScript from './components/main/tour/useScript';
import './scss/style-1.0.1.scss';
import { GoogleAnalyticsInit } from './GoogleAnalyticsConfig';
import { Helmet } from 'react-helmet';
import MobileView from './components/utils/mobile-view/mobile-view';
import { isMobileOnly } from 'react-device-detect';

const App = () => {
  // Set google street view
  const getAddressApiKey = window.ENV.REACT_APP_GOOGLE_MAPS_KEY || process.env.REACT_APP_GOOGLE_MAPS_KEY;

  const googleScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${getAddressApiKey}&callback=Function.prototype`;

  useScript(googleScriptUrl);

  useEffect(() => {
    // Init Google analytics
    GoogleAnalyticsInit();
  }, []);

  const checkUrls = () => {
    if (window.location.pathname.includes('reset-pwd') || window.location.pathname.includes('confirmation')) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Helmet>
        <html lang='en' />
        <title>Vieweet° - 360° Virtual Tours</title>
        <meta
          name='description'
          content='Vieweet° is a 360° platform that simplifies the virtual tour creation process. Create high-quality virtual tours in minutes.'
        />
      </Helmet>

      {checkUrls() && isMobileOnly ? (
        <MobileView />
      ) : (
        <div className='App'>
          <Switch>
            <Route exact path='/login' component={Login} />
            <Route exact path='/reset-pwd' component={ResetPassword} />
            <Route exact path='/forgot-pwd' component={ForgotPasswordTest} />
            <Route exact path='/confirmation' component={ConfirmAccount} />
            <Route exact path='/signup' component={SignUp} />
            <Route exact path='/activate-account' component={ActivateAccount} />
            <Route exact path='/ping' component={TestHealthPage} />
            <PrivateRoute path='/' component={Main} />
          </Switch>
        </div>
      )}
    </>
  );
};

export default App;
