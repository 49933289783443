import React, { useState } from 'react';
import CustomSelect from '../../../utils/custom-select/custom-select';
import { defineMessage } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { ReportCustomEvent } from '../../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../../google-analytics-track-list';
import { IVTConfig } from '../../../main/settings/settings';
import { linguiTranslateToString } from '../../../utils/lingui-utils/utils-lingui';

interface IProps {
  tour: any;
  configSetting: IVTConfig;
  setConfigSetting: (state: IVTConfig) => void;
  onSubmitGeneralConfig: () => void;
  savedChanges: boolean;
}

const TourConfiguration: React.FC<IProps> = ({ tour, configSetting, setConfigSetting, onSubmitGeneralConfig, savedChanges }) => {
  const [showMeasurementCustomDropDown, setShowMeasurementCustomDropDown] = useState<boolean>(false);

  const toggleMeasurementDropdown = (option?: boolean) => {
    if (option) {
      setShowMeasurementCustomDropDown(option);
    } else {
      setShowMeasurementCustomDropDown(!showMeasurementCustomDropDown);
    }
  };

  const onChangeMeasurement = (option: string) => {
    setConfigSetting({ ...configSetting, measurementUnit: linguiTranslateToString(option) });
    toggleMeasurementDropdown();
  };

  return (
    <div className='settings-modal-container'>
      <div className='update-tour-container'>
        <div className='display-flex flex-center flex-column' style={{ gap: '5px', cursor: 'default' }}>
          <div className='settings-choose-logo-text'>
            <Trans id='Change the virtual tour configuration for ' />
            {(tour && tour.name) || ''}.
          </div>
          <div className='logo-tripod-requirements'>
            <Trans id='Choose which options you want to enable or disable.' />
          </div>
        </div>
        <div>
          <div className='viewer-config-container'>
            <div className='settings-config-block-tour'>
              <div className='settings-config-option'>
                <div>
                  <Trans id='Display location' render={({ translation }) => <div className='field-text'>{translation}</div>} />
                  <Trans
                    id='Enable Google street view and full address'
                    render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                  />
                </div>
                <div className='field-input-container'>
                  <label className='switch-settings'>
                    <input
                      id='shareLocation'
                      type='checkbox'
                      checked={configSetting.shareLocation}
                      onClick={() => {
                        if (configSetting.shareLocation) {
                          //Report Custom Category and Event
                          ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableLocationSharing);
                        } else {
                          //Report Custom Category and Event
                          ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableLocationSharing);
                        }
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setConfigSetting({
                          ...configSetting,
                          shareLocation: e.target.checked === true ? true : false,
                        })
                      }
                    />
                    <span id='slider-share-location' className='slider-settings round-settings'>
                      <span className={`slider-text-${configSetting.shareLocation ? 'on' : 'off'}`}>
                        {configSetting.shareLocation ? 'ON' : 'OFF'}
                      </span>
                    </span>
                  </label>
                </div>
              </div>
              <div className='settings-config-option'>
                <div>
                  <Trans
                    id='Measurements'
                    render={({ translation }) => (
                      <div className={` ${!showMeasurementCustomDropDown ? 'field-text' : 'field-text-label'}`}>{translation}</div>
                    )}
                  />
                  <Trans
                    id='Select the unit of measurements'
                    render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                  />
                </div>
                <div className='field-input-container input-mod '>
                  <CustomSelect
                    value={configSetting.measurementUnit === linguiTranslateToString('Centimeters') ? '(cm)' : '(inches)'}
                    options={[defineMessage({ message: 'Centimeters' }), defineMessage({ message: 'Inches' })]}
                    changeDropdownValue={onChangeMeasurement}
                    showCustomDropDown={showMeasurementCustomDropDown}
                    setShowCustomDropDown={toggleMeasurementDropdown}
                    customZIndex={2}
                    customClass={`${showMeasurementCustomDropDown ? 'general-field-focus' : 'general-field-input'}`}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className='settings-config-block-tour'>
                <div className='settings-config-option'>
                  <div>
                    <Trans id='Autostart virtual tours' render={({ translation }) => <div className='field-text'>{translation}</div>} />
                    <Trans
                      id='The rotation of your tours will start automatically'
                      render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                    />
                  </div>
                  <div className='field-input-container'>
                    <label className='switch-settings'>
                      <input
                        id='autoStartTour'
                        type='checkbox'
                        checked={configSetting.autoStartTour}
                        onClick={() => {
                          if (configSetting.autoStartTour) {
                            //Report Custom Category and Event
                            ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.DisableAutoStart);
                          } else {
                            //Report Custom Category and Event
                            ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableAutoStart);
                          }
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setConfigSetting({
                            ...configSetting,
                            autoStartTour: e.target.checked === true ? true : false,
                          })
                        }
                      />
                      <span id='slider-auto-start-tour' className='slider-settings round-settings'>
                        <span className={`slider-text-${configSetting.autoStartTour ? 'on' : 'off'}`}>
                          {configSetting.autoStartTour ? 'ON' : 'OFF'}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
                <div className='settings-config-option'>
                  <div>
                    <Trans id='Google Analytics' render={({ translation }) => <div className='field-text'>{translation}</div>} />
                    <Trans
                      id='Add your own analytics into the platform'
                      render={({ translation }) => <div className='field-subtext'>{translation}</div>}
                    />
                  </div>
                  <div className='field-input-container input-mod'>
                    <Trans
                      id={'Copy your analytics url here'}
                      render={({ translation }) => (
                        <input
                          id='ga'
                          type='text'
                          name='ga'
                          onClick={() => {
                            //Report Custom Category and Event
                            ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableDisableGoogleAnalyticsIntegration);
                          }}
                          placeholder={String(translation)}
                          value={configSetting.ga}
                          className={`general-field-input-text-branding ${configSetting.ga !== '' ? 'valid-input' : ''}`}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfigSetting({ ...configSetting, ga: e.target.value });
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-btn-container-branding-config'>
        {!savedChanges ? (
          <div className='btn-config button-hover' onClick={() => onSubmitGeneralConfig()}>
            <Trans id='Save changes' />
          </div>
        ) : (
          <div className='btn-confirm'>
            <svg width='22' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <g filter='url(#filter0_d_9829_12446)'>
                <path
                  d='M8.59467 14.6036L3.71966 9.51892C3.42678 9.21345 3.42678 8.71815 3.71966 8.41265L4.7803 7.30637C5.07318 7.00087 5.54808 7.00087 5.84096 7.30637L9.125 10.7316L16.159 3.39512C16.4519 3.08965 16.9268 3.08965 17.2197 3.39512L18.2803 4.5014C18.5732 4.80687 18.5732 5.30216 18.2803 5.60767L9.65533 14.6036C9.36242 14.9091 8.88755 14.9091 8.59467 14.6036Z'
                  fill='white'
                />
              </g>
              <defs>
                <filter
                  id='filter0_d_9829_12446'
                  x='0.5'
                  y='0.166016'
                  width='21'
                  height='17.666'
                  filterUnits='userSpaceOnUse'
                  colorInterpolationFilters='sRGB'
                >
                  <feFlood floodOpacity='0' result='BackgroundImageFix' />
                  <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                  <feOffset />
                  <feGaussianBlur stdDeviation='1.5' />
                  <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0' />
                  <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_9829_12446' />
                  <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_9829_12446' result='shape' />
                </filter>
              </defs>
            </svg>
            <Trans id='Saved' />
          </div>
        )}
      </div>
    </div>
  );
};

export default TourConfiguration;
