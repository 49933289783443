import { ClientApi } from '@vva/front';
import { ILeadsGeneratedHeaders, IPanoramaViews, ICountriesViews, IDevicesViews, ITopToursHeaders, ILeadsGeneratedCSVHeaders, ISourceViewedFrom, ILeadsLiveSharingResponse } from './interface/interfaces';

const clientApi = new ClientApi();

// conditional window ENV for testing with jest
clientApi.setHeaders({
	'X-Api-Key': (window.ENV && window.ENV.REACT_APP_API_KEY ? window.ENV.REACT_APP_API_KEY : null) || process.env.REACT_APP_API_KEY,
});

export const getTopToursAnalytics = async (dateRange: string, tourId?: string): Promise<ITopToursHeaders> => {
	const toursData = await clientApi.getDataWithHeaders(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/analytics/topTours?${tourId ? `tourId=${tourId}&` : ''}dateRange=${dateRange}&limit=10`
	);
	return { data: toursData.data.data, headers: toursData.headers };
};

export const getPanoramaViews = async (id: string, dateRange: string): Promise<IPanoramaViews[]> => {
	const panoramaViewsData = await clientApi.getDataWithHeaders(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/analytics/panoramasFromTour?tourId=${id}&dateRange=${dateRange}`
	);
	return panoramaViewsData.data.data;
};

export const getViewsByCountries = async (dateRange: string, tourId?: string): Promise<ICountriesViews[]> => {
	const countriesViewsData = await clientApi.getData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/analytics/topViewedCountries?${tourId ? `tourId=${tourId}&` : ''}dateRange=${dateRange}`);
	return countriesViewsData.data;
};

export const getViewsByDevices = async (dateRange: string, tourId?: string): Promise<IDevicesViews[]> => {
	const devicesViewsData = await clientApi.getData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/analytics/devicesViewedFrom?${tourId ? `tourId=${tourId}&` : ''}dateRange=${dateRange}`);
	return devicesViewsData.data;
};

export const getViewsBySource = async (dateRange: string, tourId?: string): Promise<ISourceViewedFrom[]> => {
	const devicesViewsData = await clientApi.getData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/analytics/sources?${tourId ? `tourId=${tourId}&` : ''}dateRange=${dateRange}`);
	return devicesViewsData.data;
};

export const getLeadsGeneration = async (page: number, dateRange: string, tourId?: string): Promise<ILeadsGeneratedHeaders> => {
	const leadsGenerationData = await clientApi.getDataWithHeaders(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/analytics/leadsGenerated?${tourId ? `tourId=${tourId}&` : ''}dateRange=${dateRange}&limit=10&page=${page + 1}`
	);
	return { data: leadsGenerationData.data.data, headers: leadsGenerationData.headers };
};

export const getLeadsGenerationCSV = async (dateRange: string): Promise<ILeadsGeneratedCSVHeaders> => {
	const response = await clientApi.getDataWithHeaders(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/analytics/leadsGeneratedCSVFile?dateRange=${dateRange}`
	);
	return response;
};

export const getTourStatsCSV = async (dateRange: string): Promise<ILeadsGeneratedCSVHeaders> => {
	const response = await clientApi.getDataWithHeaders(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/analytics/tourAnalyticsCSVFile?dateRange=${dateRange}`
	);
	return response;
};

export const getLiveShareLeads = async (pageNumber: number, dateRange: string, tourOwner: string): Promise<ILeadsLiveSharingResponse> => {
	const response = await clientApi.getDataWithHeaders(
		`${window.ENV.REACT_APP_LIVE_API_URL || process.env.REACT_APP_LIVE_API_URL}/auth/users?page=${pageNumber + 1}&dateRange=${dateRange}&tourOwner=${tourOwner}`
	);
	return response.data;
};

export const getLeadsLiveSharing = async (dateRange: string, id: string): Promise<ILeadsGeneratedCSVHeaders> => {
	const response = await clientApi.getDataWithHeaders(
		`${window.ENV.REACT_APP_LIVE_API_URL || process.env.REACT_APP_LIVE_API_URL}/auth/users/CSVFile?dateRange=${dateRange}&tourOwner=${id}`
	);
	return response;
};