import React from 'react';
import './loading-spinner.scss';

interface IProps {
  loaded?: boolean;
}

const SmallerLoadingSpinner: React.FC<IProps> = ({ loaded }) => {
  return (
    <div className='bg-loader-smaller'>
      <div className='image-loading-spinner-smaller' style={{ display: loaded ? 'none' : 'block' }}>
        <div className='loader-smaller'></div>
      </div>
    </div>
  );
};

export default SmallerLoadingSpinner;
