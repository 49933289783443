import React, { createContext, useState, useContext, useEffect } from 'react';
import { getConfig } from '../../../api-helper/api-account';
import { handleError } from '../../../api-helper/api-error-handling';
import { getUser } from '../../../api-helper/api-user';
import { getUserConfig } from '../../../api-helper/api-user-config';
import { useAuth } from '../../../auth-routes/auth-context';
import { InitUserTracking } from '../../../GoogleAnalyticsConfig';

const AuthContext = createContext<any>({});

const ConfigProvider = (props: object) => {
  const { handleLogoutState } = useAuth();
  const [config, setConfig] = useState<any>();

  // Load user configuration
  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const userInfo = await getUser();
      const userConfig = await getUserConfig();
      const getMeasureUnit = await getConfig();
      let obj = {};
      if (getMeasureUnit) {
        for (const config of getMeasureUnit) {
          const value = config.userValue === '1' ? true : config.userValue;
          if (Object.keys(config.src).length !== 0) {
            obj = { ...obj, [config.src]: value };
          }
        }
      }
      userConfig.userId = userInfo.id;
      userConfig.countryId = userInfo.countryId;
      userConfig.partnerId = userInfo.partnerId;
      InitUserTracking(userInfo.id, userInfo.partnerId, userInfo.email);
      setConfig({
        ...userConfig,
        userEmail: userInfo.internetEmail ? userInfo.internetEmail : userInfo.email,
        userOptions: obj,
        fullName: userInfo.fullName,
        phoneNumber: userInfo.phoneNumber,
      });
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  return <AuthContext.Provider value={config} {...props} />;
};

const useConfig = () => useContext(AuthContext);

export { ConfigProvider, useConfig };
