import React from 'react';
import searchActiveIconSmall from '../../../images/dashboard-icons/ic-search-active-small.svg';
import searchActiveIconSmallDisabled from '../../../images/dashboard-icons/ic-search-active-small-disabled.svg';
import './searchbar.scss';

interface ISearchBarProps {
  placeholder: string;
  searchBarValue: string;
  handlerSetSearchBarValue: (arg: string) => void;
  handlerSearching: (isSearchBarLoaded: boolean, page: number) => void;
  floorplanSearch?: boolean;
  searchFloorPlanDisabled?: boolean;
}

const SearchBar: React.FC<ISearchBarProps> = ({
  placeholder,
  searchBarValue,
  handlerSetSearchBarValue,
  handlerSearching,
  floorplanSearch,
  searchFloorPlanDisabled,
}) => {
  return (
    <div className={floorplanSearch ? 'search-input-container-floor' : 'search-input-container'} id='am-searchbar'>
      <input
        type='text'
        className={`search-input ${searchBarValue !== '' ? 'search-focus' : searchFloorPlanDisabled ? 'search-disabled-floorplan' : ''}`}
        style={
          floorplanSearch
            ? {
                height: '32px',
                width: '75%',
                border: '1px solid rgba(200, 201, 209, 0.7)',
                filter: 'drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25))',
              }
            : {}
        }
        value={searchBarValue}
        placeholder={placeholder}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlerSetSearchBarValue(e.target.value)}
        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            handlerSearching(false, 0);
            e.preventDefault();
          }
        }}
      />
      {floorplanSearch ? (
        <button onClick={() => handlerSearching(false, 0)} className='search-icon-floor'>
          <img src={searchFloorPlanDisabled ? searchActiveIconSmallDisabled : searchActiveIconSmall} alt='Search' />
        </button>
      ) : (
        <button onClick={() => handlerSearching(false, 0)} className='search-icon'>
          <div className='search-state' />
        </button>
      )}
    </div>
  );
};

export default SearchBar;
