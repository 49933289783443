import React, { useEffect } from 'react';
import errorIcon from '../../../images/notification-icons/error-icon.svg';
import successIcon from '../../../images/notification-icons/success-icon.svg';
import infoIcon from '../../../images/notification-icons/info-icon.svg';
import warningIcon from '../../../images/notification-icons/warning-icon.svg';

export enum EnumNotificationType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export enum EnumNotificationSize {
  Medium = 'medium',
  Large = 'large',
}

interface IProps {
  showNotification: boolean;
  type: EnumNotificationType;
  size: EnumNotificationSize;
  message: string;
  setShowNotification: (state: boolean) => void;
  fullScreen?: boolean;
  duration?: number;
}

const Notification: React.FC<IProps> = ({ showNotification, type, size, message, setShowNotification, fullScreen, duration }) => {
  const getBGColour = () => {
    if (type === EnumNotificationType.Error) {
      return '#FADBD8';
    } else if (type === EnumNotificationType.Info) {
      return '#0e0333';
    } else if (type === EnumNotificationType.Warning) {
      return '#F9D1AD';
    } else {
      return '#D1F2EB';
    }
  };

  const getCrossColour = () => {
    if (type === EnumNotificationType.Error) {
      return '#E74C3C';
    } else if (type === EnumNotificationType.Info) {
      return '#ffffff';
    } else if (type === EnumNotificationType.Warning) {
      return '#FF7E00';
    } else {
      return '#1ABC9C';
    }
  };

  const getImageIcon = () => {
    if (type === EnumNotificationType.Error) {
      return errorIcon;
    } else if (type === EnumNotificationType.Info) {
      return infoIcon;
    } else if (type === EnumNotificationType.Warning) {
      return warningIcon;
    } else {
      return successIcon;
    }
  };

  const getHeight = () => {
    if (size === EnumNotificationSize.Large) {
      return '80px';
    } else {
      return '56px';
    }
  };

  useEffect(() => {
    const tempDuration = duration ? duration : 2000;
    if (showNotification) {
      setTimeout(() => setShowNotification(false), tempDuration);
    }
  }, [showNotification]);

  return (
    <div
      style={{
        background: getBGColour(),
        height: getHeight(),
        color: type === EnumNotificationType.Info ? '#fff' : '#000',
        width: fullScreen ? '100%' : '95%',
        marginLeft: fullScreen ? '0' : '80px',
        cursor: 'default',
      }}
      className='notification-container-global'
    >
      <img style={{ width: '24px' }} src={getImageIcon()} alt='icon' />
      {message}
      <button id='close-notification' onClick={() => setShowNotification(false)} className='btn-remove-default notification-cross'>
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M15.6875 0.312522C15.2708 -0.104174 14.5958 -0.104174 14.1791 0.312522L7.99957 6.49202L1.82006 0.312522C1.40337 -0.103463 0.729039 -0.10277 0.312342 0.313217C-0.103643 0.729912 -0.104354 1.40424 0.311648 1.82093L6.49115 8.00044L0.311648 14.1799C-0.104337 14.5966 -0.103644 15.271 0.312342 15.6877C0.729039 16.1036 1.40337 16.1044 1.82006 15.6884L7.99957 9.50885L14.1791 15.6884C14.5958 16.1043 15.2701 16.1036 15.6868 15.6877C16.1028 15.271 16.1035 14.5966 15.6875 14.1799L9.50798 8.00044L15.6875 1.82093C15.8875 1.62092 16 1.34939 16 1.06673C16 0.784066 15.8875 0.512533 15.6875 0.312523L15.6875 0.312522Z'
            fill={getCrossColour()}
          />
        </svg>
      </button>
    </div>
  );
};

export default Notification;
