import React, { useEffect, useState } from 'react';
import { ReactComponent as Blur } from '../../images/viewer-icons/blur.svg';
import { ReactComponent as BlurActive } from '../../images/viewer-icons/blur-active.svg';
import { ReactComponent as Hotspot } from '../../images/viewer-icons/hotspot.svg';
import { ReactComponent as HotspotActive } from '../../images/viewer-icons/hotspot-active.svg';
import { ReactComponent as StillImageIcon } from '../../images/viewer-icons/still-image.svg';
import { ReactComponent as StillImageIconActive } from '../../images/viewer-icons/still-image-active.svg';
import { ReactComponent as AnnotateIcon } from '../../images/viewer-icons/annotate.svg';
import { ReactComponent as AnnotateActive } from '../../images/viewer-icons/annotate-active.svg';
import { ReactComponent as NoteIcon } from '../../images/viewer-icons/note.svg';
import { ReactComponent as NoteIconActive } from '../../images/viewer-icons/note-active.svg';
import { ReactComponent as EditImage } from '../../images/viewer-icons/edit-image.svg';
import { ReactComponent as EditImageActive } from '../../images/viewer-icons/edit-image-active.svg';
import { ReactComponent as LinksIcon } from '../../images/viewer-icons/your-links.svg';
import { ReactComponent as LinksIconActive } from '../../images/viewer-icons/your-links-active.svg';
import { ReactComponent as AudioIconActive } from '../../images/viewer-icons/sound-selected.svg';
import { ReactComponent as AudioIcon } from '../../images/viewer-icons/sound-default.svg';
import { ReportCustomEvent } from '../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../google-analytics-track-list';
import { IPanorama } from '../../api-helper/interface/interfaces';
import { linguiTranslateToString } from '../utils/lingui-utils/utils-lingui';

interface IProps {
  currentPanorama: IPanorama;
  changeLeftSidebar: (option: string) => void;
  leftSidebar: any;
  toggleNewHotspotEditorMode: (state: boolean) => void;
  toggleNewNoteEditorMode: (state: boolean) => void;
  toggleNewImageEditorMode: (state: boolean) => void;
}

const LeftSidebar: React.FC<IProps> = ({
  currentPanorama,
  changeLeftSidebar,
  leftSidebar,
  toggleNewHotspotEditorMode,
  toggleNewNoteEditorMode,
  toggleNewImageEditorMode,
}) => {
  const [panoramas, setPanoramas] = useState<IPanorama>();

  useEffect(() => {
    setPanoramas(currentPanorama);
  }, [currentPanorama]);

  return (
    <React.Fragment>
      {leftSidebar.addHotspot.active && (
        <button
          title={linguiTranslateToString('Add hotspot')}
          id='add-hotspot'
          style={{ opacity: panoramas && panoramas.status < 5 ? '0.2' : '1' }}
          disabled={panoramas && panoramas.status < 5 ? true : false}
          onClick={() => {
            ReportCustomEvent(EnumCategory.ToolBar, EnumEvent.AddLink);
            if (!leftSidebar.addHotspot.status) {
              changeLeftSidebar('addHotspot');
              toggleNewHotspotEditorMode(true);
            } else {
              changeLeftSidebar('moveAround');
            }
          }}
          className='left-toolbar-icon left-toolbar-icon-bg-active'
        >
          {leftSidebar.addHotspot.status ? <HotspotActive /> : <Hotspot />}
        </button>
      )}
      {leftSidebar.blur.active && (
        <button
          title={linguiTranslateToString('Blur panorama')}
          id='add-blur'
          style={{ opacity: panoramas && panoramas.status < 5 ? '0.2' : '1' }}
          disabled={panoramas && panoramas.status < 5 ? true : false}
          onClick={() => {
            ReportCustomEvent(EnumCategory.ToolBar, EnumEvent.AddBlur);
            if (!leftSidebar.blur.status) {
              changeLeftSidebar('blur');
            } else {
              changeLeftSidebar('moveAround');
            }
          }}
          className='left-toolbar-icon left-toolbar-icon-bg-active'
        >
          {leftSidebar.blur.status ? <BlurActive /> : <Blur />}
        </button>
      )}
      {leftSidebar.editImage.active && (
        <button
          title={linguiTranslateToString('Enhance image')}
          id='edit-image'
          style={{ opacity: panoramas && panoramas.status < 5 ? '0.2' : '1' }}
          disabled={panoramas && panoramas.status < 5 ? true : false}
          onClick={() => {
            ReportCustomEvent(EnumCategory.ToolBar, EnumEvent.EnhanceImage);
            if (!leftSidebar.editImage.status) {
              changeLeftSidebar('editImage');
            } else {
              changeLeftSidebar('moveAround');
            }
          }}
          className='left-toolbar-icon left-toolbar-icon-bg-active'
        >
          {leftSidebar.editImage.status ? <EditImageActive /> : <EditImage />}
        </button>
      )}
      {leftSidebar.stillImage.active && (
        <button
          title={linguiTranslateToString('Add image / media')}
          id='add-media'
          style={{ opacity: panoramas && panoramas.status < 5 ? '0.2' : '1' }}
          disabled={panoramas && panoramas.status < 5 ? true : false}
          onClick={() => {
            ReportCustomEvent(EnumCategory.ToolBar, EnumEvent.Add2Dor3DMedia);
            if (!leftSidebar.stillImage.status) {
              changeLeftSidebar('stillImage');
              toggleNewImageEditorMode(true);
            } else {
              changeLeftSidebar('moveAround');
            }
          }}
          className='left-toolbar-icon left-toolbar-icon-bg-active'
        >
          {leftSidebar.stillImage.status ? <StillImageIconActive /> : <StillImageIcon />}
        </button>
      )}
      {leftSidebar.note.active && (
        <button
          title={linguiTranslateToString('Add notes')}
          id='add-notes'
          style={{ opacity: panoramas && panoramas.status < 5 ? '0.2' : '1' }}
          disabled={panoramas && panoramas.status < 5 ? true : false}
          onClick={() => {
            ReportCustomEvent(EnumCategory.ToolBar, EnumEvent.AddNote);
            if (!leftSidebar.note.status) {
              changeLeftSidebar('note');
              toggleNewNoteEditorMode(true);
            } else {
              changeLeftSidebar('moveAround');
            }
          }}
          className='left-toolbar-icon left-toolbar-icon-bg-active'
        >
          {leftSidebar.note.status ? <NoteIconActive /> : <NoteIcon />}
        </button>
      )}
      {leftSidebar.audio.active && (
        <button
          title={linguiTranslateToString('Add audio')}
          id='add-audio'
          style={{ opacity: panoramas && panoramas.status < 5 ? '0.2' : '1', position: 'relative' }}
          disabled={panoramas && panoramas.status < 5 ? true : false}
          onClick={() => {
            ReportCustomEvent(EnumCategory.ToolBar, EnumEvent.AddAudio);
            if (!leftSidebar.audio.status) {
              changeLeftSidebar('audio');
            } else {
              changeLeftSidebar('moveAround');
            }
          }}
          className='left-toolbar-icon left-toolbar-icon-bg-active'
        >
          {leftSidebar.audio.status ? <AudioIconActive /> : <AudioIcon />}
        </button>
      )}
      {leftSidebar.annotation.active && (
        <button
          title={linguiTranslateToString('Add annotation')}
          id='add-annotation'
          style={{ opacity: panoramas && panoramas.status < 5 ? '0.2' : '1' }}
          disabled={panoramas && panoramas.status < 5 ? true : false}
          onClick={() => {
            ReportCustomEvent(EnumCategory.ToolBar, EnumEvent.AddAnnotation);
            if (!leftSidebar.annotation.status) {
              changeLeftSidebar('annotation');
            } else {
              changeLeftSidebar('moveAround');
            }
          }}
          className='left-toolbar-icon left-toolbar-icon-bg-active toolbar-icon-separator'
        >
          {leftSidebar.annotation.status ? <AnnotateActive /> : <AnnotateIcon />}
        </button>
      )}
      {leftSidebar.linksMap.active && (
        <button
          title={linguiTranslateToString('Links map')}
          id='links-map'
          onClick={() => {
            ReportCustomEvent(EnumCategory.ToolBar, EnumEvent.SeeLinkMap);
            if (!leftSidebar.linksMap.status) {
              changeLeftSidebar('linksMap');
            } else {
              changeLeftSidebar('moveAround');
            }
          }}
          className='left-toolbar-icon left-toolbar-icon-bg-active'
        >
          {leftSidebar.linksMap.status ? <LinksIconActive /> : <LinksIcon />}
        </button>
      )}
    </React.Fragment>
  );
};

export default LeftSidebar;
