import { ClientApi } from '@vva/front';

import { ICreateFloorplan, IFloorplanHotspot, IFloorplans } from './interface/interfaces';
import { EnumFloorplanMode } from '../components/utils/tour-views/tour-rows-dollhouse';

const clientApi = new ClientApi();

// conditional window ENV for testing with jest
clientApi.setHeaders({
	'X-Api-Key': (window.ENV && window.ENV.REACT_APP_API_KEY ? window.ENV.REACT_APP_API_KEY : null) || process.env.REACT_APP_API_KEY,
});

export const getFloorplans = async (tourId: string): Promise<IFloorplans[]> => {
	const data = await clientApi.getData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/floorplans/${tourId}/connected/rooms`);
	return data.data;
};

export const deleteFloorplan = async (floorplanId: string): Promise<IFloorplans> => {
	const data = await clientApi.deleteData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/floorplans/${floorplanId}`);
	return data.data;
};

export const deleteFloorplanHotspot = async (hotspotId: string): Promise<IFloorplans> => {
	const data = await clientApi.deleteData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/floorplans/${hotspotId}/hotspot`);
	return data.data;
};

export const getFloorplanHotspots = async (flooorplanId: string): Promise<IFloorplanHotspot[]> => {
	const data = await clientApi.getData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/floorplans/${flooorplanId}/hotspots`);
	return data.data;
};

export const createFloorplan = async (tourId: string): Promise<ICreateFloorplan> => {
	const data = await clientApi.postData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/floorplans`, {
		tourId: tourId,
	});
	return data.data;
};

export const createFloorplanHotspot = async (floorplanHotspot: IFloorplanHotspot): Promise<IFloorplans> => {
	const data = await clientApi.postData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/floorplans/hotspot`, floorplanHotspot);
	return data.data;
};

export const uploadFloorplan = async (file: any, floorplanId: string): Promise<ICreateFloorplan> => {
	var formData = new FormData();
	if (file) {
		formData.append("file", file, file.name);
	}
	const data = await clientApi.postData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/floorplans/${floorplanId}/upload`, formData);
	return data.data;
};

export const downloadFloorplanZip = async (dollhouseTourId: string, mode: EnumFloorplanMode) => {
	const data = await clientApi.getDataWithHeadersBlobResponse(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/floorplanner/${dollhouseTourId}/images?format=png&mode=${mode}`);
	return data;
}