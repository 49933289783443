import React, { useEffect } from 'react';
import './notifications.scss';

interface IProps {
  display: boolean;
  notificationMessage: string;
  toggleSuccessNotification: (message: string) => void;
}

const SuccessNotification: React.FC<IProps> = ({ display, notificationMessage, toggleSuccessNotification }) => {
  useEffect(() => {
    if (display) {
      setTimeout(() => toggleSuccessNotification(''), 3000);
    }
  }, [display]);

  return (
    <div className='info-box' style={{ display: display ? '' : 'none' }}>
      <div className=''>{notificationMessage}</div>
    </div>
  );
};

export default SuccessNotification;
