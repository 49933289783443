import React, { useEffect, useRef, useState } from 'react';
import './dropup-menu.scss';
import { Trans } from '@lingui/react';
import { ReportCustomEvent } from '../../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../../google-analytics-track-list';
import { Link } from 'react-router-dom';

interface IProps {
  tour: any;
  index: number;
  panorama: any;
  previewPanoramaWrapper: any;
  downloadSingle360: (panoramaId: string) => void;
  toggleModal: (panorama: any) => void;
}

const DropUpMenuEditTour = (props: IProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const onOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setOpen(!open);
    ReportCustomEvent(EnumCategory.ThumbnailsBar, EnumEvent.OpenMenu);
  };

  const handleClickOutside = (event: Event) => {
    if (dropdownRef.current && dropdownRef.current.contains(event.target as Node)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const openExternalPanorama = (panoramaReadcode: string) => {
    const standAloneViewerUrl = window.ENV.REACT_APP_STANDALONE_VIEWER_URL || process.env.REACT_APP_STANDALONE_VIEWER_URL;
    const url = standAloneViewerUrl + panoramaReadcode;
    return url ? url : '';
  };

  return (
    <div ref={dropdownRef} className='menu-container-edit'>
      <div id='three-dot-menu-slider' className='btn-dots' onClick={onOpen} />
      <div style={{ left: 70 }} className={`menu ${open ? 'active' : 'inactive'}`}>
        <div className='custom-context-menu-edit'>
          <Link
            to={{
              pathname: `/editing-viewer/`,
              search: `?tourId=${props.tour.id}&panoramaId=${props.panorama.id}`,
              state: { tour: props.tour, displayEditTour: true },
            }}
          >
            <div className='right-options remove-default'>
              <div className='right-options-icons-edit go-to-studio' />
              <Trans id='Go to image studio' render={({ translation }) => <p className='right-options-text'>{translation}</p>} />
            </div>
          </Link>
          <a target='_blank' href={openExternalPanorama(props.panorama.readCode)} rel='noreferrer'>
            <div className='right-options remove-default'>
              <div className='right-options-icons-edit view-image' />
              <Trans id='View image' render={({ translation }) => <p className='right-options-text'>{translation}</p>} />
            </div>
          </a>
          <div className='right-options remove-default' onClick={() => props.downloadSingle360(props.panorama.id)}>
            <div className='right-options-icons-edit download360' />
            <Trans id='Download 360' render={({ translation }) => <p className='right-options-text'>{translation}</p>} />
          </div>
          <div className='right-options remove-default' onClick={() => props.toggleModal(props.panorama)}>
            <div className='right-options-icons-edit download2d' />
            <Trans id='Download 2D' render={({ translation }) => <p className='right-options-text'>{translation}</p>} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropUpMenuEditTour;
