import React, { useEffect, useRef, useState } from 'react';
import './dropup-menu.scss';
import { Trans } from '@lingui/react';
import { ReportCustomEvent } from '../../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../../google-analytics-track-list';

interface IProps {
  index: number;
  panoramaId: string;
  setAsStartingImage: (index: number) => void;
  deletePanorama: (panoramaId: string) => void;
  onSetView: (panoramaId: string) => void;
  previewPanoramaWrapper: any;
  position: number;
  processing?: boolean;
}

const DropUpMenu = (props: IProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const onOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setOpen(!open);
    ReportCustomEvent(EnumCategory.ThumbnailsBar, EnumEvent.OpenMenu);
  };

  const handleClickOutside = (event: Event) => {
    if (dropdownRef.current && dropdownRef.current.contains(event.target as Node)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const onSetStartingImage = () => {
    ReportCustomEvent(EnumCategory.Menu, EnumEvent.SetAsStartingImage);
    props.setAsStartingImage(props.index);
    setOpen(!open);
  };

  const onDeletePanorama = () => {
    ReportCustomEvent(EnumCategory.Menu, EnumEvent.DeleteImage);
    props.deletePanorama(props.panoramaId);
    setOpen(!open);
  };

  const setStartingPoint = () => {
    ReportCustomEvent(EnumCategory.Menu, EnumEvent.SelectStartingPoint);
    props.onSetView(props.panoramaId);
    setOpen(!open);
  };

  return (
    <div className='container'>
      <div ref={dropdownRef} className='menu-container'>
        <div id='three-dot-menu-slider' className='btn-dots' onClick={onOpen} />
        <div style={{ left: 70 - props.position }} className={`menu ${open ? 'active' : 'inactive'}`}>
          <div className='custom-context-menu'>
            <div className='right-options remove-default' onClick={() => onSetStartingImage()}>
              <div className='right-options-icons start' />
              <Trans id='Starting image' render={({ translation }) => <p className='right-options-text'>{translation}</p>} />
            </div>
            {props.processing ? (
              <div className='right-options-disabled remove-default' style={{ opacity: '0.2' }}>
                <div className='right-options-icons view' />
                <Trans id='Select best view' render={({ translation }) => <p className='right-options-text'>{translation}</p>} />
              </div>
            ) : (
              <div className='right-options remove-default' onClick={() => setStartingPoint()}>
                <div className='right-options-icons view' />
                <Trans id='Select best view' render={({ translation }) => <p className='right-options-text'>{translation}</p>} />
              </div>
            )}
            <div className='right-options remove-default' onClick={() => onDeletePanorama()}>
              <div className='right-options-icons delete' />
              <Trans id='Delete image' render={({ translation }) => <p className='right-options-text'>{translation}</p>} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropUpMenu;
